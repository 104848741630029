import React, { useState, useEffect } from 'react'

import moment from 'moment'
import Countdown from 'react-countdown'

interface PropType {
  timeToSale: EpochTimeStamp
  blockTimeStamp: EpochTimeStamp
  nextSaleStatus?: string
  nextFunction?: any
  showCountdownComplete?: boolean | true
}

const MintCountdown = (props: PropType): JSX.Element => {
  const { timeToSale, blockTimeStamp, nextSaleStatus, showCountdownComplete } = props
  const [preSaleDateTime, setPreSaleDateTime] = useState('')
  const [displayCountdown, setDisplayCountdown] = useState(false)

  useEffect(() => {
    if (timeToSale) {
      const nowMoment = moment(new Date()) // todays date
      const now = blockTimeStamp
      const end = moment.unix(timeToSale)
      const duration = moment.duration(end.diff(now), 'seconds')
      const diff = duration.asSeconds()

      // setPreSaleCountdown(diff)
      setPreSaleDateTime(end.format())
      if (diff > 1) {
        setDisplayCountdown(true)
      }
    }
  }, [timeToSale, blockTimeStamp])

  // Next to reload component
  const CountdownCompleted = () => <span>{showCountdownComplete && <>Sale starting next block!</>}</span>

  // Renderer callback with condition
  const renderer = ({
    days,
    hours,
    minutes,
    seconds,
    completed,
  }: {
    days: number
    hours: number
    minutes: number
    seconds: number
    completed: boolean
  }) => {
    if (completed) {
      // Render a completed state
      return <CountdownCompleted />
    } else {
      // Render a countdown
      return (
        <span>
          {days > 0 ? `${days} Days ` : ''}
          {hours > 0 ? `${hours} Hours ` : ''}
          {minutes > 0 ? `${minutes} Mins ` : ''}
          {`${seconds} Secs`}
        </span>
      )
    }
  }

  if (!displayCountdown) {
    return <></>
  }

  if (!timeToSale) {
    return (
      <div className='loading--section'>
        <h1>Countdown Loading...</h1>
      </div>
    )
  }

  return (
    <>
      {displayCountdown && (
        <div className='text-center'>
          <h4>
            {nextSaleStatus ? `${nextSaleStatus}:` : ''} {moment(preSaleDateTime).format('MMMM Do YYYY, h:mm:ss a')}
          </h4>
          <br />
          <h4>
            <Countdown date={preSaleDateTime} renderer={renderer} />
          </h4>
          <br />
        </div>
      )}
    </>
  )
}

export default MintCountdown
