import React, { useContext, useState } from 'react'
import numbro from 'numbro'

import { useContracts } from '../../hooks/useContracts'

import { useAccount, useBalance } from 'wagmi'

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

const UserEggBalance = (): JSX.Element => {
  const ContractAddresses = useContracts()

  const { address, connector: activeConnector, isConnected } = useAccount()

  const [userEGGBalance, setUserEGGBalance] = useState('0')

  useBalance({
    addressOrName: address,
    token: ContractAddresses.EGGTokenContractAddress,

    watch: true,
    // cacheTime: 30_000,
    onSuccess(data) {
      setUserEGGBalance(data.formatted)
    },
    onError(err) {
      console.log(`🚀 ~ userEGGBalance err`, err)
    },
  })

  const formattedBalance = numbro(userEGGBalance).format({
    average: true,
    mantissa: 3,
  })

  if (!userEGGBalance) {
    return (
      <div className='loading--section'>
        <h1>Balance Loading...</h1>
      </div>
    )
  }

  return (
    <>
      <p>EGG: </p>
      <p>{formattedBalance}</p>
    </>
  )
}

export default UserEggBalance
