import React, { useContext, useEffect, useState } from 'react'

import MintProgressBar from '../../components/MintProgressBar'

import MintFarmAnimals from '../../components/MintMain'
import MintCountdown from '../../components/MintCountdown'
import SpecialMintRewards from '../../components/SpecialMintRewards'

import { useAccount, useBalance, useBlockNumber, useContractReads, useProvider } from 'wagmi'
import { ethers } from 'ethers'
import { TFGSalesDataProps } from '../../types'

import numbro from 'numbro'

import { useFarmAnimalsData } from '../../hooks/useFarmAnimalsData'
import ButtonContractWrite from '../../components/ButtonContractWrite'
import { Col, Form, Row } from 'react-bootstrap'
import { useContractABIs } from '../../hooks/useContractsABI'
import { useContracts } from '../../hooks/useContracts'

const AdminPage = (): JSX.Element => {
  const ContractAddresses = useContracts()
  const ContractABIs = useContractABIs()

  const { address } = useAccount()

  const provider = useProvider()

  const [TFGSalesData, setTFGSalesData] = useState<TFGSalesDataProps>({
    saleStatus: 'paused',
    preSaleTime: 0,
    allowListTime: 0,
    preSaleFee: 0,
    preSaleStakeFee: 0,
    preSaleTokens: 0,
    preSaleMaxQty: 0,
    publicTime: 0,
    publicFee: 0,
    publicStakeFee: 0,
    publicMaxPerTx: 0,
    eggMintFee: 0,
    totalSupply: 0,
    maxSupply: 0,
    maxGen0Supply: 0,
  })

  // get live Sales status

  const [isOwner, setIsOwner] = useState(false)

  const theFarmGameMintConfig = {
    address: ContractAddresses.TheFarmGameMintContractAddress,
    abi: ContractABIs.TheFarmGameMintABI,
  }

  const farmAnimalsConfig = {
    address: ContractAddresses.FarmAnimalsContractAddress,
    abi: ContractABIs.FarmAnimalsABI,
  }

  const [devWalletEGGBalances, setDevWalletEGGBalances] = useState('DW EGG: 0')
  const [FAWalletETHBalances, setFAWalletETHBalances] = useState('FA ETH: 0')
  const [theHenHouseEGGBalances, setHenHouseEGGBalances] = useState('HH EGG: 0')
  const [tfgMintWalletETHBalances, setTFGMintWalletETHBalances] = useState('TFGM ETH: 0')
  const [SMWalletETHBalances, setSMWalletETHBalances] = useState('SM ETH: 0')

  useBalance({
    addressOrName: '0xa0ee7a142d267c1f36714e4a8f75612f20a79720',
    token: ContractAddresses.EGGTokenContractAddress,
    onSuccess(data) {
      console.log(`🚀 ~ devwallet data`, data.formatted)
      const numbroFormatted = numbro(data.formatted).format({
        average: true,
        mantissa: 2,
      })
      setDevWalletEGGBalances(`${data.symbol}: ${numbroFormatted}`)
    },
  })

  useBalance({
    addressOrName: ContractAddresses.TheFarmGameMintContractAddress,
    // token: ContractAddresses.EGGTokenContractAddress,
    onSuccess(data) {
      console.log(`🚀 ~ TFG Mint wallet data`, data.formatted)
      const numbroFormatted = numbro(data.formatted).format({
        average: true,
        mantissa: 2,
      })
      setTFGMintWalletETHBalances(`${data.symbol}: ${numbroFormatted}`)
    },
  })

  useBalance({
    addressOrName: ContractAddresses.FarmAnimalsContractAddress,
    // token: ContractAddresses.EGGTokenContractAddress,
    onSuccess(data) {
      console.log(`🚀 ~ FarmAnimals wallet data`, data.formatted)
      const numbroFormatted = numbro(data.formatted).format({
        average: true,
        mantissa: 2,
      })
      setFAWalletETHBalances(`${data.symbol}: ${numbroFormatted}`)
    },
  })

  useBalance({
    addressOrName: ContractAddresses.SpecialMintContractAddress,
    // token: ContractAddresses.EGGTokenContractAddress,
    onSuccess(data) {
      console.log(`🚀 ~ Special Mint wallet data`, data.formatted)
      const numbroFormatted = numbro(data.formatted).format({
        average: true,
        mantissa: 2,
      })
      setSMWalletETHBalances(`${data.symbol}: ${numbroFormatted}`)
    },
  })

  useBalance({
    addressOrName: ContractAddresses.HenHouseContractAddress,
    token: ContractAddresses.EGGTokenContractAddress,
    onSuccess(data) {
      const numbroFormatted = numbro(data.formatted).format({
        average: true,
        mantissa: 2,
      })
      setHenHouseEGGBalances(`${data.symbol}: ${numbroFormatted}`)
    },
  })

  const tokenId = 1

  const henHouseConfig = {
    address: ContractAddresses.HenHouseContractAddress,
    abi: ContractABIs.HenHouseABI,
  }

  const henHouseCalcConfig = {
    address: ContractAddresses.HenHouseCalcContractAddress,
    abi: ContractABIs.HenHouseCalcABI,
  }

  const farmAnimalsTraitsConfig = {
    address: ContractAddresses.FarmAnimalsTraitsContractAddress,
    abi: ContractABIs.FarmAnimalsTraitsABI,
  }

  // Get users farmAnimals balance

  const [rescuePoolEggAmount, setRescuePoolEggAmount] = useState(0)

  useContractReads({
    contracts: [
      { ...theFarmGameMintConfig, functionName: 'getSaleStatus' },
      { ...theFarmGameMintConfig, functionName: 'preSaleTime' },
      { ...theFarmGameMintConfig, functionName: 'allowListTime' },
      { ...theFarmGameMintConfig, functionName: 'preSaleFee' },
      { ...theFarmGameMintConfig, functionName: 'preSaleStakeFee' },
      { ...theFarmGameMintConfig, functionName: 'preSaleTokens' },
      { ...theFarmGameMintConfig, functionName: 'publicTime' },
      { ...theFarmGameMintConfig, functionName: 'publicFee' },
      { ...theFarmGameMintConfig, functionName: 'publicStakeFee' },
      { ...theFarmGameMintConfig, functionName: 'publicMaxPerTx' },
      { ...theFarmGameMintConfig, functionName: 'currentPriceToMint' },
      { ...farmAnimalsConfig, functionName: 'totalSupply' },
      { ...farmAnimalsConfig, functionName: 'maxSupply' },
      { ...farmAnimalsConfig, functionName: 'maxGen0Supply' },
      { ...theFarmGameMintConfig, functionName: 'owner' },
      { ...theFarmGameMintConfig, functionName: 'mintCost', args: [tokenId] },
      { ...theFarmGameMintConfig, functionName: 'mintCostNew', args: [tokenId] },
      { ...theFarmGameMintConfig, functionName: 'getSaleStatusNew' },
      { ...theFarmGameMintConfig, functionName: 'getSaleStatusNewNew' },
      { ...farmAnimalsConfig, functionName: 'getCurrentStep' },
      { ...farmAnimalsConfig, functionName: 'getCurrentStepNew' },
      { ...farmAnimalsConfig, functionName: 'getGeneration', args: [tokenId] },
      { ...farmAnimalsConfig, functionName: 'minted' },
      { ...farmAnimalsConfig, functionName: 'maxGen0Supply' },
      { ...farmAnimalsConfig, functionName: 'ownerOf', args: [tokenId] },
      { ...henHouseConfig, functionName: 'rescuedEggPool' },
      { ...farmAnimalsConfig, functionName: 'contractURI' },
      { ...farmAnimalsConfig, functionName: 'royaltyInfo', args: [tokenId, ethers.utils.parseEther('1.0')] },
      { ...farmAnimalsTraitsConfig, functionName: 'metadataDescription' },
    ],
    watch: true,
    onSuccess(data) {
      console.log('adminPage newFunc tfgContractData Success', data)

      setTFGSalesData((prevState) => ({
        ...prevState, // keep all other key-value pairs
        saleStatus: data[0] ? data![0].toString() : 'paused', // update the value of specific key
        preSaleTime: data[1] ? parseInt(data![1].toString()) : 0,
        allowListTime: data[2] ? parseInt(data![2].toString()) : 0,
        preSaleFee: data[3] ? parseFloat(ethers.utils.formatEther(data![3])) : 0,
        preSaleStakeFee: data[4] ? parseFloat(ethers.utils.formatEther(data![4])) : 0,
        preSaleTokens: data[5] ? parseInt(data![5].toString()) : 0,
        publicTime: data[6] ? parseInt(data![6].toString()) : 0,
        publicFee: data[7] ? parseFloat(ethers.utils.formatEther(data![7])) : 0,
        publicStakeFee: data[8] ? parseFloat(ethers.utils.formatEther(data![8])) : 0,
        publicMaxPerTx: data[9] ? parseInt(data![9].toString()) : 0,
        eggMintFee: data[10] ? parseFloat(ethers.utils.formatEther(data![10])) : 0,
        totalSupply: data[11] ? parseInt(data![11].toString()) : 0,
        maxSupply: data[12] ? parseInt(data![12].toString()) : 0,
        maxGen0Supply: data[13] ? parseInt(data![13].toString()) : 0,
      }))
      if (data[13]) {
        console.log('adminPage.tsx owner address', address)
        console.log('adminPage.tsx owner data[14]', data[14])
        if (address?.toLowerCase() === data[14].toString().toLowerCase()) {
          setIsOwner(true)
        }
      }
      const mintCost = data[15] ? parseFloat(ethers.utils.formatEther(data![15])) : 0
      console.log(`🚀 ~ newFunc mintCost tokenId: ${tokenId}`, mintCost)
      const mintCostNew = data[16] ? parseFloat(ethers.utils.formatEther(data![16])) : 0
      console.log(`🚀 ~ newFunc mintCostNew tokenId: ${tokenId}`, mintCostNew)

      console.log(`🚀 ~ newFunc getSaleStatus`, data[0])
      console.log(`🚀 ~ newFunc getSaleStatusNew`, data[17])
      console.log(`🚀 ~ newFunc getSaleStatusNewNew`, data[18])

      console.log(`🚀 ~ newFunc getCurrentStep`, data[19])
      console.log(`🚀 ~ newFunc getCurrentStepNew`, data[20])

      console.log(`🚀 ~ newFunc getGeneration tokenId: ${tokenId}`, data[21])
      console.log(`🚀 ~ newFunc getGeneration minted:`, data[22])
      console.log(`🚀 ~ newFunc getGeneration maxGen0Supply:`, data[23])
      console.log(`🚀 ~ newFunc ownerOf tokenId: ${tokenId}`, data[24])
      const rescuePoolBalance = parseFloat(ethers.utils.formatUnits(data[25]))
      // setRescuePoolEggAmount(Math.round(rescuePoolBalance * 100) / 100)
      console.log(`🚀 ~ newFunc royaltyInfo tokenId: ${tokenId}`, data![27][1].toString())

      setRescuePoolEggAmount(rescuePoolBalance)
    },
    onError(error) {
      console.error('adminPage.tsx useContractReads Error', error)
    },
  })

  const [blockNumber, setBlockNumber] = useState(0)
  const [blockTimeStamp, setBlockTimeStamp] = useState(0)

  useBlockNumber({
    onBlock(blockNumber) {
      console.log('adminPage useBlockNumber blocknumber2: ', blockNumber)
      setBlockNumber(blockNumber)
    },
  })

  useEffect(() => {
    const fetchBlockInfo = async () => {
      if (blockNumber > 0) {
        const blockInfo = await provider.getBlock(blockNumber)
        console.log('adminPage useBlockNumber blockInfo', blockInfo.timestamp)
        setBlockTimeStamp(blockInfo.timestamp)
      }
    }

    fetchBlockInfo().catch(console.error)
  }, [blockNumber, provider])

  const [tokenCheckId, setTokenCheckId] = useState('')
  const handleTokenId = (event: string) => {
    console.log('newTokenId value is:', event)
    if (event.length > 0) {
      setTokenCheckId(event)
    }
  }

  const [faMetadataDesc, setFaMetadataDesc] = useState('')
  const handleFATDesc = (event: string) => {
    console.log('setFaMetadataDesc value is:', event)
    if (event.length > 0) {
      setFaMetadataDesc(event)
    }
  }

  console.log(`🚀 ~ adminPage TFGData`, TFGSalesData)

  console.log(`🚀 ~ adminPage isOwner`, isOwner)

  if (!TFGSalesData || !isOwner) {
    return (
      <div className='loading--section'>
        <h1>No Access</h1>
      </div>
    )
  }

  return (
    <div>
      <section className='mint--section'>
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='egg-shop__head'>
              <h1>Admin</h1>
              <h4>Hen House: {theHenHouseEGGBalances}</h4>
              <h4>Dev: {devWalletEGGBalances}</h4>
              <h4>FA: {FAWalletETHBalances}</h4>
              <h4>TFG Mint: {tfgMintWalletETHBalances}</h4>
              <h4>SM: {SMWalletETHBalances}</h4>
              <h4>Rescue Pool: {rescuePoolEggAmount}</h4>
            </div>
            <div className='row'>
              <div className='col-lg-12'>
                <Row>
                  <Col xs={10} md={4}>
                    <Form.Control
                      style={{ width: '200px', borderRadius: 0 }}
                      type='text'
                      placeholder='Wallet'
                      onChange={(e) => {
                        handleTokenId(e.target.value)
                      }}
                    />
                  </Col>
                  <Col xs={8} md={4}>
                    <ButtonContractWrite
                      style={{
                        fontSize: '14px',
                        height: '35px',
                        lineHeight: 'normal',
                        paddingTop: '10px',
                        marginBottom: '5px',
                      }}
                      ContractAddress={ContractAddresses.TheFarmGameMintContractAddress}
                      ContractABI={ContractABIs.TheFarmGameMintABI}
                      ContractFunction='withdraw'
                      ContractArgs={[tokenCheckId]}
                      ContractOverRides={''}
                      ContractIsEnabled={tokenCheckId.length > 0}
                      ButtonTextPrepareIdle={`Withdraw idle`}
                      ButtonTextPrepareLoading={`Preparing`}
                      ButtonTextInitial={`Withdraw`}
                      ButtonTextLoading={'Waiting for approval'}
                      ButtonTextWriting={'Writing...'}
                      ButtonTextError={"Didn't work"}
                      StartAlertText={'Checking!'}
                      StartAlertOptions={{ icon: '📱' }}
                      SuccessAlertText={'Success!'}
                      SuccessAlertOptions={{ icon: '🔖' }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={10} md={4}>
                    <Form.Control
                      style={{ width: '200px', borderRadius: 0 }}
                      as='textarea'
                      placeholder='FAT Desc'
                      onChange={(e) => {
                        handleFATDesc(e.target.value)
                      }}
                    />
                  </Col>
                  <Col xs={8} md={4}>
                    <ButtonContractWrite
                      style={{
                        fontSize: '14px',
                        height: '35px',
                        lineHeight: 'normal',
                        paddingTop: '10px',
                        marginBottom: '5px',
                      }}
                      ContractAddress={ContractAddresses.FarmAnimalsTraitsContractAddress}
                      ContractABI={ContractABIs.FarmAnimalsTraitsABI}
                      ContractFunction='updateMetadataDesc'
                      ContractArgs={[faMetadataDesc]}
                      ContractOverRides={''}
                      ContractIsEnabled={faMetadataDesc.length > 10}
                      ButtonTextPrepareIdle={`Prepare idle`}
                      ButtonTextPrepareLoading={`Preparing`}
                      ButtonTextInitial={`updateMetadataDesc`}
                      ButtonTextLoading={'Waiting for approval'}
                      ButtonTextWriting={'Writing...'}
                      ButtonTextError={"Didn't work"}
                      StartAlertText={'Checking!'}
                      StartAlertOptions={{ icon: '📱' }}
                      SuccessAlertText={'Success!'}
                      SuccessAlertOptions={{ icon: '🔖' }}
                    />
                  </Col>
                </Row>
                <ButtonContractWrite
                  ContractAddress={ContractAddresses.TheFarmGameMintContractAddress}
                  ContractABI={ContractABIs.TheFarmGameMintABI}
                  ContractFunction='addCommitRandom'
                  ContractArgs={[29]}
                  ContractOverRides={{}}
                  ContractIsEnabled={true}
                  ButtonTextPrepareIdle={`Check for Hens`}
                  ButtonTextPrepareLoading={`Preparing`}
                  ButtonTextInitial={`Add Commit Rand`}
                  ButtonTextLoading={'Waiting for approval'}
                  ButtonTextWriting={'Updating Rand...'}
                  ButtonTextError={"Couldn't Update Rand"}
                  StartAlertText={'Calculating numbers!'}
                  StartAlertOptions={{ icon: '🥚' }}
                  SuccessAlertText={'Rand updated!'}
                  SuccessAlertOptions={{ icon: '🥚' }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default AdminPage
