import React, { useContext, useEffect, useState } from 'react'

import { Link } from 'react-router-dom'
import { useAccount } from 'wagmi'
import { ethers } from 'ethers'

import { EggShopItemDataProps, EggShopTokenTypeProps, SpecialMintsDataProps } from '../../types'
import { specialMintData } from '../../constants'

import ButtonContractWrite from '../ButtonContractWrite'
import { ConnectButton } from '@rainbow-me/rainbowkit'

import TFGDataContext from '../../Contexts/TFGData'
import EGGBalanceContext from '../../Contexts/EGGBalance'

import ApplePie from '../ApplePie'
import { useQuery } from 'urql'
import { useContractABIs } from '../../hooks/useContractsABI'
import { useContracts } from '../../hooks/useContracts'

import RancherScramblingEggsImage from '../../assets/images/minting_animations/rancher_scrambling_eggs.gif'

const EggShop = (): JSX.Element => {
  const ContractAddresses = useContracts()
  const TFGSalesData = useContext(TFGDataContext)
  const EGGBalance = useContext(EGGBalanceContext)
  const ContractABIs = useContractABIs()

  const { isConnected } = useAccount()

  const [imperialEggTokenMetadata, setImperialEggTokenMetadata] = useState<EggShopTokenTypeProps>()
  const [imperialEggsTotalSupply, setImperialEggsTotalSupply] = useState(0)
  const [imperialEggsMaxSupply, setImperialEggsMaxSupply] = useState(0)

  const [bronzeEggInfo, setBronzeEggInfo] = useState<EggShopItemDataProps>()

  const [goldenEggInfo, setGoldenEggInfo] = useState<EggShopItemDataProps>()

  const [platinumEggInfo, setPlatinumEggInfo] = useState<EggShopItemDataProps>()

  const [rainbowEggInfo, setRainbowEggInfo] = useState<EggShopItemDataProps>()

  const [silverEggInfo, setSilverEggInfo] = useState<EggShopItemDataProps>()

  // Get users special mint info
  const [specialMintsDataInfo, setSpecialMintsDataInfo] = useState<SpecialMintsDataProps[]>()

  const urqlQuery = `
	query eggShopInfo($eggShopContract: String, $imperialEggsContract: String) {
		eggShopItems: nfttokens(first: 10, where: {contract: $eggShopContract}) {
			id
			tokenId
			name
			maxSupply
			eggMintAmt
			eggBurnAmt
			mints
			burns
			tokenURI
		}
		imperialEggs: nfttokens(first: 1, where: {contract: $imperialEggsContract}) {
			id
			tokenId
			name
			maxSupply
			eggMintAmt
			eggBurnAmt
			mints
			burns
			contract {
				totalSupply
				maxSupply
			}
			tokenURI
		}
		specialMints(first: 10) {
			id
			typeId
			maxSupply
			minted
			specialMintFee
		}
	}
`

  // useQuery urql

  const [result, reexecuteQuery] = useQuery({
    query: urqlQuery,
    variables: {
      eggShopContract: ContractAddresses.EggShopContractAddress.toLowerCase(),
      imperialEggsContract: ContractAddresses.ImperialEggsContractAddress.toLowerCase(),
    },
    // pause: loadingCompleted,
  })

  const { data: urqlQueryData, fetching: urqlQueryFetching, error: urqlQueryError } = result

  useEffect(() => {
    if (urqlQueryData) {
      if (urqlQueryData.specialMints) {
        setSpecialMintsDataInfo(urqlQueryData.specialMints)
      }
      if (urqlQueryData.eggShopItems) {
        const eggShopData = urqlQueryData.eggShopItems

        const eggShopTokenTypeData2 = eggShopData[1].tokenURI.split(';base64,').pop() as string
        const eggShopMetadata2: EggShopTokenTypeProps = JSON.parse(atob(eggShopTokenTypeData2))
        setBronzeEggInfo({
          name: eggShopData[1].name,
          mints: eggShopData[1].mints,
          burns: eggShopData[1].burns,
          maxSupply: eggShopData[1].maxSupply,
          eggMintAmt: parseFloat(ethers.utils.formatEther(eggShopData[1].eggMintAmt)),
          eggBurnAmt: parseFloat(ethers.utils.formatEther(eggShopData[1].eggBurnAmt)),
          background: eggShopData[1].background,
          metadata: eggShopMetadata2,
        })

        const eggShopTokenTypeData4 = eggShopData[3].tokenURI.split(';base64,').pop() as string
        const eggShopMetadata4: EggShopTokenTypeProps = JSON.parse(atob(eggShopTokenTypeData4))
        setGoldenEggInfo({
          name: eggShopData[3].name,
          mints: eggShopData[3].mints,
          burns: eggShopData[3].burns,
          maxSupply: eggShopData[3].maxSupply,
          eggMintAmt: parseFloat(ethers.utils.formatEther(eggShopData[3].eggMintAmt)),
          eggBurnAmt: parseFloat(ethers.utils.formatEther(eggShopData[3].eggBurnAmt)),
          background: eggShopData[3].background,
          metadata: eggShopMetadata4,
        })

        const eggShopTokenTypeData5 = eggShopData[4].tokenURI.split(';base64,').pop() as string
        const eggShopMetadata5: EggShopTokenTypeProps = JSON.parse(atob(eggShopTokenTypeData5))
        setPlatinumEggInfo({
          name: eggShopData[4].name,
          mints: eggShopData[4].mints,
          burns: eggShopData[4].burns,
          maxSupply: eggShopData[4].maxSupply,
          eggMintAmt: parseFloat(ethers.utils.formatEther(eggShopData[4].eggMintAmt)),
          eggBurnAmt: parseFloat(ethers.utils.formatEther(eggShopData[4].eggBurnAmt)),
          background: eggShopData[4].background,
          metadata: eggShopMetadata5,
        })

        const eggShopTokenTypeData6 = eggShopData[5].tokenURI.split(';base64,').pop() as string
        const eggShopMetadata6: EggShopTokenTypeProps = JSON.parse(atob(eggShopTokenTypeData6))
        setRainbowEggInfo({
          name: eggShopData[5].name,
          mints: eggShopData[5].mints,
          burns: eggShopData[5].burns,
          maxSupply: eggShopData[5].maxSupply,
          eggMintAmt: parseFloat(ethers.utils.formatEther(eggShopData[5].eggMintAmt)),
          eggBurnAmt: parseFloat(ethers.utils.formatEther(eggShopData[5].eggBurnAmt)),
          background: eggShopData[5].background,
          metadata: eggShopMetadata6,
        })

        const eggShopTokenTypeData7 = eggShopData[6].tokenURI.split(';base64,').pop() as string
        const eggShopMetadata7: EggShopTokenTypeProps = JSON.parse(atob(eggShopTokenTypeData7))

        setSilverEggInfo({
          name: eggShopData[6].name,
          mints: eggShopData[6].mints,
          burns: eggShopData[6].burns,
          maxSupply: eggShopData[6].maxSupply,
          eggMintAmt: parseFloat(ethers.utils.formatEther(eggShopData[6].eggMintAmt)),
          eggBurnAmt: parseFloat(ethers.utils.formatEther(eggShopData[6].eggBurnAmt)),
          background: eggShopData[6].background,
          metadata: eggShopMetadata7,
        })
      }
      if (urqlQueryData.imperialEggs.length > 0) {
        const imperialEggsData = urqlQueryData.imperialEggs[0]
        setImperialEggsTotalSupply(imperialEggsData.contract.totalSupply)
        setImperialEggsMaxSupply(imperialEggsData.contract.maxSupply)

        const imperialEggTokenTypeData1 = imperialEggsData.tokenURI.split(';base64,').pop() as string
        const imperialEggMetadata1: EggShopTokenTypeProps = JSON.parse(atob(imperialEggTokenTypeData1))
        setImperialEggTokenMetadata(imperialEggMetadata1)
      }
    }
  }, [urqlQueryData])

  if (urqlQueryError) {
    return (
      <div className='loading--section'>
        <h1>Error fetching Special Mint...</h1>
      </div>
    )
  }

  if (!urqlQueryData) {
    return (
      <div className='loading--section'>
        <h1>Special Mints Loading...</h1>
      </div>
    )
  }

  if (!specialMintsDataInfo || urqlQueryFetching) {
    return (
      <div className='loading--section'>
        <h1>Special Mints Loading...</h1>
      </div>
    )
  }

  return (
    <>
      {TFGSalesData.saleStatus !== 'paused' && TFGSalesData.saleStatus !== 'soon' && (
        <div className='pb-4'>
          <ApplePie />
        </div>
      )}
      <div className='col-sm-10 col-lg-12'>
        <div className='row align-items-center justify-content-xl-start justify-content-lg-start justify-content-center'>
          <div className='col-lg-12'>
            <div className='row__head'>
              <h1>Imperial Egg</h1>
            </div>
          </div>
          <div className='col-8 col-sm-7 col-xl-2 col-lg-3 text-center'>
            <div className='egg-shop__image'>
              <img src={imperialEggTokenMetadata?.image} alt='' />
            </div>
            <span className='yellow p3 m3'>{imperialEggsMaxSupply - imperialEggsTotalSupply}</span> remaining
          </div>
          <div className='col-lg-9'>
            <div className='egg-shop__content'>
              <ul>
                <li>
                  Only <span className='yellow'>{imperialEggsMaxSupply}</span> Imperial Eggs will ever exits
                </li>
                <li>These are only awarded to Gen 0 minters via special mint rewards</li>
                <li>Each is unique, rare and beautiful</li>
                <li>Each different contents / properties that affect game play</li>
                <li>Can be held in wallet to reduce the transfer tax chances from 50% to only 20%</li>
                <ul>
                  <li>If you do get taxed, the tax rate is lowered from 5-10% to only 3-7%</li>
                </ul>
              </ul>
              <div className='row'>
                <div className='col-lg-4 col-xl-4'>
                  {specialMintsDataInfo && isConnected && (
                    <>
                      <ButtonContractWrite
                        ContractAddress={ContractAddresses.SpecialMintContractAddress}
                        ContractABI={ContractABIs.SpecialMintABI}
                        ContractFunction='mint'
                        ContractArgs={[1]}
                        ContractOverRides={{
                          value: ethers.utils.parseEther(specialMintsDataInfo[0].specialMintFee.toString()),
                        }}
                        ContractIsEnabled={true}
                        ButtonTextPrepareIdle={`Check the silver`}
                        ButtonTextPrepareLoading={`Polishing the Eggs`}
                        ButtonTextInitial={specialMintData[0].btn}
                        ButtonTextLoading={'Waiting for approval'}
                        ButtonTextWriting={'Gathering rewards...'}
                        ButtonTextError={"Couldn't wrap the reward"}
                        StartAlertText={'Wrapping rewards!'}
                        StartAlertOptions={{ icon: '🎁' }}
                        SuccessAlertText={'Your reward are being delivered!'}
                        SuccessAlertOptions={{ icon: '🎁' }}
                      />
                      <p>
                        <span>{specialMintsDataInfo ? specialMintsDataInfo[0].specialMintFee : 0} ETH</span>
                      </p>
                    </>
                  )}
                  {!isConnected && (
                    <div className='btn__card btn__anchor'>
                      <ConnectButton chainStatus='none' accountStatus='avatar' showBalance={false} />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className='margin'></div>
        </div>
        {!rainbowEggInfo ? (
          <h1 className='loading--section'>Rainbow Egg Loading...</h1>
        ) : (
          <div className='row align-items-center justify-content-xl-start justify-content-lg-start justify-content-center'>
            <div className='col-lg-12'>
              <div className='row__head'>
                <h1>Rainbow Egg</h1>
              </div>
            </div>
            <div className='col-8 col-sm-7 col-xl-2 col-lg-3 text-center'>
              <div className='egg-shop__image'>
                <img src={rainbowEggInfo?.metadata.image} alt='' />
              </div>
              {rainbowEggInfo && (
                <>
                  <span className='yellow'>{rainbowEggInfo?.maxSupply - rainbowEggInfo?.mints}</span> remaining
                </>
              )}
            </div>
            <div className='col-lg-9'>
              <div className='egg-shop__content'>
                <ul>
                  <li>
                    Only <span className='yellow'>200</span> rainbow eggs will ever exits
                  </li>
                  <li>Can only be earned by sacrificing a Hen or via special mint reward</li>
                  <li>Can be broken (burned) to upgrade a Coyotes alpha rank</li>
                  <li>Increase a Coyote Alpha rank by +1</li>
                </ul>
                <div className='row'>
                  {rainbowEggInfo && specialMintsDataInfo && isConnected && (
                    <>
                      <div className='col-lg-4 col-xl-4'>
                        <ButtonContractWrite
                          ContractAddress={ContractAddresses.SpecialMintContractAddress}
                          ContractABI={ContractABIs.SpecialMintABI}
                          ContractFunction='mint'
                          ContractArgs={[2]}
                          ContractOverRides={{
                            value: ethers.utils.parseEther(specialMintsDataInfo[1].specialMintFee.toString()),
                          }}
                          ContractIsEnabled={true}
                          ButtonTextPrepareIdle={`Rainbows are amazing`}
                          ButtonTextPrepareLoading={`Painting the rainbow`}
                          ButtonTextInitial={specialMintData[1].btn}
                          ButtonTextLoading={'Waiting for approval'}
                          ButtonTextWriting={'Gathering rewards...'}
                          ButtonTextError={"Couldn't wrap the reward"}
                          StartAlertText={'Wrapping rewards!'}
                          StartAlertOptions={{ icon: '🎁' }}
                          SuccessAlertText={'Your reward are being delivered!'}
                          SuccessAlertOptions={{ icon: '🎁' }}
                        />
                        <p>
                          <span>{specialMintsDataInfo ? specialMintsDataInfo[1].specialMintFee : 0} ETH</span>
                        </p>
                      </div>
                      <div className='col-lg-4 col-xl-4'>
                        <ButtonContractWrite
                          ContractAddress={ContractAddresses.SpecialMintContractAddress}
                          ContractABI={ContractABIs.SpecialMintABI}
                          ContractFunction='mint'
                          ContractArgs={[4]}
                          ContractOverRides={{
                            value: ethers.utils.parseEther(specialMintsDataInfo[3].specialMintFee.toString()),
                          }}
                          ContractIsEnabled={true}
                          ButtonTextPrepareIdle={`Check for wrapping paper`}
                          ButtonTextPrepareLoading={`Preparing`}
                          ButtonTextInitial={specialMintData[3].btn}
                          ButtonTextLoading={'Waiting for approval'}
                          ButtonTextWriting={'Gathering rewards...'}
                          ButtonTextError={"Couldn't wrap the reward"}
                          StartAlertText={'Wrapping rewards!'}
                          StartAlertOptions={{ icon: '🎁' }}
                          SuccessAlertText={'Your reward are being delivered!'}
                          SuccessAlertOptions={{ icon: '🎁' }}
                        />
                        <p>
                          <span>{specialMintsDataInfo ? specialMintsDataInfo[3].specialMintFee : 0} ETH</span>
                        </p>
                      </div>
                      <div className='col-lg-4 col-xl-4'>
                        <div className='col-lg-4 col-xl-4'>
                          <Link className='btn__anchor btn_width text-nowrap' to='/houses'>
                            Sacrifice a Hen
                          </Link>
                        </div>
                      </div>
                    </>
                  )}
                  {!isConnected && (
                    <div className='col-lg-4 col-xl-3'>
                      <div className='btn__card btn__anchor'>
                        <ConnectButton chainStatus='none' accountStatus='avatar' showBalance={false} />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className='margin'></div>
          </div>
        )}
        <div className='row align-items-center justify-content-xl-start justify-content-lg-start justify-content-center'>
          <div className='col-lg-12'>
            <div className='row__head'>
              <h1>Platinum Egg</h1>
            </div>
          </div>
          <div className='col-8 col-sm-7 col-xl-2 col-lg-3 text-center'>
            <div className='egg-shop__image'>
              <img src={platinumEggInfo?.metadata.image} alt='' />
            </div>
            {platinumEggInfo && (
              <>
                <span className='yellow'>{platinumEggInfo?.maxSupply - platinumEggInfo?.mints}</span> remaining
              </>
            )}
          </div>
          <div className='col-lg-9'>
            <div className='egg-shop__content'>
              <ul>
                <li>
                  Only <span className='yellow'>600</span> Platinum Eggs exist
                </li>
                <li>Can only be earned during gen 1+ minting or via special minting rewards</li>
                <li>Randomly passed out to every 100th mint to one of the previous 100 minters</li>
                <li>Can be held in a users wallet to reduce the transfer EGG transfer tax chances to 35%</li>
              </ul>
              <div className='row'>
                {platinumEggInfo && specialMintsDataInfo && isConnected && (
                  <>
                    <div className='col-lg-4 col-xl-4'>
                      <ButtonContractWrite
                        ContractAddress={ContractAddresses.SpecialMintContractAddress}
                        ContractABI={ContractABIs.SpecialMintABI}
                        ContractFunction='mint'
                        ContractArgs={[3]}
                        ContractOverRides={{
                          value: ethers.utils.parseEther(specialMintsDataInfo[2].specialMintFee.toString()),
                        }}
                        ContractIsEnabled={true}
                        ButtonTextPrepareIdle={`Check for wrapping paper`}
                        ButtonTextPrepareLoading={`Preparing`}
                        ButtonTextInitial={specialMintData[2].btn}
                        ButtonTextLoading={'Waiting for approval'}
                        ButtonTextWriting={'Gathering rewards...'}
                        ButtonTextError={"Couldn't wrap the reward"}
                        StartAlertText={'Wrapping rewards!'}
                        StartAlertOptions={{ icon: '🎁' }}
                        SuccessAlertText={'Your reward are being delivered!'}
                        SuccessAlertOptions={{ icon: '🎁' }}
                      />
                      <p>
                        <span>{specialMintsDataInfo ? specialMintsDataInfo[2].specialMintFee : 0} ETH</span>
                      </p>
                    </div>
                    <div className='col-lg-4 col-xl-4'>
                      <Link className='btn__anchor btn_width text-nowrap' to='/mint'>
                        Mint Gen 1+
                      </Link>
                    </div>
                  </>
                )}
                {!isConnected && (
                  <div className='col-lg-4 col-xl-4'>
                    <div className='btn__card btn__anchor'>
                      <ConnectButton chainStatus='none' accountStatus='avatar' showBalance={false} />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className='margin'></div>
        </div>
        <div className='row align-items-center justify-content-xl-start justify-content-lg-start justify-content-center'>
          <div className='col-lg-12'>
            <div className='row__head'>
              <h1>Golden Egg</h1>
            </div>
          </div>
          <div className='col-8 col-sm-7 col-xl-2 col-lg-3 text-center'>
            <div className='egg-shop__image'>
              <img src={goldenEggInfo?.metadata.image} alt='' />
            </div>
            {goldenEggInfo && (
              <>
                <span className='yellow'>{goldenEggInfo?.maxSupply - goldenEggInfo?.mints}</span> remaining
              </>
            )}
          </div>
          <div className='col-lg-9'>
            <div className='egg-shop__content'>
              <ul>
                <li>
                  Only <span className='yellow'>1000</span> Golden Eggs exist
                </li>
                <li>Can only be earned by staking or via special mint rewards</li>
                <li>Randomly minted to a Hen, Coyote or Rooster up on claiming EGG</li>
                <li>~24 golden Eggs are distributed per day</li>
                <li>Can be broken (burned) to receive a reward of EGG</li>
              </ul>
              <div className='row'>
                {goldenEggInfo && specialMintsDataInfo && isConnected && (
                  <>
                    <div className='col-lg-4 col-xl-4'>
                      <ButtonContractWrite
                        ContractAddress={ContractAddresses.SpecialMintContractAddress}
                        ContractABI={ContractABIs.SpecialMintABI}
                        ContractFunction='mint'
                        ContractArgs={[4]}
                        ContractOverRides={{
                          value: ethers.utils.parseEther(specialMintsDataInfo[3].specialMintFee.toString()),
                        }}
                        ContractIsEnabled={true}
                        ButtonTextPrepareIdle={`Check for wrapping paper`}
                        ButtonTextPrepareLoading={`Preparing`}
                        ButtonTextInitial={specialMintData[3].btn}
                        ButtonTextLoading={'Waiting for approval'}
                        ButtonTextWriting={'Gathering rewards...'}
                        ButtonTextError={"Couldn't wrap the reward"}
                        StartAlertText={'Wrapping rewards!'}
                        StartAlertOptions={{ icon: '🎁' }}
                        SuccessAlertText={'Your reward are being delivered!'}
                        SuccessAlertOptions={{ icon: '🎁' }}
                      />
                      <p>
                        <span>{specialMintsDataInfo ? specialMintsDataInfo[3].specialMintFee : 0} ETH</span>
                      </p>
                    </div>
                    <div className='col-lg-4 col-xl-4'>
                      <Link className='btn__anchor btn_width' to='/henhouse'>
                        Stake in Hen House
                      </Link>
                    </div>
                  </>
                )}
                {!isConnected && (
                  <div className='col-lg-4 col-xl-4'>
                    <div className='btn__card btn__anchor'>
                      <ConnectButton chainStatus='none' accountStatus='avatar' showBalance={false} />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className='margin'></div>
        </div>
        <div className='row align-items-center justify-content-xl-start justify-content-lg-start justify-content-center'>
          <div className='col-lg-12'>
            <div className='row__head'>
              <h1>Silver Egg</h1>
            </div>
          </div>
          <div className='col-8 col-sm-7 col-xl-2 col-lg-3 text-center'>
            <div className='egg-shop__image'>
              <img src={silverEggInfo?.metadata.image} alt='' />
            </div>
            {silverEggInfo && (
              <>
                <span className='yellow'>{silverEggInfo?.maxSupply - silverEggInfo?.mints}</span> remaining
              </>
            )}
          </div>
          <div className='col-lg-9'>
            <div className='egg-shop__content'>
              <ul>
                <li>
                  Only <span className='yellow'>5000</span> Silver Eggs exist
                </li>
                <li>Can be purchased by scrambling EGG at the current Gen 1+ mint price</li>
                <li>Can be broken (burned) to upgrade a hens production score</li>
                <li>Improve a single Hens EGG production by +1</li>
              </ul>
              <div className='row'>
                {silverEggInfo && specialMintsDataInfo && isConnected && (
                  <>
                    <div className='col-lg-4 col-xl-4'>
                      <ButtonContractWrite
                        ContractAddress={ContractAddresses.SpecialMintContractAddress}
                        ContractABI={ContractABIs.SpecialMintABI}
                        ContractFunction='mint'
                        ContractArgs={[5]}
                        ContractOverRides={{
                          value: ethers.utils.parseEther(specialMintsDataInfo[4].specialMintFee.toString()),
                        }}
                        ContractIsEnabled={true}
                        ButtonTextPrepareIdle={`Check for wrapping paper`}
                        ButtonTextPrepareLoading={`Preparing`}
                        ButtonTextInitial={specialMintData[4].btn}
                        ButtonTextLoading={'Waiting for approval'}
                        ButtonTextWriting={'Gathering rewards...'}
                        ButtonTextError={"Couldn't wrap the reward"}
                        StartAlertText={'Wrapping rewards!'}
                        StartAlertOptions={{ icon: '🎁' }}
                        SuccessAlertText={'Your reward are being delivered!'}
                        SuccessAlertOptions={{ icon: '🎁' }}
                      />
                      <p>
                        <span>{specialMintsDataInfo ? specialMintsDataInfo[4].specialMintFee : 0} ETH</span>
                      </p>
                    </div>
                    <div className='col-lg-4 col-xl-4'>
                      {TFGSalesData.price > 5 && EGGBalance ? (
                        <ButtonContractWrite
                          ContractAddress={ContractAddresses.TheFarmGameMintContractAddress}
                          ContractABI={ContractABIs.TheFarmGamePlayABI}
                          ContractFunction='scrambleEGG'
                          ContractArgs={[ethers.utils.parseEther(TFGSalesData.price.toString())]}
                          ContractOverRides={{}}
                          ContractIsEnabled={EGGBalance.formatted >= TFGSalesData.price && TFGSalesData.price > 5}
                          ButtonTextPrepareIdle={`Check the oven`}
                          ButtonTextPrepareLoading={`Preparing`}
                          ButtonTextInitial={`Scramble ${TFGSalesData.price} EGG`}
                          ButtonTextLoading={'Waiting for approval'}
                          ButtonTextWriting={'Grabbing the frying pan...'}
                          ButtonTextError={"Couldn't scramble any EGGs"}
                          StartAlertText={'Scrambling EGGs!'}
                          StartAlertOptions={{ icon: '🍳' }}
                          SuccessAlertText={'Your Silver Egg is on its way!'}
                          SuccessAlertOptions={{ icon: '🍳' }}
                          imageWaitTx={RancherScramblingEggsImage}
                        />
                      ) : (
                        <div className='btn__anchor btn_width text-nowrap'>Can't Scramble yet</div>
                      )}
                    </div>
                  </>
                )}
                {!isConnected && (
                  <div className='col-lg-4 col-xl-4'>
                    <div className='btn__card btn__anchor'>
                      <ConnectButton chainStatus='none' accountStatus='avatar' showBalance={false} />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className='margin'></div>
        </div>
        {/* <div className='row align-items-center justify-content-xl-start justify-content-lg-start justify-content-center'>
          <div className='col-lg-12'>
            <div className='row__head'>
              <h1>Bronze Egg</h1>
            </div>
          </div>
          <div className='col-8 col-sm-7 col-xl-2 col-lg-3'>
            <div className='egg-shop__image'>
              <img src={bronzeEggInfo?.metadata.image} alt='' />
            </div>
          </div>
          <div className='col-lg-9'>
            <div className='egg-shop__content'>
              <ul>
                <li>Only be 5000 Bronze Eggs exist</li>
                {bronzeEggInfo && <li>Only {bronzeEggInfo?.maxSupply - bronzeEggInfo?.mints} remaining</li>}
                <li>Can be won by donating 10% of current gen 1+ mint price to a lottery</li>
                <li>There will be an new lottery every 8 hours</li>
                <li>Contain secrets for the next phases of the game</li>
                <li>5 winners are randomly selected and can claim 1 Bronze Egg</li>
              </ul>
              <div className='row'>
                {bronzeEggInfo && specialMintsDataInfo && isConnected && (
                  <>
                    <div className='col-lg-4 col-xl-4'>
                      <div className='egg-shop__button'>
                        <a href='javasript:'>Donate 10% Gen 1 price</a>
                      </div>
                    </div>
                  </>
                )}
                {!isConnected && (
                  <div className='col-lg-4 col-xl-4'>
                    <div className='btn__card btn__anchor'>
                      <ConnectButton chainStatus='none' accountStatus='avatar' showBalance={false} />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className='margin__last'></div>
        </div> */}
      </div>
    </>
  )
}

export default EggShop
