import React, { useState } from 'react'

import { useContracts } from '../../hooks/useContracts'

import { useAccount } from 'wagmi'

import './styles.scss'

import MetaMastImg from '../../assets/images/metamask.svg'

const MetaMaskAddToken = (): JSX.Element => {
  const ContractAddresses = useContracts()

  const { connector: activeConnector, isConnected } = useAccount()

  const [tokenNotAdded, setTokenNotAdded] = useState(true)

  async function addTokenFunction() {
    if (activeConnector) {
      const wasAdded = await activeConnector!.watchAsset!({
        address: ContractAddresses.EGGTokenContractAddress,
        symbol: 'EGG',
        decimals: 18,
      })

      if (wasAdded) {
        setTokenNotAdded(false)
      }
    }
  }

  return (
    <>
      <div>
        {tokenNotAdded && isConnected && (
          <button onClick={() => addTokenFunction()} className='addToWallet nav-link'>
            <img src={MetaMastImg} height={32} width={32} alt='' />
          </button>
        )}
      </div>
    </>
  )
}

export default MetaMaskAddToken
