import React from 'react'

const Dao = () => {
  return (
    <>
      <main>
        <section id='character' className='dao--table'>
          <div className='container'>
            <div className='row'>
              <div className='col-12'>
                <div className='sec__head hero__header'>
                  <h1>The DAO</h1>
                </div>
              </div>
            </div>
            <div className='row justify-content-center'>
              <div className='col-lg-6 col-xl-6 col-sm-12 col-12'>
                <div className='hero__text'>
                  <h2>The DAO is coming soon.</h2>
                  <p>
                    In the DAO holders will be able to propose and vote on Treasury use, game feature and any other
                    items related to TFG.
                  </p>
                </div>
              </div>
            </div>
            {/* <div className='row justify-content-center'>
              <div className='col-lg-6'>
                <div className='sec__head'>
                  <h2>Characters</h2>
                  <div className='head__text'>
                    <ul>
                      <li>Hens earn EGG daily and pay a tax to Coyotes</li>
                      <li>Coyotes collect a tax &amp; steal Gen 1+ mints</li>
                      <li>Roosters are rare, they protect Hens, collect daily EGG, rescue dropped EGG other rewards</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </section>
      </main>
    </>
  )
}

export default Dao
