/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'

import Inventory from '../../components/Inventory'

const InventoryPage = (): JSX.Element => {
  return (
    <section className='sub--section'>
      <div className='container'>
        <div className='row justify-content-center'>
          <div className='egg-shop__head'>
            <h1>INVENTORY</h1>
          </div>
          <Inventory />
        </div>
      </div>
    </section>
  )
}

export default InventoryPage
