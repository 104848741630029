import React, { useEffect, useState } from 'react'
import UserEggBalance from '../UserEggBalance'

import { ProgressBar } from 'react-bootstrap'

import { useEggTokenData } from '../../hooks/useEggTokenData'
import ButtonClaimEgg from '../ButtonClaimEgg/ButtonClaimEgg'

interface PropTypes {
  stakedTokenList: any[]
}

const EggEmission = (props: PropTypes): JSX.Element => {
  const { stakedTokenList } = props

  const EGGTokenInfo = useEggTokenData()

  // Get users farmAnimals balance

  const [eggFaucetPercent, setEggFaucetPercent] = useState(0)
  const [eggFaucetPercentNow, setEggFaucetPercentNow] = useState(0)

  useEffect(() => {
    if (EGGTokenInfo) {
      // Setup the progress bar percentage
      let EGGFaucetPercent = (EGGTokenInfo.totalMinted / EGGTokenInfo.totalSupply) * 100
      let EGGFaucetPercentNow = 0
      if (EGGFaucetPercent < 20) {
        EGGFaucetPercent = parseFloat(EGGFaucetPercent.toFixed(3))
        setEggFaucetPercent(EGGFaucetPercent)
        setEggFaucetPercentNow(20)
      } else {
        EGGFaucetPercent = parseFloat(EGGFaucetPercent.toFixed(2))
        EGGFaucetPercentNow = EGGFaucetPercent
        setEggFaucetPercent(EGGFaucetPercent)
        setEggFaucetPercentNow(EGGFaucetPercentNow)
      }
    }
  }, [EGGTokenInfo])

  if (!stakedTokenList || !EGGTokenInfo) {
    return (
      <div className='loading--section'>
        <h1>Fetching Egg Emissions...</h1>
      </div>
    )
  }

  return (
    <>
      <div className='tab__card'>
        <div className='tab__text'>
          <h1>EGG</h1>
        </div>

        {/*
				// FIXME: Removed for now. Need to show this only after a certain amount is minted.
				<p>Phase 1 egg emission</p>
        <div className='load__card'>
          <ProgressBar
            className='brown'
            style={{ width: '100%', borderRadius: 0, fontSize: '14px', backgroundColor: '#ffffff' }}
            striped
            now={eggFaucetPercentNow}
            label={`${eggFaucetPercent}%`}
          />
        </div> */}
        <div className='wallet__card'>
          <h2>EGG in your wallet</h2>
          <span className='green'>
            <UserEggBalance />
          </span>
        </div>
        <div className='sub__btn'>
          <ButtonClaimEgg stakedTokenList={stakedTokenList} />
        </div>
      </div>
    </>
  )
}

export default EggEmission
