// @ts-nocheck
import { GraphQLResolveInfo, SelectionSetNode, FieldNode, GraphQLScalarType, GraphQLScalarTypeConfig } from 'graphql'
import type { GetMeshOptions } from '@graphql-mesh/runtime'
import type { YamlConfig } from '@graphql-mesh/types'
import { PubSub } from '@graphql-mesh/utils'
import { DefaultLogger } from '@graphql-mesh/utils'
import MeshCache from '@graphql-mesh/cache-localforage'
import { fetch as fetchFn } from '@whatwg-node/fetch'
import { fileURLToPath } from '@graphql-mesh/utils'

import { MeshResolvedSource } from '@graphql-mesh/runtime'
import { MeshTransform, MeshPlugin } from '@graphql-mesh/types'
import GraphqlHandler from '@graphql-mesh/graphql'
import UsePollingLive from '@graphprotocol/client-polling-live'
import BareMerger from '@graphql-mesh/merger-bare'
import { printWithCache } from '@graphql-mesh/utils'
import { createMeshHTTPHandler, MeshHTTPHandler } from '@graphql-mesh/http'
import {
  getMesh,
  ExecuteMeshFn,
  SubscribeMeshFn,
  MeshContext as BaseMeshContext,
  MeshInstance,
} from '@graphql-mesh/runtime'
import { MeshStore, FsStoreStorageAdapter } from '@graphql-mesh/store'
import { path as pathModule } from '@graphql-mesh/cross-helpers'
import { ImportFn } from '@graphql-mesh/types'
import type { TheFarmGameGoerliTypes } from './sources/the-farm-game-goerli/types'
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> }
export type RequireFields<T, K extends keyof T> = Omit<T, K> & { [P in K]-?: NonNullable<T[P]> }

/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  BigDecimal: any
  BigInt: any
  Bytes: any
}

export type Account = {
  id: Scalars['ID']
  address: Scalars['Bytes']
  balances: Array<AccountBalance>
  nftTokens?: Maybe<Array<OwnerTokenLookup>>
  stakedTokens?: Maybe<Array<TokenStaked>>
}

export type AccountbalancesArgs = {
  skip?: InputMaybe<Scalars['Int']>
  first?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<AccountBalance_orderBy>
  orderDirection?: InputMaybe<OrderDirection>
  where?: InputMaybe<AccountBalance_filter>
}

export type AccountnftTokensArgs = {
  skip?: InputMaybe<Scalars['Int']>
  first?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<OwnerTokenLookup_orderBy>
  orderDirection?: InputMaybe<OrderDirection>
  where?: InputMaybe<OwnerTokenLookup_filter>
}

export type AccountstakedTokensArgs = {
  skip?: InputMaybe<Scalars['Int']>
  first?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<TokenStaked_orderBy>
  orderDirection?: InputMaybe<OrderDirection>
  where?: InputMaybe<TokenStaked_filter>
}

export type AccountBalance = {
  id: Scalars['ID']
  account: Account
  token: Token
  amount: Scalars['BigDecimal']
  block?: Maybe<Scalars['BigInt']>
  modified?: Maybe<Scalars['BigInt']>
  transaction?: Maybe<Scalars['Bytes']>
}

export type AccountBalanceSnapshot = {
  id: Scalars['ID']
  account: Account
  token: Token
  amount: Scalars['BigDecimal']
  event?: Maybe<TokenEvent>
  block: Scalars['BigInt']
  timestamp: Scalars['BigInt']
  transaction: Scalars['Bytes']
}

export type AccountBalanceSnapshot_filter = {
  id?: InputMaybe<Scalars['ID']>
  id_not?: InputMaybe<Scalars['ID']>
  id_gt?: InputMaybe<Scalars['ID']>
  id_lt?: InputMaybe<Scalars['ID']>
  id_gte?: InputMaybe<Scalars['ID']>
  id_lte?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  id_not_in?: InputMaybe<Array<Scalars['ID']>>
  account?: InputMaybe<Scalars['String']>
  account_not?: InputMaybe<Scalars['String']>
  account_gt?: InputMaybe<Scalars['String']>
  account_lt?: InputMaybe<Scalars['String']>
  account_gte?: InputMaybe<Scalars['String']>
  account_lte?: InputMaybe<Scalars['String']>
  account_in?: InputMaybe<Array<Scalars['String']>>
  account_not_in?: InputMaybe<Array<Scalars['String']>>
  account_contains?: InputMaybe<Scalars['String']>
  account_contains_nocase?: InputMaybe<Scalars['String']>
  account_not_contains?: InputMaybe<Scalars['String']>
  account_not_contains_nocase?: InputMaybe<Scalars['String']>
  account_starts_with?: InputMaybe<Scalars['String']>
  account_starts_with_nocase?: InputMaybe<Scalars['String']>
  account_not_starts_with?: InputMaybe<Scalars['String']>
  account_not_starts_with_nocase?: InputMaybe<Scalars['String']>
  account_ends_with?: InputMaybe<Scalars['String']>
  account_ends_with_nocase?: InputMaybe<Scalars['String']>
  account_not_ends_with?: InputMaybe<Scalars['String']>
  account_not_ends_with_nocase?: InputMaybe<Scalars['String']>
  account_?: InputMaybe<Account_filter>
  token?: InputMaybe<Scalars['String']>
  token_not?: InputMaybe<Scalars['String']>
  token_gt?: InputMaybe<Scalars['String']>
  token_lt?: InputMaybe<Scalars['String']>
  token_gte?: InputMaybe<Scalars['String']>
  token_lte?: InputMaybe<Scalars['String']>
  token_in?: InputMaybe<Array<Scalars['String']>>
  token_not_in?: InputMaybe<Array<Scalars['String']>>
  token_contains?: InputMaybe<Scalars['String']>
  token_contains_nocase?: InputMaybe<Scalars['String']>
  token_not_contains?: InputMaybe<Scalars['String']>
  token_not_contains_nocase?: InputMaybe<Scalars['String']>
  token_starts_with?: InputMaybe<Scalars['String']>
  token_starts_with_nocase?: InputMaybe<Scalars['String']>
  token_not_starts_with?: InputMaybe<Scalars['String']>
  token_not_starts_with_nocase?: InputMaybe<Scalars['String']>
  token_ends_with?: InputMaybe<Scalars['String']>
  token_ends_with_nocase?: InputMaybe<Scalars['String']>
  token_not_ends_with?: InputMaybe<Scalars['String']>
  token_not_ends_with_nocase?: InputMaybe<Scalars['String']>
  token_?: InputMaybe<Token_filter>
  amount?: InputMaybe<Scalars['BigDecimal']>
  amount_not?: InputMaybe<Scalars['BigDecimal']>
  amount_gt?: InputMaybe<Scalars['BigDecimal']>
  amount_lt?: InputMaybe<Scalars['BigDecimal']>
  amount_gte?: InputMaybe<Scalars['BigDecimal']>
  amount_lte?: InputMaybe<Scalars['BigDecimal']>
  amount_in?: InputMaybe<Array<Scalars['BigDecimal']>>
  amount_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>
  event?: InputMaybe<Scalars['String']>
  event_not?: InputMaybe<Scalars['String']>
  event_gt?: InputMaybe<Scalars['String']>
  event_lt?: InputMaybe<Scalars['String']>
  event_gte?: InputMaybe<Scalars['String']>
  event_lte?: InputMaybe<Scalars['String']>
  event_in?: InputMaybe<Array<Scalars['String']>>
  event_not_in?: InputMaybe<Array<Scalars['String']>>
  event_contains?: InputMaybe<Scalars['String']>
  event_contains_nocase?: InputMaybe<Scalars['String']>
  event_not_contains?: InputMaybe<Scalars['String']>
  event_not_contains_nocase?: InputMaybe<Scalars['String']>
  event_starts_with?: InputMaybe<Scalars['String']>
  event_starts_with_nocase?: InputMaybe<Scalars['String']>
  event_not_starts_with?: InputMaybe<Scalars['String']>
  event_not_starts_with_nocase?: InputMaybe<Scalars['String']>
  event_ends_with?: InputMaybe<Scalars['String']>
  event_ends_with_nocase?: InputMaybe<Scalars['String']>
  event_not_ends_with?: InputMaybe<Scalars['String']>
  event_not_ends_with_nocase?: InputMaybe<Scalars['String']>
  event_?: InputMaybe<TokenEvent_filter>
  block?: InputMaybe<Scalars['BigInt']>
  block_not?: InputMaybe<Scalars['BigInt']>
  block_gt?: InputMaybe<Scalars['BigInt']>
  block_lt?: InputMaybe<Scalars['BigInt']>
  block_gte?: InputMaybe<Scalars['BigInt']>
  block_lte?: InputMaybe<Scalars['BigInt']>
  block_in?: InputMaybe<Array<Scalars['BigInt']>>
  block_not_in?: InputMaybe<Array<Scalars['BigInt']>>
  timestamp?: InputMaybe<Scalars['BigInt']>
  timestamp_not?: InputMaybe<Scalars['BigInt']>
  timestamp_gt?: InputMaybe<Scalars['BigInt']>
  timestamp_lt?: InputMaybe<Scalars['BigInt']>
  timestamp_gte?: InputMaybe<Scalars['BigInt']>
  timestamp_lte?: InputMaybe<Scalars['BigInt']>
  timestamp_in?: InputMaybe<Array<Scalars['BigInt']>>
  timestamp_not_in?: InputMaybe<Array<Scalars['BigInt']>>
  transaction?: InputMaybe<Scalars['Bytes']>
  transaction_not?: InputMaybe<Scalars['Bytes']>
  transaction_in?: InputMaybe<Array<Scalars['Bytes']>>
  transaction_not_in?: InputMaybe<Array<Scalars['Bytes']>>
  transaction_contains?: InputMaybe<Scalars['Bytes']>
  transaction_not_contains?: InputMaybe<Scalars['Bytes']>
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>
}

export type AccountBalanceSnapshot_orderBy =
  | 'id'
  | 'account'
  | 'token'
  | 'amount'
  | 'event'
  | 'block'
  | 'timestamp'
  | 'transaction'

export type AccountBalance_filter = {
  id?: InputMaybe<Scalars['ID']>
  id_not?: InputMaybe<Scalars['ID']>
  id_gt?: InputMaybe<Scalars['ID']>
  id_lt?: InputMaybe<Scalars['ID']>
  id_gte?: InputMaybe<Scalars['ID']>
  id_lte?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  id_not_in?: InputMaybe<Array<Scalars['ID']>>
  account?: InputMaybe<Scalars['String']>
  account_not?: InputMaybe<Scalars['String']>
  account_gt?: InputMaybe<Scalars['String']>
  account_lt?: InputMaybe<Scalars['String']>
  account_gte?: InputMaybe<Scalars['String']>
  account_lte?: InputMaybe<Scalars['String']>
  account_in?: InputMaybe<Array<Scalars['String']>>
  account_not_in?: InputMaybe<Array<Scalars['String']>>
  account_contains?: InputMaybe<Scalars['String']>
  account_contains_nocase?: InputMaybe<Scalars['String']>
  account_not_contains?: InputMaybe<Scalars['String']>
  account_not_contains_nocase?: InputMaybe<Scalars['String']>
  account_starts_with?: InputMaybe<Scalars['String']>
  account_starts_with_nocase?: InputMaybe<Scalars['String']>
  account_not_starts_with?: InputMaybe<Scalars['String']>
  account_not_starts_with_nocase?: InputMaybe<Scalars['String']>
  account_ends_with?: InputMaybe<Scalars['String']>
  account_ends_with_nocase?: InputMaybe<Scalars['String']>
  account_not_ends_with?: InputMaybe<Scalars['String']>
  account_not_ends_with_nocase?: InputMaybe<Scalars['String']>
  account_?: InputMaybe<Account_filter>
  token?: InputMaybe<Scalars['String']>
  token_not?: InputMaybe<Scalars['String']>
  token_gt?: InputMaybe<Scalars['String']>
  token_lt?: InputMaybe<Scalars['String']>
  token_gte?: InputMaybe<Scalars['String']>
  token_lte?: InputMaybe<Scalars['String']>
  token_in?: InputMaybe<Array<Scalars['String']>>
  token_not_in?: InputMaybe<Array<Scalars['String']>>
  token_contains?: InputMaybe<Scalars['String']>
  token_contains_nocase?: InputMaybe<Scalars['String']>
  token_not_contains?: InputMaybe<Scalars['String']>
  token_not_contains_nocase?: InputMaybe<Scalars['String']>
  token_starts_with?: InputMaybe<Scalars['String']>
  token_starts_with_nocase?: InputMaybe<Scalars['String']>
  token_not_starts_with?: InputMaybe<Scalars['String']>
  token_not_starts_with_nocase?: InputMaybe<Scalars['String']>
  token_ends_with?: InputMaybe<Scalars['String']>
  token_ends_with_nocase?: InputMaybe<Scalars['String']>
  token_not_ends_with?: InputMaybe<Scalars['String']>
  token_not_ends_with_nocase?: InputMaybe<Scalars['String']>
  token_?: InputMaybe<Token_filter>
  amount?: InputMaybe<Scalars['BigDecimal']>
  amount_not?: InputMaybe<Scalars['BigDecimal']>
  amount_gt?: InputMaybe<Scalars['BigDecimal']>
  amount_lt?: InputMaybe<Scalars['BigDecimal']>
  amount_gte?: InputMaybe<Scalars['BigDecimal']>
  amount_lte?: InputMaybe<Scalars['BigDecimal']>
  amount_in?: InputMaybe<Array<Scalars['BigDecimal']>>
  amount_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>
  block?: InputMaybe<Scalars['BigInt']>
  block_not?: InputMaybe<Scalars['BigInt']>
  block_gt?: InputMaybe<Scalars['BigInt']>
  block_lt?: InputMaybe<Scalars['BigInt']>
  block_gte?: InputMaybe<Scalars['BigInt']>
  block_lte?: InputMaybe<Scalars['BigInt']>
  block_in?: InputMaybe<Array<Scalars['BigInt']>>
  block_not_in?: InputMaybe<Array<Scalars['BigInt']>>
  modified?: InputMaybe<Scalars['BigInt']>
  modified_not?: InputMaybe<Scalars['BigInt']>
  modified_gt?: InputMaybe<Scalars['BigInt']>
  modified_lt?: InputMaybe<Scalars['BigInt']>
  modified_gte?: InputMaybe<Scalars['BigInt']>
  modified_lte?: InputMaybe<Scalars['BigInt']>
  modified_in?: InputMaybe<Array<Scalars['BigInt']>>
  modified_not_in?: InputMaybe<Array<Scalars['BigInt']>>
  transaction?: InputMaybe<Scalars['Bytes']>
  transaction_not?: InputMaybe<Scalars['Bytes']>
  transaction_in?: InputMaybe<Array<Scalars['Bytes']>>
  transaction_not_in?: InputMaybe<Array<Scalars['Bytes']>>
  transaction_contains?: InputMaybe<Scalars['Bytes']>
  transaction_not_contains?: InputMaybe<Scalars['Bytes']>
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>
}

export type AccountBalance_orderBy = 'id' | 'account' | 'token' | 'amount' | 'block' | 'modified' | 'transaction'

export type Account_filter = {
  id?: InputMaybe<Scalars['ID']>
  id_not?: InputMaybe<Scalars['ID']>
  id_gt?: InputMaybe<Scalars['ID']>
  id_lt?: InputMaybe<Scalars['ID']>
  id_gte?: InputMaybe<Scalars['ID']>
  id_lte?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  id_not_in?: InputMaybe<Array<Scalars['ID']>>
  address?: InputMaybe<Scalars['Bytes']>
  address_not?: InputMaybe<Scalars['Bytes']>
  address_in?: InputMaybe<Array<Scalars['Bytes']>>
  address_not_in?: InputMaybe<Array<Scalars['Bytes']>>
  address_contains?: InputMaybe<Scalars['Bytes']>
  address_not_contains?: InputMaybe<Scalars['Bytes']>
  balances_?: InputMaybe<AccountBalance_filter>
  nftTokens_?: InputMaybe<OwnerTokenLookup_filter>
  stakedTokens_?: InputMaybe<TokenStaked_filter>
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>
}

export type Account_orderBy = 'id' | 'address' | 'balances' | 'nftTokens' | 'stakedTokens'

export type BlockChangedFilter = {
  number_gte: Scalars['Int']
}

export type Block_height = {
  hash?: InputMaybe<Scalars['Bytes']>
  number?: InputMaybe<Scalars['Int']>
  number_gte?: InputMaybe<Scalars['Int']>
}

export type BurnEvent = TokenEvent & {
  id: Scalars['ID']
  token: Token
  amount: Scalars['BigDecimal']
  sender: Scalars['Bytes']
  burner: Scalars['Bytes']
  block: Scalars['BigInt']
  timestamp: Scalars['BigInt']
  transaction: Scalars['Bytes']
}

export type BurnEvent_filter = {
  id?: InputMaybe<Scalars['ID']>
  id_not?: InputMaybe<Scalars['ID']>
  id_gt?: InputMaybe<Scalars['ID']>
  id_lt?: InputMaybe<Scalars['ID']>
  id_gte?: InputMaybe<Scalars['ID']>
  id_lte?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  id_not_in?: InputMaybe<Array<Scalars['ID']>>
  token?: InputMaybe<Scalars['String']>
  token_not?: InputMaybe<Scalars['String']>
  token_gt?: InputMaybe<Scalars['String']>
  token_lt?: InputMaybe<Scalars['String']>
  token_gte?: InputMaybe<Scalars['String']>
  token_lte?: InputMaybe<Scalars['String']>
  token_in?: InputMaybe<Array<Scalars['String']>>
  token_not_in?: InputMaybe<Array<Scalars['String']>>
  token_contains?: InputMaybe<Scalars['String']>
  token_contains_nocase?: InputMaybe<Scalars['String']>
  token_not_contains?: InputMaybe<Scalars['String']>
  token_not_contains_nocase?: InputMaybe<Scalars['String']>
  token_starts_with?: InputMaybe<Scalars['String']>
  token_starts_with_nocase?: InputMaybe<Scalars['String']>
  token_not_starts_with?: InputMaybe<Scalars['String']>
  token_not_starts_with_nocase?: InputMaybe<Scalars['String']>
  token_ends_with?: InputMaybe<Scalars['String']>
  token_ends_with_nocase?: InputMaybe<Scalars['String']>
  token_not_ends_with?: InputMaybe<Scalars['String']>
  token_not_ends_with_nocase?: InputMaybe<Scalars['String']>
  token_?: InputMaybe<Token_filter>
  amount?: InputMaybe<Scalars['BigDecimal']>
  amount_not?: InputMaybe<Scalars['BigDecimal']>
  amount_gt?: InputMaybe<Scalars['BigDecimal']>
  amount_lt?: InputMaybe<Scalars['BigDecimal']>
  amount_gte?: InputMaybe<Scalars['BigDecimal']>
  amount_lte?: InputMaybe<Scalars['BigDecimal']>
  amount_in?: InputMaybe<Array<Scalars['BigDecimal']>>
  amount_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>
  sender?: InputMaybe<Scalars['Bytes']>
  sender_not?: InputMaybe<Scalars['Bytes']>
  sender_in?: InputMaybe<Array<Scalars['Bytes']>>
  sender_not_in?: InputMaybe<Array<Scalars['Bytes']>>
  sender_contains?: InputMaybe<Scalars['Bytes']>
  sender_not_contains?: InputMaybe<Scalars['Bytes']>
  burner?: InputMaybe<Scalars['Bytes']>
  burner_not?: InputMaybe<Scalars['Bytes']>
  burner_in?: InputMaybe<Array<Scalars['Bytes']>>
  burner_not_in?: InputMaybe<Array<Scalars['Bytes']>>
  burner_contains?: InputMaybe<Scalars['Bytes']>
  burner_not_contains?: InputMaybe<Scalars['Bytes']>
  block?: InputMaybe<Scalars['BigInt']>
  block_not?: InputMaybe<Scalars['BigInt']>
  block_gt?: InputMaybe<Scalars['BigInt']>
  block_lt?: InputMaybe<Scalars['BigInt']>
  block_gte?: InputMaybe<Scalars['BigInt']>
  block_lte?: InputMaybe<Scalars['BigInt']>
  block_in?: InputMaybe<Array<Scalars['BigInt']>>
  block_not_in?: InputMaybe<Array<Scalars['BigInt']>>
  timestamp?: InputMaybe<Scalars['BigInt']>
  timestamp_not?: InputMaybe<Scalars['BigInt']>
  timestamp_gt?: InputMaybe<Scalars['BigInt']>
  timestamp_lt?: InputMaybe<Scalars['BigInt']>
  timestamp_gte?: InputMaybe<Scalars['BigInt']>
  timestamp_lte?: InputMaybe<Scalars['BigInt']>
  timestamp_in?: InputMaybe<Array<Scalars['BigInt']>>
  timestamp_not_in?: InputMaybe<Array<Scalars['BigInt']>>
  transaction?: InputMaybe<Scalars['Bytes']>
  transaction_not?: InputMaybe<Scalars['Bytes']>
  transaction_in?: InputMaybe<Array<Scalars['Bytes']>>
  transaction_not_in?: InputMaybe<Array<Scalars['Bytes']>>
  transaction_contains?: InputMaybe<Scalars['Bytes']>
  transaction_not_contains?: InputMaybe<Scalars['Bytes']>
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>
}

export type BurnEvent_orderBy = 'id' | 'token' | 'amount' | 'sender' | 'burner' | 'block' | 'timestamp' | 'transaction'

export type GoldenEggAwarded = {
  id: Scalars['ID']
  recipient: Scalars['Bytes']
}

export type GoldenEggAwarded_filter = {
  id?: InputMaybe<Scalars['ID']>
  id_not?: InputMaybe<Scalars['ID']>
  id_gt?: InputMaybe<Scalars['ID']>
  id_lt?: InputMaybe<Scalars['ID']>
  id_gte?: InputMaybe<Scalars['ID']>
  id_lte?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  id_not_in?: InputMaybe<Array<Scalars['ID']>>
  recipient?: InputMaybe<Scalars['Bytes']>
  recipient_not?: InputMaybe<Scalars['Bytes']>
  recipient_in?: InputMaybe<Array<Scalars['Bytes']>>
  recipient_not_in?: InputMaybe<Array<Scalars['Bytes']>>
  recipient_contains?: InputMaybe<Scalars['Bytes']>
  recipient_not_contains?: InputMaybe<Scalars['Bytes']>
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>
}

export type GoldenEggAwarded_orderBy = 'id' | 'recipient'

export type MintEvent = TokenEvent & {
  id: Scalars['ID']
  token: Token
  amount: Scalars['BigDecimal']
  sender: Scalars['Bytes']
  minter: Scalars['Bytes']
  destination: Scalars['Bytes']
  block: Scalars['BigInt']
  timestamp: Scalars['BigInt']
  transaction: Scalars['Bytes']
}

export type MintEvent_filter = {
  id?: InputMaybe<Scalars['ID']>
  id_not?: InputMaybe<Scalars['ID']>
  id_gt?: InputMaybe<Scalars['ID']>
  id_lt?: InputMaybe<Scalars['ID']>
  id_gte?: InputMaybe<Scalars['ID']>
  id_lte?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  id_not_in?: InputMaybe<Array<Scalars['ID']>>
  token?: InputMaybe<Scalars['String']>
  token_not?: InputMaybe<Scalars['String']>
  token_gt?: InputMaybe<Scalars['String']>
  token_lt?: InputMaybe<Scalars['String']>
  token_gte?: InputMaybe<Scalars['String']>
  token_lte?: InputMaybe<Scalars['String']>
  token_in?: InputMaybe<Array<Scalars['String']>>
  token_not_in?: InputMaybe<Array<Scalars['String']>>
  token_contains?: InputMaybe<Scalars['String']>
  token_contains_nocase?: InputMaybe<Scalars['String']>
  token_not_contains?: InputMaybe<Scalars['String']>
  token_not_contains_nocase?: InputMaybe<Scalars['String']>
  token_starts_with?: InputMaybe<Scalars['String']>
  token_starts_with_nocase?: InputMaybe<Scalars['String']>
  token_not_starts_with?: InputMaybe<Scalars['String']>
  token_not_starts_with_nocase?: InputMaybe<Scalars['String']>
  token_ends_with?: InputMaybe<Scalars['String']>
  token_ends_with_nocase?: InputMaybe<Scalars['String']>
  token_not_ends_with?: InputMaybe<Scalars['String']>
  token_not_ends_with_nocase?: InputMaybe<Scalars['String']>
  token_?: InputMaybe<Token_filter>
  amount?: InputMaybe<Scalars['BigDecimal']>
  amount_not?: InputMaybe<Scalars['BigDecimal']>
  amount_gt?: InputMaybe<Scalars['BigDecimal']>
  amount_lt?: InputMaybe<Scalars['BigDecimal']>
  amount_gte?: InputMaybe<Scalars['BigDecimal']>
  amount_lte?: InputMaybe<Scalars['BigDecimal']>
  amount_in?: InputMaybe<Array<Scalars['BigDecimal']>>
  amount_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>
  sender?: InputMaybe<Scalars['Bytes']>
  sender_not?: InputMaybe<Scalars['Bytes']>
  sender_in?: InputMaybe<Array<Scalars['Bytes']>>
  sender_not_in?: InputMaybe<Array<Scalars['Bytes']>>
  sender_contains?: InputMaybe<Scalars['Bytes']>
  sender_not_contains?: InputMaybe<Scalars['Bytes']>
  minter?: InputMaybe<Scalars['Bytes']>
  minter_not?: InputMaybe<Scalars['Bytes']>
  minter_in?: InputMaybe<Array<Scalars['Bytes']>>
  minter_not_in?: InputMaybe<Array<Scalars['Bytes']>>
  minter_contains?: InputMaybe<Scalars['Bytes']>
  minter_not_contains?: InputMaybe<Scalars['Bytes']>
  destination?: InputMaybe<Scalars['Bytes']>
  destination_not?: InputMaybe<Scalars['Bytes']>
  destination_in?: InputMaybe<Array<Scalars['Bytes']>>
  destination_not_in?: InputMaybe<Array<Scalars['Bytes']>>
  destination_contains?: InputMaybe<Scalars['Bytes']>
  destination_not_contains?: InputMaybe<Scalars['Bytes']>
  block?: InputMaybe<Scalars['BigInt']>
  block_not?: InputMaybe<Scalars['BigInt']>
  block_gt?: InputMaybe<Scalars['BigInt']>
  block_lt?: InputMaybe<Scalars['BigInt']>
  block_gte?: InputMaybe<Scalars['BigInt']>
  block_lte?: InputMaybe<Scalars['BigInt']>
  block_in?: InputMaybe<Array<Scalars['BigInt']>>
  block_not_in?: InputMaybe<Array<Scalars['BigInt']>>
  timestamp?: InputMaybe<Scalars['BigInt']>
  timestamp_not?: InputMaybe<Scalars['BigInt']>
  timestamp_gt?: InputMaybe<Scalars['BigInt']>
  timestamp_lt?: InputMaybe<Scalars['BigInt']>
  timestamp_gte?: InputMaybe<Scalars['BigInt']>
  timestamp_lte?: InputMaybe<Scalars['BigInt']>
  timestamp_in?: InputMaybe<Array<Scalars['BigInt']>>
  timestamp_not_in?: InputMaybe<Array<Scalars['BigInt']>>
  transaction?: InputMaybe<Scalars['Bytes']>
  transaction_not?: InputMaybe<Scalars['Bytes']>
  transaction_in?: InputMaybe<Array<Scalars['Bytes']>>
  transaction_not_in?: InputMaybe<Array<Scalars['Bytes']>>
  transaction_contains?: InputMaybe<Scalars['Bytes']>
  transaction_not_contains?: InputMaybe<Scalars['Bytes']>
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>
}

export type MintEvent_orderBy =
  | 'id'
  | 'token'
  | 'amount'
  | 'sender'
  | 'minter'
  | 'destination'
  | 'block'
  | 'timestamp'
  | 'transaction'

export type NFTToken = {
  id: Scalars['ID']
  contract: NFTTokenContract
  tokenId: Scalars['Int']
  creator?: Maybe<Account>
  owners?: Maybe<Array<OwnerTokenLookup>>
  createdAtTimeStamp: Scalars['BigInt']
  tokenURI: Scalars['String']
  bonusPercentage?: Maybe<Scalars['BigDecimal']>
  bonusDurationMins?: Maybe<Scalars['BigInt']>
  startTime?: Maybe<Scalars['BigInt']>
  name?: Maybe<Scalars['String']>
  kind?: Maybe<Scalars['String']>
  maxSupply?: Maybe<Scalars['Int']>
  mints?: Maybe<Scalars['Int']>
  burns?: Maybe<Scalars['Int']>
  eggMintAmt?: Maybe<Scalars['BigDecimal']>
  eggBurnAmt?: Maybe<Scalars['BigDecimal']>
}

export type NFTTokenownersArgs = {
  skip?: InputMaybe<Scalars['Int']>
  first?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<OwnerTokenLookup_orderBy>
  orderDirection?: InputMaybe<OrderDirection>
  where?: InputMaybe<OwnerTokenLookup_filter>
}

export type NFTTokenContract = {
  id: Scalars['ID']
  isLikelyERC1155: Scalars['Boolean']
  name?: Maybe<Scalars['String']>
  symbol?: Maybe<Scalars['String']>
  totalSupply?: Maybe<Scalars['Int']>
  maxSupply?: Maybe<Scalars['Int']>
  maxGen0Supply?: Maybe<Scalars['Int']>
  tokens: Array<NFTToken>
}

export type NFTTokenContracttokensArgs = {
  skip?: InputMaybe<Scalars['Int']>
  first?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<NFTToken_orderBy>
  orderDirection?: InputMaybe<OrderDirection>
  where?: InputMaybe<NFTToken_filter>
}

export type NFTTokenContract_filter = {
  id?: InputMaybe<Scalars['ID']>
  id_not?: InputMaybe<Scalars['ID']>
  id_gt?: InputMaybe<Scalars['ID']>
  id_lt?: InputMaybe<Scalars['ID']>
  id_gte?: InputMaybe<Scalars['ID']>
  id_lte?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  id_not_in?: InputMaybe<Array<Scalars['ID']>>
  isLikelyERC1155?: InputMaybe<Scalars['Boolean']>
  isLikelyERC1155_not?: InputMaybe<Scalars['Boolean']>
  isLikelyERC1155_in?: InputMaybe<Array<Scalars['Boolean']>>
  isLikelyERC1155_not_in?: InputMaybe<Array<Scalars['Boolean']>>
  name?: InputMaybe<Scalars['String']>
  name_not?: InputMaybe<Scalars['String']>
  name_gt?: InputMaybe<Scalars['String']>
  name_lt?: InputMaybe<Scalars['String']>
  name_gte?: InputMaybe<Scalars['String']>
  name_lte?: InputMaybe<Scalars['String']>
  name_in?: InputMaybe<Array<Scalars['String']>>
  name_not_in?: InputMaybe<Array<Scalars['String']>>
  name_contains?: InputMaybe<Scalars['String']>
  name_contains_nocase?: InputMaybe<Scalars['String']>
  name_not_contains?: InputMaybe<Scalars['String']>
  name_not_contains_nocase?: InputMaybe<Scalars['String']>
  name_starts_with?: InputMaybe<Scalars['String']>
  name_starts_with_nocase?: InputMaybe<Scalars['String']>
  name_not_starts_with?: InputMaybe<Scalars['String']>
  name_not_starts_with_nocase?: InputMaybe<Scalars['String']>
  name_ends_with?: InputMaybe<Scalars['String']>
  name_ends_with_nocase?: InputMaybe<Scalars['String']>
  name_not_ends_with?: InputMaybe<Scalars['String']>
  name_not_ends_with_nocase?: InputMaybe<Scalars['String']>
  symbol?: InputMaybe<Scalars['String']>
  symbol_not?: InputMaybe<Scalars['String']>
  symbol_gt?: InputMaybe<Scalars['String']>
  symbol_lt?: InputMaybe<Scalars['String']>
  symbol_gte?: InputMaybe<Scalars['String']>
  symbol_lte?: InputMaybe<Scalars['String']>
  symbol_in?: InputMaybe<Array<Scalars['String']>>
  symbol_not_in?: InputMaybe<Array<Scalars['String']>>
  symbol_contains?: InputMaybe<Scalars['String']>
  symbol_contains_nocase?: InputMaybe<Scalars['String']>
  symbol_not_contains?: InputMaybe<Scalars['String']>
  symbol_not_contains_nocase?: InputMaybe<Scalars['String']>
  symbol_starts_with?: InputMaybe<Scalars['String']>
  symbol_starts_with_nocase?: InputMaybe<Scalars['String']>
  symbol_not_starts_with?: InputMaybe<Scalars['String']>
  symbol_not_starts_with_nocase?: InputMaybe<Scalars['String']>
  symbol_ends_with?: InputMaybe<Scalars['String']>
  symbol_ends_with_nocase?: InputMaybe<Scalars['String']>
  symbol_not_ends_with?: InputMaybe<Scalars['String']>
  symbol_not_ends_with_nocase?: InputMaybe<Scalars['String']>
  totalSupply?: InputMaybe<Scalars['Int']>
  totalSupply_not?: InputMaybe<Scalars['Int']>
  totalSupply_gt?: InputMaybe<Scalars['Int']>
  totalSupply_lt?: InputMaybe<Scalars['Int']>
  totalSupply_gte?: InputMaybe<Scalars['Int']>
  totalSupply_lte?: InputMaybe<Scalars['Int']>
  totalSupply_in?: InputMaybe<Array<Scalars['Int']>>
  totalSupply_not_in?: InputMaybe<Array<Scalars['Int']>>
  maxSupply?: InputMaybe<Scalars['Int']>
  maxSupply_not?: InputMaybe<Scalars['Int']>
  maxSupply_gt?: InputMaybe<Scalars['Int']>
  maxSupply_lt?: InputMaybe<Scalars['Int']>
  maxSupply_gte?: InputMaybe<Scalars['Int']>
  maxSupply_lte?: InputMaybe<Scalars['Int']>
  maxSupply_in?: InputMaybe<Array<Scalars['Int']>>
  maxSupply_not_in?: InputMaybe<Array<Scalars['Int']>>
  maxGen0Supply?: InputMaybe<Scalars['Int']>
  maxGen0Supply_not?: InputMaybe<Scalars['Int']>
  maxGen0Supply_gt?: InputMaybe<Scalars['Int']>
  maxGen0Supply_lt?: InputMaybe<Scalars['Int']>
  maxGen0Supply_gte?: InputMaybe<Scalars['Int']>
  maxGen0Supply_lte?: InputMaybe<Scalars['Int']>
  maxGen0Supply_in?: InputMaybe<Array<Scalars['Int']>>
  maxGen0Supply_not_in?: InputMaybe<Array<Scalars['Int']>>
  tokens_?: InputMaybe<NFTToken_filter>
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>
}

export type NFTTokenContract_orderBy =
  | 'id'
  | 'isLikelyERC1155'
  | 'name'
  | 'symbol'
  | 'totalSupply'
  | 'maxSupply'
  | 'maxGen0Supply'
  | 'tokens'

export type NFTToken_filter = {
  id?: InputMaybe<Scalars['ID']>
  id_not?: InputMaybe<Scalars['ID']>
  id_gt?: InputMaybe<Scalars['ID']>
  id_lt?: InputMaybe<Scalars['ID']>
  id_gte?: InputMaybe<Scalars['ID']>
  id_lte?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  id_not_in?: InputMaybe<Array<Scalars['ID']>>
  contract?: InputMaybe<Scalars['String']>
  contract_not?: InputMaybe<Scalars['String']>
  contract_gt?: InputMaybe<Scalars['String']>
  contract_lt?: InputMaybe<Scalars['String']>
  contract_gte?: InputMaybe<Scalars['String']>
  contract_lte?: InputMaybe<Scalars['String']>
  contract_in?: InputMaybe<Array<Scalars['String']>>
  contract_not_in?: InputMaybe<Array<Scalars['String']>>
  contract_contains?: InputMaybe<Scalars['String']>
  contract_contains_nocase?: InputMaybe<Scalars['String']>
  contract_not_contains?: InputMaybe<Scalars['String']>
  contract_not_contains_nocase?: InputMaybe<Scalars['String']>
  contract_starts_with?: InputMaybe<Scalars['String']>
  contract_starts_with_nocase?: InputMaybe<Scalars['String']>
  contract_not_starts_with?: InputMaybe<Scalars['String']>
  contract_not_starts_with_nocase?: InputMaybe<Scalars['String']>
  contract_ends_with?: InputMaybe<Scalars['String']>
  contract_ends_with_nocase?: InputMaybe<Scalars['String']>
  contract_not_ends_with?: InputMaybe<Scalars['String']>
  contract_not_ends_with_nocase?: InputMaybe<Scalars['String']>
  contract_?: InputMaybe<NFTTokenContract_filter>
  tokenId?: InputMaybe<Scalars['Int']>
  tokenId_not?: InputMaybe<Scalars['Int']>
  tokenId_gt?: InputMaybe<Scalars['Int']>
  tokenId_lt?: InputMaybe<Scalars['Int']>
  tokenId_gte?: InputMaybe<Scalars['Int']>
  tokenId_lte?: InputMaybe<Scalars['Int']>
  tokenId_in?: InputMaybe<Array<Scalars['Int']>>
  tokenId_not_in?: InputMaybe<Array<Scalars['Int']>>
  creator?: InputMaybe<Scalars['String']>
  creator_not?: InputMaybe<Scalars['String']>
  creator_gt?: InputMaybe<Scalars['String']>
  creator_lt?: InputMaybe<Scalars['String']>
  creator_gte?: InputMaybe<Scalars['String']>
  creator_lte?: InputMaybe<Scalars['String']>
  creator_in?: InputMaybe<Array<Scalars['String']>>
  creator_not_in?: InputMaybe<Array<Scalars['String']>>
  creator_contains?: InputMaybe<Scalars['String']>
  creator_contains_nocase?: InputMaybe<Scalars['String']>
  creator_not_contains?: InputMaybe<Scalars['String']>
  creator_not_contains_nocase?: InputMaybe<Scalars['String']>
  creator_starts_with?: InputMaybe<Scalars['String']>
  creator_starts_with_nocase?: InputMaybe<Scalars['String']>
  creator_not_starts_with?: InputMaybe<Scalars['String']>
  creator_not_starts_with_nocase?: InputMaybe<Scalars['String']>
  creator_ends_with?: InputMaybe<Scalars['String']>
  creator_ends_with_nocase?: InputMaybe<Scalars['String']>
  creator_not_ends_with?: InputMaybe<Scalars['String']>
  creator_not_ends_with_nocase?: InputMaybe<Scalars['String']>
  creator_?: InputMaybe<Account_filter>
  owners_?: InputMaybe<OwnerTokenLookup_filter>
  createdAtTimeStamp?: InputMaybe<Scalars['BigInt']>
  createdAtTimeStamp_not?: InputMaybe<Scalars['BigInt']>
  createdAtTimeStamp_gt?: InputMaybe<Scalars['BigInt']>
  createdAtTimeStamp_lt?: InputMaybe<Scalars['BigInt']>
  createdAtTimeStamp_gte?: InputMaybe<Scalars['BigInt']>
  createdAtTimeStamp_lte?: InputMaybe<Scalars['BigInt']>
  createdAtTimeStamp_in?: InputMaybe<Array<Scalars['BigInt']>>
  createdAtTimeStamp_not_in?: InputMaybe<Array<Scalars['BigInt']>>
  tokenURI?: InputMaybe<Scalars['String']>
  tokenURI_not?: InputMaybe<Scalars['String']>
  tokenURI_gt?: InputMaybe<Scalars['String']>
  tokenURI_lt?: InputMaybe<Scalars['String']>
  tokenURI_gte?: InputMaybe<Scalars['String']>
  tokenURI_lte?: InputMaybe<Scalars['String']>
  tokenURI_in?: InputMaybe<Array<Scalars['String']>>
  tokenURI_not_in?: InputMaybe<Array<Scalars['String']>>
  tokenURI_contains?: InputMaybe<Scalars['String']>
  tokenURI_contains_nocase?: InputMaybe<Scalars['String']>
  tokenURI_not_contains?: InputMaybe<Scalars['String']>
  tokenURI_not_contains_nocase?: InputMaybe<Scalars['String']>
  tokenURI_starts_with?: InputMaybe<Scalars['String']>
  tokenURI_starts_with_nocase?: InputMaybe<Scalars['String']>
  tokenURI_not_starts_with?: InputMaybe<Scalars['String']>
  tokenURI_not_starts_with_nocase?: InputMaybe<Scalars['String']>
  tokenURI_ends_with?: InputMaybe<Scalars['String']>
  tokenURI_ends_with_nocase?: InputMaybe<Scalars['String']>
  tokenURI_not_ends_with?: InputMaybe<Scalars['String']>
  tokenURI_not_ends_with_nocase?: InputMaybe<Scalars['String']>
  bonusPercentage?: InputMaybe<Scalars['BigDecimal']>
  bonusPercentage_not?: InputMaybe<Scalars['BigDecimal']>
  bonusPercentage_gt?: InputMaybe<Scalars['BigDecimal']>
  bonusPercentage_lt?: InputMaybe<Scalars['BigDecimal']>
  bonusPercentage_gte?: InputMaybe<Scalars['BigDecimal']>
  bonusPercentage_lte?: InputMaybe<Scalars['BigDecimal']>
  bonusPercentage_in?: InputMaybe<Array<Scalars['BigDecimal']>>
  bonusPercentage_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>
  bonusDurationMins?: InputMaybe<Scalars['BigInt']>
  bonusDurationMins_not?: InputMaybe<Scalars['BigInt']>
  bonusDurationMins_gt?: InputMaybe<Scalars['BigInt']>
  bonusDurationMins_lt?: InputMaybe<Scalars['BigInt']>
  bonusDurationMins_gte?: InputMaybe<Scalars['BigInt']>
  bonusDurationMins_lte?: InputMaybe<Scalars['BigInt']>
  bonusDurationMins_in?: InputMaybe<Array<Scalars['BigInt']>>
  bonusDurationMins_not_in?: InputMaybe<Array<Scalars['BigInt']>>
  startTime?: InputMaybe<Scalars['BigInt']>
  startTime_not?: InputMaybe<Scalars['BigInt']>
  startTime_gt?: InputMaybe<Scalars['BigInt']>
  startTime_lt?: InputMaybe<Scalars['BigInt']>
  startTime_gte?: InputMaybe<Scalars['BigInt']>
  startTime_lte?: InputMaybe<Scalars['BigInt']>
  startTime_in?: InputMaybe<Array<Scalars['BigInt']>>
  startTime_not_in?: InputMaybe<Array<Scalars['BigInt']>>
  name?: InputMaybe<Scalars['String']>
  name_not?: InputMaybe<Scalars['String']>
  name_gt?: InputMaybe<Scalars['String']>
  name_lt?: InputMaybe<Scalars['String']>
  name_gte?: InputMaybe<Scalars['String']>
  name_lte?: InputMaybe<Scalars['String']>
  name_in?: InputMaybe<Array<Scalars['String']>>
  name_not_in?: InputMaybe<Array<Scalars['String']>>
  name_contains?: InputMaybe<Scalars['String']>
  name_contains_nocase?: InputMaybe<Scalars['String']>
  name_not_contains?: InputMaybe<Scalars['String']>
  name_not_contains_nocase?: InputMaybe<Scalars['String']>
  name_starts_with?: InputMaybe<Scalars['String']>
  name_starts_with_nocase?: InputMaybe<Scalars['String']>
  name_not_starts_with?: InputMaybe<Scalars['String']>
  name_not_starts_with_nocase?: InputMaybe<Scalars['String']>
  name_ends_with?: InputMaybe<Scalars['String']>
  name_ends_with_nocase?: InputMaybe<Scalars['String']>
  name_not_ends_with?: InputMaybe<Scalars['String']>
  name_not_ends_with_nocase?: InputMaybe<Scalars['String']>
  kind?: InputMaybe<Scalars['String']>
  kind_not?: InputMaybe<Scalars['String']>
  kind_gt?: InputMaybe<Scalars['String']>
  kind_lt?: InputMaybe<Scalars['String']>
  kind_gte?: InputMaybe<Scalars['String']>
  kind_lte?: InputMaybe<Scalars['String']>
  kind_in?: InputMaybe<Array<Scalars['String']>>
  kind_not_in?: InputMaybe<Array<Scalars['String']>>
  kind_contains?: InputMaybe<Scalars['String']>
  kind_contains_nocase?: InputMaybe<Scalars['String']>
  kind_not_contains?: InputMaybe<Scalars['String']>
  kind_not_contains_nocase?: InputMaybe<Scalars['String']>
  kind_starts_with?: InputMaybe<Scalars['String']>
  kind_starts_with_nocase?: InputMaybe<Scalars['String']>
  kind_not_starts_with?: InputMaybe<Scalars['String']>
  kind_not_starts_with_nocase?: InputMaybe<Scalars['String']>
  kind_ends_with?: InputMaybe<Scalars['String']>
  kind_ends_with_nocase?: InputMaybe<Scalars['String']>
  kind_not_ends_with?: InputMaybe<Scalars['String']>
  kind_not_ends_with_nocase?: InputMaybe<Scalars['String']>
  maxSupply?: InputMaybe<Scalars['Int']>
  maxSupply_not?: InputMaybe<Scalars['Int']>
  maxSupply_gt?: InputMaybe<Scalars['Int']>
  maxSupply_lt?: InputMaybe<Scalars['Int']>
  maxSupply_gte?: InputMaybe<Scalars['Int']>
  maxSupply_lte?: InputMaybe<Scalars['Int']>
  maxSupply_in?: InputMaybe<Array<Scalars['Int']>>
  maxSupply_not_in?: InputMaybe<Array<Scalars['Int']>>
  mints?: InputMaybe<Scalars['Int']>
  mints_not?: InputMaybe<Scalars['Int']>
  mints_gt?: InputMaybe<Scalars['Int']>
  mints_lt?: InputMaybe<Scalars['Int']>
  mints_gte?: InputMaybe<Scalars['Int']>
  mints_lte?: InputMaybe<Scalars['Int']>
  mints_in?: InputMaybe<Array<Scalars['Int']>>
  mints_not_in?: InputMaybe<Array<Scalars['Int']>>
  burns?: InputMaybe<Scalars['Int']>
  burns_not?: InputMaybe<Scalars['Int']>
  burns_gt?: InputMaybe<Scalars['Int']>
  burns_lt?: InputMaybe<Scalars['Int']>
  burns_gte?: InputMaybe<Scalars['Int']>
  burns_lte?: InputMaybe<Scalars['Int']>
  burns_in?: InputMaybe<Array<Scalars['Int']>>
  burns_not_in?: InputMaybe<Array<Scalars['Int']>>
  eggMintAmt?: InputMaybe<Scalars['BigDecimal']>
  eggMintAmt_not?: InputMaybe<Scalars['BigDecimal']>
  eggMintAmt_gt?: InputMaybe<Scalars['BigDecimal']>
  eggMintAmt_lt?: InputMaybe<Scalars['BigDecimal']>
  eggMintAmt_gte?: InputMaybe<Scalars['BigDecimal']>
  eggMintAmt_lte?: InputMaybe<Scalars['BigDecimal']>
  eggMintAmt_in?: InputMaybe<Array<Scalars['BigDecimal']>>
  eggMintAmt_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>
  eggBurnAmt?: InputMaybe<Scalars['BigDecimal']>
  eggBurnAmt_not?: InputMaybe<Scalars['BigDecimal']>
  eggBurnAmt_gt?: InputMaybe<Scalars['BigDecimal']>
  eggBurnAmt_lt?: InputMaybe<Scalars['BigDecimal']>
  eggBurnAmt_gte?: InputMaybe<Scalars['BigDecimal']>
  eggBurnAmt_lte?: InputMaybe<Scalars['BigDecimal']>
  eggBurnAmt_in?: InputMaybe<Array<Scalars['BigDecimal']>>
  eggBurnAmt_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>
}

export type NFTToken_orderBy =
  | 'id'
  | 'contract'
  | 'tokenId'
  | 'creator'
  | 'owners'
  | 'createdAtTimeStamp'
  | 'tokenURI'
  | 'bonusPercentage'
  | 'bonusDurationMins'
  | 'startTime'
  | 'name'
  | 'kind'
  | 'maxSupply'
  | 'mints'
  | 'burns'
  | 'eggMintAmt'
  | 'eggBurnAmt'

/** Defines the order direction, either ascending or descending */
export type OrderDirection = 'asc' | 'desc'

export type OwnerTokenLookup = {
  id: Scalars['ID']
  owner: Account
  token: NFTToken
  contract: NFTTokenContract
  quantity: Scalars['BigInt']
}

export type OwnerTokenLookup_filter = {
  id?: InputMaybe<Scalars['ID']>
  id_not?: InputMaybe<Scalars['ID']>
  id_gt?: InputMaybe<Scalars['ID']>
  id_lt?: InputMaybe<Scalars['ID']>
  id_gte?: InputMaybe<Scalars['ID']>
  id_lte?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  id_not_in?: InputMaybe<Array<Scalars['ID']>>
  owner?: InputMaybe<Scalars['String']>
  owner_not?: InputMaybe<Scalars['String']>
  owner_gt?: InputMaybe<Scalars['String']>
  owner_lt?: InputMaybe<Scalars['String']>
  owner_gte?: InputMaybe<Scalars['String']>
  owner_lte?: InputMaybe<Scalars['String']>
  owner_in?: InputMaybe<Array<Scalars['String']>>
  owner_not_in?: InputMaybe<Array<Scalars['String']>>
  owner_contains?: InputMaybe<Scalars['String']>
  owner_contains_nocase?: InputMaybe<Scalars['String']>
  owner_not_contains?: InputMaybe<Scalars['String']>
  owner_not_contains_nocase?: InputMaybe<Scalars['String']>
  owner_starts_with?: InputMaybe<Scalars['String']>
  owner_starts_with_nocase?: InputMaybe<Scalars['String']>
  owner_not_starts_with?: InputMaybe<Scalars['String']>
  owner_not_starts_with_nocase?: InputMaybe<Scalars['String']>
  owner_ends_with?: InputMaybe<Scalars['String']>
  owner_ends_with_nocase?: InputMaybe<Scalars['String']>
  owner_not_ends_with?: InputMaybe<Scalars['String']>
  owner_not_ends_with_nocase?: InputMaybe<Scalars['String']>
  owner_?: InputMaybe<Account_filter>
  token?: InputMaybe<Scalars['String']>
  token_not?: InputMaybe<Scalars['String']>
  token_gt?: InputMaybe<Scalars['String']>
  token_lt?: InputMaybe<Scalars['String']>
  token_gte?: InputMaybe<Scalars['String']>
  token_lte?: InputMaybe<Scalars['String']>
  token_in?: InputMaybe<Array<Scalars['String']>>
  token_not_in?: InputMaybe<Array<Scalars['String']>>
  token_contains?: InputMaybe<Scalars['String']>
  token_contains_nocase?: InputMaybe<Scalars['String']>
  token_not_contains?: InputMaybe<Scalars['String']>
  token_not_contains_nocase?: InputMaybe<Scalars['String']>
  token_starts_with?: InputMaybe<Scalars['String']>
  token_starts_with_nocase?: InputMaybe<Scalars['String']>
  token_not_starts_with?: InputMaybe<Scalars['String']>
  token_not_starts_with_nocase?: InputMaybe<Scalars['String']>
  token_ends_with?: InputMaybe<Scalars['String']>
  token_ends_with_nocase?: InputMaybe<Scalars['String']>
  token_not_ends_with?: InputMaybe<Scalars['String']>
  token_not_ends_with_nocase?: InputMaybe<Scalars['String']>
  token_?: InputMaybe<NFTToken_filter>
  contract?: InputMaybe<Scalars['String']>
  contract_not?: InputMaybe<Scalars['String']>
  contract_gt?: InputMaybe<Scalars['String']>
  contract_lt?: InputMaybe<Scalars['String']>
  contract_gte?: InputMaybe<Scalars['String']>
  contract_lte?: InputMaybe<Scalars['String']>
  contract_in?: InputMaybe<Array<Scalars['String']>>
  contract_not_in?: InputMaybe<Array<Scalars['String']>>
  contract_contains?: InputMaybe<Scalars['String']>
  contract_contains_nocase?: InputMaybe<Scalars['String']>
  contract_not_contains?: InputMaybe<Scalars['String']>
  contract_not_contains_nocase?: InputMaybe<Scalars['String']>
  contract_starts_with?: InputMaybe<Scalars['String']>
  contract_starts_with_nocase?: InputMaybe<Scalars['String']>
  contract_not_starts_with?: InputMaybe<Scalars['String']>
  contract_not_starts_with_nocase?: InputMaybe<Scalars['String']>
  contract_ends_with?: InputMaybe<Scalars['String']>
  contract_ends_with_nocase?: InputMaybe<Scalars['String']>
  contract_not_ends_with?: InputMaybe<Scalars['String']>
  contract_not_ends_with_nocase?: InputMaybe<Scalars['String']>
  contract_?: InputMaybe<NFTTokenContract_filter>
  quantity?: InputMaybe<Scalars['BigInt']>
  quantity_not?: InputMaybe<Scalars['BigInt']>
  quantity_gt?: InputMaybe<Scalars['BigInt']>
  quantity_lt?: InputMaybe<Scalars['BigInt']>
  quantity_gte?: InputMaybe<Scalars['BigInt']>
  quantity_lte?: InputMaybe<Scalars['BigInt']>
  quantity_in?: InputMaybe<Array<Scalars['BigInt']>>
  quantity_not_in?: InputMaybe<Array<Scalars['BigInt']>>
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>
}

export type OwnerTokenLookup_orderBy = 'id' | 'owner' | 'token' | 'contract' | 'quantity'

export type PauseEvent = TokenEvent & {
  id: Scalars['ID']
  token: Token
  amount: Scalars['BigDecimal']
  sender: Scalars['Bytes']
  pauser: Scalars['Bytes']
  block: Scalars['BigInt']
  timestamp: Scalars['BigInt']
  transaction: Scalars['Bytes']
}

export type PauseEvent_filter = {
  id?: InputMaybe<Scalars['ID']>
  id_not?: InputMaybe<Scalars['ID']>
  id_gt?: InputMaybe<Scalars['ID']>
  id_lt?: InputMaybe<Scalars['ID']>
  id_gte?: InputMaybe<Scalars['ID']>
  id_lte?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  id_not_in?: InputMaybe<Array<Scalars['ID']>>
  token?: InputMaybe<Scalars['String']>
  token_not?: InputMaybe<Scalars['String']>
  token_gt?: InputMaybe<Scalars['String']>
  token_lt?: InputMaybe<Scalars['String']>
  token_gte?: InputMaybe<Scalars['String']>
  token_lte?: InputMaybe<Scalars['String']>
  token_in?: InputMaybe<Array<Scalars['String']>>
  token_not_in?: InputMaybe<Array<Scalars['String']>>
  token_contains?: InputMaybe<Scalars['String']>
  token_contains_nocase?: InputMaybe<Scalars['String']>
  token_not_contains?: InputMaybe<Scalars['String']>
  token_not_contains_nocase?: InputMaybe<Scalars['String']>
  token_starts_with?: InputMaybe<Scalars['String']>
  token_starts_with_nocase?: InputMaybe<Scalars['String']>
  token_not_starts_with?: InputMaybe<Scalars['String']>
  token_not_starts_with_nocase?: InputMaybe<Scalars['String']>
  token_ends_with?: InputMaybe<Scalars['String']>
  token_ends_with_nocase?: InputMaybe<Scalars['String']>
  token_not_ends_with?: InputMaybe<Scalars['String']>
  token_not_ends_with_nocase?: InputMaybe<Scalars['String']>
  token_?: InputMaybe<Token_filter>
  amount?: InputMaybe<Scalars['BigDecimal']>
  amount_not?: InputMaybe<Scalars['BigDecimal']>
  amount_gt?: InputMaybe<Scalars['BigDecimal']>
  amount_lt?: InputMaybe<Scalars['BigDecimal']>
  amount_gte?: InputMaybe<Scalars['BigDecimal']>
  amount_lte?: InputMaybe<Scalars['BigDecimal']>
  amount_in?: InputMaybe<Array<Scalars['BigDecimal']>>
  amount_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>
  sender?: InputMaybe<Scalars['Bytes']>
  sender_not?: InputMaybe<Scalars['Bytes']>
  sender_in?: InputMaybe<Array<Scalars['Bytes']>>
  sender_not_in?: InputMaybe<Array<Scalars['Bytes']>>
  sender_contains?: InputMaybe<Scalars['Bytes']>
  sender_not_contains?: InputMaybe<Scalars['Bytes']>
  pauser?: InputMaybe<Scalars['Bytes']>
  pauser_not?: InputMaybe<Scalars['Bytes']>
  pauser_in?: InputMaybe<Array<Scalars['Bytes']>>
  pauser_not_in?: InputMaybe<Array<Scalars['Bytes']>>
  pauser_contains?: InputMaybe<Scalars['Bytes']>
  pauser_not_contains?: InputMaybe<Scalars['Bytes']>
  block?: InputMaybe<Scalars['BigInt']>
  block_not?: InputMaybe<Scalars['BigInt']>
  block_gt?: InputMaybe<Scalars['BigInt']>
  block_lt?: InputMaybe<Scalars['BigInt']>
  block_gte?: InputMaybe<Scalars['BigInt']>
  block_lte?: InputMaybe<Scalars['BigInt']>
  block_in?: InputMaybe<Array<Scalars['BigInt']>>
  block_not_in?: InputMaybe<Array<Scalars['BigInt']>>
  timestamp?: InputMaybe<Scalars['BigInt']>
  timestamp_not?: InputMaybe<Scalars['BigInt']>
  timestamp_gt?: InputMaybe<Scalars['BigInt']>
  timestamp_lt?: InputMaybe<Scalars['BigInt']>
  timestamp_gte?: InputMaybe<Scalars['BigInt']>
  timestamp_lte?: InputMaybe<Scalars['BigInt']>
  timestamp_in?: InputMaybe<Array<Scalars['BigInt']>>
  timestamp_not_in?: InputMaybe<Array<Scalars['BigInt']>>
  transaction?: InputMaybe<Scalars['Bytes']>
  transaction_not?: InputMaybe<Scalars['Bytes']>
  transaction_in?: InputMaybe<Array<Scalars['Bytes']>>
  transaction_not_in?: InputMaybe<Array<Scalars['Bytes']>>
  transaction_contains?: InputMaybe<Scalars['Bytes']>
  transaction_not_contains?: InputMaybe<Scalars['Bytes']>
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>
}

export type PauseEvent_orderBy = 'id' | 'token' | 'amount' | 'sender' | 'pauser' | 'block' | 'timestamp' | 'transaction'

export type Query = {
  tfgdata?: Maybe<TFGData>
  tfgdatas: Array<TFGData>
  account?: Maybe<Account>
  accounts: Array<Account>
  accountBalance?: Maybe<AccountBalance>
  accountBalances: Array<AccountBalance>
  accountBalanceSnapshot?: Maybe<AccountBalanceSnapshot>
  accountBalanceSnapshots: Array<AccountBalanceSnapshot>
  token?: Maybe<Token>
  tokens: Array<Token>
  burnEvent?: Maybe<BurnEvent>
  burnEvents: Array<BurnEvent>
  mintEvent?: Maybe<MintEvent>
  mintEvents: Array<MintEvent>
  transferEvent?: Maybe<TransferEvent>
  transferEvents: Array<TransferEvent>
  pauseEvent?: Maybe<PauseEvent>
  pauseEvents: Array<PauseEvent>
  nfttoken?: Maybe<NFTToken>
  nfttokens: Array<NFTToken>
  ownerTokenLookup?: Maybe<OwnerTokenLookup>
  ownerTokenLookups: Array<OwnerTokenLookup>
  nfttokenContract?: Maybe<NFTTokenContract>
  nfttokenContracts: Array<NFTTokenContract>
  tokenStaked?: Maybe<TokenStaked>
  tokenStakeds: Array<TokenStaked>
  roosterReceivedDroppedEgg?: Maybe<RoosterReceivedDroppedEgg>
  roosterReceivedDroppedEggs: Array<RoosterReceivedDroppedEgg>
  goldenEggAwarded?: Maybe<GoldenEggAwarded>
  goldenEggAwardeds: Array<GoldenEggAwarded>
  specialMint?: Maybe<SpecialMint>
  specialMints: Array<SpecialMint>
  tfgstat?: Maybe<TFGStat>
  tfgstats: Array<TFGStat>
  tokenEvent?: Maybe<TokenEvent>
  tokenEvents: Array<TokenEvent>
  /** Access to subgraph metadata */
  _meta?: Maybe<_Meta_>
}

export type QuerytfgdataArgs = {
  id: Scalars['ID']
  block?: InputMaybe<Block_height>
  subgraphError?: _SubgraphErrorPolicy_
}

export type QuerytfgdatasArgs = {
  skip?: InputMaybe<Scalars['Int']>
  first?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<TFGData_orderBy>
  orderDirection?: InputMaybe<OrderDirection>
  where?: InputMaybe<TFGData_filter>
  block?: InputMaybe<Block_height>
  subgraphError?: _SubgraphErrorPolicy_
}

export type QueryaccountArgs = {
  id: Scalars['ID']
  block?: InputMaybe<Block_height>
  subgraphError?: _SubgraphErrorPolicy_
}

export type QueryaccountsArgs = {
  skip?: InputMaybe<Scalars['Int']>
  first?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Account_orderBy>
  orderDirection?: InputMaybe<OrderDirection>
  where?: InputMaybe<Account_filter>
  block?: InputMaybe<Block_height>
  subgraphError?: _SubgraphErrorPolicy_
}

export type QueryaccountBalanceArgs = {
  id: Scalars['ID']
  block?: InputMaybe<Block_height>
  subgraphError?: _SubgraphErrorPolicy_
}

export type QueryaccountBalancesArgs = {
  skip?: InputMaybe<Scalars['Int']>
  first?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<AccountBalance_orderBy>
  orderDirection?: InputMaybe<OrderDirection>
  where?: InputMaybe<AccountBalance_filter>
  block?: InputMaybe<Block_height>
  subgraphError?: _SubgraphErrorPolicy_
}

export type QueryaccountBalanceSnapshotArgs = {
  id: Scalars['ID']
  block?: InputMaybe<Block_height>
  subgraphError?: _SubgraphErrorPolicy_
}

export type QueryaccountBalanceSnapshotsArgs = {
  skip?: InputMaybe<Scalars['Int']>
  first?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<AccountBalanceSnapshot_orderBy>
  orderDirection?: InputMaybe<OrderDirection>
  where?: InputMaybe<AccountBalanceSnapshot_filter>
  block?: InputMaybe<Block_height>
  subgraphError?: _SubgraphErrorPolicy_
}

export type QuerytokenArgs = {
  id: Scalars['ID']
  block?: InputMaybe<Block_height>
  subgraphError?: _SubgraphErrorPolicy_
}

export type QuerytokensArgs = {
  skip?: InputMaybe<Scalars['Int']>
  first?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Token_orderBy>
  orderDirection?: InputMaybe<OrderDirection>
  where?: InputMaybe<Token_filter>
  block?: InputMaybe<Block_height>
  subgraphError?: _SubgraphErrorPolicy_
}

export type QueryburnEventArgs = {
  id: Scalars['ID']
  block?: InputMaybe<Block_height>
  subgraphError?: _SubgraphErrorPolicy_
}

export type QueryburnEventsArgs = {
  skip?: InputMaybe<Scalars['Int']>
  first?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<BurnEvent_orderBy>
  orderDirection?: InputMaybe<OrderDirection>
  where?: InputMaybe<BurnEvent_filter>
  block?: InputMaybe<Block_height>
  subgraphError?: _SubgraphErrorPolicy_
}

export type QuerymintEventArgs = {
  id: Scalars['ID']
  block?: InputMaybe<Block_height>
  subgraphError?: _SubgraphErrorPolicy_
}

export type QuerymintEventsArgs = {
  skip?: InputMaybe<Scalars['Int']>
  first?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<MintEvent_orderBy>
  orderDirection?: InputMaybe<OrderDirection>
  where?: InputMaybe<MintEvent_filter>
  block?: InputMaybe<Block_height>
  subgraphError?: _SubgraphErrorPolicy_
}

export type QuerytransferEventArgs = {
  id: Scalars['ID']
  block?: InputMaybe<Block_height>
  subgraphError?: _SubgraphErrorPolicy_
}

export type QuerytransferEventsArgs = {
  skip?: InputMaybe<Scalars['Int']>
  first?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<TransferEvent_orderBy>
  orderDirection?: InputMaybe<OrderDirection>
  where?: InputMaybe<TransferEvent_filter>
  block?: InputMaybe<Block_height>
  subgraphError?: _SubgraphErrorPolicy_
}

export type QuerypauseEventArgs = {
  id: Scalars['ID']
  block?: InputMaybe<Block_height>
  subgraphError?: _SubgraphErrorPolicy_
}

export type QuerypauseEventsArgs = {
  skip?: InputMaybe<Scalars['Int']>
  first?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<PauseEvent_orderBy>
  orderDirection?: InputMaybe<OrderDirection>
  where?: InputMaybe<PauseEvent_filter>
  block?: InputMaybe<Block_height>
  subgraphError?: _SubgraphErrorPolicy_
}

export type QuerynfttokenArgs = {
  id: Scalars['ID']
  block?: InputMaybe<Block_height>
  subgraphError?: _SubgraphErrorPolicy_
}

export type QuerynfttokensArgs = {
  skip?: InputMaybe<Scalars['Int']>
  first?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<NFTToken_orderBy>
  orderDirection?: InputMaybe<OrderDirection>
  where?: InputMaybe<NFTToken_filter>
  block?: InputMaybe<Block_height>
  subgraphError?: _SubgraphErrorPolicy_
}

export type QueryownerTokenLookupArgs = {
  id: Scalars['ID']
  block?: InputMaybe<Block_height>
  subgraphError?: _SubgraphErrorPolicy_
}

export type QueryownerTokenLookupsArgs = {
  skip?: InputMaybe<Scalars['Int']>
  first?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<OwnerTokenLookup_orderBy>
  orderDirection?: InputMaybe<OrderDirection>
  where?: InputMaybe<OwnerTokenLookup_filter>
  block?: InputMaybe<Block_height>
  subgraphError?: _SubgraphErrorPolicy_
}

export type QuerynfttokenContractArgs = {
  id: Scalars['ID']
  block?: InputMaybe<Block_height>
  subgraphError?: _SubgraphErrorPolicy_
}

export type QuerynfttokenContractsArgs = {
  skip?: InputMaybe<Scalars['Int']>
  first?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<NFTTokenContract_orderBy>
  orderDirection?: InputMaybe<OrderDirection>
  where?: InputMaybe<NFTTokenContract_filter>
  block?: InputMaybe<Block_height>
  subgraphError?: _SubgraphErrorPolicy_
}

export type QuerytokenStakedArgs = {
  id: Scalars['ID']
  block?: InputMaybe<Block_height>
  subgraphError?: _SubgraphErrorPolicy_
}

export type QuerytokenStakedsArgs = {
  skip?: InputMaybe<Scalars['Int']>
  first?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<TokenStaked_orderBy>
  orderDirection?: InputMaybe<OrderDirection>
  where?: InputMaybe<TokenStaked_filter>
  block?: InputMaybe<Block_height>
  subgraphError?: _SubgraphErrorPolicy_
}

export type QueryroosterReceivedDroppedEggArgs = {
  id: Scalars['ID']
  block?: InputMaybe<Block_height>
  subgraphError?: _SubgraphErrorPolicy_
}

export type QueryroosterReceivedDroppedEggsArgs = {
  skip?: InputMaybe<Scalars['Int']>
  first?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<RoosterReceivedDroppedEgg_orderBy>
  orderDirection?: InputMaybe<OrderDirection>
  where?: InputMaybe<RoosterReceivedDroppedEgg_filter>
  block?: InputMaybe<Block_height>
  subgraphError?: _SubgraphErrorPolicy_
}

export type QuerygoldenEggAwardedArgs = {
  id: Scalars['ID']
  block?: InputMaybe<Block_height>
  subgraphError?: _SubgraphErrorPolicy_
}

export type QuerygoldenEggAwardedsArgs = {
  skip?: InputMaybe<Scalars['Int']>
  first?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<GoldenEggAwarded_orderBy>
  orderDirection?: InputMaybe<OrderDirection>
  where?: InputMaybe<GoldenEggAwarded_filter>
  block?: InputMaybe<Block_height>
  subgraphError?: _SubgraphErrorPolicy_
}

export type QueryspecialMintArgs = {
  id: Scalars['ID']
  block?: InputMaybe<Block_height>
  subgraphError?: _SubgraphErrorPolicy_
}

export type QueryspecialMintsArgs = {
  skip?: InputMaybe<Scalars['Int']>
  first?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<SpecialMint_orderBy>
  orderDirection?: InputMaybe<OrderDirection>
  where?: InputMaybe<SpecialMint_filter>
  block?: InputMaybe<Block_height>
  subgraphError?: _SubgraphErrorPolicy_
}

export type QuerytfgstatArgs = {
  id: Scalars['ID']
  block?: InputMaybe<Block_height>
  subgraphError?: _SubgraphErrorPolicy_
}

export type QuerytfgstatsArgs = {
  skip?: InputMaybe<Scalars['Int']>
  first?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<TFGStat_orderBy>
  orderDirection?: InputMaybe<OrderDirection>
  where?: InputMaybe<TFGStat_filter>
  block?: InputMaybe<Block_height>
  subgraphError?: _SubgraphErrorPolicy_
}

export type QuerytokenEventArgs = {
  id: Scalars['ID']
  block?: InputMaybe<Block_height>
  subgraphError?: _SubgraphErrorPolicy_
}

export type QuerytokenEventsArgs = {
  skip?: InputMaybe<Scalars['Int']>
  first?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<TokenEvent_orderBy>
  orderDirection?: InputMaybe<OrderDirection>
  where?: InputMaybe<TokenEvent_filter>
  block?: InputMaybe<Block_height>
  subgraphError?: _SubgraphErrorPolicy_
}

export type Query_metaArgs = {
  block?: InputMaybe<Block_height>
}

export type RoosterReceivedDroppedEgg = {
  id: Scalars['ID']
  owner: Scalars['Bytes']
  tokenId: Scalars['Int']
  amount: Scalars['BigInt']
}

export type RoosterReceivedDroppedEgg_filter = {
  id?: InputMaybe<Scalars['ID']>
  id_not?: InputMaybe<Scalars['ID']>
  id_gt?: InputMaybe<Scalars['ID']>
  id_lt?: InputMaybe<Scalars['ID']>
  id_gte?: InputMaybe<Scalars['ID']>
  id_lte?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  id_not_in?: InputMaybe<Array<Scalars['ID']>>
  owner?: InputMaybe<Scalars['Bytes']>
  owner_not?: InputMaybe<Scalars['Bytes']>
  owner_in?: InputMaybe<Array<Scalars['Bytes']>>
  owner_not_in?: InputMaybe<Array<Scalars['Bytes']>>
  owner_contains?: InputMaybe<Scalars['Bytes']>
  owner_not_contains?: InputMaybe<Scalars['Bytes']>
  tokenId?: InputMaybe<Scalars['Int']>
  tokenId_not?: InputMaybe<Scalars['Int']>
  tokenId_gt?: InputMaybe<Scalars['Int']>
  tokenId_lt?: InputMaybe<Scalars['Int']>
  tokenId_gte?: InputMaybe<Scalars['Int']>
  tokenId_lte?: InputMaybe<Scalars['Int']>
  tokenId_in?: InputMaybe<Array<Scalars['Int']>>
  tokenId_not_in?: InputMaybe<Array<Scalars['Int']>>
  amount?: InputMaybe<Scalars['BigInt']>
  amount_not?: InputMaybe<Scalars['BigInt']>
  amount_gt?: InputMaybe<Scalars['BigInt']>
  amount_lt?: InputMaybe<Scalars['BigInt']>
  amount_gte?: InputMaybe<Scalars['BigInt']>
  amount_lte?: InputMaybe<Scalars['BigInt']>
  amount_in?: InputMaybe<Array<Scalars['BigInt']>>
  amount_not_in?: InputMaybe<Array<Scalars['BigInt']>>
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>
}

export type RoosterReceivedDroppedEgg_orderBy = 'id' | 'owner' | 'tokenId' | 'amount'

export type SpecialMint = {
  id: Scalars['ID']
  typeId: Scalars['Int']
  imperialEggQtys?: Maybe<Scalars['BigInt']>
  bonusEGGDuration?: Maybe<Scalars['BigInt']>
  bonusEGGPercentage?: Maybe<Scalars['Int']>
  bonusEGGAmount?: Maybe<Scalars['BigInt']>
  specialMintFee: Scalars['BigDecimal']
  maxSupply: Scalars['Int']
  minted?: Maybe<Scalars['Int']>
}

export type SpecialMint_filter = {
  id?: InputMaybe<Scalars['ID']>
  id_not?: InputMaybe<Scalars['ID']>
  id_gt?: InputMaybe<Scalars['ID']>
  id_lt?: InputMaybe<Scalars['ID']>
  id_gte?: InputMaybe<Scalars['ID']>
  id_lte?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  id_not_in?: InputMaybe<Array<Scalars['ID']>>
  typeId?: InputMaybe<Scalars['Int']>
  typeId_not?: InputMaybe<Scalars['Int']>
  typeId_gt?: InputMaybe<Scalars['Int']>
  typeId_lt?: InputMaybe<Scalars['Int']>
  typeId_gte?: InputMaybe<Scalars['Int']>
  typeId_lte?: InputMaybe<Scalars['Int']>
  typeId_in?: InputMaybe<Array<Scalars['Int']>>
  typeId_not_in?: InputMaybe<Array<Scalars['Int']>>
  imperialEggQtys?: InputMaybe<Scalars['BigInt']>
  imperialEggQtys_not?: InputMaybe<Scalars['BigInt']>
  imperialEggQtys_gt?: InputMaybe<Scalars['BigInt']>
  imperialEggQtys_lt?: InputMaybe<Scalars['BigInt']>
  imperialEggQtys_gte?: InputMaybe<Scalars['BigInt']>
  imperialEggQtys_lte?: InputMaybe<Scalars['BigInt']>
  imperialEggQtys_in?: InputMaybe<Array<Scalars['BigInt']>>
  imperialEggQtys_not_in?: InputMaybe<Array<Scalars['BigInt']>>
  bonusEGGDuration?: InputMaybe<Scalars['BigInt']>
  bonusEGGDuration_not?: InputMaybe<Scalars['BigInt']>
  bonusEGGDuration_gt?: InputMaybe<Scalars['BigInt']>
  bonusEGGDuration_lt?: InputMaybe<Scalars['BigInt']>
  bonusEGGDuration_gte?: InputMaybe<Scalars['BigInt']>
  bonusEGGDuration_lte?: InputMaybe<Scalars['BigInt']>
  bonusEGGDuration_in?: InputMaybe<Array<Scalars['BigInt']>>
  bonusEGGDuration_not_in?: InputMaybe<Array<Scalars['BigInt']>>
  bonusEGGPercentage?: InputMaybe<Scalars['Int']>
  bonusEGGPercentage_not?: InputMaybe<Scalars['Int']>
  bonusEGGPercentage_gt?: InputMaybe<Scalars['Int']>
  bonusEGGPercentage_lt?: InputMaybe<Scalars['Int']>
  bonusEGGPercentage_gte?: InputMaybe<Scalars['Int']>
  bonusEGGPercentage_lte?: InputMaybe<Scalars['Int']>
  bonusEGGPercentage_in?: InputMaybe<Array<Scalars['Int']>>
  bonusEGGPercentage_not_in?: InputMaybe<Array<Scalars['Int']>>
  bonusEGGAmount?: InputMaybe<Scalars['BigInt']>
  bonusEGGAmount_not?: InputMaybe<Scalars['BigInt']>
  bonusEGGAmount_gt?: InputMaybe<Scalars['BigInt']>
  bonusEGGAmount_lt?: InputMaybe<Scalars['BigInt']>
  bonusEGGAmount_gte?: InputMaybe<Scalars['BigInt']>
  bonusEGGAmount_lte?: InputMaybe<Scalars['BigInt']>
  bonusEGGAmount_in?: InputMaybe<Array<Scalars['BigInt']>>
  bonusEGGAmount_not_in?: InputMaybe<Array<Scalars['BigInt']>>
  specialMintFee?: InputMaybe<Scalars['BigDecimal']>
  specialMintFee_not?: InputMaybe<Scalars['BigDecimal']>
  specialMintFee_gt?: InputMaybe<Scalars['BigDecimal']>
  specialMintFee_lt?: InputMaybe<Scalars['BigDecimal']>
  specialMintFee_gte?: InputMaybe<Scalars['BigDecimal']>
  specialMintFee_lte?: InputMaybe<Scalars['BigDecimal']>
  specialMintFee_in?: InputMaybe<Array<Scalars['BigDecimal']>>
  specialMintFee_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>
  maxSupply?: InputMaybe<Scalars['Int']>
  maxSupply_not?: InputMaybe<Scalars['Int']>
  maxSupply_gt?: InputMaybe<Scalars['Int']>
  maxSupply_lt?: InputMaybe<Scalars['Int']>
  maxSupply_gte?: InputMaybe<Scalars['Int']>
  maxSupply_lte?: InputMaybe<Scalars['Int']>
  maxSupply_in?: InputMaybe<Array<Scalars['Int']>>
  maxSupply_not_in?: InputMaybe<Array<Scalars['Int']>>
  minted?: InputMaybe<Scalars['Int']>
  minted_not?: InputMaybe<Scalars['Int']>
  minted_gt?: InputMaybe<Scalars['Int']>
  minted_lt?: InputMaybe<Scalars['Int']>
  minted_gte?: InputMaybe<Scalars['Int']>
  minted_lte?: InputMaybe<Scalars['Int']>
  minted_in?: InputMaybe<Array<Scalars['Int']>>
  minted_not_in?: InputMaybe<Array<Scalars['Int']>>
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>
}

export type SpecialMint_orderBy =
  | 'id'
  | 'typeId'
  | 'imperialEggQtys'
  | 'bonusEGGDuration'
  | 'bonusEGGPercentage'
  | 'bonusEGGAmount'
  | 'specialMintFee'
  | 'maxSupply'
  | 'minted'

export type Subscription = {
  tfgdata?: Maybe<TFGData>
  tfgdatas: Array<TFGData>
  account?: Maybe<Account>
  accounts: Array<Account>
  accountBalance?: Maybe<AccountBalance>
  accountBalances: Array<AccountBalance>
  accountBalanceSnapshot?: Maybe<AccountBalanceSnapshot>
  accountBalanceSnapshots: Array<AccountBalanceSnapshot>
  token?: Maybe<Token>
  tokens: Array<Token>
  burnEvent?: Maybe<BurnEvent>
  burnEvents: Array<BurnEvent>
  mintEvent?: Maybe<MintEvent>
  mintEvents: Array<MintEvent>
  transferEvent?: Maybe<TransferEvent>
  transferEvents: Array<TransferEvent>
  pauseEvent?: Maybe<PauseEvent>
  pauseEvents: Array<PauseEvent>
  nfttoken?: Maybe<NFTToken>
  nfttokens: Array<NFTToken>
  ownerTokenLookup?: Maybe<OwnerTokenLookup>
  ownerTokenLookups: Array<OwnerTokenLookup>
  nfttokenContract?: Maybe<NFTTokenContract>
  nfttokenContracts: Array<NFTTokenContract>
  tokenStaked?: Maybe<TokenStaked>
  tokenStakeds: Array<TokenStaked>
  roosterReceivedDroppedEgg?: Maybe<RoosterReceivedDroppedEgg>
  roosterReceivedDroppedEggs: Array<RoosterReceivedDroppedEgg>
  goldenEggAwarded?: Maybe<GoldenEggAwarded>
  goldenEggAwardeds: Array<GoldenEggAwarded>
  specialMint?: Maybe<SpecialMint>
  specialMints: Array<SpecialMint>
  tfgstat?: Maybe<TFGStat>
  tfgstats: Array<TFGStat>
  tokenEvent?: Maybe<TokenEvent>
  tokenEvents: Array<TokenEvent>
  /** Access to subgraph metadata */
  _meta?: Maybe<_Meta_>
}

export type SubscriptiontfgdataArgs = {
  id: Scalars['ID']
  block?: InputMaybe<Block_height>
  subgraphError?: _SubgraphErrorPolicy_
}

export type SubscriptiontfgdatasArgs = {
  skip?: InputMaybe<Scalars['Int']>
  first?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<TFGData_orderBy>
  orderDirection?: InputMaybe<OrderDirection>
  where?: InputMaybe<TFGData_filter>
  block?: InputMaybe<Block_height>
  subgraphError?: _SubgraphErrorPolicy_
}

export type SubscriptionaccountArgs = {
  id: Scalars['ID']
  block?: InputMaybe<Block_height>
  subgraphError?: _SubgraphErrorPolicy_
}

export type SubscriptionaccountsArgs = {
  skip?: InputMaybe<Scalars['Int']>
  first?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Account_orderBy>
  orderDirection?: InputMaybe<OrderDirection>
  where?: InputMaybe<Account_filter>
  block?: InputMaybe<Block_height>
  subgraphError?: _SubgraphErrorPolicy_
}

export type SubscriptionaccountBalanceArgs = {
  id: Scalars['ID']
  block?: InputMaybe<Block_height>
  subgraphError?: _SubgraphErrorPolicy_
}

export type SubscriptionaccountBalancesArgs = {
  skip?: InputMaybe<Scalars['Int']>
  first?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<AccountBalance_orderBy>
  orderDirection?: InputMaybe<OrderDirection>
  where?: InputMaybe<AccountBalance_filter>
  block?: InputMaybe<Block_height>
  subgraphError?: _SubgraphErrorPolicy_
}

export type SubscriptionaccountBalanceSnapshotArgs = {
  id: Scalars['ID']
  block?: InputMaybe<Block_height>
  subgraphError?: _SubgraphErrorPolicy_
}

export type SubscriptionaccountBalanceSnapshotsArgs = {
  skip?: InputMaybe<Scalars['Int']>
  first?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<AccountBalanceSnapshot_orderBy>
  orderDirection?: InputMaybe<OrderDirection>
  where?: InputMaybe<AccountBalanceSnapshot_filter>
  block?: InputMaybe<Block_height>
  subgraphError?: _SubgraphErrorPolicy_
}

export type SubscriptiontokenArgs = {
  id: Scalars['ID']
  block?: InputMaybe<Block_height>
  subgraphError?: _SubgraphErrorPolicy_
}

export type SubscriptiontokensArgs = {
  skip?: InputMaybe<Scalars['Int']>
  first?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Token_orderBy>
  orderDirection?: InputMaybe<OrderDirection>
  where?: InputMaybe<Token_filter>
  block?: InputMaybe<Block_height>
  subgraphError?: _SubgraphErrorPolicy_
}

export type SubscriptionburnEventArgs = {
  id: Scalars['ID']
  block?: InputMaybe<Block_height>
  subgraphError?: _SubgraphErrorPolicy_
}

export type SubscriptionburnEventsArgs = {
  skip?: InputMaybe<Scalars['Int']>
  first?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<BurnEvent_orderBy>
  orderDirection?: InputMaybe<OrderDirection>
  where?: InputMaybe<BurnEvent_filter>
  block?: InputMaybe<Block_height>
  subgraphError?: _SubgraphErrorPolicy_
}

export type SubscriptionmintEventArgs = {
  id: Scalars['ID']
  block?: InputMaybe<Block_height>
  subgraphError?: _SubgraphErrorPolicy_
}

export type SubscriptionmintEventsArgs = {
  skip?: InputMaybe<Scalars['Int']>
  first?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<MintEvent_orderBy>
  orderDirection?: InputMaybe<OrderDirection>
  where?: InputMaybe<MintEvent_filter>
  block?: InputMaybe<Block_height>
  subgraphError?: _SubgraphErrorPolicy_
}

export type SubscriptiontransferEventArgs = {
  id: Scalars['ID']
  block?: InputMaybe<Block_height>
  subgraphError?: _SubgraphErrorPolicy_
}

export type SubscriptiontransferEventsArgs = {
  skip?: InputMaybe<Scalars['Int']>
  first?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<TransferEvent_orderBy>
  orderDirection?: InputMaybe<OrderDirection>
  where?: InputMaybe<TransferEvent_filter>
  block?: InputMaybe<Block_height>
  subgraphError?: _SubgraphErrorPolicy_
}

export type SubscriptionpauseEventArgs = {
  id: Scalars['ID']
  block?: InputMaybe<Block_height>
  subgraphError?: _SubgraphErrorPolicy_
}

export type SubscriptionpauseEventsArgs = {
  skip?: InputMaybe<Scalars['Int']>
  first?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<PauseEvent_orderBy>
  orderDirection?: InputMaybe<OrderDirection>
  where?: InputMaybe<PauseEvent_filter>
  block?: InputMaybe<Block_height>
  subgraphError?: _SubgraphErrorPolicy_
}

export type SubscriptionnfttokenArgs = {
  id: Scalars['ID']
  block?: InputMaybe<Block_height>
  subgraphError?: _SubgraphErrorPolicy_
}

export type SubscriptionnfttokensArgs = {
  skip?: InputMaybe<Scalars['Int']>
  first?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<NFTToken_orderBy>
  orderDirection?: InputMaybe<OrderDirection>
  where?: InputMaybe<NFTToken_filter>
  block?: InputMaybe<Block_height>
  subgraphError?: _SubgraphErrorPolicy_
}

export type SubscriptionownerTokenLookupArgs = {
  id: Scalars['ID']
  block?: InputMaybe<Block_height>
  subgraphError?: _SubgraphErrorPolicy_
}

export type SubscriptionownerTokenLookupsArgs = {
  skip?: InputMaybe<Scalars['Int']>
  first?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<OwnerTokenLookup_orderBy>
  orderDirection?: InputMaybe<OrderDirection>
  where?: InputMaybe<OwnerTokenLookup_filter>
  block?: InputMaybe<Block_height>
  subgraphError?: _SubgraphErrorPolicy_
}

export type SubscriptionnfttokenContractArgs = {
  id: Scalars['ID']
  block?: InputMaybe<Block_height>
  subgraphError?: _SubgraphErrorPolicy_
}

export type SubscriptionnfttokenContractsArgs = {
  skip?: InputMaybe<Scalars['Int']>
  first?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<NFTTokenContract_orderBy>
  orderDirection?: InputMaybe<OrderDirection>
  where?: InputMaybe<NFTTokenContract_filter>
  block?: InputMaybe<Block_height>
  subgraphError?: _SubgraphErrorPolicy_
}

export type SubscriptiontokenStakedArgs = {
  id: Scalars['ID']
  block?: InputMaybe<Block_height>
  subgraphError?: _SubgraphErrorPolicy_
}

export type SubscriptiontokenStakedsArgs = {
  skip?: InputMaybe<Scalars['Int']>
  first?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<TokenStaked_orderBy>
  orderDirection?: InputMaybe<OrderDirection>
  where?: InputMaybe<TokenStaked_filter>
  block?: InputMaybe<Block_height>
  subgraphError?: _SubgraphErrorPolicy_
}

export type SubscriptionroosterReceivedDroppedEggArgs = {
  id: Scalars['ID']
  block?: InputMaybe<Block_height>
  subgraphError?: _SubgraphErrorPolicy_
}

export type SubscriptionroosterReceivedDroppedEggsArgs = {
  skip?: InputMaybe<Scalars['Int']>
  first?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<RoosterReceivedDroppedEgg_orderBy>
  orderDirection?: InputMaybe<OrderDirection>
  where?: InputMaybe<RoosterReceivedDroppedEgg_filter>
  block?: InputMaybe<Block_height>
  subgraphError?: _SubgraphErrorPolicy_
}

export type SubscriptiongoldenEggAwardedArgs = {
  id: Scalars['ID']
  block?: InputMaybe<Block_height>
  subgraphError?: _SubgraphErrorPolicy_
}

export type SubscriptiongoldenEggAwardedsArgs = {
  skip?: InputMaybe<Scalars['Int']>
  first?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<GoldenEggAwarded_orderBy>
  orderDirection?: InputMaybe<OrderDirection>
  where?: InputMaybe<GoldenEggAwarded_filter>
  block?: InputMaybe<Block_height>
  subgraphError?: _SubgraphErrorPolicy_
}

export type SubscriptionspecialMintArgs = {
  id: Scalars['ID']
  block?: InputMaybe<Block_height>
  subgraphError?: _SubgraphErrorPolicy_
}

export type SubscriptionspecialMintsArgs = {
  skip?: InputMaybe<Scalars['Int']>
  first?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<SpecialMint_orderBy>
  orderDirection?: InputMaybe<OrderDirection>
  where?: InputMaybe<SpecialMint_filter>
  block?: InputMaybe<Block_height>
  subgraphError?: _SubgraphErrorPolicy_
}

export type SubscriptiontfgstatArgs = {
  id: Scalars['ID']
  block?: InputMaybe<Block_height>
  subgraphError?: _SubgraphErrorPolicy_
}

export type SubscriptiontfgstatsArgs = {
  skip?: InputMaybe<Scalars['Int']>
  first?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<TFGStat_orderBy>
  orderDirection?: InputMaybe<OrderDirection>
  where?: InputMaybe<TFGStat_filter>
  block?: InputMaybe<Block_height>
  subgraphError?: _SubgraphErrorPolicy_
}

export type SubscriptiontokenEventArgs = {
  id: Scalars['ID']
  block?: InputMaybe<Block_height>
  subgraphError?: _SubgraphErrorPolicy_
}

export type SubscriptiontokenEventsArgs = {
  skip?: InputMaybe<Scalars['Int']>
  first?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<TokenEvent_orderBy>
  orderDirection?: InputMaybe<OrderDirection>
  where?: InputMaybe<TokenEvent_filter>
  block?: InputMaybe<Block_height>
  subgraphError?: _SubgraphErrorPolicy_
}

export type Subscription_metaArgs = {
  block?: InputMaybe<Block_height>
}

export type TFGData = {
  id: Scalars['ID']
  preSaleTime?: Maybe<Scalars['BigInt']>
  preSaleFee?: Maybe<Scalars['BigDecimal']>
  preSaleStakeFee?: Maybe<Scalars['BigDecimal']>
  preSaleMaxQty?: Maybe<Scalars['Int']>
  allowListTime?: Maybe<Scalars['BigInt']>
  publicTime?: Maybe<Scalars['BigInt']>
  publicFee?: Maybe<Scalars['BigDecimal']>
  publicStakeFee?: Maybe<Scalars['BigDecimal']>
  publicMaxPerTx?: Maybe<Scalars['Int']>
  publicMaxQty?: Maybe<Scalars['Int']>
  teamReserve?: Maybe<Scalars['Int']>
  changeNameFee?: Maybe<Scalars['BigDecimal']>
  changeDescFee?: Maybe<Scalars['BigDecimal']>
  changeBGColorFee?: Maybe<Scalars['BigDecimal']>
  farmAnimalsAddress?: Maybe<Scalars['String']>
}

export type TFGData_filter = {
  id?: InputMaybe<Scalars['ID']>
  id_not?: InputMaybe<Scalars['ID']>
  id_gt?: InputMaybe<Scalars['ID']>
  id_lt?: InputMaybe<Scalars['ID']>
  id_gte?: InputMaybe<Scalars['ID']>
  id_lte?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  id_not_in?: InputMaybe<Array<Scalars['ID']>>
  preSaleTime?: InputMaybe<Scalars['BigInt']>
  preSaleTime_not?: InputMaybe<Scalars['BigInt']>
  preSaleTime_gt?: InputMaybe<Scalars['BigInt']>
  preSaleTime_lt?: InputMaybe<Scalars['BigInt']>
  preSaleTime_gte?: InputMaybe<Scalars['BigInt']>
  preSaleTime_lte?: InputMaybe<Scalars['BigInt']>
  preSaleTime_in?: InputMaybe<Array<Scalars['BigInt']>>
  preSaleTime_not_in?: InputMaybe<Array<Scalars['BigInt']>>
  preSaleFee?: InputMaybe<Scalars['BigDecimal']>
  preSaleFee_not?: InputMaybe<Scalars['BigDecimal']>
  preSaleFee_gt?: InputMaybe<Scalars['BigDecimal']>
  preSaleFee_lt?: InputMaybe<Scalars['BigDecimal']>
  preSaleFee_gte?: InputMaybe<Scalars['BigDecimal']>
  preSaleFee_lte?: InputMaybe<Scalars['BigDecimal']>
  preSaleFee_in?: InputMaybe<Array<Scalars['BigDecimal']>>
  preSaleFee_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>
  preSaleStakeFee?: InputMaybe<Scalars['BigDecimal']>
  preSaleStakeFee_not?: InputMaybe<Scalars['BigDecimal']>
  preSaleStakeFee_gt?: InputMaybe<Scalars['BigDecimal']>
  preSaleStakeFee_lt?: InputMaybe<Scalars['BigDecimal']>
  preSaleStakeFee_gte?: InputMaybe<Scalars['BigDecimal']>
  preSaleStakeFee_lte?: InputMaybe<Scalars['BigDecimal']>
  preSaleStakeFee_in?: InputMaybe<Array<Scalars['BigDecimal']>>
  preSaleStakeFee_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>
  preSaleMaxQty?: InputMaybe<Scalars['Int']>
  preSaleMaxQty_not?: InputMaybe<Scalars['Int']>
  preSaleMaxQty_gt?: InputMaybe<Scalars['Int']>
  preSaleMaxQty_lt?: InputMaybe<Scalars['Int']>
  preSaleMaxQty_gte?: InputMaybe<Scalars['Int']>
  preSaleMaxQty_lte?: InputMaybe<Scalars['Int']>
  preSaleMaxQty_in?: InputMaybe<Array<Scalars['Int']>>
  preSaleMaxQty_not_in?: InputMaybe<Array<Scalars['Int']>>
  allowListTime?: InputMaybe<Scalars['BigInt']>
  allowListTime_not?: InputMaybe<Scalars['BigInt']>
  allowListTime_gt?: InputMaybe<Scalars['BigInt']>
  allowListTime_lt?: InputMaybe<Scalars['BigInt']>
  allowListTime_gte?: InputMaybe<Scalars['BigInt']>
  allowListTime_lte?: InputMaybe<Scalars['BigInt']>
  allowListTime_in?: InputMaybe<Array<Scalars['BigInt']>>
  allowListTime_not_in?: InputMaybe<Array<Scalars['BigInt']>>
  publicTime?: InputMaybe<Scalars['BigInt']>
  publicTime_not?: InputMaybe<Scalars['BigInt']>
  publicTime_gt?: InputMaybe<Scalars['BigInt']>
  publicTime_lt?: InputMaybe<Scalars['BigInt']>
  publicTime_gte?: InputMaybe<Scalars['BigInt']>
  publicTime_lte?: InputMaybe<Scalars['BigInt']>
  publicTime_in?: InputMaybe<Array<Scalars['BigInt']>>
  publicTime_not_in?: InputMaybe<Array<Scalars['BigInt']>>
  publicFee?: InputMaybe<Scalars['BigDecimal']>
  publicFee_not?: InputMaybe<Scalars['BigDecimal']>
  publicFee_gt?: InputMaybe<Scalars['BigDecimal']>
  publicFee_lt?: InputMaybe<Scalars['BigDecimal']>
  publicFee_gte?: InputMaybe<Scalars['BigDecimal']>
  publicFee_lte?: InputMaybe<Scalars['BigDecimal']>
  publicFee_in?: InputMaybe<Array<Scalars['BigDecimal']>>
  publicFee_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>
  publicStakeFee?: InputMaybe<Scalars['BigDecimal']>
  publicStakeFee_not?: InputMaybe<Scalars['BigDecimal']>
  publicStakeFee_gt?: InputMaybe<Scalars['BigDecimal']>
  publicStakeFee_lt?: InputMaybe<Scalars['BigDecimal']>
  publicStakeFee_gte?: InputMaybe<Scalars['BigDecimal']>
  publicStakeFee_lte?: InputMaybe<Scalars['BigDecimal']>
  publicStakeFee_in?: InputMaybe<Array<Scalars['BigDecimal']>>
  publicStakeFee_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>
  publicMaxPerTx?: InputMaybe<Scalars['Int']>
  publicMaxPerTx_not?: InputMaybe<Scalars['Int']>
  publicMaxPerTx_gt?: InputMaybe<Scalars['Int']>
  publicMaxPerTx_lt?: InputMaybe<Scalars['Int']>
  publicMaxPerTx_gte?: InputMaybe<Scalars['Int']>
  publicMaxPerTx_lte?: InputMaybe<Scalars['Int']>
  publicMaxPerTx_in?: InputMaybe<Array<Scalars['Int']>>
  publicMaxPerTx_not_in?: InputMaybe<Array<Scalars['Int']>>
  publicMaxQty?: InputMaybe<Scalars['Int']>
  publicMaxQty_not?: InputMaybe<Scalars['Int']>
  publicMaxQty_gt?: InputMaybe<Scalars['Int']>
  publicMaxQty_lt?: InputMaybe<Scalars['Int']>
  publicMaxQty_gte?: InputMaybe<Scalars['Int']>
  publicMaxQty_lte?: InputMaybe<Scalars['Int']>
  publicMaxQty_in?: InputMaybe<Array<Scalars['Int']>>
  publicMaxQty_not_in?: InputMaybe<Array<Scalars['Int']>>
  teamReserve?: InputMaybe<Scalars['Int']>
  teamReserve_not?: InputMaybe<Scalars['Int']>
  teamReserve_gt?: InputMaybe<Scalars['Int']>
  teamReserve_lt?: InputMaybe<Scalars['Int']>
  teamReserve_gte?: InputMaybe<Scalars['Int']>
  teamReserve_lte?: InputMaybe<Scalars['Int']>
  teamReserve_in?: InputMaybe<Array<Scalars['Int']>>
  teamReserve_not_in?: InputMaybe<Array<Scalars['Int']>>
  changeNameFee?: InputMaybe<Scalars['BigDecimal']>
  changeNameFee_not?: InputMaybe<Scalars['BigDecimal']>
  changeNameFee_gt?: InputMaybe<Scalars['BigDecimal']>
  changeNameFee_lt?: InputMaybe<Scalars['BigDecimal']>
  changeNameFee_gte?: InputMaybe<Scalars['BigDecimal']>
  changeNameFee_lte?: InputMaybe<Scalars['BigDecimal']>
  changeNameFee_in?: InputMaybe<Array<Scalars['BigDecimal']>>
  changeNameFee_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>
  changeDescFee?: InputMaybe<Scalars['BigDecimal']>
  changeDescFee_not?: InputMaybe<Scalars['BigDecimal']>
  changeDescFee_gt?: InputMaybe<Scalars['BigDecimal']>
  changeDescFee_lt?: InputMaybe<Scalars['BigDecimal']>
  changeDescFee_gte?: InputMaybe<Scalars['BigDecimal']>
  changeDescFee_lte?: InputMaybe<Scalars['BigDecimal']>
  changeDescFee_in?: InputMaybe<Array<Scalars['BigDecimal']>>
  changeDescFee_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>
  changeBGColorFee?: InputMaybe<Scalars['BigDecimal']>
  changeBGColorFee_not?: InputMaybe<Scalars['BigDecimal']>
  changeBGColorFee_gt?: InputMaybe<Scalars['BigDecimal']>
  changeBGColorFee_lt?: InputMaybe<Scalars['BigDecimal']>
  changeBGColorFee_gte?: InputMaybe<Scalars['BigDecimal']>
  changeBGColorFee_lte?: InputMaybe<Scalars['BigDecimal']>
  changeBGColorFee_in?: InputMaybe<Array<Scalars['BigDecimal']>>
  changeBGColorFee_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>
  farmAnimalsAddress?: InputMaybe<Scalars['String']>
  farmAnimalsAddress_not?: InputMaybe<Scalars['String']>
  farmAnimalsAddress_gt?: InputMaybe<Scalars['String']>
  farmAnimalsAddress_lt?: InputMaybe<Scalars['String']>
  farmAnimalsAddress_gte?: InputMaybe<Scalars['String']>
  farmAnimalsAddress_lte?: InputMaybe<Scalars['String']>
  farmAnimalsAddress_in?: InputMaybe<Array<Scalars['String']>>
  farmAnimalsAddress_not_in?: InputMaybe<Array<Scalars['String']>>
  farmAnimalsAddress_contains?: InputMaybe<Scalars['String']>
  farmAnimalsAddress_contains_nocase?: InputMaybe<Scalars['String']>
  farmAnimalsAddress_not_contains?: InputMaybe<Scalars['String']>
  farmAnimalsAddress_not_contains_nocase?: InputMaybe<Scalars['String']>
  farmAnimalsAddress_starts_with?: InputMaybe<Scalars['String']>
  farmAnimalsAddress_starts_with_nocase?: InputMaybe<Scalars['String']>
  farmAnimalsAddress_not_starts_with?: InputMaybe<Scalars['String']>
  farmAnimalsAddress_not_starts_with_nocase?: InputMaybe<Scalars['String']>
  farmAnimalsAddress_ends_with?: InputMaybe<Scalars['String']>
  farmAnimalsAddress_ends_with_nocase?: InputMaybe<Scalars['String']>
  farmAnimalsAddress_not_ends_with?: InputMaybe<Scalars['String']>
  farmAnimalsAddress_not_ends_with_nocase?: InputMaybe<Scalars['String']>
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>
}

export type TFGData_orderBy =
  | 'id'
  | 'preSaleTime'
  | 'preSaleFee'
  | 'preSaleStakeFee'
  | 'preSaleMaxQty'
  | 'allowListTime'
  | 'publicTime'
  | 'publicFee'
  | 'publicStakeFee'
  | 'publicMaxPerTx'
  | 'publicMaxQty'
  | 'teamReserve'
  | 'changeNameFee'
  | 'changeDescFee'
  | 'changeBGColorFee'
  | 'farmAnimalsAddress'

export type TFGStat = {
  id: Scalars['ID']
  hensMinted?: Maybe<Scalars['Int']>
  henTwinsMinted?: Maybe<Scalars['Int']>
  hensBurned?: Maybe<Scalars['Int']>
  hensStaked?: Maybe<Scalars['Int']>
  hensKidnapped?: Maybe<Scalars['Int']>
  hensRescued?: Maybe<Scalars['Int']>
  hensSacrificed?: Maybe<Scalars['Int']>
  hensEggTaxesPaid?: Maybe<Scalars['BigDecimal']>
  coyotesMinted?: Maybe<Scalars['Int']>
  coyotesBurned?: Maybe<Scalars['Int']>
  coyotesStaked?: Maybe<Scalars['Int']>
  coyotesKidnapped?: Maybe<Scalars['Int']>
  coyotesSacrificed?: Maybe<Scalars['Int']>
  coyoteEggTaxCollected?: Maybe<Scalars['BigDecimal']>
  roostersMinted?: Maybe<Scalars['Int']>
  roostersBurned?: Maybe<Scalars['Int']>
  roostersStaked?: Maybe<Scalars['Int']>
  roostersEggRobbed?: Maybe<Scalars['BigDecimal']>
  eggsProduced?: Maybe<Scalars['BigDecimal']>
  eggsTaxed?: Maybe<Scalars['BigDecimal']>
  eggsDropped?: Maybe<Scalars['BigDecimal']>
  eggsRescued?: Maybe<Scalars['BigDecimal']>
  eggShopPiesBaked?: Maybe<Scalars['Int']>
  eggShopPiesSold?: Maybe<Scalars['Int']>
  eggShopPiesStolen?: Maybe<Scalars['Int']>
  imperialEggAwarded?: Maybe<Scalars['Int']>
  rainbowEggAwarded?: Maybe<Scalars['Int']>
  platinumEggAwarded?: Maybe<Scalars['Int']>
  goldenEggAwarded?: Maybe<Scalars['Int']>
  silverEggAwarded?: Maybe<Scalars['Int']>
  bronzeEggAwarded?: Maybe<Scalars['Int']>
}

export type TFGStat_filter = {
  id?: InputMaybe<Scalars['ID']>
  id_not?: InputMaybe<Scalars['ID']>
  id_gt?: InputMaybe<Scalars['ID']>
  id_lt?: InputMaybe<Scalars['ID']>
  id_gte?: InputMaybe<Scalars['ID']>
  id_lte?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  id_not_in?: InputMaybe<Array<Scalars['ID']>>
  hensMinted?: InputMaybe<Scalars['Int']>
  hensMinted_not?: InputMaybe<Scalars['Int']>
  hensMinted_gt?: InputMaybe<Scalars['Int']>
  hensMinted_lt?: InputMaybe<Scalars['Int']>
  hensMinted_gte?: InputMaybe<Scalars['Int']>
  hensMinted_lte?: InputMaybe<Scalars['Int']>
  hensMinted_in?: InputMaybe<Array<Scalars['Int']>>
  hensMinted_not_in?: InputMaybe<Array<Scalars['Int']>>
  henTwinsMinted?: InputMaybe<Scalars['Int']>
  henTwinsMinted_not?: InputMaybe<Scalars['Int']>
  henTwinsMinted_gt?: InputMaybe<Scalars['Int']>
  henTwinsMinted_lt?: InputMaybe<Scalars['Int']>
  henTwinsMinted_gte?: InputMaybe<Scalars['Int']>
  henTwinsMinted_lte?: InputMaybe<Scalars['Int']>
  henTwinsMinted_in?: InputMaybe<Array<Scalars['Int']>>
  henTwinsMinted_not_in?: InputMaybe<Array<Scalars['Int']>>
  hensBurned?: InputMaybe<Scalars['Int']>
  hensBurned_not?: InputMaybe<Scalars['Int']>
  hensBurned_gt?: InputMaybe<Scalars['Int']>
  hensBurned_lt?: InputMaybe<Scalars['Int']>
  hensBurned_gte?: InputMaybe<Scalars['Int']>
  hensBurned_lte?: InputMaybe<Scalars['Int']>
  hensBurned_in?: InputMaybe<Array<Scalars['Int']>>
  hensBurned_not_in?: InputMaybe<Array<Scalars['Int']>>
  hensStaked?: InputMaybe<Scalars['Int']>
  hensStaked_not?: InputMaybe<Scalars['Int']>
  hensStaked_gt?: InputMaybe<Scalars['Int']>
  hensStaked_lt?: InputMaybe<Scalars['Int']>
  hensStaked_gte?: InputMaybe<Scalars['Int']>
  hensStaked_lte?: InputMaybe<Scalars['Int']>
  hensStaked_in?: InputMaybe<Array<Scalars['Int']>>
  hensStaked_not_in?: InputMaybe<Array<Scalars['Int']>>
  hensKidnapped?: InputMaybe<Scalars['Int']>
  hensKidnapped_not?: InputMaybe<Scalars['Int']>
  hensKidnapped_gt?: InputMaybe<Scalars['Int']>
  hensKidnapped_lt?: InputMaybe<Scalars['Int']>
  hensKidnapped_gte?: InputMaybe<Scalars['Int']>
  hensKidnapped_lte?: InputMaybe<Scalars['Int']>
  hensKidnapped_in?: InputMaybe<Array<Scalars['Int']>>
  hensKidnapped_not_in?: InputMaybe<Array<Scalars['Int']>>
  hensRescued?: InputMaybe<Scalars['Int']>
  hensRescued_not?: InputMaybe<Scalars['Int']>
  hensRescued_gt?: InputMaybe<Scalars['Int']>
  hensRescued_lt?: InputMaybe<Scalars['Int']>
  hensRescued_gte?: InputMaybe<Scalars['Int']>
  hensRescued_lte?: InputMaybe<Scalars['Int']>
  hensRescued_in?: InputMaybe<Array<Scalars['Int']>>
  hensRescued_not_in?: InputMaybe<Array<Scalars['Int']>>
  hensSacrificed?: InputMaybe<Scalars['Int']>
  hensSacrificed_not?: InputMaybe<Scalars['Int']>
  hensSacrificed_gt?: InputMaybe<Scalars['Int']>
  hensSacrificed_lt?: InputMaybe<Scalars['Int']>
  hensSacrificed_gte?: InputMaybe<Scalars['Int']>
  hensSacrificed_lte?: InputMaybe<Scalars['Int']>
  hensSacrificed_in?: InputMaybe<Array<Scalars['Int']>>
  hensSacrificed_not_in?: InputMaybe<Array<Scalars['Int']>>
  hensEggTaxesPaid?: InputMaybe<Scalars['BigDecimal']>
  hensEggTaxesPaid_not?: InputMaybe<Scalars['BigDecimal']>
  hensEggTaxesPaid_gt?: InputMaybe<Scalars['BigDecimal']>
  hensEggTaxesPaid_lt?: InputMaybe<Scalars['BigDecimal']>
  hensEggTaxesPaid_gte?: InputMaybe<Scalars['BigDecimal']>
  hensEggTaxesPaid_lte?: InputMaybe<Scalars['BigDecimal']>
  hensEggTaxesPaid_in?: InputMaybe<Array<Scalars['BigDecimal']>>
  hensEggTaxesPaid_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>
  coyotesMinted?: InputMaybe<Scalars['Int']>
  coyotesMinted_not?: InputMaybe<Scalars['Int']>
  coyotesMinted_gt?: InputMaybe<Scalars['Int']>
  coyotesMinted_lt?: InputMaybe<Scalars['Int']>
  coyotesMinted_gte?: InputMaybe<Scalars['Int']>
  coyotesMinted_lte?: InputMaybe<Scalars['Int']>
  coyotesMinted_in?: InputMaybe<Array<Scalars['Int']>>
  coyotesMinted_not_in?: InputMaybe<Array<Scalars['Int']>>
  coyotesBurned?: InputMaybe<Scalars['Int']>
  coyotesBurned_not?: InputMaybe<Scalars['Int']>
  coyotesBurned_gt?: InputMaybe<Scalars['Int']>
  coyotesBurned_lt?: InputMaybe<Scalars['Int']>
  coyotesBurned_gte?: InputMaybe<Scalars['Int']>
  coyotesBurned_lte?: InputMaybe<Scalars['Int']>
  coyotesBurned_in?: InputMaybe<Array<Scalars['Int']>>
  coyotesBurned_not_in?: InputMaybe<Array<Scalars['Int']>>
  coyotesStaked?: InputMaybe<Scalars['Int']>
  coyotesStaked_not?: InputMaybe<Scalars['Int']>
  coyotesStaked_gt?: InputMaybe<Scalars['Int']>
  coyotesStaked_lt?: InputMaybe<Scalars['Int']>
  coyotesStaked_gte?: InputMaybe<Scalars['Int']>
  coyotesStaked_lte?: InputMaybe<Scalars['Int']>
  coyotesStaked_in?: InputMaybe<Array<Scalars['Int']>>
  coyotesStaked_not_in?: InputMaybe<Array<Scalars['Int']>>
  coyotesKidnapped?: InputMaybe<Scalars['Int']>
  coyotesKidnapped_not?: InputMaybe<Scalars['Int']>
  coyotesKidnapped_gt?: InputMaybe<Scalars['Int']>
  coyotesKidnapped_lt?: InputMaybe<Scalars['Int']>
  coyotesKidnapped_gte?: InputMaybe<Scalars['Int']>
  coyotesKidnapped_lte?: InputMaybe<Scalars['Int']>
  coyotesKidnapped_in?: InputMaybe<Array<Scalars['Int']>>
  coyotesKidnapped_not_in?: InputMaybe<Array<Scalars['Int']>>
  coyotesSacrificed?: InputMaybe<Scalars['Int']>
  coyotesSacrificed_not?: InputMaybe<Scalars['Int']>
  coyotesSacrificed_gt?: InputMaybe<Scalars['Int']>
  coyotesSacrificed_lt?: InputMaybe<Scalars['Int']>
  coyotesSacrificed_gte?: InputMaybe<Scalars['Int']>
  coyotesSacrificed_lte?: InputMaybe<Scalars['Int']>
  coyotesSacrificed_in?: InputMaybe<Array<Scalars['Int']>>
  coyotesSacrificed_not_in?: InputMaybe<Array<Scalars['Int']>>
  coyoteEggTaxCollected?: InputMaybe<Scalars['BigDecimal']>
  coyoteEggTaxCollected_not?: InputMaybe<Scalars['BigDecimal']>
  coyoteEggTaxCollected_gt?: InputMaybe<Scalars['BigDecimal']>
  coyoteEggTaxCollected_lt?: InputMaybe<Scalars['BigDecimal']>
  coyoteEggTaxCollected_gte?: InputMaybe<Scalars['BigDecimal']>
  coyoteEggTaxCollected_lte?: InputMaybe<Scalars['BigDecimal']>
  coyoteEggTaxCollected_in?: InputMaybe<Array<Scalars['BigDecimal']>>
  coyoteEggTaxCollected_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>
  roostersMinted?: InputMaybe<Scalars['Int']>
  roostersMinted_not?: InputMaybe<Scalars['Int']>
  roostersMinted_gt?: InputMaybe<Scalars['Int']>
  roostersMinted_lt?: InputMaybe<Scalars['Int']>
  roostersMinted_gte?: InputMaybe<Scalars['Int']>
  roostersMinted_lte?: InputMaybe<Scalars['Int']>
  roostersMinted_in?: InputMaybe<Array<Scalars['Int']>>
  roostersMinted_not_in?: InputMaybe<Array<Scalars['Int']>>
  roostersBurned?: InputMaybe<Scalars['Int']>
  roostersBurned_not?: InputMaybe<Scalars['Int']>
  roostersBurned_gt?: InputMaybe<Scalars['Int']>
  roostersBurned_lt?: InputMaybe<Scalars['Int']>
  roostersBurned_gte?: InputMaybe<Scalars['Int']>
  roostersBurned_lte?: InputMaybe<Scalars['Int']>
  roostersBurned_in?: InputMaybe<Array<Scalars['Int']>>
  roostersBurned_not_in?: InputMaybe<Array<Scalars['Int']>>
  roostersStaked?: InputMaybe<Scalars['Int']>
  roostersStaked_not?: InputMaybe<Scalars['Int']>
  roostersStaked_gt?: InputMaybe<Scalars['Int']>
  roostersStaked_lt?: InputMaybe<Scalars['Int']>
  roostersStaked_gte?: InputMaybe<Scalars['Int']>
  roostersStaked_lte?: InputMaybe<Scalars['Int']>
  roostersStaked_in?: InputMaybe<Array<Scalars['Int']>>
  roostersStaked_not_in?: InputMaybe<Array<Scalars['Int']>>
  roostersEggRobbed?: InputMaybe<Scalars['BigDecimal']>
  roostersEggRobbed_not?: InputMaybe<Scalars['BigDecimal']>
  roostersEggRobbed_gt?: InputMaybe<Scalars['BigDecimal']>
  roostersEggRobbed_lt?: InputMaybe<Scalars['BigDecimal']>
  roostersEggRobbed_gte?: InputMaybe<Scalars['BigDecimal']>
  roostersEggRobbed_lte?: InputMaybe<Scalars['BigDecimal']>
  roostersEggRobbed_in?: InputMaybe<Array<Scalars['BigDecimal']>>
  roostersEggRobbed_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>
  eggsProduced?: InputMaybe<Scalars['BigDecimal']>
  eggsProduced_not?: InputMaybe<Scalars['BigDecimal']>
  eggsProduced_gt?: InputMaybe<Scalars['BigDecimal']>
  eggsProduced_lt?: InputMaybe<Scalars['BigDecimal']>
  eggsProduced_gte?: InputMaybe<Scalars['BigDecimal']>
  eggsProduced_lte?: InputMaybe<Scalars['BigDecimal']>
  eggsProduced_in?: InputMaybe<Array<Scalars['BigDecimal']>>
  eggsProduced_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>
  eggsTaxed?: InputMaybe<Scalars['BigDecimal']>
  eggsTaxed_not?: InputMaybe<Scalars['BigDecimal']>
  eggsTaxed_gt?: InputMaybe<Scalars['BigDecimal']>
  eggsTaxed_lt?: InputMaybe<Scalars['BigDecimal']>
  eggsTaxed_gte?: InputMaybe<Scalars['BigDecimal']>
  eggsTaxed_lte?: InputMaybe<Scalars['BigDecimal']>
  eggsTaxed_in?: InputMaybe<Array<Scalars['BigDecimal']>>
  eggsTaxed_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>
  eggsDropped?: InputMaybe<Scalars['BigDecimal']>
  eggsDropped_not?: InputMaybe<Scalars['BigDecimal']>
  eggsDropped_gt?: InputMaybe<Scalars['BigDecimal']>
  eggsDropped_lt?: InputMaybe<Scalars['BigDecimal']>
  eggsDropped_gte?: InputMaybe<Scalars['BigDecimal']>
  eggsDropped_lte?: InputMaybe<Scalars['BigDecimal']>
  eggsDropped_in?: InputMaybe<Array<Scalars['BigDecimal']>>
  eggsDropped_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>
  eggsRescued?: InputMaybe<Scalars['BigDecimal']>
  eggsRescued_not?: InputMaybe<Scalars['BigDecimal']>
  eggsRescued_gt?: InputMaybe<Scalars['BigDecimal']>
  eggsRescued_lt?: InputMaybe<Scalars['BigDecimal']>
  eggsRescued_gte?: InputMaybe<Scalars['BigDecimal']>
  eggsRescued_lte?: InputMaybe<Scalars['BigDecimal']>
  eggsRescued_in?: InputMaybe<Array<Scalars['BigDecimal']>>
  eggsRescued_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>
  eggShopPiesBaked?: InputMaybe<Scalars['Int']>
  eggShopPiesBaked_not?: InputMaybe<Scalars['Int']>
  eggShopPiesBaked_gt?: InputMaybe<Scalars['Int']>
  eggShopPiesBaked_lt?: InputMaybe<Scalars['Int']>
  eggShopPiesBaked_gte?: InputMaybe<Scalars['Int']>
  eggShopPiesBaked_lte?: InputMaybe<Scalars['Int']>
  eggShopPiesBaked_in?: InputMaybe<Array<Scalars['Int']>>
  eggShopPiesBaked_not_in?: InputMaybe<Array<Scalars['Int']>>
  eggShopPiesSold?: InputMaybe<Scalars['Int']>
  eggShopPiesSold_not?: InputMaybe<Scalars['Int']>
  eggShopPiesSold_gt?: InputMaybe<Scalars['Int']>
  eggShopPiesSold_lt?: InputMaybe<Scalars['Int']>
  eggShopPiesSold_gte?: InputMaybe<Scalars['Int']>
  eggShopPiesSold_lte?: InputMaybe<Scalars['Int']>
  eggShopPiesSold_in?: InputMaybe<Array<Scalars['Int']>>
  eggShopPiesSold_not_in?: InputMaybe<Array<Scalars['Int']>>
  eggShopPiesStolen?: InputMaybe<Scalars['Int']>
  eggShopPiesStolen_not?: InputMaybe<Scalars['Int']>
  eggShopPiesStolen_gt?: InputMaybe<Scalars['Int']>
  eggShopPiesStolen_lt?: InputMaybe<Scalars['Int']>
  eggShopPiesStolen_gte?: InputMaybe<Scalars['Int']>
  eggShopPiesStolen_lte?: InputMaybe<Scalars['Int']>
  eggShopPiesStolen_in?: InputMaybe<Array<Scalars['Int']>>
  eggShopPiesStolen_not_in?: InputMaybe<Array<Scalars['Int']>>
  imperialEggAwarded?: InputMaybe<Scalars['Int']>
  imperialEggAwarded_not?: InputMaybe<Scalars['Int']>
  imperialEggAwarded_gt?: InputMaybe<Scalars['Int']>
  imperialEggAwarded_lt?: InputMaybe<Scalars['Int']>
  imperialEggAwarded_gte?: InputMaybe<Scalars['Int']>
  imperialEggAwarded_lte?: InputMaybe<Scalars['Int']>
  imperialEggAwarded_in?: InputMaybe<Array<Scalars['Int']>>
  imperialEggAwarded_not_in?: InputMaybe<Array<Scalars['Int']>>
  rainbowEggAwarded?: InputMaybe<Scalars['Int']>
  rainbowEggAwarded_not?: InputMaybe<Scalars['Int']>
  rainbowEggAwarded_gt?: InputMaybe<Scalars['Int']>
  rainbowEggAwarded_lt?: InputMaybe<Scalars['Int']>
  rainbowEggAwarded_gte?: InputMaybe<Scalars['Int']>
  rainbowEggAwarded_lte?: InputMaybe<Scalars['Int']>
  rainbowEggAwarded_in?: InputMaybe<Array<Scalars['Int']>>
  rainbowEggAwarded_not_in?: InputMaybe<Array<Scalars['Int']>>
  platinumEggAwarded?: InputMaybe<Scalars['Int']>
  platinumEggAwarded_not?: InputMaybe<Scalars['Int']>
  platinumEggAwarded_gt?: InputMaybe<Scalars['Int']>
  platinumEggAwarded_lt?: InputMaybe<Scalars['Int']>
  platinumEggAwarded_gte?: InputMaybe<Scalars['Int']>
  platinumEggAwarded_lte?: InputMaybe<Scalars['Int']>
  platinumEggAwarded_in?: InputMaybe<Array<Scalars['Int']>>
  platinumEggAwarded_not_in?: InputMaybe<Array<Scalars['Int']>>
  goldenEggAwarded?: InputMaybe<Scalars['Int']>
  goldenEggAwarded_not?: InputMaybe<Scalars['Int']>
  goldenEggAwarded_gt?: InputMaybe<Scalars['Int']>
  goldenEggAwarded_lt?: InputMaybe<Scalars['Int']>
  goldenEggAwarded_gte?: InputMaybe<Scalars['Int']>
  goldenEggAwarded_lte?: InputMaybe<Scalars['Int']>
  goldenEggAwarded_in?: InputMaybe<Array<Scalars['Int']>>
  goldenEggAwarded_not_in?: InputMaybe<Array<Scalars['Int']>>
  silverEggAwarded?: InputMaybe<Scalars['Int']>
  silverEggAwarded_not?: InputMaybe<Scalars['Int']>
  silverEggAwarded_gt?: InputMaybe<Scalars['Int']>
  silverEggAwarded_lt?: InputMaybe<Scalars['Int']>
  silverEggAwarded_gte?: InputMaybe<Scalars['Int']>
  silverEggAwarded_lte?: InputMaybe<Scalars['Int']>
  silverEggAwarded_in?: InputMaybe<Array<Scalars['Int']>>
  silverEggAwarded_not_in?: InputMaybe<Array<Scalars['Int']>>
  bronzeEggAwarded?: InputMaybe<Scalars['Int']>
  bronzeEggAwarded_not?: InputMaybe<Scalars['Int']>
  bronzeEggAwarded_gt?: InputMaybe<Scalars['Int']>
  bronzeEggAwarded_lt?: InputMaybe<Scalars['Int']>
  bronzeEggAwarded_gte?: InputMaybe<Scalars['Int']>
  bronzeEggAwarded_lte?: InputMaybe<Scalars['Int']>
  bronzeEggAwarded_in?: InputMaybe<Array<Scalars['Int']>>
  bronzeEggAwarded_not_in?: InputMaybe<Array<Scalars['Int']>>
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>
}

export type TFGStat_orderBy =
  | 'id'
  | 'hensMinted'
  | 'henTwinsMinted'
  | 'hensBurned'
  | 'hensStaked'
  | 'hensKidnapped'
  | 'hensRescued'
  | 'hensSacrificed'
  | 'hensEggTaxesPaid'
  | 'coyotesMinted'
  | 'coyotesBurned'
  | 'coyotesStaked'
  | 'coyotesKidnapped'
  | 'coyotesSacrificed'
  | 'coyoteEggTaxCollected'
  | 'roostersMinted'
  | 'roostersBurned'
  | 'roostersStaked'
  | 'roostersEggRobbed'
  | 'eggsProduced'
  | 'eggsTaxed'
  | 'eggsDropped'
  | 'eggsRescued'
  | 'eggShopPiesBaked'
  | 'eggShopPiesSold'
  | 'eggShopPiesStolen'
  | 'imperialEggAwarded'
  | 'rainbowEggAwarded'
  | 'platinumEggAwarded'
  | 'goldenEggAwarded'
  | 'silverEggAwarded'
  | 'bronzeEggAwarded'

export type Token = {
  id: Scalars['ID']
  address: Scalars['Bytes']
  decimals: Scalars['Int']
  name: Scalars['String']
  symbol: Scalars['String']
  description?: Maybe<Scalars['String']>
  imageUrl?: Maybe<Scalars['String']>
  paused?: Maybe<Scalars['Boolean']>
  eventCount: Scalars['BigInt']
  burnEventCount: Scalars['BigInt']
  mintEventCount: Scalars['BigInt']
  transferEventCount: Scalars['BigInt']
  totalSupply: Scalars['BigDecimal']
  totalBurned: Scalars['BigDecimal']
  totalMinted: Scalars['BigDecimal']
  totalTransferred: Scalars['BigDecimal']
  events: Array<TokenEvent>
}

export type TokeneventsArgs = {
  skip?: InputMaybe<Scalars['Int']>
  first?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<TokenEvent_orderBy>
  orderDirection?: InputMaybe<OrderDirection>
  where?: InputMaybe<TokenEvent_filter>
}

export type TokenEvent = {
  id: Scalars['ID']
  token: Token
  amount: Scalars['BigDecimal']
  sender: Scalars['Bytes']
  block: Scalars['BigInt']
  timestamp: Scalars['BigInt']
  transaction: Scalars['Bytes']
}

export type TokenEvent_filter = {
  id?: InputMaybe<Scalars['ID']>
  id_not?: InputMaybe<Scalars['ID']>
  id_gt?: InputMaybe<Scalars['ID']>
  id_lt?: InputMaybe<Scalars['ID']>
  id_gte?: InputMaybe<Scalars['ID']>
  id_lte?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  id_not_in?: InputMaybe<Array<Scalars['ID']>>
  token?: InputMaybe<Scalars['String']>
  token_not?: InputMaybe<Scalars['String']>
  token_gt?: InputMaybe<Scalars['String']>
  token_lt?: InputMaybe<Scalars['String']>
  token_gte?: InputMaybe<Scalars['String']>
  token_lte?: InputMaybe<Scalars['String']>
  token_in?: InputMaybe<Array<Scalars['String']>>
  token_not_in?: InputMaybe<Array<Scalars['String']>>
  token_contains?: InputMaybe<Scalars['String']>
  token_contains_nocase?: InputMaybe<Scalars['String']>
  token_not_contains?: InputMaybe<Scalars['String']>
  token_not_contains_nocase?: InputMaybe<Scalars['String']>
  token_starts_with?: InputMaybe<Scalars['String']>
  token_starts_with_nocase?: InputMaybe<Scalars['String']>
  token_not_starts_with?: InputMaybe<Scalars['String']>
  token_not_starts_with_nocase?: InputMaybe<Scalars['String']>
  token_ends_with?: InputMaybe<Scalars['String']>
  token_ends_with_nocase?: InputMaybe<Scalars['String']>
  token_not_ends_with?: InputMaybe<Scalars['String']>
  token_not_ends_with_nocase?: InputMaybe<Scalars['String']>
  token_?: InputMaybe<Token_filter>
  amount?: InputMaybe<Scalars['BigDecimal']>
  amount_not?: InputMaybe<Scalars['BigDecimal']>
  amount_gt?: InputMaybe<Scalars['BigDecimal']>
  amount_lt?: InputMaybe<Scalars['BigDecimal']>
  amount_gte?: InputMaybe<Scalars['BigDecimal']>
  amount_lte?: InputMaybe<Scalars['BigDecimal']>
  amount_in?: InputMaybe<Array<Scalars['BigDecimal']>>
  amount_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>
  sender?: InputMaybe<Scalars['Bytes']>
  sender_not?: InputMaybe<Scalars['Bytes']>
  sender_in?: InputMaybe<Array<Scalars['Bytes']>>
  sender_not_in?: InputMaybe<Array<Scalars['Bytes']>>
  sender_contains?: InputMaybe<Scalars['Bytes']>
  sender_not_contains?: InputMaybe<Scalars['Bytes']>
  block?: InputMaybe<Scalars['BigInt']>
  block_not?: InputMaybe<Scalars['BigInt']>
  block_gt?: InputMaybe<Scalars['BigInt']>
  block_lt?: InputMaybe<Scalars['BigInt']>
  block_gte?: InputMaybe<Scalars['BigInt']>
  block_lte?: InputMaybe<Scalars['BigInt']>
  block_in?: InputMaybe<Array<Scalars['BigInt']>>
  block_not_in?: InputMaybe<Array<Scalars['BigInt']>>
  timestamp?: InputMaybe<Scalars['BigInt']>
  timestamp_not?: InputMaybe<Scalars['BigInt']>
  timestamp_gt?: InputMaybe<Scalars['BigInt']>
  timestamp_lt?: InputMaybe<Scalars['BigInt']>
  timestamp_gte?: InputMaybe<Scalars['BigInt']>
  timestamp_lte?: InputMaybe<Scalars['BigInt']>
  timestamp_in?: InputMaybe<Array<Scalars['BigInt']>>
  timestamp_not_in?: InputMaybe<Array<Scalars['BigInt']>>
  transaction?: InputMaybe<Scalars['Bytes']>
  transaction_not?: InputMaybe<Scalars['Bytes']>
  transaction_in?: InputMaybe<Array<Scalars['Bytes']>>
  transaction_not_in?: InputMaybe<Array<Scalars['Bytes']>>
  transaction_contains?: InputMaybe<Scalars['Bytes']>
  transaction_not_contains?: InputMaybe<Scalars['Bytes']>
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>
}

export type TokenEvent_orderBy = 'id' | 'token' | 'amount' | 'sender' | 'block' | 'timestamp' | 'transaction'

export type TokenStaked = {
  id: Scalars['ID']
  owner: Account
  tokenId: Scalars['Int']
  kind?: Maybe<Scalars['String']>
  eggPerRank: Scalars['BigInt']
  stakedTimestamp: Scalars['BigInt']
  unstakeTimestamp: Scalars['BigInt']
  bonusPercentage?: Maybe<Scalars['BigDecimal']>
  bonusDurationMins?: Maybe<Scalars['BigInt']>
  startTime?: Maybe<Scalars['BigInt']>
}

export type TokenStaked_filter = {
  id?: InputMaybe<Scalars['ID']>
  id_not?: InputMaybe<Scalars['ID']>
  id_gt?: InputMaybe<Scalars['ID']>
  id_lt?: InputMaybe<Scalars['ID']>
  id_gte?: InputMaybe<Scalars['ID']>
  id_lte?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  id_not_in?: InputMaybe<Array<Scalars['ID']>>
  owner?: InputMaybe<Scalars['String']>
  owner_not?: InputMaybe<Scalars['String']>
  owner_gt?: InputMaybe<Scalars['String']>
  owner_lt?: InputMaybe<Scalars['String']>
  owner_gte?: InputMaybe<Scalars['String']>
  owner_lte?: InputMaybe<Scalars['String']>
  owner_in?: InputMaybe<Array<Scalars['String']>>
  owner_not_in?: InputMaybe<Array<Scalars['String']>>
  owner_contains?: InputMaybe<Scalars['String']>
  owner_contains_nocase?: InputMaybe<Scalars['String']>
  owner_not_contains?: InputMaybe<Scalars['String']>
  owner_not_contains_nocase?: InputMaybe<Scalars['String']>
  owner_starts_with?: InputMaybe<Scalars['String']>
  owner_starts_with_nocase?: InputMaybe<Scalars['String']>
  owner_not_starts_with?: InputMaybe<Scalars['String']>
  owner_not_starts_with_nocase?: InputMaybe<Scalars['String']>
  owner_ends_with?: InputMaybe<Scalars['String']>
  owner_ends_with_nocase?: InputMaybe<Scalars['String']>
  owner_not_ends_with?: InputMaybe<Scalars['String']>
  owner_not_ends_with_nocase?: InputMaybe<Scalars['String']>
  owner_?: InputMaybe<Account_filter>
  tokenId?: InputMaybe<Scalars['Int']>
  tokenId_not?: InputMaybe<Scalars['Int']>
  tokenId_gt?: InputMaybe<Scalars['Int']>
  tokenId_lt?: InputMaybe<Scalars['Int']>
  tokenId_gte?: InputMaybe<Scalars['Int']>
  tokenId_lte?: InputMaybe<Scalars['Int']>
  tokenId_in?: InputMaybe<Array<Scalars['Int']>>
  tokenId_not_in?: InputMaybe<Array<Scalars['Int']>>
  kind?: InputMaybe<Scalars['String']>
  kind_not?: InputMaybe<Scalars['String']>
  kind_gt?: InputMaybe<Scalars['String']>
  kind_lt?: InputMaybe<Scalars['String']>
  kind_gte?: InputMaybe<Scalars['String']>
  kind_lte?: InputMaybe<Scalars['String']>
  kind_in?: InputMaybe<Array<Scalars['String']>>
  kind_not_in?: InputMaybe<Array<Scalars['String']>>
  kind_contains?: InputMaybe<Scalars['String']>
  kind_contains_nocase?: InputMaybe<Scalars['String']>
  kind_not_contains?: InputMaybe<Scalars['String']>
  kind_not_contains_nocase?: InputMaybe<Scalars['String']>
  kind_starts_with?: InputMaybe<Scalars['String']>
  kind_starts_with_nocase?: InputMaybe<Scalars['String']>
  kind_not_starts_with?: InputMaybe<Scalars['String']>
  kind_not_starts_with_nocase?: InputMaybe<Scalars['String']>
  kind_ends_with?: InputMaybe<Scalars['String']>
  kind_ends_with_nocase?: InputMaybe<Scalars['String']>
  kind_not_ends_with?: InputMaybe<Scalars['String']>
  kind_not_ends_with_nocase?: InputMaybe<Scalars['String']>
  eggPerRank?: InputMaybe<Scalars['BigInt']>
  eggPerRank_not?: InputMaybe<Scalars['BigInt']>
  eggPerRank_gt?: InputMaybe<Scalars['BigInt']>
  eggPerRank_lt?: InputMaybe<Scalars['BigInt']>
  eggPerRank_gte?: InputMaybe<Scalars['BigInt']>
  eggPerRank_lte?: InputMaybe<Scalars['BigInt']>
  eggPerRank_in?: InputMaybe<Array<Scalars['BigInt']>>
  eggPerRank_not_in?: InputMaybe<Array<Scalars['BigInt']>>
  stakedTimestamp?: InputMaybe<Scalars['BigInt']>
  stakedTimestamp_not?: InputMaybe<Scalars['BigInt']>
  stakedTimestamp_gt?: InputMaybe<Scalars['BigInt']>
  stakedTimestamp_lt?: InputMaybe<Scalars['BigInt']>
  stakedTimestamp_gte?: InputMaybe<Scalars['BigInt']>
  stakedTimestamp_lte?: InputMaybe<Scalars['BigInt']>
  stakedTimestamp_in?: InputMaybe<Array<Scalars['BigInt']>>
  stakedTimestamp_not_in?: InputMaybe<Array<Scalars['BigInt']>>
  unstakeTimestamp?: InputMaybe<Scalars['BigInt']>
  unstakeTimestamp_not?: InputMaybe<Scalars['BigInt']>
  unstakeTimestamp_gt?: InputMaybe<Scalars['BigInt']>
  unstakeTimestamp_lt?: InputMaybe<Scalars['BigInt']>
  unstakeTimestamp_gte?: InputMaybe<Scalars['BigInt']>
  unstakeTimestamp_lte?: InputMaybe<Scalars['BigInt']>
  unstakeTimestamp_in?: InputMaybe<Array<Scalars['BigInt']>>
  unstakeTimestamp_not_in?: InputMaybe<Array<Scalars['BigInt']>>
  bonusPercentage?: InputMaybe<Scalars['BigDecimal']>
  bonusPercentage_not?: InputMaybe<Scalars['BigDecimal']>
  bonusPercentage_gt?: InputMaybe<Scalars['BigDecimal']>
  bonusPercentage_lt?: InputMaybe<Scalars['BigDecimal']>
  bonusPercentage_gte?: InputMaybe<Scalars['BigDecimal']>
  bonusPercentage_lte?: InputMaybe<Scalars['BigDecimal']>
  bonusPercentage_in?: InputMaybe<Array<Scalars['BigDecimal']>>
  bonusPercentage_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>
  bonusDurationMins?: InputMaybe<Scalars['BigInt']>
  bonusDurationMins_not?: InputMaybe<Scalars['BigInt']>
  bonusDurationMins_gt?: InputMaybe<Scalars['BigInt']>
  bonusDurationMins_lt?: InputMaybe<Scalars['BigInt']>
  bonusDurationMins_gte?: InputMaybe<Scalars['BigInt']>
  bonusDurationMins_lte?: InputMaybe<Scalars['BigInt']>
  bonusDurationMins_in?: InputMaybe<Array<Scalars['BigInt']>>
  bonusDurationMins_not_in?: InputMaybe<Array<Scalars['BigInt']>>
  startTime?: InputMaybe<Scalars['BigInt']>
  startTime_not?: InputMaybe<Scalars['BigInt']>
  startTime_gt?: InputMaybe<Scalars['BigInt']>
  startTime_lt?: InputMaybe<Scalars['BigInt']>
  startTime_gte?: InputMaybe<Scalars['BigInt']>
  startTime_lte?: InputMaybe<Scalars['BigInt']>
  startTime_in?: InputMaybe<Array<Scalars['BigInt']>>
  startTime_not_in?: InputMaybe<Array<Scalars['BigInt']>>
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>
}

export type TokenStaked_orderBy =
  | 'id'
  | 'owner'
  | 'tokenId'
  | 'kind'
  | 'eggPerRank'
  | 'stakedTimestamp'
  | 'unstakeTimestamp'
  | 'bonusPercentage'
  | 'bonusDurationMins'
  | 'startTime'

export type Token_filter = {
  id?: InputMaybe<Scalars['ID']>
  id_not?: InputMaybe<Scalars['ID']>
  id_gt?: InputMaybe<Scalars['ID']>
  id_lt?: InputMaybe<Scalars['ID']>
  id_gte?: InputMaybe<Scalars['ID']>
  id_lte?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  id_not_in?: InputMaybe<Array<Scalars['ID']>>
  address?: InputMaybe<Scalars['Bytes']>
  address_not?: InputMaybe<Scalars['Bytes']>
  address_in?: InputMaybe<Array<Scalars['Bytes']>>
  address_not_in?: InputMaybe<Array<Scalars['Bytes']>>
  address_contains?: InputMaybe<Scalars['Bytes']>
  address_not_contains?: InputMaybe<Scalars['Bytes']>
  decimals?: InputMaybe<Scalars['Int']>
  decimals_not?: InputMaybe<Scalars['Int']>
  decimals_gt?: InputMaybe<Scalars['Int']>
  decimals_lt?: InputMaybe<Scalars['Int']>
  decimals_gte?: InputMaybe<Scalars['Int']>
  decimals_lte?: InputMaybe<Scalars['Int']>
  decimals_in?: InputMaybe<Array<Scalars['Int']>>
  decimals_not_in?: InputMaybe<Array<Scalars['Int']>>
  name?: InputMaybe<Scalars['String']>
  name_not?: InputMaybe<Scalars['String']>
  name_gt?: InputMaybe<Scalars['String']>
  name_lt?: InputMaybe<Scalars['String']>
  name_gte?: InputMaybe<Scalars['String']>
  name_lte?: InputMaybe<Scalars['String']>
  name_in?: InputMaybe<Array<Scalars['String']>>
  name_not_in?: InputMaybe<Array<Scalars['String']>>
  name_contains?: InputMaybe<Scalars['String']>
  name_contains_nocase?: InputMaybe<Scalars['String']>
  name_not_contains?: InputMaybe<Scalars['String']>
  name_not_contains_nocase?: InputMaybe<Scalars['String']>
  name_starts_with?: InputMaybe<Scalars['String']>
  name_starts_with_nocase?: InputMaybe<Scalars['String']>
  name_not_starts_with?: InputMaybe<Scalars['String']>
  name_not_starts_with_nocase?: InputMaybe<Scalars['String']>
  name_ends_with?: InputMaybe<Scalars['String']>
  name_ends_with_nocase?: InputMaybe<Scalars['String']>
  name_not_ends_with?: InputMaybe<Scalars['String']>
  name_not_ends_with_nocase?: InputMaybe<Scalars['String']>
  symbol?: InputMaybe<Scalars['String']>
  symbol_not?: InputMaybe<Scalars['String']>
  symbol_gt?: InputMaybe<Scalars['String']>
  symbol_lt?: InputMaybe<Scalars['String']>
  symbol_gte?: InputMaybe<Scalars['String']>
  symbol_lte?: InputMaybe<Scalars['String']>
  symbol_in?: InputMaybe<Array<Scalars['String']>>
  symbol_not_in?: InputMaybe<Array<Scalars['String']>>
  symbol_contains?: InputMaybe<Scalars['String']>
  symbol_contains_nocase?: InputMaybe<Scalars['String']>
  symbol_not_contains?: InputMaybe<Scalars['String']>
  symbol_not_contains_nocase?: InputMaybe<Scalars['String']>
  symbol_starts_with?: InputMaybe<Scalars['String']>
  symbol_starts_with_nocase?: InputMaybe<Scalars['String']>
  symbol_not_starts_with?: InputMaybe<Scalars['String']>
  symbol_not_starts_with_nocase?: InputMaybe<Scalars['String']>
  symbol_ends_with?: InputMaybe<Scalars['String']>
  symbol_ends_with_nocase?: InputMaybe<Scalars['String']>
  symbol_not_ends_with?: InputMaybe<Scalars['String']>
  symbol_not_ends_with_nocase?: InputMaybe<Scalars['String']>
  description?: InputMaybe<Scalars['String']>
  description_not?: InputMaybe<Scalars['String']>
  description_gt?: InputMaybe<Scalars['String']>
  description_lt?: InputMaybe<Scalars['String']>
  description_gte?: InputMaybe<Scalars['String']>
  description_lte?: InputMaybe<Scalars['String']>
  description_in?: InputMaybe<Array<Scalars['String']>>
  description_not_in?: InputMaybe<Array<Scalars['String']>>
  description_contains?: InputMaybe<Scalars['String']>
  description_contains_nocase?: InputMaybe<Scalars['String']>
  description_not_contains?: InputMaybe<Scalars['String']>
  description_not_contains_nocase?: InputMaybe<Scalars['String']>
  description_starts_with?: InputMaybe<Scalars['String']>
  description_starts_with_nocase?: InputMaybe<Scalars['String']>
  description_not_starts_with?: InputMaybe<Scalars['String']>
  description_not_starts_with_nocase?: InputMaybe<Scalars['String']>
  description_ends_with?: InputMaybe<Scalars['String']>
  description_ends_with_nocase?: InputMaybe<Scalars['String']>
  description_not_ends_with?: InputMaybe<Scalars['String']>
  description_not_ends_with_nocase?: InputMaybe<Scalars['String']>
  imageUrl?: InputMaybe<Scalars['String']>
  imageUrl_not?: InputMaybe<Scalars['String']>
  imageUrl_gt?: InputMaybe<Scalars['String']>
  imageUrl_lt?: InputMaybe<Scalars['String']>
  imageUrl_gte?: InputMaybe<Scalars['String']>
  imageUrl_lte?: InputMaybe<Scalars['String']>
  imageUrl_in?: InputMaybe<Array<Scalars['String']>>
  imageUrl_not_in?: InputMaybe<Array<Scalars['String']>>
  imageUrl_contains?: InputMaybe<Scalars['String']>
  imageUrl_contains_nocase?: InputMaybe<Scalars['String']>
  imageUrl_not_contains?: InputMaybe<Scalars['String']>
  imageUrl_not_contains_nocase?: InputMaybe<Scalars['String']>
  imageUrl_starts_with?: InputMaybe<Scalars['String']>
  imageUrl_starts_with_nocase?: InputMaybe<Scalars['String']>
  imageUrl_not_starts_with?: InputMaybe<Scalars['String']>
  imageUrl_not_starts_with_nocase?: InputMaybe<Scalars['String']>
  imageUrl_ends_with?: InputMaybe<Scalars['String']>
  imageUrl_ends_with_nocase?: InputMaybe<Scalars['String']>
  imageUrl_not_ends_with?: InputMaybe<Scalars['String']>
  imageUrl_not_ends_with_nocase?: InputMaybe<Scalars['String']>
  paused?: InputMaybe<Scalars['Boolean']>
  paused_not?: InputMaybe<Scalars['Boolean']>
  paused_in?: InputMaybe<Array<Scalars['Boolean']>>
  paused_not_in?: InputMaybe<Array<Scalars['Boolean']>>
  eventCount?: InputMaybe<Scalars['BigInt']>
  eventCount_not?: InputMaybe<Scalars['BigInt']>
  eventCount_gt?: InputMaybe<Scalars['BigInt']>
  eventCount_lt?: InputMaybe<Scalars['BigInt']>
  eventCount_gte?: InputMaybe<Scalars['BigInt']>
  eventCount_lte?: InputMaybe<Scalars['BigInt']>
  eventCount_in?: InputMaybe<Array<Scalars['BigInt']>>
  eventCount_not_in?: InputMaybe<Array<Scalars['BigInt']>>
  burnEventCount?: InputMaybe<Scalars['BigInt']>
  burnEventCount_not?: InputMaybe<Scalars['BigInt']>
  burnEventCount_gt?: InputMaybe<Scalars['BigInt']>
  burnEventCount_lt?: InputMaybe<Scalars['BigInt']>
  burnEventCount_gte?: InputMaybe<Scalars['BigInt']>
  burnEventCount_lte?: InputMaybe<Scalars['BigInt']>
  burnEventCount_in?: InputMaybe<Array<Scalars['BigInt']>>
  burnEventCount_not_in?: InputMaybe<Array<Scalars['BigInt']>>
  mintEventCount?: InputMaybe<Scalars['BigInt']>
  mintEventCount_not?: InputMaybe<Scalars['BigInt']>
  mintEventCount_gt?: InputMaybe<Scalars['BigInt']>
  mintEventCount_lt?: InputMaybe<Scalars['BigInt']>
  mintEventCount_gte?: InputMaybe<Scalars['BigInt']>
  mintEventCount_lte?: InputMaybe<Scalars['BigInt']>
  mintEventCount_in?: InputMaybe<Array<Scalars['BigInt']>>
  mintEventCount_not_in?: InputMaybe<Array<Scalars['BigInt']>>
  transferEventCount?: InputMaybe<Scalars['BigInt']>
  transferEventCount_not?: InputMaybe<Scalars['BigInt']>
  transferEventCount_gt?: InputMaybe<Scalars['BigInt']>
  transferEventCount_lt?: InputMaybe<Scalars['BigInt']>
  transferEventCount_gte?: InputMaybe<Scalars['BigInt']>
  transferEventCount_lte?: InputMaybe<Scalars['BigInt']>
  transferEventCount_in?: InputMaybe<Array<Scalars['BigInt']>>
  transferEventCount_not_in?: InputMaybe<Array<Scalars['BigInt']>>
  totalSupply?: InputMaybe<Scalars['BigDecimal']>
  totalSupply_not?: InputMaybe<Scalars['BigDecimal']>
  totalSupply_gt?: InputMaybe<Scalars['BigDecimal']>
  totalSupply_lt?: InputMaybe<Scalars['BigDecimal']>
  totalSupply_gte?: InputMaybe<Scalars['BigDecimal']>
  totalSupply_lte?: InputMaybe<Scalars['BigDecimal']>
  totalSupply_in?: InputMaybe<Array<Scalars['BigDecimal']>>
  totalSupply_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>
  totalBurned?: InputMaybe<Scalars['BigDecimal']>
  totalBurned_not?: InputMaybe<Scalars['BigDecimal']>
  totalBurned_gt?: InputMaybe<Scalars['BigDecimal']>
  totalBurned_lt?: InputMaybe<Scalars['BigDecimal']>
  totalBurned_gte?: InputMaybe<Scalars['BigDecimal']>
  totalBurned_lte?: InputMaybe<Scalars['BigDecimal']>
  totalBurned_in?: InputMaybe<Array<Scalars['BigDecimal']>>
  totalBurned_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>
  totalMinted?: InputMaybe<Scalars['BigDecimal']>
  totalMinted_not?: InputMaybe<Scalars['BigDecimal']>
  totalMinted_gt?: InputMaybe<Scalars['BigDecimal']>
  totalMinted_lt?: InputMaybe<Scalars['BigDecimal']>
  totalMinted_gte?: InputMaybe<Scalars['BigDecimal']>
  totalMinted_lte?: InputMaybe<Scalars['BigDecimal']>
  totalMinted_in?: InputMaybe<Array<Scalars['BigDecimal']>>
  totalMinted_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>
  totalTransferred?: InputMaybe<Scalars['BigDecimal']>
  totalTransferred_not?: InputMaybe<Scalars['BigDecimal']>
  totalTransferred_gt?: InputMaybe<Scalars['BigDecimal']>
  totalTransferred_lt?: InputMaybe<Scalars['BigDecimal']>
  totalTransferred_gte?: InputMaybe<Scalars['BigDecimal']>
  totalTransferred_lte?: InputMaybe<Scalars['BigDecimal']>
  totalTransferred_in?: InputMaybe<Array<Scalars['BigDecimal']>>
  totalTransferred_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>
  events_?: InputMaybe<TokenEvent_filter>
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>
}

export type Token_orderBy =
  | 'id'
  | 'address'
  | 'decimals'
  | 'name'
  | 'symbol'
  | 'description'
  | 'imageUrl'
  | 'paused'
  | 'eventCount'
  | 'burnEventCount'
  | 'mintEventCount'
  | 'transferEventCount'
  | 'totalSupply'
  | 'totalBurned'
  | 'totalMinted'
  | 'totalTransferred'
  | 'events'

export type TransferEvent = TokenEvent & {
  id: Scalars['ID']
  token: Token
  amount: Scalars['BigDecimal']
  sender: Scalars['Bytes']
  source: Scalars['Bytes']
  destination: Scalars['Bytes']
  block: Scalars['BigInt']
  timestamp: Scalars['BigInt']
  transaction: Scalars['Bytes']
}

export type TransferEvent_filter = {
  id?: InputMaybe<Scalars['ID']>
  id_not?: InputMaybe<Scalars['ID']>
  id_gt?: InputMaybe<Scalars['ID']>
  id_lt?: InputMaybe<Scalars['ID']>
  id_gte?: InputMaybe<Scalars['ID']>
  id_lte?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  id_not_in?: InputMaybe<Array<Scalars['ID']>>
  token?: InputMaybe<Scalars['String']>
  token_not?: InputMaybe<Scalars['String']>
  token_gt?: InputMaybe<Scalars['String']>
  token_lt?: InputMaybe<Scalars['String']>
  token_gte?: InputMaybe<Scalars['String']>
  token_lte?: InputMaybe<Scalars['String']>
  token_in?: InputMaybe<Array<Scalars['String']>>
  token_not_in?: InputMaybe<Array<Scalars['String']>>
  token_contains?: InputMaybe<Scalars['String']>
  token_contains_nocase?: InputMaybe<Scalars['String']>
  token_not_contains?: InputMaybe<Scalars['String']>
  token_not_contains_nocase?: InputMaybe<Scalars['String']>
  token_starts_with?: InputMaybe<Scalars['String']>
  token_starts_with_nocase?: InputMaybe<Scalars['String']>
  token_not_starts_with?: InputMaybe<Scalars['String']>
  token_not_starts_with_nocase?: InputMaybe<Scalars['String']>
  token_ends_with?: InputMaybe<Scalars['String']>
  token_ends_with_nocase?: InputMaybe<Scalars['String']>
  token_not_ends_with?: InputMaybe<Scalars['String']>
  token_not_ends_with_nocase?: InputMaybe<Scalars['String']>
  token_?: InputMaybe<Token_filter>
  amount?: InputMaybe<Scalars['BigDecimal']>
  amount_not?: InputMaybe<Scalars['BigDecimal']>
  amount_gt?: InputMaybe<Scalars['BigDecimal']>
  amount_lt?: InputMaybe<Scalars['BigDecimal']>
  amount_gte?: InputMaybe<Scalars['BigDecimal']>
  amount_lte?: InputMaybe<Scalars['BigDecimal']>
  amount_in?: InputMaybe<Array<Scalars['BigDecimal']>>
  amount_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>
  sender?: InputMaybe<Scalars['Bytes']>
  sender_not?: InputMaybe<Scalars['Bytes']>
  sender_in?: InputMaybe<Array<Scalars['Bytes']>>
  sender_not_in?: InputMaybe<Array<Scalars['Bytes']>>
  sender_contains?: InputMaybe<Scalars['Bytes']>
  sender_not_contains?: InputMaybe<Scalars['Bytes']>
  source?: InputMaybe<Scalars['Bytes']>
  source_not?: InputMaybe<Scalars['Bytes']>
  source_in?: InputMaybe<Array<Scalars['Bytes']>>
  source_not_in?: InputMaybe<Array<Scalars['Bytes']>>
  source_contains?: InputMaybe<Scalars['Bytes']>
  source_not_contains?: InputMaybe<Scalars['Bytes']>
  destination?: InputMaybe<Scalars['Bytes']>
  destination_not?: InputMaybe<Scalars['Bytes']>
  destination_in?: InputMaybe<Array<Scalars['Bytes']>>
  destination_not_in?: InputMaybe<Array<Scalars['Bytes']>>
  destination_contains?: InputMaybe<Scalars['Bytes']>
  destination_not_contains?: InputMaybe<Scalars['Bytes']>
  block?: InputMaybe<Scalars['BigInt']>
  block_not?: InputMaybe<Scalars['BigInt']>
  block_gt?: InputMaybe<Scalars['BigInt']>
  block_lt?: InputMaybe<Scalars['BigInt']>
  block_gte?: InputMaybe<Scalars['BigInt']>
  block_lte?: InputMaybe<Scalars['BigInt']>
  block_in?: InputMaybe<Array<Scalars['BigInt']>>
  block_not_in?: InputMaybe<Array<Scalars['BigInt']>>
  timestamp?: InputMaybe<Scalars['BigInt']>
  timestamp_not?: InputMaybe<Scalars['BigInt']>
  timestamp_gt?: InputMaybe<Scalars['BigInt']>
  timestamp_lt?: InputMaybe<Scalars['BigInt']>
  timestamp_gte?: InputMaybe<Scalars['BigInt']>
  timestamp_lte?: InputMaybe<Scalars['BigInt']>
  timestamp_in?: InputMaybe<Array<Scalars['BigInt']>>
  timestamp_not_in?: InputMaybe<Array<Scalars['BigInt']>>
  transaction?: InputMaybe<Scalars['Bytes']>
  transaction_not?: InputMaybe<Scalars['Bytes']>
  transaction_in?: InputMaybe<Array<Scalars['Bytes']>>
  transaction_not_in?: InputMaybe<Array<Scalars['Bytes']>>
  transaction_contains?: InputMaybe<Scalars['Bytes']>
  transaction_not_contains?: InputMaybe<Scalars['Bytes']>
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>
}

export type TransferEvent_orderBy =
  | 'id'
  | 'token'
  | 'amount'
  | 'sender'
  | 'source'
  | 'destination'
  | 'block'
  | 'timestamp'
  | 'transaction'

export type _Block_ = {
  /** The hash of the block */
  hash?: Maybe<Scalars['Bytes']>
  /** The block number */
  number: Scalars['Int']
  /** Integer representation of the timestamp stored in blocks for the chain */
  timestamp?: Maybe<Scalars['Int']>
}

/** The type for the top-level _meta field */
export type _Meta_ = {
  /**
   * Information about a specific subgraph block. The hash of the block
   * will be null if the _meta field has a block constraint that asks for
   * a block number. It will be filled if the _meta field has no block constraint
   * and therefore asks for the latest  block
   *
   */
  block: _Block_
  /** The deployment ID */
  deployment: Scalars['String']
  /** If `true`, the subgraph encountered indexing errors at some past block */
  hasIndexingErrors: Scalars['Boolean']
}

export type _SubgraphErrorPolicy_ =
  /** Data will be returned even if the subgraph has indexing errors */
  | 'allow'
  /** If the subgraph has indexing errors, data will be omitted. The default. */
  | 'deny'

export type WithIndex<TObject> = TObject & Record<string, any>
export type ResolversObject<TObject> = WithIndex<TObject>

export type ResolverTypeWrapper<T> = Promise<T> | T

export type ResolverWithResolve<TResult, TParent, TContext, TArgs> = {
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>
}

export type LegacyStitchingResolver<TResult, TParent, TContext, TArgs> = {
  fragment: string
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>
}

export type NewStitchingResolver<TResult, TParent, TContext, TArgs> = {
  selectionSet: string | ((fieldNode: FieldNode) => SelectionSetNode)
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>
}
export type StitchingResolver<TResult, TParent, TContext, TArgs> =
  | LegacyStitchingResolver<TResult, TParent, TContext, TArgs>
  | NewStitchingResolver<TResult, TParent, TContext, TArgs>
export type Resolver<TResult, TParent = {}, TContext = {}, TArgs = {}> =
  | ResolverFn<TResult, TParent, TContext, TArgs>
  | ResolverWithResolve<TResult, TParent, TContext, TArgs>
  | StitchingResolver<TResult, TParent, TContext, TArgs>

export type ResolverFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo,
) => Promise<TResult> | TResult

export type SubscriptionSubscribeFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo,
) => AsyncIterable<TResult> | Promise<AsyncIterable<TResult>>

export type SubscriptionResolveFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo,
) => TResult | Promise<TResult>

export interface SubscriptionSubscriberObject<TResult, TKey extends string, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<{ [key in TKey]: TResult }, TParent, TContext, TArgs>
  resolve?: SubscriptionResolveFn<TResult, { [key in TKey]: TResult }, TContext, TArgs>
}

export interface SubscriptionResolverObject<TResult, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<any, TParent, TContext, TArgs>
  resolve: SubscriptionResolveFn<TResult, any, TContext, TArgs>
}

export type SubscriptionObject<TResult, TKey extends string, TParent, TContext, TArgs> =
  | SubscriptionSubscriberObject<TResult, TKey, TParent, TContext, TArgs>
  | SubscriptionResolverObject<TResult, TParent, TContext, TArgs>

export type SubscriptionResolver<TResult, TKey extends string, TParent = {}, TContext = {}, TArgs = {}> =
  | ((...args: any[]) => SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>)
  | SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>

export type TypeResolveFn<TTypes, TParent = {}, TContext = {}> = (
  parent: TParent,
  context: TContext,
  info: GraphQLResolveInfo,
) => Maybe<TTypes> | Promise<Maybe<TTypes>>

export type IsTypeOfResolverFn<T = {}, TContext = {}> = (
  obj: T,
  context: TContext,
  info: GraphQLResolveInfo,
) => boolean | Promise<boolean>

export type NextResolverFn<T> = () => Promise<T>

export type DirectiveResolverFn<TResult = {}, TParent = {}, TContext = {}, TArgs = {}> = (
  next: NextResolverFn<TResult>,
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo,
) => TResult | Promise<TResult>

/** Mapping between all available schema types and the resolvers types */
export type ResolversTypes = ResolversObject<{
  Account: ResolverTypeWrapper<Account>
  AccountBalance: ResolverTypeWrapper<AccountBalance>
  AccountBalanceSnapshot: ResolverTypeWrapper<AccountBalanceSnapshot>
  AccountBalanceSnapshot_filter: AccountBalanceSnapshot_filter
  AccountBalanceSnapshot_orderBy: AccountBalanceSnapshot_orderBy
  AccountBalance_filter: AccountBalance_filter
  AccountBalance_orderBy: AccountBalance_orderBy
  Account_filter: Account_filter
  Account_orderBy: Account_orderBy
  BigDecimal: ResolverTypeWrapper<Scalars['BigDecimal']>
  BigInt: ResolverTypeWrapper<Scalars['BigInt']>
  BlockChangedFilter: BlockChangedFilter
  Block_height: Block_height
  Boolean: ResolverTypeWrapper<Scalars['Boolean']>
  BurnEvent: ResolverTypeWrapper<BurnEvent>
  BurnEvent_filter: BurnEvent_filter
  BurnEvent_orderBy: BurnEvent_orderBy
  Bytes: ResolverTypeWrapper<Scalars['Bytes']>
  Float: ResolverTypeWrapper<Scalars['Float']>
  GoldenEggAwarded: ResolverTypeWrapper<GoldenEggAwarded>
  GoldenEggAwarded_filter: GoldenEggAwarded_filter
  GoldenEggAwarded_orderBy: GoldenEggAwarded_orderBy
  ID: ResolverTypeWrapper<Scalars['ID']>
  Int: ResolverTypeWrapper<Scalars['Int']>
  MintEvent: ResolverTypeWrapper<MintEvent>
  MintEvent_filter: MintEvent_filter
  MintEvent_orderBy: MintEvent_orderBy
  NFTToken: ResolverTypeWrapper<NFTToken>
  NFTTokenContract: ResolverTypeWrapper<NFTTokenContract>
  NFTTokenContract_filter: NFTTokenContract_filter
  NFTTokenContract_orderBy: NFTTokenContract_orderBy
  NFTToken_filter: NFTToken_filter
  NFTToken_orderBy: NFTToken_orderBy
  OrderDirection: OrderDirection
  OwnerTokenLookup: ResolverTypeWrapper<OwnerTokenLookup>
  OwnerTokenLookup_filter: OwnerTokenLookup_filter
  OwnerTokenLookup_orderBy: OwnerTokenLookup_orderBy
  PauseEvent: ResolverTypeWrapper<PauseEvent>
  PauseEvent_filter: PauseEvent_filter
  PauseEvent_orderBy: PauseEvent_orderBy
  Query: ResolverTypeWrapper<{}>
  RoosterReceivedDroppedEgg: ResolverTypeWrapper<RoosterReceivedDroppedEgg>
  RoosterReceivedDroppedEgg_filter: RoosterReceivedDroppedEgg_filter
  RoosterReceivedDroppedEgg_orderBy: RoosterReceivedDroppedEgg_orderBy
  SpecialMint: ResolverTypeWrapper<SpecialMint>
  SpecialMint_filter: SpecialMint_filter
  SpecialMint_orderBy: SpecialMint_orderBy
  String: ResolverTypeWrapper<Scalars['String']>
  Subscription: ResolverTypeWrapper<{}>
  TFGData: ResolverTypeWrapper<TFGData>
  TFGData_filter: TFGData_filter
  TFGData_orderBy: TFGData_orderBy
  TFGStat: ResolverTypeWrapper<TFGStat>
  TFGStat_filter: TFGStat_filter
  TFGStat_orderBy: TFGStat_orderBy
  Token: ResolverTypeWrapper<Token>
  TokenEvent:
    | ResolversTypes['BurnEvent']
    | ResolversTypes['MintEvent']
    | ResolversTypes['PauseEvent']
    | ResolversTypes['TransferEvent']
  TokenEvent_filter: TokenEvent_filter
  TokenEvent_orderBy: TokenEvent_orderBy
  TokenStaked: ResolverTypeWrapper<TokenStaked>
  TokenStaked_filter: TokenStaked_filter
  TokenStaked_orderBy: TokenStaked_orderBy
  Token_filter: Token_filter
  Token_orderBy: Token_orderBy
  TransferEvent: ResolverTypeWrapper<TransferEvent>
  TransferEvent_filter: TransferEvent_filter
  TransferEvent_orderBy: TransferEvent_orderBy
  _Block_: ResolverTypeWrapper<_Block_>
  _Meta_: ResolverTypeWrapper<_Meta_>
  _SubgraphErrorPolicy_: _SubgraphErrorPolicy_
}>

/** Mapping between all available schema types and the resolvers parents */
export type ResolversParentTypes = ResolversObject<{
  Account: Account
  AccountBalance: AccountBalance
  AccountBalanceSnapshot: AccountBalanceSnapshot
  AccountBalanceSnapshot_filter: AccountBalanceSnapshot_filter
  AccountBalance_filter: AccountBalance_filter
  Account_filter: Account_filter
  BigDecimal: Scalars['BigDecimal']
  BigInt: Scalars['BigInt']
  BlockChangedFilter: BlockChangedFilter
  Block_height: Block_height
  Boolean: Scalars['Boolean']
  BurnEvent: BurnEvent
  BurnEvent_filter: BurnEvent_filter
  Bytes: Scalars['Bytes']
  Float: Scalars['Float']
  GoldenEggAwarded: GoldenEggAwarded
  GoldenEggAwarded_filter: GoldenEggAwarded_filter
  ID: Scalars['ID']
  Int: Scalars['Int']
  MintEvent: MintEvent
  MintEvent_filter: MintEvent_filter
  NFTToken: NFTToken
  NFTTokenContract: NFTTokenContract
  NFTTokenContract_filter: NFTTokenContract_filter
  NFTToken_filter: NFTToken_filter
  OwnerTokenLookup: OwnerTokenLookup
  OwnerTokenLookup_filter: OwnerTokenLookup_filter
  PauseEvent: PauseEvent
  PauseEvent_filter: PauseEvent_filter
  Query: {}
  RoosterReceivedDroppedEgg: RoosterReceivedDroppedEgg
  RoosterReceivedDroppedEgg_filter: RoosterReceivedDroppedEgg_filter
  SpecialMint: SpecialMint
  SpecialMint_filter: SpecialMint_filter
  String: Scalars['String']
  Subscription: {}
  TFGData: TFGData
  TFGData_filter: TFGData_filter
  TFGStat: TFGStat
  TFGStat_filter: TFGStat_filter
  Token: Token
  TokenEvent:
    | ResolversParentTypes['BurnEvent']
    | ResolversParentTypes['MintEvent']
    | ResolversParentTypes['PauseEvent']
    | ResolversParentTypes['TransferEvent']
  TokenEvent_filter: TokenEvent_filter
  TokenStaked: TokenStaked
  TokenStaked_filter: TokenStaked_filter
  Token_filter: Token_filter
  TransferEvent: TransferEvent
  TransferEvent_filter: TransferEvent_filter
  _Block_: _Block_
  _Meta_: _Meta_
}>

export type entityDirectiveArgs = {}

export type entityDirectiveResolver<
  Result,
  Parent,
  ContextType = MeshContext,
  Args = entityDirectiveArgs,
> = DirectiveResolverFn<Result, Parent, ContextType, Args>

export type subgraphIdDirectiveArgs = {
  id: Scalars['String']
}

export type subgraphIdDirectiveResolver<
  Result,
  Parent,
  ContextType = MeshContext,
  Args = subgraphIdDirectiveArgs,
> = DirectiveResolverFn<Result, Parent, ContextType, Args>

export type derivedFromDirectiveArgs = {
  field: Scalars['String']
}

export type derivedFromDirectiveResolver<
  Result,
  Parent,
  ContextType = MeshContext,
  Args = derivedFromDirectiveArgs,
> = DirectiveResolverFn<Result, Parent, ContextType, Args>

export type AccountResolvers<
  ContextType = MeshContext,
  ParentType extends ResolversParentTypes['Account'] = ResolversParentTypes['Account'],
> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  address?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>
  balances?: Resolver<
    Array<ResolversTypes['AccountBalance']>,
    ParentType,
    ContextType,
    RequireFields<AccountbalancesArgs, 'skip' | 'first'>
  >
  nftTokens?: Resolver<
    Maybe<Array<ResolversTypes['OwnerTokenLookup']>>,
    ParentType,
    ContextType,
    RequireFields<AccountnftTokensArgs, 'skip' | 'first'>
  >
  stakedTokens?: Resolver<
    Maybe<Array<ResolversTypes['TokenStaked']>>,
    ParentType,
    ContextType,
    RequireFields<AccountstakedTokensArgs, 'skip' | 'first'>
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type AccountBalanceResolvers<
  ContextType = MeshContext,
  ParentType extends ResolversParentTypes['AccountBalance'] = ResolversParentTypes['AccountBalance'],
> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  account?: Resolver<ResolversTypes['Account'], ParentType, ContextType>
  token?: Resolver<ResolversTypes['Token'], ParentType, ContextType>
  amount?: Resolver<ResolversTypes['BigDecimal'], ParentType, ContextType>
  block?: Resolver<Maybe<ResolversTypes['BigInt']>, ParentType, ContextType>
  modified?: Resolver<Maybe<ResolversTypes['BigInt']>, ParentType, ContextType>
  transaction?: Resolver<Maybe<ResolversTypes['Bytes']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type AccountBalanceSnapshotResolvers<
  ContextType = MeshContext,
  ParentType extends ResolversParentTypes['AccountBalanceSnapshot'] = ResolversParentTypes['AccountBalanceSnapshot'],
> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  account?: Resolver<ResolversTypes['Account'], ParentType, ContextType>
  token?: Resolver<ResolversTypes['Token'], ParentType, ContextType>
  amount?: Resolver<ResolversTypes['BigDecimal'], ParentType, ContextType>
  event?: Resolver<Maybe<ResolversTypes['TokenEvent']>, ParentType, ContextType>
  block?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>
  timestamp?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>
  transaction?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export interface BigDecimalScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['BigDecimal'], any> {
  name: 'BigDecimal'
}

export interface BigIntScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['BigInt'], any> {
  name: 'BigInt'
}

export type BurnEventResolvers<
  ContextType = MeshContext,
  ParentType extends ResolversParentTypes['BurnEvent'] = ResolversParentTypes['BurnEvent'],
> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  token?: Resolver<ResolversTypes['Token'], ParentType, ContextType>
  amount?: Resolver<ResolversTypes['BigDecimal'], ParentType, ContextType>
  sender?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>
  burner?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>
  block?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>
  timestamp?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>
  transaction?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export interface BytesScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['Bytes'], any> {
  name: 'Bytes'
}

export type GoldenEggAwardedResolvers<
  ContextType = MeshContext,
  ParentType extends ResolversParentTypes['GoldenEggAwarded'] = ResolversParentTypes['GoldenEggAwarded'],
> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  recipient?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type MintEventResolvers<
  ContextType = MeshContext,
  ParentType extends ResolversParentTypes['MintEvent'] = ResolversParentTypes['MintEvent'],
> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  token?: Resolver<ResolversTypes['Token'], ParentType, ContextType>
  amount?: Resolver<ResolversTypes['BigDecimal'], ParentType, ContextType>
  sender?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>
  minter?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>
  destination?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>
  block?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>
  timestamp?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>
  transaction?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type NFTTokenResolvers<
  ContextType = MeshContext,
  ParentType extends ResolversParentTypes['NFTToken'] = ResolversParentTypes['NFTToken'],
> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  contract?: Resolver<ResolversTypes['NFTTokenContract'], ParentType, ContextType>
  tokenId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  creator?: Resolver<Maybe<ResolversTypes['Account']>, ParentType, ContextType>
  owners?: Resolver<
    Maybe<Array<ResolversTypes['OwnerTokenLookup']>>,
    ParentType,
    ContextType,
    RequireFields<NFTTokenownersArgs, 'skip' | 'first'>
  >
  createdAtTimeStamp?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>
  tokenURI?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  bonusPercentage?: Resolver<Maybe<ResolversTypes['BigDecimal']>, ParentType, ContextType>
  bonusDurationMins?: Resolver<Maybe<ResolversTypes['BigInt']>, ParentType, ContextType>
  startTime?: Resolver<Maybe<ResolversTypes['BigInt']>, ParentType, ContextType>
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  kind?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  maxSupply?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  mints?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  burns?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  eggMintAmt?: Resolver<Maybe<ResolversTypes['BigDecimal']>, ParentType, ContextType>
  eggBurnAmt?: Resolver<Maybe<ResolversTypes['BigDecimal']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type NFTTokenContractResolvers<
  ContextType = MeshContext,
  ParentType extends ResolversParentTypes['NFTTokenContract'] = ResolversParentTypes['NFTTokenContract'],
> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  isLikelyERC1155?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  symbol?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  totalSupply?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  maxSupply?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  maxGen0Supply?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  tokens?: Resolver<
    Array<ResolversTypes['NFTToken']>,
    ParentType,
    ContextType,
    RequireFields<NFTTokenContracttokensArgs, 'skip' | 'first'>
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type OwnerTokenLookupResolvers<
  ContextType = MeshContext,
  ParentType extends ResolversParentTypes['OwnerTokenLookup'] = ResolversParentTypes['OwnerTokenLookup'],
> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  owner?: Resolver<ResolversTypes['Account'], ParentType, ContextType>
  token?: Resolver<ResolversTypes['NFTToken'], ParentType, ContextType>
  contract?: Resolver<ResolversTypes['NFTTokenContract'], ParentType, ContextType>
  quantity?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type PauseEventResolvers<
  ContextType = MeshContext,
  ParentType extends ResolversParentTypes['PauseEvent'] = ResolversParentTypes['PauseEvent'],
> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  token?: Resolver<ResolversTypes['Token'], ParentType, ContextType>
  amount?: Resolver<ResolversTypes['BigDecimal'], ParentType, ContextType>
  sender?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>
  pauser?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>
  block?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>
  timestamp?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>
  transaction?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type QueryResolvers<
  ContextType = MeshContext,
  ParentType extends ResolversParentTypes['Query'] = ResolversParentTypes['Query'],
> = ResolversObject<{
  tfgdata?: Resolver<
    Maybe<ResolversTypes['TFGData']>,
    ParentType,
    ContextType,
    RequireFields<QuerytfgdataArgs, 'id' | 'subgraphError'>
  >
  tfgdatas?: Resolver<
    Array<ResolversTypes['TFGData']>,
    ParentType,
    ContextType,
    RequireFields<QuerytfgdatasArgs, 'skip' | 'first' | 'subgraphError'>
  >
  account?: Resolver<
    Maybe<ResolversTypes['Account']>,
    ParentType,
    ContextType,
    RequireFields<QueryaccountArgs, 'id' | 'subgraphError'>
  >
  accounts?: Resolver<
    Array<ResolversTypes['Account']>,
    ParentType,
    ContextType,
    RequireFields<QueryaccountsArgs, 'skip' | 'first' | 'subgraphError'>
  >
  accountBalance?: Resolver<
    Maybe<ResolversTypes['AccountBalance']>,
    ParentType,
    ContextType,
    RequireFields<QueryaccountBalanceArgs, 'id' | 'subgraphError'>
  >
  accountBalances?: Resolver<
    Array<ResolversTypes['AccountBalance']>,
    ParentType,
    ContextType,
    RequireFields<QueryaccountBalancesArgs, 'skip' | 'first' | 'subgraphError'>
  >
  accountBalanceSnapshot?: Resolver<
    Maybe<ResolversTypes['AccountBalanceSnapshot']>,
    ParentType,
    ContextType,
    RequireFields<QueryaccountBalanceSnapshotArgs, 'id' | 'subgraphError'>
  >
  accountBalanceSnapshots?: Resolver<
    Array<ResolversTypes['AccountBalanceSnapshot']>,
    ParentType,
    ContextType,
    RequireFields<QueryaccountBalanceSnapshotsArgs, 'skip' | 'first' | 'subgraphError'>
  >
  token?: Resolver<
    Maybe<ResolversTypes['Token']>,
    ParentType,
    ContextType,
    RequireFields<QuerytokenArgs, 'id' | 'subgraphError'>
  >
  tokens?: Resolver<
    Array<ResolversTypes['Token']>,
    ParentType,
    ContextType,
    RequireFields<QuerytokensArgs, 'skip' | 'first' | 'subgraphError'>
  >
  burnEvent?: Resolver<
    Maybe<ResolversTypes['BurnEvent']>,
    ParentType,
    ContextType,
    RequireFields<QueryburnEventArgs, 'id' | 'subgraphError'>
  >
  burnEvents?: Resolver<
    Array<ResolversTypes['BurnEvent']>,
    ParentType,
    ContextType,
    RequireFields<QueryburnEventsArgs, 'skip' | 'first' | 'subgraphError'>
  >
  mintEvent?: Resolver<
    Maybe<ResolversTypes['MintEvent']>,
    ParentType,
    ContextType,
    RequireFields<QuerymintEventArgs, 'id' | 'subgraphError'>
  >
  mintEvents?: Resolver<
    Array<ResolversTypes['MintEvent']>,
    ParentType,
    ContextType,
    RequireFields<QuerymintEventsArgs, 'skip' | 'first' | 'subgraphError'>
  >
  transferEvent?: Resolver<
    Maybe<ResolversTypes['TransferEvent']>,
    ParentType,
    ContextType,
    RequireFields<QuerytransferEventArgs, 'id' | 'subgraphError'>
  >
  transferEvents?: Resolver<
    Array<ResolversTypes['TransferEvent']>,
    ParentType,
    ContextType,
    RequireFields<QuerytransferEventsArgs, 'skip' | 'first' | 'subgraphError'>
  >
  pauseEvent?: Resolver<
    Maybe<ResolversTypes['PauseEvent']>,
    ParentType,
    ContextType,
    RequireFields<QuerypauseEventArgs, 'id' | 'subgraphError'>
  >
  pauseEvents?: Resolver<
    Array<ResolversTypes['PauseEvent']>,
    ParentType,
    ContextType,
    RequireFields<QuerypauseEventsArgs, 'skip' | 'first' | 'subgraphError'>
  >
  nfttoken?: Resolver<
    Maybe<ResolversTypes['NFTToken']>,
    ParentType,
    ContextType,
    RequireFields<QuerynfttokenArgs, 'id' | 'subgraphError'>
  >
  nfttokens?: Resolver<
    Array<ResolversTypes['NFTToken']>,
    ParentType,
    ContextType,
    RequireFields<QuerynfttokensArgs, 'skip' | 'first' | 'subgraphError'>
  >
  ownerTokenLookup?: Resolver<
    Maybe<ResolversTypes['OwnerTokenLookup']>,
    ParentType,
    ContextType,
    RequireFields<QueryownerTokenLookupArgs, 'id' | 'subgraphError'>
  >
  ownerTokenLookups?: Resolver<
    Array<ResolversTypes['OwnerTokenLookup']>,
    ParentType,
    ContextType,
    RequireFields<QueryownerTokenLookupsArgs, 'skip' | 'first' | 'subgraphError'>
  >
  nfttokenContract?: Resolver<
    Maybe<ResolversTypes['NFTTokenContract']>,
    ParentType,
    ContextType,
    RequireFields<QuerynfttokenContractArgs, 'id' | 'subgraphError'>
  >
  nfttokenContracts?: Resolver<
    Array<ResolversTypes['NFTTokenContract']>,
    ParentType,
    ContextType,
    RequireFields<QuerynfttokenContractsArgs, 'skip' | 'first' | 'subgraphError'>
  >
  tokenStaked?: Resolver<
    Maybe<ResolversTypes['TokenStaked']>,
    ParentType,
    ContextType,
    RequireFields<QuerytokenStakedArgs, 'id' | 'subgraphError'>
  >
  tokenStakeds?: Resolver<
    Array<ResolversTypes['TokenStaked']>,
    ParentType,
    ContextType,
    RequireFields<QuerytokenStakedsArgs, 'skip' | 'first' | 'subgraphError'>
  >
  roosterReceivedDroppedEgg?: Resolver<
    Maybe<ResolversTypes['RoosterReceivedDroppedEgg']>,
    ParentType,
    ContextType,
    RequireFields<QueryroosterReceivedDroppedEggArgs, 'id' | 'subgraphError'>
  >
  roosterReceivedDroppedEggs?: Resolver<
    Array<ResolversTypes['RoosterReceivedDroppedEgg']>,
    ParentType,
    ContextType,
    RequireFields<QueryroosterReceivedDroppedEggsArgs, 'skip' | 'first' | 'subgraphError'>
  >
  goldenEggAwarded?: Resolver<
    Maybe<ResolversTypes['GoldenEggAwarded']>,
    ParentType,
    ContextType,
    RequireFields<QuerygoldenEggAwardedArgs, 'id' | 'subgraphError'>
  >
  goldenEggAwardeds?: Resolver<
    Array<ResolversTypes['GoldenEggAwarded']>,
    ParentType,
    ContextType,
    RequireFields<QuerygoldenEggAwardedsArgs, 'skip' | 'first' | 'subgraphError'>
  >
  specialMint?: Resolver<
    Maybe<ResolversTypes['SpecialMint']>,
    ParentType,
    ContextType,
    RequireFields<QueryspecialMintArgs, 'id' | 'subgraphError'>
  >
  specialMints?: Resolver<
    Array<ResolversTypes['SpecialMint']>,
    ParentType,
    ContextType,
    RequireFields<QueryspecialMintsArgs, 'skip' | 'first' | 'subgraphError'>
  >
  tfgstat?: Resolver<
    Maybe<ResolversTypes['TFGStat']>,
    ParentType,
    ContextType,
    RequireFields<QuerytfgstatArgs, 'id' | 'subgraphError'>
  >
  tfgstats?: Resolver<
    Array<ResolversTypes['TFGStat']>,
    ParentType,
    ContextType,
    RequireFields<QuerytfgstatsArgs, 'skip' | 'first' | 'subgraphError'>
  >
  tokenEvent?: Resolver<
    Maybe<ResolversTypes['TokenEvent']>,
    ParentType,
    ContextType,
    RequireFields<QuerytokenEventArgs, 'id' | 'subgraphError'>
  >
  tokenEvents?: Resolver<
    Array<ResolversTypes['TokenEvent']>,
    ParentType,
    ContextType,
    RequireFields<QuerytokenEventsArgs, 'skip' | 'first' | 'subgraphError'>
  >
  _meta?: Resolver<Maybe<ResolversTypes['_Meta_']>, ParentType, ContextType, Partial<Query_metaArgs>>
}>

export type RoosterReceivedDroppedEggResolvers<
  ContextType = MeshContext,
  ParentType extends ResolversParentTypes['RoosterReceivedDroppedEgg'] = ResolversParentTypes['RoosterReceivedDroppedEgg'],
> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  owner?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>
  tokenId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  amount?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type SpecialMintResolvers<
  ContextType = MeshContext,
  ParentType extends ResolversParentTypes['SpecialMint'] = ResolversParentTypes['SpecialMint'],
> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  typeId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  imperialEggQtys?: Resolver<Maybe<ResolversTypes['BigInt']>, ParentType, ContextType>
  bonusEGGDuration?: Resolver<Maybe<ResolversTypes['BigInt']>, ParentType, ContextType>
  bonusEGGPercentage?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  bonusEGGAmount?: Resolver<Maybe<ResolversTypes['BigInt']>, ParentType, ContextType>
  specialMintFee?: Resolver<ResolversTypes['BigDecimal'], ParentType, ContextType>
  maxSupply?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  minted?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type SubscriptionResolvers<
  ContextType = MeshContext,
  ParentType extends ResolversParentTypes['Subscription'] = ResolversParentTypes['Subscription'],
> = ResolversObject<{
  tfgdata?: SubscriptionResolver<
    Maybe<ResolversTypes['TFGData']>,
    'tfgdata',
    ParentType,
    ContextType,
    RequireFields<SubscriptiontfgdataArgs, 'id' | 'subgraphError'>
  >
  tfgdatas?: SubscriptionResolver<
    Array<ResolversTypes['TFGData']>,
    'tfgdatas',
    ParentType,
    ContextType,
    RequireFields<SubscriptiontfgdatasArgs, 'skip' | 'first' | 'subgraphError'>
  >
  account?: SubscriptionResolver<
    Maybe<ResolversTypes['Account']>,
    'account',
    ParentType,
    ContextType,
    RequireFields<SubscriptionaccountArgs, 'id' | 'subgraphError'>
  >
  accounts?: SubscriptionResolver<
    Array<ResolversTypes['Account']>,
    'accounts',
    ParentType,
    ContextType,
    RequireFields<SubscriptionaccountsArgs, 'skip' | 'first' | 'subgraphError'>
  >
  accountBalance?: SubscriptionResolver<
    Maybe<ResolversTypes['AccountBalance']>,
    'accountBalance',
    ParentType,
    ContextType,
    RequireFields<SubscriptionaccountBalanceArgs, 'id' | 'subgraphError'>
  >
  accountBalances?: SubscriptionResolver<
    Array<ResolversTypes['AccountBalance']>,
    'accountBalances',
    ParentType,
    ContextType,
    RequireFields<SubscriptionaccountBalancesArgs, 'skip' | 'first' | 'subgraphError'>
  >
  accountBalanceSnapshot?: SubscriptionResolver<
    Maybe<ResolversTypes['AccountBalanceSnapshot']>,
    'accountBalanceSnapshot',
    ParentType,
    ContextType,
    RequireFields<SubscriptionaccountBalanceSnapshotArgs, 'id' | 'subgraphError'>
  >
  accountBalanceSnapshots?: SubscriptionResolver<
    Array<ResolversTypes['AccountBalanceSnapshot']>,
    'accountBalanceSnapshots',
    ParentType,
    ContextType,
    RequireFields<SubscriptionaccountBalanceSnapshotsArgs, 'skip' | 'first' | 'subgraphError'>
  >
  token?: SubscriptionResolver<
    Maybe<ResolversTypes['Token']>,
    'token',
    ParentType,
    ContextType,
    RequireFields<SubscriptiontokenArgs, 'id' | 'subgraphError'>
  >
  tokens?: SubscriptionResolver<
    Array<ResolversTypes['Token']>,
    'tokens',
    ParentType,
    ContextType,
    RequireFields<SubscriptiontokensArgs, 'skip' | 'first' | 'subgraphError'>
  >
  burnEvent?: SubscriptionResolver<
    Maybe<ResolversTypes['BurnEvent']>,
    'burnEvent',
    ParentType,
    ContextType,
    RequireFields<SubscriptionburnEventArgs, 'id' | 'subgraphError'>
  >
  burnEvents?: SubscriptionResolver<
    Array<ResolversTypes['BurnEvent']>,
    'burnEvents',
    ParentType,
    ContextType,
    RequireFields<SubscriptionburnEventsArgs, 'skip' | 'first' | 'subgraphError'>
  >
  mintEvent?: SubscriptionResolver<
    Maybe<ResolversTypes['MintEvent']>,
    'mintEvent',
    ParentType,
    ContextType,
    RequireFields<SubscriptionmintEventArgs, 'id' | 'subgraphError'>
  >
  mintEvents?: SubscriptionResolver<
    Array<ResolversTypes['MintEvent']>,
    'mintEvents',
    ParentType,
    ContextType,
    RequireFields<SubscriptionmintEventsArgs, 'skip' | 'first' | 'subgraphError'>
  >
  transferEvent?: SubscriptionResolver<
    Maybe<ResolversTypes['TransferEvent']>,
    'transferEvent',
    ParentType,
    ContextType,
    RequireFields<SubscriptiontransferEventArgs, 'id' | 'subgraphError'>
  >
  transferEvents?: SubscriptionResolver<
    Array<ResolversTypes['TransferEvent']>,
    'transferEvents',
    ParentType,
    ContextType,
    RequireFields<SubscriptiontransferEventsArgs, 'skip' | 'first' | 'subgraphError'>
  >
  pauseEvent?: SubscriptionResolver<
    Maybe<ResolversTypes['PauseEvent']>,
    'pauseEvent',
    ParentType,
    ContextType,
    RequireFields<SubscriptionpauseEventArgs, 'id' | 'subgraphError'>
  >
  pauseEvents?: SubscriptionResolver<
    Array<ResolversTypes['PauseEvent']>,
    'pauseEvents',
    ParentType,
    ContextType,
    RequireFields<SubscriptionpauseEventsArgs, 'skip' | 'first' | 'subgraphError'>
  >
  nfttoken?: SubscriptionResolver<
    Maybe<ResolversTypes['NFTToken']>,
    'nfttoken',
    ParentType,
    ContextType,
    RequireFields<SubscriptionnfttokenArgs, 'id' | 'subgraphError'>
  >
  nfttokens?: SubscriptionResolver<
    Array<ResolversTypes['NFTToken']>,
    'nfttokens',
    ParentType,
    ContextType,
    RequireFields<SubscriptionnfttokensArgs, 'skip' | 'first' | 'subgraphError'>
  >
  ownerTokenLookup?: SubscriptionResolver<
    Maybe<ResolversTypes['OwnerTokenLookup']>,
    'ownerTokenLookup',
    ParentType,
    ContextType,
    RequireFields<SubscriptionownerTokenLookupArgs, 'id' | 'subgraphError'>
  >
  ownerTokenLookups?: SubscriptionResolver<
    Array<ResolversTypes['OwnerTokenLookup']>,
    'ownerTokenLookups',
    ParentType,
    ContextType,
    RequireFields<SubscriptionownerTokenLookupsArgs, 'skip' | 'first' | 'subgraphError'>
  >
  nfttokenContract?: SubscriptionResolver<
    Maybe<ResolversTypes['NFTTokenContract']>,
    'nfttokenContract',
    ParentType,
    ContextType,
    RequireFields<SubscriptionnfttokenContractArgs, 'id' | 'subgraphError'>
  >
  nfttokenContracts?: SubscriptionResolver<
    Array<ResolversTypes['NFTTokenContract']>,
    'nfttokenContracts',
    ParentType,
    ContextType,
    RequireFields<SubscriptionnfttokenContractsArgs, 'skip' | 'first' | 'subgraphError'>
  >
  tokenStaked?: SubscriptionResolver<
    Maybe<ResolversTypes['TokenStaked']>,
    'tokenStaked',
    ParentType,
    ContextType,
    RequireFields<SubscriptiontokenStakedArgs, 'id' | 'subgraphError'>
  >
  tokenStakeds?: SubscriptionResolver<
    Array<ResolversTypes['TokenStaked']>,
    'tokenStakeds',
    ParentType,
    ContextType,
    RequireFields<SubscriptiontokenStakedsArgs, 'skip' | 'first' | 'subgraphError'>
  >
  roosterReceivedDroppedEgg?: SubscriptionResolver<
    Maybe<ResolversTypes['RoosterReceivedDroppedEgg']>,
    'roosterReceivedDroppedEgg',
    ParentType,
    ContextType,
    RequireFields<SubscriptionroosterReceivedDroppedEggArgs, 'id' | 'subgraphError'>
  >
  roosterReceivedDroppedEggs?: SubscriptionResolver<
    Array<ResolversTypes['RoosterReceivedDroppedEgg']>,
    'roosterReceivedDroppedEggs',
    ParentType,
    ContextType,
    RequireFields<SubscriptionroosterReceivedDroppedEggsArgs, 'skip' | 'first' | 'subgraphError'>
  >
  goldenEggAwarded?: SubscriptionResolver<
    Maybe<ResolversTypes['GoldenEggAwarded']>,
    'goldenEggAwarded',
    ParentType,
    ContextType,
    RequireFields<SubscriptiongoldenEggAwardedArgs, 'id' | 'subgraphError'>
  >
  goldenEggAwardeds?: SubscriptionResolver<
    Array<ResolversTypes['GoldenEggAwarded']>,
    'goldenEggAwardeds',
    ParentType,
    ContextType,
    RequireFields<SubscriptiongoldenEggAwardedsArgs, 'skip' | 'first' | 'subgraphError'>
  >
  specialMint?: SubscriptionResolver<
    Maybe<ResolversTypes['SpecialMint']>,
    'specialMint',
    ParentType,
    ContextType,
    RequireFields<SubscriptionspecialMintArgs, 'id' | 'subgraphError'>
  >
  specialMints?: SubscriptionResolver<
    Array<ResolversTypes['SpecialMint']>,
    'specialMints',
    ParentType,
    ContextType,
    RequireFields<SubscriptionspecialMintsArgs, 'skip' | 'first' | 'subgraphError'>
  >
  tfgstat?: SubscriptionResolver<
    Maybe<ResolversTypes['TFGStat']>,
    'tfgstat',
    ParentType,
    ContextType,
    RequireFields<SubscriptiontfgstatArgs, 'id' | 'subgraphError'>
  >
  tfgstats?: SubscriptionResolver<
    Array<ResolversTypes['TFGStat']>,
    'tfgstats',
    ParentType,
    ContextType,
    RequireFields<SubscriptiontfgstatsArgs, 'skip' | 'first' | 'subgraphError'>
  >
  tokenEvent?: SubscriptionResolver<
    Maybe<ResolversTypes['TokenEvent']>,
    'tokenEvent',
    ParentType,
    ContextType,
    RequireFields<SubscriptiontokenEventArgs, 'id' | 'subgraphError'>
  >
  tokenEvents?: SubscriptionResolver<
    Array<ResolversTypes['TokenEvent']>,
    'tokenEvents',
    ParentType,
    ContextType,
    RequireFields<SubscriptiontokenEventsArgs, 'skip' | 'first' | 'subgraphError'>
  >
  _meta?: SubscriptionResolver<
    Maybe<ResolversTypes['_Meta_']>,
    '_meta',
    ParentType,
    ContextType,
    Partial<Subscription_metaArgs>
  >
}>

export type TFGDataResolvers<
  ContextType = MeshContext,
  ParentType extends ResolversParentTypes['TFGData'] = ResolversParentTypes['TFGData'],
> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  preSaleTime?: Resolver<Maybe<ResolversTypes['BigInt']>, ParentType, ContextType>
  preSaleFee?: Resolver<Maybe<ResolversTypes['BigDecimal']>, ParentType, ContextType>
  preSaleStakeFee?: Resolver<Maybe<ResolversTypes['BigDecimal']>, ParentType, ContextType>
  preSaleMaxQty?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  allowListTime?: Resolver<Maybe<ResolversTypes['BigInt']>, ParentType, ContextType>
  publicTime?: Resolver<Maybe<ResolversTypes['BigInt']>, ParentType, ContextType>
  publicFee?: Resolver<Maybe<ResolversTypes['BigDecimal']>, ParentType, ContextType>
  publicStakeFee?: Resolver<Maybe<ResolversTypes['BigDecimal']>, ParentType, ContextType>
  publicMaxPerTx?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  publicMaxQty?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  teamReserve?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  changeNameFee?: Resolver<Maybe<ResolversTypes['BigDecimal']>, ParentType, ContextType>
  changeDescFee?: Resolver<Maybe<ResolversTypes['BigDecimal']>, ParentType, ContextType>
  changeBGColorFee?: Resolver<Maybe<ResolversTypes['BigDecimal']>, ParentType, ContextType>
  farmAnimalsAddress?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type TFGStatResolvers<
  ContextType = MeshContext,
  ParentType extends ResolversParentTypes['TFGStat'] = ResolversParentTypes['TFGStat'],
> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  hensMinted?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  henTwinsMinted?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  hensBurned?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  hensStaked?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  hensKidnapped?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  hensRescued?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  hensSacrificed?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  hensEggTaxesPaid?: Resolver<Maybe<ResolversTypes['BigDecimal']>, ParentType, ContextType>
  coyotesMinted?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  coyotesBurned?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  coyotesStaked?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  coyotesKidnapped?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  coyotesSacrificed?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  coyoteEggTaxCollected?: Resolver<Maybe<ResolversTypes['BigDecimal']>, ParentType, ContextType>
  roostersMinted?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  roostersBurned?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  roostersStaked?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  roostersEggRobbed?: Resolver<Maybe<ResolversTypes['BigDecimal']>, ParentType, ContextType>
  eggsProduced?: Resolver<Maybe<ResolversTypes['BigDecimal']>, ParentType, ContextType>
  eggsTaxed?: Resolver<Maybe<ResolversTypes['BigDecimal']>, ParentType, ContextType>
  eggsDropped?: Resolver<Maybe<ResolversTypes['BigDecimal']>, ParentType, ContextType>
  eggsRescued?: Resolver<Maybe<ResolversTypes['BigDecimal']>, ParentType, ContextType>
  eggShopPiesBaked?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  eggShopPiesSold?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  eggShopPiesStolen?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  imperialEggAwarded?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  rainbowEggAwarded?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  platinumEggAwarded?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  goldenEggAwarded?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  silverEggAwarded?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  bronzeEggAwarded?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type TokenResolvers<
  ContextType = MeshContext,
  ParentType extends ResolversParentTypes['Token'] = ResolversParentTypes['Token'],
> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  address?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>
  decimals?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  symbol?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  imageUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  paused?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  eventCount?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>
  burnEventCount?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>
  mintEventCount?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>
  transferEventCount?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>
  totalSupply?: Resolver<ResolversTypes['BigDecimal'], ParentType, ContextType>
  totalBurned?: Resolver<ResolversTypes['BigDecimal'], ParentType, ContextType>
  totalMinted?: Resolver<ResolversTypes['BigDecimal'], ParentType, ContextType>
  totalTransferred?: Resolver<ResolversTypes['BigDecimal'], ParentType, ContextType>
  events?: Resolver<
    Array<ResolversTypes['TokenEvent']>,
    ParentType,
    ContextType,
    RequireFields<TokeneventsArgs, 'skip' | 'first'>
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type TokenEventResolvers<
  ContextType = MeshContext,
  ParentType extends ResolversParentTypes['TokenEvent'] = ResolversParentTypes['TokenEvent'],
> = ResolversObject<{
  __resolveType: TypeResolveFn<'BurnEvent' | 'MintEvent' | 'PauseEvent' | 'TransferEvent', ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  token?: Resolver<ResolversTypes['Token'], ParentType, ContextType>
  amount?: Resolver<ResolversTypes['BigDecimal'], ParentType, ContextType>
  sender?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>
  block?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>
  timestamp?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>
  transaction?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>
}>

export type TokenStakedResolvers<
  ContextType = MeshContext,
  ParentType extends ResolversParentTypes['TokenStaked'] = ResolversParentTypes['TokenStaked'],
> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  owner?: Resolver<ResolversTypes['Account'], ParentType, ContextType>
  tokenId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  kind?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  eggPerRank?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>
  stakedTimestamp?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>
  unstakeTimestamp?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>
  bonusPercentage?: Resolver<Maybe<ResolversTypes['BigDecimal']>, ParentType, ContextType>
  bonusDurationMins?: Resolver<Maybe<ResolversTypes['BigInt']>, ParentType, ContextType>
  startTime?: Resolver<Maybe<ResolversTypes['BigInt']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type TransferEventResolvers<
  ContextType = MeshContext,
  ParentType extends ResolversParentTypes['TransferEvent'] = ResolversParentTypes['TransferEvent'],
> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  token?: Resolver<ResolversTypes['Token'], ParentType, ContextType>
  amount?: Resolver<ResolversTypes['BigDecimal'], ParentType, ContextType>
  sender?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>
  source?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>
  destination?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>
  block?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>
  timestamp?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>
  transaction?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type _Block_Resolvers<
  ContextType = MeshContext,
  ParentType extends ResolversParentTypes['_Block_'] = ResolversParentTypes['_Block_'],
> = ResolversObject<{
  hash?: Resolver<Maybe<ResolversTypes['Bytes']>, ParentType, ContextType>
  number?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  timestamp?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type _Meta_Resolvers<
  ContextType = MeshContext,
  ParentType extends ResolversParentTypes['_Meta_'] = ResolversParentTypes['_Meta_'],
> = ResolversObject<{
  block?: Resolver<ResolversTypes['_Block_'], ParentType, ContextType>
  deployment?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  hasIndexingErrors?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type Resolvers<ContextType = MeshContext> = ResolversObject<{
  Account?: AccountResolvers<ContextType>
  AccountBalance?: AccountBalanceResolvers<ContextType>
  AccountBalanceSnapshot?: AccountBalanceSnapshotResolvers<ContextType>
  BigDecimal?: GraphQLScalarType
  BigInt?: GraphQLScalarType
  BurnEvent?: BurnEventResolvers<ContextType>
  Bytes?: GraphQLScalarType
  GoldenEggAwarded?: GoldenEggAwardedResolvers<ContextType>
  MintEvent?: MintEventResolvers<ContextType>
  NFTToken?: NFTTokenResolvers<ContextType>
  NFTTokenContract?: NFTTokenContractResolvers<ContextType>
  OwnerTokenLookup?: OwnerTokenLookupResolvers<ContextType>
  PauseEvent?: PauseEventResolvers<ContextType>
  Query?: QueryResolvers<ContextType>
  RoosterReceivedDroppedEgg?: RoosterReceivedDroppedEggResolvers<ContextType>
  SpecialMint?: SpecialMintResolvers<ContextType>
  Subscription?: SubscriptionResolvers<ContextType>
  TFGData?: TFGDataResolvers<ContextType>
  TFGStat?: TFGStatResolvers<ContextType>
  Token?: TokenResolvers<ContextType>
  TokenEvent?: TokenEventResolvers<ContextType>
  TokenStaked?: TokenStakedResolvers<ContextType>
  TransferEvent?: TransferEventResolvers<ContextType>
  _Block_?: _Block_Resolvers<ContextType>
  _Meta_?: _Meta_Resolvers<ContextType>
}>

export type DirectiveResolvers<ContextType = MeshContext> = ResolversObject<{
  entity?: entityDirectiveResolver<any, any, ContextType>
  subgraphId?: subgraphIdDirectiveResolver<any, any, ContextType>
  derivedFrom?: derivedFromDirectiveResolver<any, any, ContextType>
}>

export type MeshContext = TheFarmGameGoerliTypes.Context & BaseMeshContext

const baseDir = pathModule.join(pathModule.dirname(fileURLToPath(import.meta.url)), '..')

const importFn: ImportFn = <T>(moduleId: string) => {
  const relativeModuleId = (pathModule.isAbsolute(moduleId) ? pathModule.relative(baseDir, moduleId) : moduleId)
    .split('\\')
    .join('/')
    .replace(baseDir + '/', '')
  switch (relativeModuleId) {
    case '.graphclient/sources/the-farm-game-goerli/introspectionSchema':
      return import('./sources/the-farm-game-goerli/introspectionSchema') as T

    default:
      return Promise.reject(new Error(`Cannot find module '${relativeModuleId}'.`))
  }
}

const rootStore = new MeshStore(
  '.graphclient',
  new FsStoreStorageAdapter({
    cwd: baseDir,
    importFn,
    fileType: 'ts',
  }),
  {
    readonly: true,
    validate: false,
  },
)

export const rawServeConfig: YamlConfig.Config['serve'] = undefined as any
export async function getMeshOptions(): Promise<GetMeshOptions> {
  const pubsub = new PubSub()
  const sourcesStore = rootStore.child('sources')
  const logger = new DefaultLogger('GraphClient')
  const cache = new (MeshCache as any)({
    ...({} as any),
    importFn,
    store: rootStore.child('cache'),
    pubsub,
    logger,
  } as any)

  const sources: MeshResolvedSource[] = []
  const transforms: MeshTransform[] = []
  const additionalEnvelopPlugins: MeshPlugin<any>[] = []
  const theFarmGameGoerliTransforms = []
  const additionalTypeDefs = [] as any[]
  const theFarmGameGoerliHandler = new GraphqlHandler({
    name: 'the-farm-game-goerli',
    config: { endpoint: 'http://127.0.0.1:8000/subgraphs/name/the-farm-game' },
    baseDir,
    cache,
    pubsub,
    store: sourcesStore.child('the-farm-game-goerli'),
    logger: logger.child('the-farm-game-goerli'),
    importFn,
  })
  sources[0] = {
    name: 'the-farm-game-goerli',
    handler: theFarmGameGoerliHandler,
    transforms: theFarmGameGoerliTransforms,
  }
  additionalEnvelopPlugins[0] = await UsePollingLive({
    ...{
      defaultInterval: 10000,
    },
    logger: logger.child('pollingLive'),
    cache,
    pubsub,
    baseDir,
    importFn,
  })
  const additionalResolvers = [] as any[]
  const merger = new (BareMerger as any)({
    cache,
    pubsub,
    logger: logger.child('bareMerger'),
    store: rootStore.child('bareMerger'),
  })

  return {
    sources,
    transforms,
    additionalTypeDefs,
    additionalResolvers,
    cache,
    pubsub,
    merger,
    logger,
    additionalEnvelopPlugins,
    get documents() {
      return []
    },
    fetchFn,
  }
}

export function createBuiltMeshHTTPHandler(): MeshHTTPHandler<MeshContext> {
  return createMeshHTTPHandler<MeshContext>({
    baseDir,
    getBuiltMesh: getBuiltGraphClient,
    rawServeConfig: undefined,
  })
}

let meshInstance$: Promise<MeshInstance> | undefined

export function getBuiltGraphClient(): Promise<MeshInstance> {
  if (meshInstance$ == null) {
    meshInstance$ = getMeshOptions()
      .then((meshOptions) => getMesh(meshOptions))
      .then((mesh) => {
        const id = mesh.pubsub.subscribe('destroy', () => {
          meshInstance$ = undefined
          mesh.pubsub.unsubscribe(id)
        })
        return mesh
      })
  }
  return meshInstance$
}

export const execute: ExecuteMeshFn = (...args) => getBuiltGraphClient().then(({ execute }) => execute(...args))

export const subscribe: SubscribeMeshFn = (...args) => getBuiltGraphClient().then(({ subscribe }) => subscribe(...args))
