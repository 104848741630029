import { useState } from 'react'
import { Button, Spinner } from 'react-bootstrap'
import './style.scss'

import toast from 'react-hot-toast'

import {
  useAccount,
  useContractWrite,
  useWaitForTransaction,
  usePrepareContractWrite,
  useSigner,
  useBalance,
} from 'wagmi'

import TransactionModal from '../TransactionModal'

interface PropType {
  ContractAddress: string
  ContractABI: any
  ContractFunction: string
  ContractArgs: any[]
  ContractOverRides: any
  ContractIsEnabled: boolean
  ButtonTextPrepareLoading: string
  ButtonTextPrepareIdle: string
  ButtonTextInitial: string
  ButtonTextLoading: string
  ButtonTextWriting: string
  ButtonTextError: string
  ButtonTextInsufficientBalance?: string
  StartAlertText: string
  StartAlertOptions: any
  OnStartFunction?: any
  SuccessAlertText: string
  SuccessAlertOptions: any
  OnSuccessFunction?: any
  imageWaitTx?: string
  style?: any
  debug?: boolean
}

const GAS_LIMIT_MULTIPLIER = 1.5

const ButtonContractWrite = (props: PropType): JSX.Element => {
  const {
    ContractAddress,
    ContractABI,
    ContractFunction,
    ContractArgs,
    ContractOverRides,
    ContractIsEnabled,
    ButtonTextPrepareLoading,
    ButtonTextPrepareIdle,
    ButtonTextInitial,
    ButtonTextLoading,
    ButtonTextWriting,
    ButtonTextError,
    ButtonTextInsufficientBalance = 'Insufficient Balance',
    StartAlertText,
    StartAlertOptions,
    OnStartFunction,
    SuccessAlertText,
    SuccessAlertOptions,
    OnSuccessFunction,
    imageWaitTx,
    style,
    debug,
  } = props

  const { data: signer, isError, isLoading } = useSigner()

  const { address } = useAccount()
  const { data: balanceData, isLoading: balanceIsLoading } = useBalance({
    addressOrName: address,
  })

  const [showTxPendingModal, setShowTxPendingModal] = useState(false)

  const handleShowModal = (show: boolean) => {
    setShowTxPendingModal(show)
    if (OnSuccessFunction) {
      OnSuccessFunction()
    }
  }

  const {
    config: prepareConfig,
    error: prepareError,
    status: prepareStatus,
    isLoading: prepareIsLoading,
    isIdle: prepareIsIdle,
    isSuccess: prepareIsSuccess,
    isError: prepareIsError,
  } = usePrepareContractWrite({
    address: ContractAddress,
    abi: ContractABI,
    functionName: ContractFunction,
    args: ContractArgs,
    overrides: ContractOverRides,
    enabled: ContractIsEnabled && !!address,
    // && !isExecuting,
    signer: signer,
    onSuccess(data) {
      // console.log(`🚀 ~ usePrepareContractWrite data args: ${ContractArgs}`, data)
      // console.log(`🚀 ~ usePrepareContractWrite initial gasLimit args: ${ContractArgs}`, data.request.gasLimit.toString())
      // const gasLimitMulti = data.request.gasLimit * GAS_LIMIT_MULTIPLIER
      // console.log(`🚀 ~ usePrepareContractWrite gasLimit Multi A args: ${ContractArgs}`, Math.ceil(gasLimitMulti * GAS_LIMIT_MULTIPLIER ))
    },
    onError(error) {
      if (error && prepareIsError) {
        // console.error(`🚀 ~ displayMintItems ${ContractFunction} usePrepareContractWrite error.message:`, error.message)
        // FIXME: Disabled errors for now
        // const revertReason = parseEthersError(error.message)
        // toast.error(`usePrepareContractWrite Error: ${revertReason}`)
        // setTimeout(() => {
        //   writeReset()
        // }, 2000)
      }
    },
  })

  const {
    data: writeData,
    write: contractWrite,
    isLoading: writeIsLoading,
    isIdle: writeIsIdle,
    isSuccess: writeIsSuccess,
    isError: writeIsError,
    reset: writeReset,
  } = useContractWrite({
    ...prepareConfig,
    request: {
      ...prepareConfig.request,
      gasLimit: Math.ceil(prepareConfig?.request?.gasLimit * GAS_LIMIT_MULTIPLIER),
    },
    onSuccess(data) {
      // console.log(`🚀 ~ useContractWrite success data`, data)
      // toast.success(StartAlertText, StartAlertOptions)
      if (imageWaitTx) {
        setShowTxPendingModal(true)
      }
    },
    onError(error) {
      if (error && writeIsError) {
        console.error(`🚀 ~ displayMintItems ${ContractFunction} useContractWrite error`, error)
        // FIXME: Disabled errors for now
        // const revertReason = parseEthersError(error.message)
        // toast.error(`contractWrite Error: ${revertReason}`)
        // setTimeout(() => {
        //   writeReset()
        // }, 2000)
      }
    },
  })

  const {
    data: waitTxData,
    isIdle: txIsIdle,
    isLoading: txIsLoading,
    isFetching: txIsFetching,
    isSuccess: txIsSuccess,
    isFetched: txIsFetched,
    isRefetching: txIsReFetched,
    isError: txIsError,
  } = useWaitForTransaction({
    confirmations: 1,
    hash: writeData?.hash,
    wait: writeData?.wait,
    onSuccess(data) {
      // console.log(`🚀 ~ tx success data`, data)
      // console.log(`🚀 ~ tx success data`, data.gasUsed.toString())
      // toast.success(SuccessAlertText, SuccessAlertOptions)
    },
    onError(error) {
      setTimeout(() => {
        writeReset()
      }, 4000)
      if (error && txIsError) {
        console.error(`🚀 ~ displayMintItems ${ContractFunction} useWaitForTransaction error.name`, error.name)
        console.error(`🚀 ~ displayMintItems ${ContractFunction} useWaitForTransaction error.message`, error.message)
        console.error(`🚀 ~ displayMintItems ${ContractFunction} useWaitForTransaction error.stack`, error.stack)
        console.error(`🚀 ~ displayMintItems ${ContractFunction} useWaitForTransaction error.cause`, error.cause)
        // FIXME: Disabled errors for now
        // const revertReason = parseEthersError(error.message)
        // toast.error(`useWaitForTransaction Error: ${revertReason}`)
      }
    },
  })

  const write = () => {
    if (OnStartFunction) OnStartFunction()
    contractWrite?.()
  }

  function ShowSpinner() {
    return (
      <>
        <Spinner as='span' animation='border' size='sm' role='status' aria-hidden='true' />{' '}
      </>
    )
  }

  if (!prepareConfig) {
    return (
      <div className='loading--section'>
        <h1>Button Loading...</h1>
      </div>
    )
  }

  return (
    <>
      <Button
        style={style}
        disabled={writeIsLoading || txIsLoading || prepareIsIdle || prepareIsLoading || !ContractIsEnabled}
        className='btn__anchor btn-primary btn_width text-nowrap'
        data-mint-loading={writeIsLoading}
        data-mint-started={writeIsSuccess}
        onClick={() => write()}
      >
        {debug && prepareIsLoading && (
          <>
            'prepareIsLoading' <br />
          </>
        )}
        {debug && prepareIsIdle && (
          <>
            'prepareIsIdle' <br />
          </>
        )}
        {debug && prepareIsError && (
          <>
            'prepareIsError' <br />
          </>
        )}
        {debug && prepareIsSuccess && (
          <>
            'prepareIsSuccess' <br />
          </>
        )}
        {debug && writeIsIdle && (
          <>
            'writeIsIdle' <br />
          </>
        )}
        {debug && writeIsLoading && (
          <>
            'writeIsLoading' <br />
          </>
        )}
        {debug && writeIsError && (
          <>
            'writeIsError' <br />
          </>
        )}
        {debug && writeIsSuccess && (
          <>
            'writeIsSuccess' <br />
          </>
        )}
        {debug && txIsIdle && (
          <>
            'txIsIdle' <br />
          </>
        )}
        {debug && txIsSuccess && (
          <>
            'txIsSuccess' <br />
          </>
        )}
        {debug && txIsLoading && (
          <>
            'txIsLoading' <br />
          </>
        )}
        {debug && txIsFetching && (
          <>
            'txIsFetching' <br />
          </>
        )}
        {debug && txIsFetched && (
          <>
            'txIsFetched' <br />
          </>
        )}
        {debug && txIsReFetched && (
          <>
            'txIsReFetched' <br />
          </>
        )}
        {debug && txIsError && (
          <>
            'txIsError' <br />
          </>
        )}
        {debug && ContractIsEnabled && (
          <>
            'ContractIsEnabled' <br />
          </>
        )}

        {(prepareIsIdle || !ContractIsEnabled) && <>{ButtonTextPrepareIdle}</>}

        {ContractIsEnabled && (
          <>
            {(prepareIsLoading || balanceIsLoading) && (
              <>
                <ShowSpinner /> {ButtonTextPrepareLoading}
              </>
            )}
            {((writeIsIdle && txIsIdle) || (txIsSuccess && writeIsSuccess)) &&
              prepareIsSuccess &&
              (prepareConfig?.request?.value && balanceData?.value && prepareConfig.request.value.gt(balanceData.value)
                ? ButtonTextInsufficientBalance
                : ButtonTextInitial)}

            {writeIsLoading && txIsIdle && (
              <>
                <ShowSpinner /> {ButtonTextLoading}
              </>
            )}
            {txIsLoading && (
              <>
                <ShowSpinner /> {ButtonTextWriting}
              </>
            )}
            {(writeIsError || prepareError) && ButtonTextError}
          </>
        )}
      </Button>

      <TransactionModal
        showModal={showTxPendingModal}
        handelModalDisplay={handleShowModal}
        rawLogs={waitTxData}
        imageWaitTx={imageWaitTx ? imageWaitTx : undefined}
      />
    </>
  )
}

export default ButtonContractWrite
