/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useContext, useState } from 'react'

import { useBalance } from 'wagmi'
import numbro from 'numbro'

import { useContracts } from '../../hooks/useContracts'

const TreasuryBalanceBox = (): JSX.Element => {
  const ContractAddresses = useContracts()

  const [theFarmDaoETHBalances, setTheFarmDaoETHBalances] = useState('ETH: 0')
  const [theFarmDaoEGGBalances, setTheFarmDaoEGGBalances] = useState('EGG: 0')

  useBalance({
    addressOrName: ContractAddresses.TheFarmDaoExecutorContractAddress,
    onSuccess(data) {
      const numbroFormatted = numbro(data.formatted).format({
        average: true,
        mantissa: 2,
      })
      setTheFarmDaoETHBalances(`${data.symbol}: ${numbroFormatted}`)
    },
  })

  useBalance({
    addressOrName: ContractAddresses.TheFarmDaoExecutorContractAddress,
    token: ContractAddresses.EGGTokenContractAddress,
    onSuccess(data) {
      const numbroFormatted = numbro(data.formatted).format({
        average: true,
        mantissa: 2,
      })
      setTheFarmDaoEGGBalances(`${data.symbol}: ${numbroFormatted}`)
    },
  })

  return (
    <div className='treasury__card'>
      <a
        className='nav-link'
        href={`https://etherscan.io/tokenholdings?a=${ContractAddresses.TheFarmDaoExecutorContractAddress}`}
      >
        <h1>Treasury</h1>
        <p>{theFarmDaoEGGBalances}</p>
        <p>{theFarmDaoETHBalances}</p>
      </a>
    </div>
  )
}

export default TreasuryBalanceBox
