import React from 'react'
import { ProjectData, FooterData } from '../../constants'
import { Link } from 'react-router-dom'

import './BottomFooter.scss'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const BottomFooter = () => {
  return (
    <div id='mainFtrBtm' className='text-center'>
      <ul className='copy'>
        <li>
          &copy; {FooterData.copyrightYear}, by {ProjectData.projectTitle} |
        </li>
        {FooterData.verifiedContractUrl && FooterData.verifiedContractAddress && (
          <li>
            {' '}
            <a href={`${FooterData.verifiedContractUrl}/${FooterData.verifiedContractAddress}`}>
              {FooterData.verifiedContractTitle}
            </a>{' '}
            |
          </li>
        )}
        {/* <li>
          {' '}
          <Link to='/terms'>{FooterData.termsTitle}</Link> |{' '}
        </li> */}
        {ProjectData.socials.map(
          (item, index) =>
            item.url && (
              <li key={index.toString()}>
                <a target='_blank' href={item.url} rel='noreferrer'>
                  <svg xmlns='http://www.w3.org/2000/svg' viewBox={item.svgViewBox} height={24} width={24}>
                    <path fill='#ffffff' d={item.svgData} className=''></path>
                  </svg>
                </a>{' '}
              </li>
            ),
        )}
      </ul>
    </div>
  )
}

export default BottomFooter
