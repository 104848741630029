import { useState, useEffect } from 'react'

import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'

import { useAccount, useBalance, useContractRead } from 'wagmi'

import { ApplePieBakeBurnProps, EggShopItemDataProps, EggShopTokenTypeProps } from '../../types'

import Base64ToJson from '../../utils/Base64ToJson'

import ButtonContractWrite from '../ButtonContractWrite'

import { useQuery } from 'urql'
import { ethers } from 'ethers'

import ApplePieBakingImg from '../../assets/images/minting_animations/apple_pie_baking_start.gif'
import ApplePieSellingStart from '../../assets/images/minting_animations/apple_pie_selling_start.gif'

import { useFarmAnimalsData } from '../../hooks/useFarmAnimalsData'
import { useContractABIs } from '../../hooks/useContractsABI'
import { useContracts } from '../../hooks/useContracts'

const ApplePie = (): JSX.Element => {
  const ContractAddresses = useContracts()

  const ContractABIs = useContractABIs()

  const { address } = useAccount()
  const [userApplePieBalance, setUserApplePieBalance] = useState(0)

  const [userApplePieInfo, setUserApplePieInfo] = useState<EggShopItemDataProps>()

  const [userApplePieFeeInfo, setUserApplePieFeeInfo] = useState<ApplePieBakeBurnProps>()

  const tfgSupplyData = useFarmAnimalsData()

  const [show, setShow] = useState(false)

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)
  const handleTweet = () => {
    const tweetShareURI = 'https://twitter.com/intent/tweet?'
    const hashtags = 'hashtags=TFG,theFarmGame,nft'
    const originalReferer = '&original_referer=https%3A%2F%2Fthefarm.game%2F'
    const imageURI = ''
    // const other = '&ref_src=twsrc%5Etfw%7Ctwcamp%5Ebuttonembed%7Ctwterm%5Eshare%7Ctwgr%5E&related=twitterapi%2Ctwitter'
    const message =
      '&text=I%20just%20baked%20an%20Apple%20Pie%20🥧%20on%20The%20Farm%20Game&url=https%3A%2F%2Fthefarm.game'
    const via = '&via=the_farm_game'

    const fullURI = tweetShareURI + hashtags + originalReferer + message + imageURI + via

    const win = window.open(fullURI, '_blank')
    win?.focus()
  }

  const theFarmGamePlay = {
    address: ContractAddresses.TheFarmGamePlayContractAddress,
    abi: ContractABIs.TheFarmGamePlayABI,
  }

  useContractRead({
    ...theFarmGamePlay,
    functionName: 'getApplePiePrice',
    overrides: { from: address },
    // cacheTime: 10_000,
    watch: false,
    onSuccess(data) {
      if (data) {
        setUserApplePieFeeInfo({
          mintFee: parseFloat(ethers.utils.formatUnits(data[0])),
          burnFee: parseFloat(ethers.utils.formatUnits(data[1])),
        })
      }
    },
    onError(error) {
      console.error('applePie getApplePiePrice Error', error)
    },
  })

  const [userEGGBalance, setUserEGGBalance] = useState(0)

  useBalance({
    addressOrName: address,
    token: ContractAddresses.EGGTokenContractAddress,
    watch: false,
    onSuccess(data) {
      const bnBalance = data.formatted.toString()
      setUserEGGBalance(parseFloat(bnBalance))
    },
    onError(err) {
      console.error(`🚀 ~ MintGen1 UserEggBalance err`, err)
    },
  })

  // Get users apple pie balance & info

  const GetUserEggTokenApplePieBalance = `
	query accountEggTokenApplePieBalance($accountAddress: String, $farmAnimalsContract: String, $applePieTokenId: String, $applePieUserTokenId: String) {
		account(id: $accountAddress) {
			applePie: nftTokens(
				where: {id: $applePieUserTokenId}
			) {
				id
				quantity
			}
		}
		applePie: nfttoken(id: $applePieTokenId) {
			id
			kind
			maxSupply
			mints
			burns
			eggMintAmt
			eggBurnAmt
			tokenURI
		}

	}
`

  // useQuery urql

  const [result, reexecuteQuery] = useQuery({
    query: GetUserEggTokenApplePieBalance,
    variables: {
      accountAddress: address?.toLowerCase(),
      eggShopContract: ContractAddresses.EggShopContractAddress.toLowerCase(),
      applePieTokenId: `${ContractAddresses.EggShopContractAddress.toLowerCase()}_1`,
      applePieUserTokenId: `${address?.toLowerCase()}:${ContractAddresses.EggShopContractAddress.toLowerCase()}_1`,
      farmAnimalsContract: ContractAddresses.FarmAnimalsContractAddress.toLowerCase(),
    },
    // pause: loadingCompleted,
  })

  const { data: urqlData, fetching: urqlFetching, error: urqlError } = result

  useEffect(() => {
    if (urqlData) {
      if (urqlData.account && urqlData.account.applePie.length > 0 && urqlData.account.applePie[0].quantity) {
        setUserApplePieBalance(urqlData.account.applePie[0].quantity)
      }

      if (urqlData.applePie) {
        const applePieData = urqlData.applePie
        const applePieMetadata: EggShopTokenTypeProps = Base64ToJson(applePieData.tokenURI)
        setUserApplePieInfo({
          name: 'Apple Pie',
          mints: applePieData.mints,
          burns: applePieData.burns,
          maxSupply: applePieData.maxSupply,
          eggMintAmt: parseFloat(applePieData.eggMintAmt),
          eggBurnAmt: parseFloat(applePieData.eggBurnAmt),
          background: '',
          metadata: applePieMetadata,
          image: applePieMetadata.image,
        })
      }
    }
  }, [urqlData])

  if (urqlFetching) {
    return (
      <div className='loading--section'>
        <h1>Fetching Apple Pies...</h1>
      </div>
    )
  }
  if (urqlError) {
    return (
      <div className='loading--section'>
        <h1>Error fetching applePie...</h1>
      </div>
    )
  }

  if (!urqlData || !tfgSupplyData) {
    return (
      <div className='loading--section'>
        <h1>Apple Pies Loading...</h1>
      </div>
    )
  }

  return (
    <div className='tab__card'>
      <div className='tab__text'>
        <h1>Apple pie</h1>
      </div>
      <div className='in__zoom'>
        <div className='in__image'>
          <img src={userApplePieInfo?.image} width='182px' alt='' />
        </div>
      </div>
      {userApplePieInfo && userApplePieFeeInfo && tfgSupplyData?.totalSupply >= tfgSupplyData?.maxGen0Supply ? (
        <>
          <h2>{userApplePieBalance > 0 ? `You have ${userApplePieBalance} apple pies` : `You have no apple pies`}</h2>
          {userEGGBalance > userApplePieFeeInfo.mintFee ? (
            <div className='btn__box'>
              <div className='btn__card'>
                <ButtonContractWrite
                  ContractAddress={ContractAddresses.TheFarmGamePlayContractAddress}
                  ContractABI={ContractABIs.TheFarmGamePlayABI}
                  ContractFunction='bakeApplePies'
                  ContractArgs={[ethers.utils.parseEther(userApplePieFeeInfo.mintFee.toString()), 1]}
                  ContractOverRides={''}
                  ContractIsEnabled={userEGGBalance >= userApplePieFeeInfo.mintFee}
                  ButtonTextPrepareIdle={`Check the oven`}
                  ButtonTextPrepareLoading={`Gathering ingredients`}
                  ButtonTextInitial={`Bake a pie: Cost ${userApplePieFeeInfo.mintFee} EGG`}
                  ButtonTextLoading={'Waiting for approval'}
                  ButtonTextWriting={'Baking Pie...'}
                  ButtonTextError={"Couldn't Bake the Pie"}
                  StartAlertText={'putting an apple pie in the oven!'}
                  StartAlertOptions={{ icon: '🥧' }}
                  SuccessAlertText={'baked an apple pie!'}
                  SuccessAlertOptions={{ icon: '🥧' }}
                  imageWaitTx={ApplePieBakingImg}
                  // debug={true}
                />

                {userApplePieBalance > 0 && (
                  <ButtonContractWrite
                    ContractAddress={ContractAddresses.TheFarmGamePlayContractAddress}
                    ContractABI={ContractABIs.TheFarmGamePlayABI}
                    ContractFunction='burnApplePies'
                    ContractArgs={[1]}
                    ContractOverRides={''}
                    ContractIsEnabled={userApplePieBalance > 0}
                    ButtonTextPrepareIdle={`Check the oven`}
                    ButtonTextPrepareLoading={`Grabbing the forks`}
                    ButtonTextInitial={`Burn a pie: receive ${userApplePieFeeInfo.burnFee} EGG`}
                    ButtonTextLoading={'Waiting for approval'}
                    ButtonTextWriting={'Burning Pie...'}
                    ButtonTextError={"Couldn't Burn the Pie"}
                    StartAlertText={'putting the pie in an open flame!'}
                    StartAlertOptions={{ icon: '🥧🔥' }}
                    SuccessAlertText={'burned an apple pie!'}
                    SuccessAlertOptions={{ icon: '🥧🔥' }}
                    imageWaitTx={ApplePieSellingStart}
                    // debug={true}
                  />
                )}
              </div>
            </div>
          ) : (
            <h3>Not enough EGG to buy Apple pies</h3>
          )}
        </>
      ) : (
        <h2>Apple pies go on sale after all gen 0 are minted</h2>
      )}

      <h5>
        Apple pie help protect your gen 1+ mints from being stolen
        <br />
        Coyotes love Apple Pies 🥧
      </h5>

      <>
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title className='brown'>You got pie</Modal.Title>
          </Modal.Header>
          <Modal.Body className='brown'>
            Thats a nice fresh hot pie!
            <br /> Share you pie with the web3 world?
          </Modal.Body>
          <Modal.Footer>
            <Button variant='secondary' onClick={handleClose}>
              Not sharing my pie
            </Button>
            <Button variant='primary' onClick={handleTweet}>
              Share Pie via Tweet
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    </div>
  )
}

export default ApplePie
