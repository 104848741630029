import _ from 'lodash'
import deployments from '../deployments.json'
import { useNetwork } from 'wagmi'
import { TFGContractABIs } from '../types'

export function useContractABIs() {
  const { chain } = useNetwork()

  let contractABIs = {}

  if (chain) {
    const currentDeployment = _.pick(deployments, [chain?.id])

    let deploymentValues = Object.values(currentDeployment) // "plain value"
    if (deploymentValues.length) {
      deploymentValues = deploymentValues[0]

      contractABIs = {
        EGGTokenABI: deploymentValues[0].contracts.EGGToken?.abi || '',
        EggShopABI: deploymentValues[0].contracts.EggShop?.abi || '',
        FarmAnimalsABI: deploymentValues[0].contracts.FarmAnimals?.abi || '',
        FarmAnimalsTraitsABI: deploymentValues[0].contracts.FarmAnimalsTraits?.abi || '',
        HenHouseAdvantageABI: deploymentValues[0].contracts.HenHouseAdvantage?.abi || '',
        HenHouseCalcABI: deploymentValues[0].contracts.HenHouseCalc?.abi || '',
        HenHouseABI: deploymentValues[0].contracts.HenHouse?.abi || '',
        ImperialEggsABI: deploymentValues[0].contracts.ImperialEggs?.abi || '',
        SpecialMintABI: deploymentValues[0].contracts.SpecialMint?.abi || '',
        TheFarmGameMintABI: deploymentValues[0].contracts.TheFarmGameMint?.abi || '',
        TheFarmGamePlayABI: deploymentValues[0].contracts.TheFarmGamePlay?.abi || '',
        TheFarmDaoExecutorABI: deploymentValues[0].contracts.TheFarmDAOExecutor?.abi || '',
      }
    }
  }

  return contractABIs as TFGContractABIs
}
