import React from 'react'

import NFTCard from '../NFTCard'

interface PropTypes {
  name?: string
  ContractAddress: string
  ContractABI: any
  ContractType: string
  tokenList: number[]
  balanceList?: number[]
  isStakeable?: boolean
  isStaked?: boolean
  stakedTimestampList?: any[]
  unstakeTimestampList?: any[]
  stakedContractAddress?: string
  stakedContractABI?: any
  showToStakeSelect?: boolean
  showClaimSelect?: boolean
  showUnstakeSelect?: boolean
  showClaimBalance?: boolean
  handleStake?: any
  handleClaim?: any
  handleUnstake?: any
}

const NFTCardList = (props: PropTypes): JSX.Element => {
  const {
    name,
    ContractAddress,
    ContractABI,
    ContractType,
    tokenList,
    balanceList,
    isStaked,
    stakedTimestampList,
    unstakeTimestampList,
    isStakeable,
    stakedContractAddress,
    stakedContractABI,
    showToStakeSelect,
    showClaimSelect,
    showUnstakeSelect,
    showClaimBalance,
    handleStake,
    handleClaim,
    handleUnstake,
  } = props

  return (
    <div className='container center text-center position-relative '>
      {name && <h3>{name}</h3>}
      <div className='row justify-content-start chick__card'>
        {tokenList ? (
          tokenList.map((item, index) => (
            <>
              <NFTCard
                key={`${item}-${index}`}
                ContractAddress={ContractAddress}
                ContractABI={ContractABI}
                ContractType={ContractType}
                tokenId={item}
                tokensOwned={balanceList ? balanceList[index] : undefined}
                isStakeable={isStakeable}
                isStaked={isStaked}
                stakedTimestamp={stakedTimestampList ? stakedTimestampList[index] : undefined}
                unstakeTimestamp={unstakeTimestampList ? unstakeTimestampList[index] : undefined}
                stakedContractAddress={stakedContractAddress}
                stakedContractABI={stakedContractABI}
                showToStakeSelect={showToStakeSelect}
                showClaimSelect={showClaimSelect}
                showUnstakeSelect={showUnstakeSelect}
                showClaimBalance={showClaimBalance}
                handleStake={handleStake}
                handleClaim={handleClaim}
                handleUnstake={handleUnstake}
              />
            </>
          ))
        ) : (
          <h1>No items</h1>
        )}
      </div>
    </div>
  )
}

export default NFTCardList
