import React, { useEffect, useState } from 'react'

import { metadataType } from '../../types'

// import Base64ToJson from '../../utils/Base64ToJson'

import './styles.scss'
import NFTUnClaimedBalance from '../NFTUnClaimedBalance'
import moment from 'moment'

import { useQuery } from 'urql'

import FarmAnimalNFTModal from '../NFTModal/NFTModal'
import NFTClaimUnstake from '../NFTClaimUnstake'
import { useContractRead } from 'wagmi'
import { useContracts } from '../../hooks/useContracts'
import { useContractABIs } from '../../hooks/useContractsABI'

interface PropTypes {
  ContractAddress: string
  ContractABI: any
  ContractType: string
  tokenId: number
  tokensOwned?: number
  isStakeable?: boolean
  isStaked?: boolean
  stakedTimestamp?: any
  unstakeTimestamp?: any
  stakedContractAddress?: string
  stakedContractABI?: any
  showToStakeSelect?: boolean
  showClaimSelect?: boolean
  showUnstakeSelect?: boolean
  showClaimBalance?: boolean
  handleStake?: any
  handleClaim?: any
  handleUnstake?: any
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const NFTCard = (props: PropTypes): JSX.Element => {
  const {
    ContractAddress,
    ContractABI,
    ContractType,
    tokenId,
    tokensOwned,
    isStakeable,
    isStaked,
    stakedTimestamp,
    unstakeTimestamp,
    stakedContractAddress,
    stakedContractABI,
    showToStakeSelect,
    showClaimSelect,
    showUnstakeSelect,
    showClaimBalance = false,
    handleStake,
    handleClaim,
    handleUnstake,
  } = props

  function Base64ToJson(Base64Data: string) {
    const rawDATA = Base64Data.split(';base64,').pop() as string

    const JSONPayload = JSON.parse(atob(rawDATA))

    return JSONPayload
  }

  const [show, setShow] = useState(false)
  const [tokenMetadata, setTokenMetadata] = useState<metadataType>()
  const [canUnstake, setCanUnstake] = useState(false)
  const [tokenKind, setTokenKind] = useState('')

  // const [checked, setChecked] = React.useState(false)

  // const handleChange = () => {
  //   setChecked(!checked)
  //   handleSelect(tokenId)
  // }

  const handleShowModal = (show: boolean) => {
    setShow(show)
  }

  const handleShow = () => setShow(true)

  const GetTokenId = `
	query GetTokenInfo($tokenId: String!){
		nfttoken(id: $tokenId) {
			id
			kind
			tokenId
			tokenURI
		}
	}
`

  // useQuery urql
  const tokenQueryId = `${ContractAddress.toLowerCase()}_${tokenId}`

  const [result, reexecuteQuery] = useQuery({
    query: GetTokenId,
    variables: { tokenId: tokenQueryId },
    // pause: loadingCompleted,
  })

  const { data, fetching, error } = result

  const [readFromBlockChain, setReadFromBlockChain] = useState(false)

  const ContractAddresses = useContracts()
  const ContractABIs = useContractABIs()

  const farmAnimalsConfig = {
    address: ContractAddresses.FarmAnimalsContractAddress,
    abi: ContractABIs.FarmAnimalsABI,
  }

  // FIXME: This can be done better with a custom hook getting tokenURI from graph and fallback to blockchain
  const { isFetching } = useContractRead({
    ...farmAnimalsConfig,
    enabled: readFromBlockChain,
    functionName: 'tokenURI',
    args: [tokenId],
    watch: false,
    onSuccess(data) {
      const tokenMetadata = Base64ToJson(data.toString())

      setTokenMetadata(tokenMetadata)
      let kind = 'HEN'
      for (const attribute of tokenMetadata.attributes) {
        if (attribute.type) {
          setTokenKind(kind)
        }
      }
    },
    onError(err) {
      console.log(`🚀 ~ err`, err)
    },
  })

  useEffect(() => {
    if (data) {
      if (data.nfttoken.tokenURI !== '') {
        const tokenMetadata = Base64ToJson(data.nfttoken.tokenURI.toString())
        setTokenMetadata(tokenMetadata)
        setTokenKind(data.nfttoken.kind)
      } else {
        if (ContractAddress === ContractAddresses.FarmAnimalsContractAddress) {
          setReadFromBlockChain(true)
        }
      }
    }
    if (stakedTimestamp && unstakeTimestamp && data) {
      const currentUnixTimestamp = moment().unix()
      const canUnstakeCheck = currentUnixTimestamp > parseInt(unstakeTimestamp)
      setCanUnstake(canUnstakeCheck)
    }
  }, [data, stakedTimestamp, unstakeTimestamp])

  if (fetching || isFetching) {
    return (
      <div className='chick__card col-2 pb-4' key={`fetching-${tokenId}`}>
        <div className='tmb'>
          <h5>Fetching #{tokenId}...</h5>
        </div>
      </div>
    )
  }
  if (error || !tokenMetadata) {
    return (
      <div className='chick__card col-2 pb-4' key={`error-${tokenId}`}>
        <div className='tmb'>
          <h5>Error #{tokenId}...</h5>
        </div>
      </div>
    )
  }

  return (
    <>
      <div className='chick__card card col-2 pb-2 pt-2' key={`div-${tokenId}`}>
        <h5 style={{ fontSize: 14 }}>{tokenMetadata ? tokenMetadata.name : `Loading ID: ${tokenId}`}</h5>
        <div className='tmb'>
          {tokenMetadata && <img onClick={handleShow} src={tokenMetadata.image} alt={tokenMetadata.name} />}
        </div>
        <div className='card-body'>
          {tokensOwned && <p style={{ fontSize: 14 }}>QTY: {tokensOwned}</p>}
          {isStaked && stakedContractAddress && (
            <>
              {showClaimBalance && (
                <NFTUnClaimedBalance
                  tokenId={tokenId}
                  stakedContractAddress={stakedContractAddress}
                  stakedContractABI={stakedContractABI}
                />
              )}

              <NFTClaimUnstake
                tokenId={tokenId}
                showClaimSelect={showClaimSelect}
                showUnstakeSelect={showUnstakeSelect}
                handleClaim={handleClaim}
                handleUnstake={handleUnstake}
              />
            </>
          )}
          {isStakeable && (
            <>
              <NFTClaimUnstake
                tokenId={tokenId}
                showToStakeSelect={showToStakeSelect}
                showClaimSelect={false}
                showUnstakeSelect={false}
                handleStake={handleStake}
              />
            </>
          )}
        </div>
      </div>

      <FarmAnimalNFTModal
        key={`modal-${tokenId}`}
        showModal={show}
        handelModalDisplay={handleShowModal}
        tokenId={tokenId}
        tokenMetadata={tokenMetadata}
        isStakeable={isStakeable}
        isStaked={isStaked}
        stakedTimestamp={stakedTimestamp}
        unstakeTimestamp={unstakeTimestamp}
        stakedContractAddress={stakedContractAddress}
        stakedContractABI={stakedContractABI}
        canUnstake={canUnstake}
        tokenKind={tokenKind}
      />
    </>
  )
}

export default NFTCard
