/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import Main from '../../components/Main'

function MainPage(): JSX.Element {
  return (
    <div>
      <Main />
    </div>
  )
}

export default MainPage
