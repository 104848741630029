import React, { useState, useEffect } from 'react'

import TabImg2 from '../../assets/images/chick_1.png'
import { ConnectButton } from '@rainbow-me/rainbowkit'
import { useAccount, useBalance, useContractReads } from 'wagmi'

import { TFGSalesDataProps } from '../../types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons'
import ButtonContractWrite from '../ButtonContractWrite'
import GasLimitNote from '../GasLimitNote'

import MintingCharacter from '../../assets/images/minting_animations/new_character_mint.gif'
import MintCommitImage from '../../assets/images/minting_animations/mint_commit.gif'

import { useContractABIs } from '../../hooks/useContractsABI'
import { useContracts } from '../../hooks/useContracts'

interface PropTypes {
  TFGSalesData: TFGSalesDataProps
}

const MintGen1 = (props: PropTypes): JSX.Element => {
  const { TFGSalesData } = props

  const ContractAddresses = useContracts()
  const ContractABIs = useContractABIs()

  const FarmGameContractAddress = ContractAddresses.TheFarmGameMintContractAddress

  const { address, isConnected } = useAccount()

  const [pauseWatch, setPauseWatch] = useState(false)
  const [hasNoPendingMints, setHasNoPendingMints] = useState(false)
  const [canReveal, setCanReveal] = useState(false)

  const handlePauseWatch = () => {
    setPauseWatch(true)
  }

  const handleMintSuccess = (data: any) => {
    setPauseWatch(false)
  }
  const handleMintStakeSuccess = (data: any) => {
    setPauseWatch(false)
  }

  const handleRevealSuccess = (data: any) => {
    setPauseWatch(false)
  }

  const [mintQty, setMintQty] = useState(1)
  const [maxQty, setMaxQty] = useState(TFGSalesData.publicMaxPerTx)

  // Gen 1

  const [mintEGGTotal, setMintEGGTotal] = useState(TFGSalesData.eggMintFee)

  const [userUnstakedBalance, setUserUnStakedBalance] = useState(0)
  const [userStakedBalance, setUserStakedBalance] = useState(0)

  // Egg Balance check
  const [userEGGBalance, setUserEGGBalance] = useState(0)

  useBalance({
    addressOrName: address,
    token: ContractAddresses.EGGTokenContractAddress,
    watch: false,
    onSuccess(data) {
      const bnBalance = data.formatted.toString()
      setUserEGGBalance(parseFloat(bnBalance))
    },
    onError(err) {
      console.error(`🚀 ~ MintGen1 UserEggBalance err`, err)
    },
  })

  // FarmAnimals Balance check
  const farmAnimalsConfig = {
    address: ContractAddresses.FarmAnimalsContractAddress,
    abi: ContractABIs.FarmAnimalsABI,
  }
  const henHouseConfig = {
    address: ContractAddresses.HenHouseContractAddress,
    abi: ContractABIs.HenHouseABI,
  }

  const theFarmAnimalMintConfig = {
    address: ContractAddresses.TheFarmGameMintContractAddress,
    abi: ContractABIs.TheFarmGameMintABI,
  }

  useContractReads({
    contracts: [
      { ...farmAnimalsConfig, functionName: 'balanceOf', args: [address] },
      { ...henHouseConfig, functionName: 'getStakedByAddress', args: [address?.toLowerCase()] },
      { ...theFarmAnimalMintConfig, functionName: 'hasMintPending', args: [address?.toLowerCase()] },
      { ...theFarmAnimalMintConfig, functionName: 'canReveal', args: [address?.toLowerCase()] },
    ],
    watch: true,
    // watch: !pauseWatch,
    onSuccess(data) {
      if (data) {
        // Unstaked
        const unStakedBalance = data[0] ? parseInt(data[0].toString()) : 0

        setUserUnStakedBalance(unStakedBalance)

        // Staked
        const stakedBalance = data[1] ? data[1].length : 0
        setUserStakedBalance(stakedBalance)

        setHasNoPendingMints(!data[2])

        const canRevealResult = data[3] as unknown as boolean
        setCanReveal(canRevealResult)
      }
    },
  })

  const updateMintAmount = (status: boolean) => {
    if (TFGSalesData) {
      if (status && mintQty) {
        setMintQty(mintQty + 1)

        setMintEGGTotal((mintQty + 1) * TFGSalesData.eggMintFee)
      } else if (!status && mintQty > 1) {
        setMintQty(mintQty - 1)

        setMintEGGTotal((mintQty - 1) * TFGSalesData.eggMintFee)
      }
    }
  }

  useEffect(() => {
    // declare the async data fetching function

    if (TFGSalesData) {
      setMaxQty(TFGSalesData.publicMaxPerTx)

      if (mintQty === 1) {
        setMintEGGTotal(TFGSalesData.eggMintFee)
        setMintQty(1)
      }

      if (TFGSalesData.totalSupply + TFGSalesData.publicMaxPerTx < TFGSalesData.maxSupply) {
        setMaxQty(TFGSalesData.publicMaxPerTx)
      } else {
        setMaxQty(TFGSalesData.maxSupply - TFGSalesData.totalSupply)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [TFGSalesData])

  if (!TFGSalesData) {
    return (
      <div className='loading--section'>
        <h1>Minting Loading...</h1>
      </div>
    )
  }

  return (
    <>
      <div className='col-xl-5 col-lg-6'>
        <div className='tab__card'>
          <div className='tab__text'>
            {/* <h1>MINTING {TFGSalesData?.saleStatus}</h1> */}
            <p>
              Gen 0 Supply:{' '}
              {TFGSalesData.totalSupply > TFGSalesData.maxGen0Supply
                ? TFGSalesData.maxGen0Supply
                : TFGSalesData.totalSupply}
              /{TFGSalesData.maxGen0Supply}
            </p>
            <p>
              Gen 1+ Supply: {TFGSalesData.totalSupply - TFGSalesData.maxGen0Supply}/
              {TFGSalesData.maxSupply - TFGSalesData.maxGen0Supply}
            </p>
            {(userUnstakedBalance > 0 || userStakedBalance > 0) && (
              <p>You own {userUnstakedBalance + userStakedBalance} animals</p>
            )}
          </div>
          <div className='btn__box'>
            {isConnected && hasNoPendingMints && (
              <>
                <div className='in__zoom'>
                  <div className='in__content'>
                    {userEGGBalance > mintEGGTotal && mintQty !== 1 && (
                      <button className='btn-circle' onClick={() => updateMintAmount(false)}>
                        <FontAwesomeIcon className='btn_qty brown' icon={faMinus} />
                      </button>
                    )}
                    <div className='in__zoom__first'>
                      <img src={TabImg2} alt='' />
                    </div>
                    {userEGGBalance > mintEGGTotal &&
                      userEGGBalance - mintEGGTotal > TFGSalesData.eggMintFee &&
                      mintQty < maxQty && (
                        <button
                          className='btn-circle'
                          disabled={mintQty === maxQty}
                          onClick={() => updateMintAmount(true)}
                        >
                          <FontAwesomeIcon className='brown' icon={faPlus} />
                        </button>
                      )}
                  </div>
                  <h2>
                    mint <span className='brown'>{mintQty}</span> for {mintEGGTotal} EGG
                  </h2>
                </div>
                {TFGSalesData.totalSupply >= TFGSalesData.maxGen0Supply && (
                  <>
                    {userEGGBalance >= mintEGGTotal ? (
                      <>
                        <div className='btn__card' onClick={handlePauseWatch}>
                          <>
                            <ButtonContractWrite
                              ContractAddress={FarmGameContractAddress}
                              ContractABI={ContractABIs.TheFarmGameMintABI}
                              ContractFunction='mintCommitGen1'
                              ContractArgs={[mintQty, false]}
                              ContractOverRides={{}}
                              ContractIsEnabled={
                                mintEGGTotal > 0 && userEGGBalance >= mintEGGTotal && hasNoPendingMints
                              }
                              ButtonTextPrepareIdle={`Check for Hens`}
                              ButtonTextPrepareLoading={`Preparing`}
                              ButtonTextInitial={`Mint Commit`}
                              ButtonTextLoading={'Waiting for approval'}
                              ButtonTextWriting={'Hatching hens...'}
                              ButtonTextError={"Couldn't Hatch any Hens"}
                              StartAlertText={'Incubating Hen Eggs!'}
                              StartAlertOptions={{ icon: '🥚' }}
                              SuccessAlertText={'Hatched a Hen!'}
                              SuccessAlertOptions={{ icon: '🥚' }}
                              OnSuccessFunction={handleMintSuccess}
                              imageWaitTx={MintCommitImage}
                            />
                            <ButtonContractWrite
                              ContractAddress={FarmGameContractAddress}
                              ContractABI={ContractABIs.TheFarmGameMintABI}
                              ContractFunction='mintCommitGen1'
                              ContractArgs={[mintQty, true]}
                              ContractOverRides={{}}
                              ContractIsEnabled={
                                mintEGGTotal > 0 && userEGGBalance >= mintEGGTotal && hasNoPendingMints
                              }
                              ButtonTextPrepareIdle={`Check for Hens`}
                              ButtonTextPrepareLoading={`Preparing`}
                              ButtonTextInitial={`Mint & Stake Commit`}
                              ButtonTextLoading={'Waiting for approval'}
                              ButtonTextWriting={'Hatching hens...'}
                              ButtonTextError={"Couldn't Hatch any Hens"}
                              StartAlertText={'Incubating Hen Eggs!'}
                              StartAlertOptions={{ icon: '🥚' }}
                              SuccessAlertText={'Moved into Hen House!'}
                              SuccessAlertOptions={{ icon: '🥚' }}
                              OnSuccessFunction={handleMintStakeSuccess}
                              imageWaitTx={MintCommitImage}
                            />
                          </>
                        </div>
                        <GasLimitNote />
                        <br />
                        <h1>Gen 1 minting limited to 5 per tx</h1>
                        <br />
                      </>
                    ) : (
                      <h1>You do not have enough EGG</h1>
                    )}
                  </>
                )}
              </>
            )}
            {isConnected && !hasNoPendingMints && (
              <>
                <h1>You have pending mints</h1>
                <h1>Reveal opens every ~15mins</h1>
                <div className='btn__card' onClick={handlePauseWatch}>
                  <ButtonContractWrite
                    ContractAddress={FarmGameContractAddress}
                    ContractABI={ContractABIs.TheFarmGameMintABI}
                    ContractFunction='mintReveal'
                    ContractArgs={[]}
                    ContractOverRides={{}}
                    ContractIsEnabled={canReveal && !hasNoPendingMints}
                    ButtonTextPrepareIdle={`No peeking`}
                    ButtonTextPrepareLoading={`Preparing`}
                    ButtonTextInitial={`Reveal Animals`}
                    ButtonTextLoading={'Waiting for approval'}
                    ButtonTextWriting={'Hatching hens...'}
                    ButtonTextError={"Couldn't Hatch any Hens"}
                    StartAlertText={'Incubating Hen Eggs!'}
                    StartAlertOptions={{ icon: '🥚' }}
                    SuccessAlertText={'Hatched a Hen!'}
                    SuccessAlertOptions={{ icon: '🥚' }}
                    OnSuccessFunction={handleRevealSuccess}
                    imageWaitTx={MintingCharacter}
                  />
                </div>
                <GasLimitNote />
              </>
            )}

            {!isConnected && (
              <div className='btn__card btn__anchor'>
                <ConnectButton chainStatus='none' accountStatus='avatar' showBalance={false} />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default MintGen1
