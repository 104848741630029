import React from 'react'

import { TFGSalesDataProps } from '../../types'

import MintPreSale from './MintPreSale'
import MintAllowList from './MintAllowList'

interface PropTypes {
  TFGSalesData: TFGSalesDataProps
  blockTimeStamp: EpochTimeStamp
}

const AllowListCheck = (props: PropTypes): JSX.Element => {
  const { TFGSalesData, blockTimeStamp } = props

  if (!TFGSalesData) {
    return (
      <div className='loading--section'>
        <h1>AllowListCheck Loading...</h1>
      </div>
    )
  }

  return (
    <>
      <MintPreSale TFGSalesData={TFGSalesData} blockTimeStamp={blockTimeStamp} />
      <MintAllowList TFGSalesData={TFGSalesData} blockTimeStamp={blockTimeStamp} />
    </>
  )
}

export default AllowListCheck
