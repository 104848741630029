import { useState, useEffect, useContext } from 'react'
import { useContractReads } from 'wagmi'
import mapOrder from '../utils/MapByOrder'
import Base64ToJson from '../utils/Base64ToJson'
import { ethers } from 'ethers'
import _ from 'lodash'
import { DisplayInfoType } from '../types'

import numbro from 'numbro'

import MintHenImage from '../assets/images/stats/hen_check.png'
import MintedTwins from '../assets/images/minting_animations/minted_twins.gif'

import ApplePieBakingFinish from '../assets/images/minting_animations/apple_pie_baking_finish.gif'
import ApplePieSoldFinish from '../assets/images/minting_animations/apple_pie_sold_finish.gif'
import ApplePieTaken from '../assets/images/minting_animations/apple_pie_taken.gif'

import EggShopMintAwardBronze from '../assets/images/minting_animations/mint_award_bronze.gif'
import EggShopMintAwardGold from '../assets/images/minting_animations/mint_award_gold.gif'
import EggShopMintAwardPlatinum from '../assets/images/minting_animations/mint_award_platinum.gif'
import EggShopMintAwardRainbow from '../assets/images/minting_animations/mint_award_rainbow.gif'
import EggShopMintAwardSilver from '../assets/images/minting_animations/mint_award_silver.gif'

import MintAward5k from '../assets/images/minting_animations/mint_award_egg_5k.gif'
import MintAward10k from '../assets/images/minting_animations/mint_award_egg_10k.gif'
import MintAward15k from '../assets/images/minting_animations/mint_award_egg_15k.gif'
import MintAward20k from '../assets/images/minting_animations/mint_award_egg_20k.gif'
import MintAward25k from '../assets/images/minting_animations/mint_award_egg_25k.gif'
import MintAward50k from '../assets/images/minting_animations/mint_award_egg_50k.gif'

import RainbowEggBreakingFinish from '../assets/images/minting_animations/rainbow_egg_breaking_finish.gif'

import GatheringEggsFinish from '../assets/images/minting_animations/gathering_eggs_finish.gif'

import NewCharacterMint from '../assets/images/minting_animations/new_character_mint.gif'

import HenLeavingHenHouseFinish from '../assets/images/minting_animations/hen_leaving_hen_house_finish.gif'
import HenMoveToHenHouse from '../assets/images/minting_animations/hen_move_to_hen_house.gif'
import HenKidnapped from '../assets/images/minting_animations/hen_kidnapped.gif'
import HenSacrificedStart from '../assets/images/minting_animations/hen_sacrificed_start.gif'

import HenRescued from '../assets/images/minting_animations/hen_rescued.gif'

import CoyoteKidnapped from '../assets/images/minting_animations/coyote_kidnapped.gif'
import CoyoteMoveToTaxDen from '../assets/images/minting_animations/coyote_move_to_tax_den.gif'
import CoyoteLeavingTaxDenStart from '../assets/images/minting_animations/coyote_leaving_tax_den_start.gif'
import coyoteLeavingTaxDenFinish from '../assets/images/minting_animations/coyote_leaving_tax_den_finish.gif'

import RoosterMoveToGuardHouse from '../assets/images/minting_animations/rooster_move_to_guard_house.gif'
import RoosterLeavingGuardHouseStart from '../assets/images/minting_animations/rooster_leaving_guard_house_start.gif'
import RoosterLeavingGuardHouseFinish from '../assets/images/minting_animations/rooster_leaving_guard_house_finish.gif'

import { useContractABIs } from './useContractsABI'
import { useContracts } from './useContracts'

const trackedEvents = [
  // EggCitement
  'event EggCitementReward(address indexed,uint256 indexed,string)',
  // EggShop
  'event ApprovalForAll(address indexed,address indexed,bool)',
  'event EggShopBurn(uint256 indexed,address indexed,uint256)',
  'event EggShopMint(uint256 indexed,address indexed,uint256)',
  'event InitializedContract(address)',
  'event OwnershipTransferred(address indexed,address indexed)',
  'event Paused(address)',
  'event TransferBatch(address indexed,address indexed,address indexed,uint256[],uint256[])',
  'event TransferSingle(address indexed,address indexed,address indexed,uint256,uint256)',
  'event TypeNameUpdated(uint256 indexed,string)',
  'event URI(string,uint256 indexed)',
  'event Unpaused(address)',
  'event UpdateTypeSupplyExchange(uint256 indexed,uint256,uint256,uint256)',
  // EGGToken
  'event SwapAndLiquify(uint256,uint256,uint256)',
  'event SwapAndLiquifyEnabledUpdated(bool)',
  'event Transfer(address indexed,address indexed,uint256)',
  // FarmAnimals
  'event AdvantageUpdated(address indexed,uint256 indexed,string indexed,uint256,uint256)',
  'event Approval(address indexed,address indexed,uint256 indexed)',
  'event Burn(string,uint256 indexed)',
  'event ConsecutiveTransfer(uint256 indexed,uint256,address indexed,address indexed)',
  'event DelegateChanged(address indexed,address indexed,address indexed)',
  'event DelegateVotesChanged(address indexed,uint256,uint256)',
  'event HenTwinMinted(address indexed,uint256,address indexed,uint256)',
  'event Mint(string,address indexed,uint256 indexed)',
  // FarmAnimalsTraits
  'event ChangeBackground(uint256 indexed,string,uint256)',
  'event ChangeBio(uint256 indexed,string,uint256)',
  'event ChangeName(uint256 indexed,string,uint256)',
  'event UpdatedChangeBGColorFee(uint256,uint256)',
  'event UpdatedChangeBioFee(uint256,uint256)',
  'event UpdatedChangeNameFee(uint256,uint256)',
  'function addColorToPalette(uint8,string)',
  // HenHouse
  'event EggClaimedUnstaked(uint256 indexed,bool indexed,string,uint256,uint256)',
  'event GoldenEggAwarded(address indexed)',
  'event RoosterReceivedDroppedEgg(address indexed,uint256 indexed,uint256)',
  'event TokenStaked(address indexed,uint256 indexed,string,uint256,uint256,uint256)',
  // Hen House Advantage
  'event AdvantageBonusAdded(uint256 indexed,uint256,uint256)',
  'event AdvantageBonusRemoved(uint256 indexed)',
  // Imperial Egg
  'event ConsecutiveTransfer(uint256 indexed,uint256,address indexed,address indexed)',
  'event ImperialEggBurn(address indexed,uint256 indexed)',
  'event ImperialEggMint(address indexed,uint256 indexed)',
  'event Transfer(address indexed,address indexed,uint256 indexed)',
  // Special Mint
  'event Add(uint256 indexed,uint256,uint256)',
  'event MintedSpecial(address indexed,uint256 indexed)',
  'event Update(uint256 indexed,uint256,uint256)',
  // TFG Mint
  'event ApplePieTaken(address indexed,address indexed,uint256 indexed)',
  'event EggShopAward(address indexed,uint256 indexed)',
  'event MintCommitted(address indexed,uint256 indexed)',
  'event MintRevealed(address indexed,uint256 indexed)',
  'event SaleStatusUpdated(uint256,uint256,uint256,uint256,uint256,uint256,uint256)',
  'event TokenKidnapped(address indexed,address indexed,uint256 indexed,string)',
  'event TokenRescued(address indexed,address indexed,uint256 indexed,string)',
  // TFG Play
  'event EggShopBroken(address indexed,uint256 indexed)',
  'event Sacrificed(address indexed,uint256 indexed,string indexed)',
]

interface TokenIdImage {
  tokenId: number
  image: string
}

// Token image exists if the token id can be found in the current list of images tied to token ids
const tokenImageExists = (tokenIdImages: TokenIdImage[], tokenId: number) => {
  return _.find(tokenIdImages, { tokenId }) !== undefined
}

export function usePrepareLogOrder(rawLogs: any, enabled: boolean = false, fallbackImage?: string) {
  const ContractAddresses = useContracts()
  const ContractABIs = useContractABIs()

  const [data, setData] = useState<DisplayInfoType[][]>()

  const [isPending, setIsPending] = useState(true)
  const [error, setError] = useState(null)

  const [parsedLog, setParsedLog] = useState<ethers.utils.LogDescription[]>([])
  const [tokenIdsToRead, setTokenIdsToRead] = useState<any>([])
  const [tokenIdImages, setTokenIdImages] = useState<TokenIdImage[]>([])

  const [finalLogsPrepared, setFinalLogsPrepared] = useState(false)

  useContractReads({
    contracts: [...tokenIdsToRead],
    enabled: tokenIdsToRead.length > 0 && enabled,
    watch: false,
    onSuccess(data: string[]) {
      if (data) {
        const newTokenIdImages: TokenIdImage[] = []
        data.forEach((tokenUri, index) => {
          const tokenMetadata = Base64ToJson(tokenUri.toString())
          newTokenIdImages.push({ tokenId: tokenIdsToRead[index].args[0], image: tokenMetadata.image })
        })
        setTokenIdImages(newTokenIdImages)
        setFinalLogsPrepared(true)
      }
    },
  })

  useEffect(() => {
    const farmAnimalsConfig = {
      address: ContractAddresses.FarmAnimalsContractAddress,
      abi: ContractABIs.FarmAnimalsABI,
    }
    if (rawLogs && rawLogs.logs && enabled) {
      const iFace = new ethers.utils.Interface(trackedEvents)

      const txLog: ethers.utils.LogDescription[] = []

      rawLogs.logs.forEach((log: { topics: string[]; data: string }) => {
        try {
          txLog.push(iFace.parseLog(log))
        } catch (error) {
          console.error(`🚀 ~ usePrepareLogOrder error iface`, error)
        }
      })
      console.log(`🚀 ~ usePrepareLogOrder txLog`, txLog)

      const newTokenIdsToRead: any[] = []
      console.log(`🚀 ~ newTokenIdsToRead`, newTokenIdsToRead)

      for (const log of txLog) {
        console.log(`🚀 ~ usePrepareLogOrder log`, log)
        if (log && log.name === 'Mint') {
          if (log.args[0] === 'HEN' || log.args[0] === 'COYOTE' || log.args[0] === 'ROOSTER') {
            newTokenIdsToRead.push({
              ...farmAnimalsConfig,
              functionName: 'tokenURI',
              args: [log.args[2]],
            })
          }
        }
      }
      setParsedLog(txLog)
      if (newTokenIdsToRead.length > 0) {
        setTokenIdsToRead(newTokenIdsToRead)
      } else {
        setFinalLogsPrepared(true)
      }
    }
  }, [ContractAddresses.FarmAnimalsContractAddress, enabled, rawLogs])

  // FIXME: If minted to founder during GEN0 do not include mint events
  useEffect(() => {
    if (finalLogsPrepared && enabled) {
      let logDisplayOrder: DisplayInfoType[] = []
      // Reverse the order
      const reversedParsedLogs = parsedLog.reverse()
      console.log(`🚀 ~ usePrepareLogOrder reversedParsedLogs`, reversedParsedLogs)
      if (reversedParsedLogs) {
        for (const log of reversedParsedLogs) {
          // some log events are undefined, ignore them
          if (log) {
            switch (log.name) {
              case 'Mint': {
                if (log.args[0] === 'HEN' || log.args[0] === 'COYOTE' || log.args[0] === 'ROOSTER') {
                  // Tokens being minted should only be displayed if the transaction is still pending
                  // Otherwise, the full details should be shown with the correct NFT image
                  if (!tokenImageExists(tokenIdImages, log.args[2])) {
                    logDisplayOrder.push({
                      title: 'Mint',
                      event: log.name,
                      image: fallbackImage,
                    })
                  } else {
                    const tokenImageFilter = _.find(tokenIdImages, { tokenId: log.args[2] })
                    logDisplayOrder.push({
                      title: 'Mint',
                      event: log.name,
                      kind: log.args[0],
                      recipient: log.args[1],
                      tokenId: parseInt(log.args[2].toString()),
                      image: tokenImageFilter.image,
                    })
                  }
                }
                break
              }
              case 'HenTwinMinted': {
                // Twins being minted should only be displayed if the transaction is still pending
                if (!tokenImageExists(tokenIdImages, log.args[1])) {
                  logDisplayOrder.push({
                    title: 'Twins',
                    event: log.name,
                    kind: 'HEN',
                    recipient: log.args[0],
                    tokenId: parseInt(log.args[1].toString()),
                    image: MintedTwins,
                    note: 'You Minted Twins!',
                  })
                }
                break
              }
              case 'TokenKidnapped': {
                if (log.args[3] === 'HEN') {
                  logDisplayOrder.push({
                    title: 'Hen Kidnapped',
                    event: log.name,
                    kind: 'HEN',
                    recipient: log.args[1],
                    tokenId: parseInt(log.args[2].toString()),
                    // tokenId: ethers.BigNumber.from(log.args[2]).toNumber(),
                    image: HenKidnapped,
                    note: 'Oh no! Your Hen was kidnapped',
                  })
                } else if (log.args[3] === 'COYOTE') {
                  logDisplayOrder.push({
                    title: 'Coyote joined another pack',
                    event: log.name,
                    kind: 'COYOTE',
                    recipient: log.args[1],
                    tokenId: parseInt(log.args[2].toString()),
                    // tokenId: ethers.BigNumber.from(log.args[2]).toNumber(),
                    image: CoyoteKidnapped,
                    note: 'Oh no! Your Coyote was kidnapped',
                  })
                }
                break
              }
              case 'ApplePieTaken': {
                logDisplayOrder.push({
                  title: 'Apple Pie Taken',
                  event: log.name,
                  recipient: log.args[0],
                  tokenId: parseInt(log.args[1].toString()),
                  // tokenId: ethers.BigNumber.from(log.args[1]).toNumber(),
                  image: ApplePieTaken,
                  note: 'Fresh Apple Pie has been served',
                })
                break
              }
              case 'TokenRescued': {
                logDisplayOrder.push({
                  title: 'Hen Rescued',
                  event: log.name,
                  recipient: log.args[0],
                  tokenId: parseInt(log.args[2].toString()),
                  image: HenRescued,
                  note: 'The Hen was rescued by a Rooster',
                })
                break
              }
              case 'TokenStaked': {
                console.log(`🚀 ~ TokenStaked`)
                const tokenId = parseInt(log.args[1].toString())
                // const tokenId = ethers.BigNumber.from(log.args[1]).toNumber()
                // Token staked images should only be displayed if the NFT images exist
                if (tokenImageExists(tokenIdImages, tokenId)) {
                  if (log.args[2] === 'HEN') {
                    console.log(`🚀 ~ log.args[2] HEN:`, log.args[2])
                    logDisplayOrder.push({
                      title: 'Hen in Hen House',
                      event: log.name,
                      kind: 'HEN',
                      recipient: log.args[0],
                      tokenId,
                      image: HenMoveToHenHouse,
                      note: 'All settled in the Hen House',
                    })
                  } else if (log.args[2] === 'COYOTE') {
                    console.log(`🚀 ~ log.args[2] COYOTE:`, log.args[2])
                    logDisplayOrder.push({
                      title: 'Coyote in the Tax Den',
                      event: log.name,
                      kind: 'COYOTE',
                      recipient: log.args[0],
                      tokenId,
                      image: CoyoteMoveToTaxDen,
                      note: 'Coyote is now counting taxes',
                    })
                  } else if (log.args[2] === 'ROOSTER') {
                    console.log(`🚀 ~ log.args[2] ROOSTER:`, log.args[2])
                    logDisplayOrder.push({
                      title: 'Rooster in the Guard House',
                      event: log.name,
                      kind: 'ROOSTER',
                      recipient: log.args[0],
                      tokenId,
                      image: RoosterMoveToGuardHouse,
                      note: 'Rooster on duty',
                    })
                  }
                }
                break
              }
              case 'EggShopAward': {
                let finishedImage: string
                switch (log.args[1]) {
                  case 1: {
                    finishedImage = ApplePieBakingFinish
                    break
                  }
                  case 2: {
                    finishedImage = EggShopMintAwardBronze
                    break
                  }
                  case 3: {
                    finishedImage = EggShopMintAwardBronze
                    break
                  }
                  case 4: {
                    finishedImage = EggShopMintAwardGold
                    break
                  }
                  case 5: {
                    finishedImage = EggShopMintAwardPlatinum
                    break
                  }
                  case 6: {
                    finishedImage = EggShopMintAwardRainbow
                    break
                  }
                  case 7: {
                    finishedImage = EggShopMintAwardSilver
                    break
                  }
                  case 8: {
                    finishedImage = EggShopMintAwardBronze
                    break
                  }
                }
                logDisplayOrder.push({
                  title: 'Egg Shop Award',
                  event: log.name,
                  recipient: log.args[0],
                  tokenId: parseInt(log.args[1].toString()),
                  // tokenId: ethers.BigNumber.from(log.args[1]).toNumber(),
                  image: finishedImage,
                  note: 'You received an Egg Shop item',
                })
                break
              }
              case 'EggCitementReward': {
                let finishedImage: string
                switch (log.args[2]) {
                  case 'egg_token_5k': {
                    finishedImage = MintAward5k
                    break
                  }
                  case 'egg_token_10k': {
                    finishedImage = MintAward10k
                    break
                  }
                  case 'egg_token_15k': {
                    finishedImage = MintAward15k
                    break
                  }
                  case 'silver_egg': {
                    finishedImage = EggShopMintAwardSilver
                    break
                  }
                  case 'gold_egg': {
                    finishedImage = EggShopMintAwardGold
                    break
                  }
                  case 'platinum_egg': {
                    finishedImage = EggShopMintAwardPlatinum
                    break
                  }
                  case 'rainbow_egg': {
                    finishedImage = EggShopMintAwardRainbow
                    break
                  }
                  case 'imperial_egg': {
                    finishedImage = MintHenImage
                    break
                  }
                }
                logDisplayOrder.push({
                  title: 'Egg-Citement Reward!',
                  event: log.name,
                  recipient: log.args[0],
                  tokenId: parseInt(log.args[1].toString()),
                  // tokenId: ethers.BigNumber.from(log.args[1]).toNumber(),
                  image: finishedImage,
                  note: 'You got an EggCitement reward',
                })
                break
              }
              case 'EggClaimedUnstaked': {
                const amountClaimed = numbro(parseFloat(ethers.utils.formatEther(log.args[3]))).format({
                  average: false,
                  mantissa: 2,
                })

                let finishedImage: string
                switch (log.args[2]) {
                  case 'HEN': {
                    finishedImage = HenLeavingHenHouseFinish
                    break
                  }
                  case 'COYOTE': {
                    finishedImage = coyoteLeavingTaxDenFinish
                    break
                  }
                  case 'ROOSTER': {
                    finishedImage = RoosterLeavingGuardHouseFinish
                    break
                  }
                }

                if (log.args[1]) {
                  // Unstaked & EGG Claim
                  logDisplayOrder.push({
                    title: 'Unstaked & EGG Claimed',
                    event: log.name,
                    // recipient: log.args[0],
                    tokenId: parseInt(log.args[0].toString()),
                    image: finishedImage,
                    note: `Received ${amountClaimed} after paying taxes`,
                  })
                } else {
                  // Only Claimed EGG
                  logDisplayOrder.push({
                    title: 'EGG Claimed',
                    event: log.name,
                    // recipient: log.args[0],
                    tokenId: parseInt(log.args[0].toString()),
                    image: GatheringEggsFinish,
                    note: `Received ${amountClaimed} after paying taxes`,
                  })
                }
                break
              }
              case 'AdvantageUpdated': {
                let finishedImage: string
                switch (log.args[2]) {
                  case 'HEN': {
                    finishedImage = RainbowEggBreakingFinish
                    break
                  }
                  case 'COYOTE': {
                    finishedImage = RainbowEggBreakingFinish
                    break
                  }
                  case 'ROOSTER': {
                    finishedImage = RainbowEggBreakingFinish
                    break
                  }
                }

                logDisplayOrder.push({
                  title: 'Advantage Upgraded',
                  event: log.name,
                  // recipient: log.args[0],
                  tokenId: parseInt(log.args[1].toString()),
                  image: finishedImage,
                  note: `${log.args[2]} upgraded from ${log.args[3]} to ${log.args[4]}`,
                })
                break
              }
              case 'Sacrificed': {
                logDisplayOrder.push({
                  title: 'Advantage Upgraded',
                  event: log.name,
                  // recipient: log.args[0],
                  tokenId: parseInt(log.args[1].toString()),
                  image: HenSacrificedStart,
                  note: `${log.args[2]} upgraded from ${log.args[3]} to ${log.args[4]}`,
                })
              }
            }
          }
        }

        const eventOrder = [
          'HenTwinMinted',
          'Mint',
          'TokenKidnapped',
          'TokenRescued',
          'ApplePieTaken',
          'TokenStaked',
          'EggCitementReward',
          'EggShopAward',
          'EggClaimedUnstaked',
          'AdvantageUpdated',
          'Sacrificed',
        ]

        logDisplayOrder = _.sortBy(logDisplayOrder, ['tokenId'])
        const logsGroupedById = _.groupBy(logDisplayOrder, 'tokenId')

        const logsSortedByEvent: DisplayInfoType[] = []
        for (const item in logsGroupedById) {
          logsSortedByEvent.push(_.sortBy(logsGroupedById[item], 'event'))
        }

        const newData: DisplayInfoType[][] = []
        for (const group of logsSortedByEvent) {
          newData.push(mapOrder(group, eventOrder, 'event'))
        }
        setData(newData)
        setIsPending(false)
      }
    }
  }, [enabled, parsedLog, tokenIdImages])

  return { data, isPending, error }
}
