import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'

import { metadataType } from '../../types'

import { Badge, Button, Col, Container, Row } from 'react-bootstrap'

import './styles.scss'

import { useQuery } from 'urql'
import { Link } from 'react-router-dom'

interface PropTypes {
  ContractAddress: string
  tokenId: number
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const NFTToken = (props: PropTypes): JSX.Element => {
  const { ContractAddress, tokenId } = props

  function Base64ToJson(Base64Data: string) {
    const rawDATA = Base64Data.split(';base64,').pop() as string

    const JSONPayload = JSON.parse(atob(rawDATA))

    return JSONPayload
  }

  const [tokenMetadata, setTokenMetadata] = useState<metadataType>()

  const [tokenKind, setTokenKind] = useState('')

  const GetTokenId = `
	query GetTokenInfo($tokenId: String!){
		nfttoken(id: $tokenId) {
			id
			kind
			tokenId
			tokenURI
		}
	}
`

  // useQuery urql
  const tokenQueryId = `${ContractAddress.toLowerCase()}_${tokenId}`

  const [result, reexecuteQuery] = useQuery({
    query: GetTokenId,
    variables: { tokenId: tokenQueryId },
    // pause: loadingCompleted,
  })

  const { data, fetching, error } = result

  useEffect(() => {
    if (data) {
      const tokenMetadata = Base64ToJson(data.nfttoken.tokenURI.toString())
      setTokenMetadata(tokenMetadata)
      setTokenKind(data.nfttoken.kind)
    }
  }, [data])

  if (fetching) {
    return (
      <div className='chick__card col-2 pb-4' key={tokenId}>
        <div className='tmb'>
          <h5>Fetching #{tokenId}...</h5>
        </div>
      </div>
    )
  }
  if (error) {
    return (
      <div className='chick__card col-2 pb-4' key={tokenId}>
        <div className='tmb'>
          <h5>Error #{tokenId}...</h5>
        </div>
      </div>
    )
  }

  if (!tokenMetadata) {
    return (
      <div className='chick__card col-2 pb-4' key={tokenId}>
        <div className='tmb'>
          <h5>error #{tokenId}...</h5>
        </div>
      </div>
    )
  }
  // TODO: Need to fix the og:image url, probably need to have that served from the backend server
  return (
    <>
      <Helmet>
        <title>{`The Farm Game: ${tokenId}`}</title>
        <meta property='og:title' content={`The Farm Game: ${tokenMetadata.name}`} />
        <meta property='description' content={tokenMetadata.description} />
        {/* <meta property='og:image' content={tokenMetadata.image} /> */}
        <meta property='og:url' content={`https://opensea.io/assets/ethereum/${ContractAddress}/${tokenId}`} />

        <meta name='twitter:card' content='summary_large_image' />
        <meta name='twitter:title' content={`The Farm Game: ${tokenMetadata.name}`} />
        <meta name='twitter:domain' content='opensea.io' />
        <meta name='twitter:description' content={tokenMetadata.description} />
        {/* <meta property='twitter:image' content={tokenMetadata.image} /> */}
        <meta property='twitter:url' content={`https://opensea.io/assets/ethereum/${ContractAddress}/${tokenId}`} />
      </Helmet>
      <Container className='p-4' style={{ width: '80%' }}>
        <Row>
          <Col>
            <h3>{tokenMetadata ? tokenMetadata.name : `Loading ID: ${tokenId}`}</h3>
          </Col>
        </Row>
        <Row className='justify-content-md-center'>
          <Col xs={12} md={8} className='d-flex justify-content-center pb-3'>
            {tokenMetadata && (
              <img src={tokenMetadata.image} alt={tokenMetadata.name} style={{ alignContent: 'center' }} />
            )}
          </Col>

          <Col xs={6} md={4}>
            {/* {tokenMetadata.background_color && <p style={{ fontSize: 14 }}>Background: {tokenMetadata.background_color}</p>} */}
            {tokenMetadata?.attributes &&
              tokenMetadata?.attributes?.map((attribute, index) => {
                return (
                  <>
                    <Badge bg='primary' key={index} style={{ fontSize: '15px', margin: '2px' }}>
                      {attribute.trait_type} - {attribute.value}
                    </Badge>
                  </>
                )
              })}
          </Col>
        </Row>
        <Row>
          <Col>
            {tokenMetadata.description && <p style={{ fontSize: 14 }}>Description: {tokenMetadata.description}</p>}
          </Col>
        </Row>
        <Row>
          <Col>
            <Button variant='primary'>
              <a
                className='nav-link'
                target={'_blank'}
                href={`https://opensea.io/assets/ethereum/${ContractAddress}/${tokenId}`}
                rel='noreferrer'
              >
                View on OpenSea
              </a>
            </Button>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default NFTToken
