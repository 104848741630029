import React, { useContext, useEffect, useState } from 'react'

import { EGGTokenDataProps, TFGSupplyProps } from '../types'

import { useQuery } from 'urql'

import { useContracts } from './useContracts'

export function useEggTokenData() {
  const [eggTokenData, setEGGTokenEGGTokenData] = useState<EGGTokenDataProps>()

  const ContractAddresses = useContracts()

  const urqlQuery = `
	query MyQuery($eggTokenContract: String) {
		token(id: $eggTokenContract) {
			id
			totalMinted
			totalSupply
			totalBurned
			name
			symbol
		}
	}
	`

  const [result, reexecuteQuery] = useQuery({
    query: urqlQuery,
    variables: { eggTokenContract: ContractAddresses.EGGTokenContractAddress.toLowerCase() },
  })

  const { data: urqlData, fetching: urqlFetching, error: urqlError } = result

  useEffect(() => {
    if (urqlData) {
      if (urqlData.token) {
        const data = urqlData.token

        setEGGTokenEGGTokenData({
          totalMinted: data.totalMinted,
          totalSupply: data.totalSupply,
          totalBurned: data.totalBurned,
          name: data.name,
          symbol: data.symbol,
        })
      }
    }
  }, [urqlData])

  return eggTokenData as EGGTokenDataProps
}
