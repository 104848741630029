/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import { useParams } from 'react-router-dom'

import NFTToken from '../../components/NFTToken'

const TokenPage = (): JSX.Element => {
  let { contractAddress, tokenId } = useParams()

  if (!contractAddress) {
    return (
      <div className='chick__card col-2 pb-4' key={tokenId}>
        <div className='tmb'>
          <h5>Contract not found {contractAddress}</h5>
        </div>
      </div>
    )
  }

  if (!tokenId) {
    return (
      <div className='chick__card col-2 pb-4' key={tokenId}>
        <div className='tmb'>
          <h5>Token not found #{tokenId}</h5>
        </div>
      </div>
    )
  }

  const newTokenId = parseInt(tokenId)

  return (
    <section className='sub--section'>
      <div className='container'>
        <div className='row justify-content-center'>
          <div className='egg-shop__head'></div>
          <NFTToken ContractAddress={contractAddress} tokenId={newTokenId} />
        </div>
      </div>
    </section>
  )
}

export default TokenPage
