import React, { useContext, useEffect, useState } from 'react'

import { useAccount } from 'wagmi'

import ButtonContractWrite from '../ButtonContractWrite'
import EGGTokenContext from '../../Contexts/EGGToken'

import NFTCardList from '../NFTCardList'
import ApplePie from '../ApplePie'
import { useQuery } from 'urql'
import EggEmission from '../EggEmission/EggEmission'
import { useContractABIs } from '../../hooks/useContractsABI'
import { useContracts } from '../../hooks/useContracts'

const Houses = (): JSX.Element => {
  const ContractAddresses = useContracts()
  const EGGTokenInfo = useContext(EGGTokenContext)
  const ContractABIs = useContractABIs()

  const { address } = useAccount()

  // Setup the progress bar percentage
  let EGGFaucetPercent = (EGGTokenInfo.totalMinted / EGGTokenInfo.totalSupply) * 100
  let EGGFaucetPercentNow = 0
  if (EGGFaucetPercent < 20) {
    EGGFaucetPercent = parseFloat(EGGFaucetPercent.toFixed(3))
    EGGFaucetPercentNow = 20
  } else {
    EGGFaucetPercent = parseFloat(EGGFaucetPercent.toFixed(2))
    EGGFaucetPercentNow = EGGFaucetPercent
  }

  const [selectedToStakeTokenIds, setSelectedToStakeTokenIds] = useState<number[]>([])

  const selectToStakedItem = (tokenId: number) => {
    if (selectedToStakeTokenIds.indexOf(tokenId) === -1) {
      setSelectedToStakeTokenIds([...selectedToStakeTokenIds, tokenId])
    } else {
      const newSelection = selectedToStakeTokenIds.filter((s) => s !== tokenId)
      setSelectedToStakeTokenIds(newSelection)
    }
  }

  const [selectedToClaimTokenIds, setSelectedToClaimTokenIds] = useState<number[]>([])

  const selectClaimedItem = (tokenId: number) => {
    if (selectedToClaimTokenIds.indexOf(tokenId) === -1) {
      setSelectedToClaimTokenIds([...selectedToClaimTokenIds, tokenId])
    } else {
      const newSelection = selectedToClaimTokenIds.filter((s) => s !== tokenId)
      setSelectedToClaimTokenIds(newSelection)
    }
  }

  const [selectedToUnstakedTokenIds, setSelectedToUnstakedTokenIds] = useState<number[]>([])

  const selectUnstakedItem = (tokenId: number) => {
    if (selectedToUnstakedTokenIds.indexOf(tokenId) === -1) {
      setSelectedToUnstakedTokenIds([...selectedToUnstakedTokenIds, tokenId])
    } else {
      const newSelection = selectedToUnstakedTokenIds.filter((s) => s !== tokenId)
      setSelectedToUnstakedTokenIds(newSelection)
    }
  }

  const handleSuccessStake = async (data: any) => {
    setSelectedToStakeTokenIds([])
    // reexecuteQuery()
  }

  const handleSuccessClaim = async (unstake: boolean) => {
    setSelectedToUnstakedTokenIds([])
    // reexecuteQuery()
  }

  // Get users farmAnimals balance
  const [walletUnstakedTokenIds, setWalletUnstakedTokenIds] = useState<number[]>([])
  const [walletStakedHenTokenIds, setWalletStakedHenTokenIds] = useState<number[]>([])
  const [walletStakedHenTimestamp, setWalletStakedHenTimestamp] = useState<number[]>([])
  const [walletUnstakeHenTimestamp, setWalletUnstakeHenTimestamp] = useState<number[]>([])

  const [walletStakedCoyoteTokenIds, setWalletStakedCoyoteTokenIds] = useState<number[]>([])
  const [walletStakedCoyoteTimestamp, setWalletStakedCoyoteTimestamp] = useState<number[]>([])
  const [walletUnstakeCoyoteTimestamp, setWalletUnstakeCoyoteTimestamp] = useState<number[]>([])

  const [walletStakedRoosterTokenIds, setWalletStakedRoosterTokenIds] = useState<number[]>([])
  const [walletStakedRoosterTimestamp, setWalletStakedRoosterTimestamp] = useState<number[]>([])
  const [walletUnstakeRoosterTimestamp, setWalletUnstakeRoosterTimestamp] = useState<number[]>([])

  const [walletAllStakedTokenIds, setWalletAllStakedTokenIds] = useState<number[]>([])

  const [walletEggShopTokenIds, setWalletEggShopTokenIds] = useState<number[]>([])
  const [walletEggShopTokenBalances, setWalletEggShopTokenBalances] = useState<number[]>([])
  // const [walletImperialEggTokenIds, setWalletImperialEggTokenIds] = useState<number[]>([])

  const HenHouseQuery = `
	query HenHouseQuery(
		$accountAddress: String!
		$farmAnimalsContract: String!
		$imperialEggContract: String!
		$eggShopContract: String!
	) @live(interval: 20000) {
		account(id: $accountAddress) {
			farmAnimals: nftTokens(where: { contract: $farmAnimalsContract }) {
				id
				token {
					id
					tokenId
					kind
				}
			}
			eggShop: nftTokens(where: { contract: $eggShopContract }) {
				id
				quantity
				token {
					id
					tokenId
				}
			}
			imperialEggs: nftTokens(where: { contract: $imperialEggContract }) {
				id
				token {
					id
					tokenId
				}
			}
			stakedHens: stakedTokens(orderBy: tokenId, where: { kind: "HEN" }) {
				id
				tokenId
				kind
				stakedTimestamp
				unstakeTimestamp
			}
			stakedCoyotes: stakedTokens(orderBy: tokenId, where: { kind: "COYOTE" }) {
				id
				tokenId
				kind
				stakedTimestamp
				unstakeTimestamp
			}
			stakedRoosters: stakedTokens(orderBy: tokenId, where: { kind: "ROOSTER" }) {
				id
				tokenId
				kind
				stakedTimestamp
				unstakeTimestamp
			}
		}
	}

`

  // useQuery urql

  const [result, reexecuteQuery] = useQuery({
    query: HenHouseQuery,
    variables: {
      accountAddress: address?.toLowerCase(),
      imperialEggContract: ContractAddresses.ImperialEggsContractAddress.toLowerCase(),
      farmAnimalsContract: ContractAddresses.FarmAnimalsContractAddress.toLowerCase(),
      eggShopContract: ContractAddresses.EggShopContractAddress.toLowerCase(),
    },
    // pause: loadingCompleted,
  })

  const { data: urqlData, fetching: urqlFetching, error: urqlError } = result

  useEffect(() => {
    if (urqlData) {
      if (urqlData.account && urqlData.account.farmAnimals) {
        const tokenIds: any[] = []
        urqlData.account.farmAnimals.forEach((token: any) => {
          tokenIds.push(token.token.tokenId)
        })
        setWalletUnstakedTokenIds(tokenIds)
      }
      if (urqlData.account && urqlData.account.eggShop) {
        const tokenIds: any[] = []
        const balances: any[] = []
        urqlData.account.eggShop.forEach((token: any) => {
          tokenIds.push(token.token.tokenId)
          balances.push(token.quantity)
        })
        setWalletEggShopTokenIds(tokenIds)
        setWalletEggShopTokenBalances(balances)
      }

      let allStakedTokenIds: any[] = []
      if (urqlData.account && urqlData.account.stakedHens) {
        const tokenIds: any[] = []
        const stakedTimestamps: any[] = []
        const unstakeTimestamps: any[] = []
        urqlData.account.stakedHens.forEach((token: any) => {
          tokenIds.push(token.tokenId)
          stakedTimestamps.push(token.stakedTimestamp)
          unstakeTimestamps.push(token.unstakeTimestamp)
          allStakedTokenIds.push(token.tokenId)
        })
        setWalletStakedHenTokenIds(tokenIds)
        setWalletStakedHenTimestamp(stakedTimestamps)
        setWalletUnstakeHenTimestamp(unstakeTimestamps)
      }
      if (urqlData.account && urqlData.account.stakedRoosters) {
        const tokenIds: any[] = []
        const stakedTimestamps: any[] = []
        const unstakeTimestamps: any[] = []
        urqlData.account.stakedRoosters.forEach((token: any) => {
          tokenIds.push(token.tokenId)
          stakedTimestamps.push(token.stakedTimestamp)
          unstakeTimestamps.push(token.unstakeTimestamp)
          allStakedTokenIds.push(token.tokenId)
        })
        setWalletStakedRoosterTokenIds(tokenIds)
        setWalletStakedRoosterTimestamp(stakedTimestamps)
        setWalletUnstakeRoosterTimestamp(unstakeTimestamps)
      }
      if (urqlData.account && urqlData.account.stakedCoyotes) {
        const tokenIds: any[] = []
        const stakedTimestamps: any[] = []
        const unstakeTimestamps: any[] = []
        urqlData.account.stakedCoyotes.forEach((token: any) => {
          tokenIds.push(token.tokenId)
          stakedTimestamps.push(token.stakedTimestamp)
          unstakeTimestamps.push(token.unstakeTimestamp)
          allStakedTokenIds.push(token.tokenId)
        })
        setWalletStakedCoyoteTokenIds(tokenIds)
        setWalletStakedCoyoteTimestamp(stakedTimestamps)
        setWalletUnstakeCoyoteTimestamp(unstakeTimestamps)
      }
      setWalletAllStakedTokenIds(allStakedTokenIds)
    }
  }, [urqlData])

  if (urqlFetching) {
    return (
      <div className='loading--section'>
        <h1>Fetching henhouse...</h1>
      </div>
    )
  }
  if (urqlError) {
    return (
      <div className='loading--section'>
        <h1>Error fetching inventory...</h1>
      </div>
    )
  }

  return (
    <>
      <div style={{ marginBottom: '20px' }} className='col-xl-11 col-lg-12 border_full'>
        <div className='container center text-center position-relative'>
          <div className='row '>
            <div className='col-xl-6 col-lg-6 justify-content-left '>
              <EggEmission stakedTokenList={walletAllStakedTokenIds} />
            </div>
            <div className='col-xl-6 col-lg-6 justify-content-right  '>
              <ApplePie />
            </div>
          </div>

          <div className='col-xl-12 col-lg-8'>
            <div className=' tab__card'>
              <h1>Unstaked Animals</h1>
              <NFTCardList
                key={'henhouse-farmAnimals'}
                name={`In the Wild: ${walletUnstakedTokenIds.length}`}
                ContractAddress={ContractAddresses.FarmAnimalsContractAddress}
                ContractABI={ContractABIs.FarmAnimalsABI}
                ContractType='ERC721'
                tokenList={walletUnstakedTokenIds}
                isStakeable={true}
                showToStakeSelect={true}
                handleStake={selectToStakedItem}
              />
              <>
                {walletUnstakedTokenIds.length > 0 && (
                  <div className='btn__box'>
                    <div className='btn__card'>
                      <ButtonContractWrite
                        ContractAddress={ContractAddresses.HenHouseContractAddress}
                        ContractABI={ContractABIs.HenHouseABI}
                        ContractFunction='addManyToHenHouse'
                        ContractArgs={[address, selectedToStakeTokenIds]}
                        ContractOverRides={{}}
                        ContractIsEnabled={selectedToStakeTokenIds.length > 0}
                        ButtonTextPrepareIdle={`⬆️ Select to Stake ⬆️ `}
                        ButtonTextPrepareLoading={`Preparing`}
                        ButtonTextInitial={'Stake to Earn EGG'}
                        ButtonTextLoading={'Waiting for approval'}
                        ButtonTextWriting={'Packing clothes...'}
                        ButtonTextError={"Couldn't stake"}
                        StartAlertText={'Moving In!'}
                        StartAlertOptions={{ icon: '🚚' }}
                        OnStartFunction={handleSuccessStake}
                        SuccessAlertText={'Settling in the Hen House!'}
                        SuccessAlertOptions={{ icon: '🏠' }}
                        OnSuccessFunction={handleSuccessStake}
                      />
                    </div>
                  </div>
                )}
              </>
            </div>
          </div>
          <div className='col-xl-12 col-lg-8'>
            <div className=' tab__card'>
              <h1>Staked Animals: {walletAllStakedTokenIds.length}</h1>
              <NFTCardList
                key={'henhouse-stakedHens'}
                name={`Hen House: ${walletStakedHenTokenIds.length}`}
                ContractAddress={ContractAddresses.FarmAnimalsContractAddress}
                ContractABI={ContractABIs.FarmAnimalsABI}
                ContractType='ERC721'
                tokenList={walletStakedHenTokenIds}
                isStaked={true}
                stakedTimestampList={walletStakedHenTimestamp}
                unstakeTimestampList={walletUnstakeHenTimestamp}
                stakedContractAddress={ContractAddresses.HenHouseCalcContractAddress}
                stakedContractABI={ContractABIs.HenHouseCalcABI}
                showClaimSelect={selectedToClaimTokenIds.length >= 0 && selectedToUnstakedTokenIds.length === 0}
                showUnstakeSelect={selectedToUnstakedTokenIds.length >= 0 && selectedToClaimTokenIds.length === 0}
                showClaimBalance={true}
                handleClaim={selectClaimedItem}
                handleUnstake={selectUnstakedItem}
              />
              <NFTCardList
                key={'henhouse-stakedRoosters'}
                name={`Rooster Guard House: ${walletStakedRoosterTokenIds.length}`}
                ContractAddress={ContractAddresses.FarmAnimalsContractAddress}
                ContractABI={ContractABIs.FarmAnimalsABI}
                ContractType='ERC721'
                tokenList={walletStakedRoosterTokenIds}
                isStaked={true}
                stakedTimestampList={walletStakedRoosterTimestamp}
                unstakeTimestampList={walletUnstakeRoosterTimestamp}
                stakedContractAddress={ContractAddresses.HenHouseCalcContractAddress}
                stakedContractABI={ContractABIs.HenHouseCalcABI}
                showClaimSelect={selectedToClaimTokenIds.length >= 0 && selectedToUnstakedTokenIds.length === 0}
                showUnstakeSelect={selectedToUnstakedTokenIds.length >= 0 && selectedToClaimTokenIds.length === 0}
                showClaimBalance={true}
                handleClaim={selectClaimedItem}
                handleUnstake={selectUnstakedItem}
              />
              <NFTCardList
                key={'henhouse-stakedCoyotes'}
                name={`Coyote Tax Den: ${walletStakedCoyoteTokenIds.length}`}
                ContractAddress={ContractAddresses.FarmAnimalsContractAddress}
                ContractABI={ContractABIs.FarmAnimalsABI}
                ContractType='ERC721'
                tokenList={walletStakedCoyoteTokenIds}
                isStaked={true}
                stakedTimestampList={walletStakedCoyoteTimestamp}
                unstakeTimestampList={walletUnstakeCoyoteTimestamp}
                stakedContractAddress={ContractAddresses.HenHouseCalcContractAddress}
                stakedContractABI={ContractABIs.HenHouseCalcABI}
                showClaimSelect={selectedToClaimTokenIds.length >= 0 && selectedToUnstakedTokenIds.length === 0}
                showUnstakeSelect={selectedToUnstakedTokenIds.length >= 0 && selectedToClaimTokenIds.length === 0}
                showClaimBalance={true}
                handleClaim={selectClaimedItem}
                handleUnstake={selectUnstakedItem}
              />
              <>
                {walletAllStakedTokenIds.length > 0 && (
                  <div className='btn__box'>
                    <div className='btn__card'>
                      {selectedToClaimTokenIds.length >= 0 && selectedToUnstakedTokenIds.length === 0 && (
                        <ButtonContractWrite
                          key={'henhouse-unstakeClaimEgg'}
                          ContractAddress={ContractAddresses.HenHouseContractAddress}
                          ContractABI={ContractABIs.HenHouseABI}
                          ContractFunction='claimManyFromHenHouseAndDen'
                          ContractArgs={[selectedToClaimTokenIds, false]}
                          ContractOverRides={{}}
                          ContractIsEnabled={
                            selectedToClaimTokenIds.length > 0 && selectedToUnstakedTokenIds.length === 0
                          }
                          ButtonTextPrepareIdle={`⬆️  Select to claim EGG ⬆️ `}
                          ButtonTextPrepareLoading={`Preparing`}
                          ButtonTextInitial={'Claim selected EGG'}
                          ButtonTextLoading={'Waiting for approval'}
                          ButtonTextWriting={'Gathering EGG 🪺...'}
                          ButtonTextError={"Couldn't gather any EGG"}
                          StartAlertText={'Boxing up EGG!'}
                          StartAlertOptions={{ icon: '🥚' }}
                          SuccessAlertText={'Your EGG is being delivered!'}
                          SuccessAlertOptions={{ icon: '🥚' }}
                          OnSuccessFunction={handleSuccessClaim}
                        />
                      )}

                      {selectedToUnstakedTokenIds.length >= 0 && selectedToClaimTokenIds.length === 0 && (
                        <ButtonContractWrite
                          key={'henhouse-unstakeClaimAll'}
                          ContractAddress={ContractAddresses.HenHouseContractAddress}
                          ContractABI={ContractABIs.HenHouseABI}
                          ContractFunction='claimManyFromHenHouseAndDen'
                          ContractArgs={[selectedToUnstakedTokenIds, true]}
                          ContractOverRides={{}}
                          ContractIsEnabled={
                            selectedToUnstakedTokenIds.length > 0 && selectedToClaimTokenIds.length === 0
                          }
                          ButtonTextPrepareIdle={`⬆️  Select to unStake ⬆️ `}
                          ButtonTextPrepareLoading={`Preparing`}
                          ButtonTextInitial={'Claim EGG & Unstake selected'}
                          ButtonTextLoading={'Waiting for approval'}
                          ButtonTextWriting={'Gathering EGG 🪺...'}
                          ButtonTextError={"Couldn't gather any EGG"}
                          StartAlertText={'Packing up clothes!'}
                          StartAlertOptions={{ icon: '🥚' }}
                          SuccessAlertText={'EGG claimed & Moved out!'}
                          SuccessAlertOptions={{ icon: '🥚' }}
                          OnSuccessFunction={handleSuccessClaim}
                        />
                      )}
                    </div>
                  </div>
                )}
              </>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Houses
