import React, { useState } from 'react'

import { useAccount, useContractRead } from 'wagmi'

import { FormCheck } from 'react-bootstrap'

import { useContractABIs } from '../../hooks/useContractsABI'
import { useContracts } from '../../hooks/useContracts'

interface PropTypes {
  tokenId: number
  stakedContractAddress?: string
  stakedContractABI?: any
  showToStakeSelect?: boolean
  showClaimSelect?: boolean
  showUnstakeSelect?: boolean
  handleStake?: any
  handleClaim?: any
  handleUnstake?: any
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const NFTClaimUnstake = (props: PropTypes): JSX.Element => {
  const {
    tokenId,
    stakedContractAddress,
    stakedContractABI,
    showToStakeSelect,
    showClaimSelect,
    showUnstakeSelect,
    handleStake,
    handleClaim,
    handleUnstake,
  } = props

  const ContractAddresses = useContracts()
  const ContractABIs = useContractABIs()

  const { address } = useAccount()

  const [canUnstake, setCanUnstake] = useState(false)

  const [toStakeChecked, setToStakeChecked] = React.useState(false)

  const handleToStakeLocal = () => {
    setToStakeChecked(!toStakeChecked)
    handleStake(tokenId)
  }

  const [claimChecked, setClaimChecked] = React.useState(false)

  const handleClaimLocal = () => {
    setClaimChecked(!claimChecked)
    handleClaim(tokenId)
  }

  const [unstakeChecked, setUnstakeChecked] = React.useState(false)

  const handleUnstakeLocal = () => {
    setUnstakeChecked(!unstakeChecked)
    handleUnstake(tokenId)
  }

  const stakedContractConfig = {
    address: stakedContractAddress,
    abi: stakedContractABI,
  }

  const henHouseConfig = {
    address: ContractAddresses.HenHouseContractAddress,
    abi: ContractABIs.HenHouseABI,
  }

  useContractRead({
    ...henHouseConfig,
    functionName: 'canUnstake',
    args: [tokenId],
    watch: true,
    enabled: showUnstakeSelect,
    cacheTime: 30_000,
    overrides: { from: address },
    onSuccess(data) {
      setCanUnstake(data as unknown as boolean)
    },
    onError(error) {
      console.error(`NFTClaimUnstake calculateRewards Error tokenId: ${tokenId}`, error)
    },
  })

  return (
    <div>
      {showToStakeSelect && (
        <>
          <FormCheck label='Stake' className='stake-check' onChange={handleToStakeLocal} />
        </>
      )}
      {showClaimSelect && (
        <>
          <FormCheck label='Claim' className='stake-check' onChange={handleClaimLocal} />
        </>
      )}
      {showUnstakeSelect && canUnstake && (
        <>
          <FormCheck label='Leave' className='stake-check' onChange={handleUnstakeLocal} />
        </>
      )}
    </div>
  )
}

export default NFTClaimUnstake
