import React, { Suspense } from 'react'

import { Toaster } from 'react-hot-toast'
import { HashRouter as Router, Routes, Route } from 'react-router-dom'
import { useNetwork } from 'wagmi'

import Navbar from './components/Navbar'
import AllowListCheckPage from './views/AllowListCheck'
import MainPage from './views/MainPage'
import MintPage from './views/MintPage'
import EggShop from './views/EggShopPage'
import HenHousePage from './views/Houses'
import StatsPage from './views/StatsPage'
import WhitepaperPage from './views/WhitepaperPage'
import BottomFooter from './components/BottomFooter'
import InventoryPage from './views/InventoryPage'
import TokenPage from './views/TokenPage'
import { HelmetProvider } from 'react-helmet-async'

import { createClient, Provider } from 'urql'
import { graphExchange } from '@graphprotocol/client-urql'
import * as GraphClient from './graphclient'
import AdminPage from './views/AdminPage'
import DaoPage from './views/DaoPage'

const subgraphAPIURL = process.env.REACT_APP_SUBGRAPH_API_URL

const client = createClient({
  url: subgraphAPIURL!,
  exchanges: [graphExchange(GraphClient)],
})

const App = () => {
  const { chain, chains } = useNetwork()

  return (
    <>
      <HelmetProvider>
        <Provider value={client}>
          <Router>
            <Suspense
              fallback={
                <div className='loading--section'>
                  <h1>App Loading...</h1>
                </div>
              }
            >
              <Navbar />
              <Routes>
                <Route path='/' element={<MainPage />} />
                <Route path='/wallet_check' element={<AllowListCheckPage />} />
                <Route path='/mint' element={<MintPage />} />
                <Route path='/eggshop' element={<EggShop />} />
                <Route path='/henhouse' element={<HenHousePage />} />
                <Route path='/inventory' element={<InventoryPage />} />
                <Route path='/stats' element={<StatsPage />} />
                <Route path='/dao' element={<DaoPage />} />
                <Route path='/whitepaper' element={<WhitepaperPage />} />
                <Route path='/tokens/:contract' element={<TokenPage />} />
                <Route path='/tokens/:contractAddress/:tokenId' element={<TokenPage />} />
                <Route path='/admin' element={<AdminPage />} />
              </Routes>
              <BottomFooter />
              {chain && <div>Connected to {chain.name}</div>}
              {chains && <div>Available chains: {chains.map((chain) => `${chain.name} `)}</div>}
            </Suspense>
          </Router>
        </Provider>
        <Toaster
          position='top-center'
          toastOptions={{
            style: {
              fontSize: '17px',
            },
          }}
        />
      </HelmetProvider>
    </>
  )
}

export default App
