import React, { useContext, useEffect, useState } from 'react'

import { TFGSupplyProps } from '../types'

import { useQuery } from 'urql'

import { useContracts } from './useContracts'

export function useFarmAnimalsData() {
  const [tfgData, setData] = useState<TFGSupplyProps>()

  const ContractAddresses = useContracts()

  const urqlQuery = `
		query MyQuery($farmAnimalsContract: String) {
			tfgSupply: nfttokenContract(id: $farmAnimalsContract) {
				maxGen0Supply
				maxSupply
				totalSupply
			}
		}
	`

  const [result, reexecuteQuery] = useQuery({
    query: urqlQuery,
    variables: { farmAnimalsContract: ContractAddresses.FarmAnimalsContractAddress.toLowerCase() },
  })

  const { data: urqlData, fetching: urqlFetching, error: urqlError } = result

  useEffect(() => {
    if (urqlData) {
      if (urqlData.tfgSupply) {
        const data = urqlData.tfgSupply

        setData({
          maxGen0Supply: data.maxGen0Supply,
          maxSupply: data.maxSupply,
          totalSupply: data.totalSupply,
        })
      }
    }
  }, [urqlData])

  return tfgData as TFGSupplyProps
}
