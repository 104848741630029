import './wdyr'
import React from 'react'
import ReactDOM from 'react-dom/client'
import reportWebVitals from './reportWebVitals'
import './scss/root.scss'

import '@rainbow-me/rainbowkit/styles.css'
import { getDefaultWallets, RainbowKitProvider, Theme } from '@rainbow-me/rainbowkit'
import { chain, configureChains, createClient, WagmiConfig } from 'wagmi'
import { alchemyProvider } from 'wagmi/providers/alchemy'
import { publicProvider } from 'wagmi/providers/public'

import App from './App'

const myCustomTheme: Theme = {
  blurs: {
    modalOverlay: '',
  },
  colors: {
    accentColor: '#6BA819',
    accentColorForeground: '#ffffff',
    actionButtonBorder: '#ffffff',
    actionButtonBorderMobile: '#ffffff',
    actionButtonSecondaryBackground: '#ffffff',
    closeButton: '#ffffff',
    closeButtonBackground: '#C27A4C',
    connectButtonBackground: '#FFC115',
    connectButtonBackgroundError: '#FF191A',
    connectButtonInnerBackground: '#C27A4C',
    connectButtonText: '#ffffff',
    connectButtonTextError: '#FF191A',
    connectionIndicator: '#6BA819',
    downloadBottomCardBackground: '...',
    downloadTopCardBackground: '...',
    error: '#FF191A',
    generalBorder: '#ffffff',
    generalBorderDim: '#ffffff',
    menuItemBackground: '#ffffff',
    modalBackdrop: '', // Change the entire background color
    modalBackground: '#FFC115',
    modalBorder: '#ffffff',
    modalText: '#ffffff',
    modalTextDim: '#ffffff',
    modalTextSecondary: '#ffffff',
    profileAction: '...',
    profileActionHover: '...',
    profileForeground: '...',
    selectedOptionBorder: '...',
    standby: '...',
  },
  fonts: {
    body: '...',
  },
  radii: {
    actionButton: '...',
    connectButton: '...',
    menuButton: '...',
    modal: '...',
    modalMobile: '...',
  },
  shadows: {
    connectButton: '...',
    dialog: '...',
    profileDetailsAction: '...',
    selectedOption: '...',
    selectedWallet: '...',
    walletLogo: '...',
  },
}

const { chains, provider } = configureChains(
  [
    ...(process.env.REACT_APP_ENABLE_TESTNETS === 'true' ? [chain.localhost, chain.goerli] : []),
    chain.localhost,
    chain.goerli,
  ],
  [alchemyProvider({ apiKey: process.env.REACT_APP_ALCHEMY_API_KEY }), publicProvider()],
)

const { connectors } = getDefaultWallets({
  appName: 'The Farm Game',
  chains,
})

const wagmiClient = createClient({
  autoConnect: true,
  connectors,
  provider,
})

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
  <React.StrictMode>
    <WagmiConfig client={wagmiClient}>
      <RainbowKitProvider chains={chains} theme={myCustomTheme} coolMode>
        <App />
      </RainbowKitProvider>
    </WagmiConfig>
  </React.StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
