/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import Dao from '../../components/Dao'

function DaoPage(): JSX.Element {
  return (
    <div>
      <Dao />
    </div>
  )
}

export default DaoPage
