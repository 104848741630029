import React, { createContext } from 'react'

import { TFGDataProps } from '../types'

const TFGDataContext = createContext<TFGDataProps>({
  saleStatus: 'paused',
  price: 0,
  totalSupply: 0,
  maxSupply: 0,
  maxGen0Supply: 0,
  preSaleTime: 0,
  publicSaleTime: 0,
})

export default TFGDataContext
