/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'

import Whitepaper from '../../components/Whitepaper'

function WhitepaperPage(): JSX.Element {
  return (
    <div>
      <Whitepaper />
    </div>
  )
}

export default WhitepaperPage
