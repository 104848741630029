import React, { useState, useEffect } from 'react'

import { ConnectButton } from '@rainbow-me/rainbowkit'
import { useAccount } from 'wagmi'

import { MerkleMintDataProps, TFGSalesDataProps } from '../../types'

import axios from 'axios'

import MintCountdown from '../MintCountdown'
import { Nav } from 'react-bootstrap'

interface PropTypes {
  TFGSalesData: TFGSalesDataProps
  blockTimeStamp: EpochTimeStamp
}

const MintPreSale = (props: PropTypes): JSX.Element => {
  const { TFGSalesData, blockTimeStamp } = props

  const { address, isConnected } = useAccount()

  // PreSale
  const [isMerkleWhiteListed, setIsMerkleWhiteListed] = useState(false)
  const [merkleInfo, setMerkleInfo] = useState<MerkleMintDataProps>()
  const [verifiedMerkle, setVerifiedMerkle] = useState(false)

  useEffect(() => {
    const fetchData = async () => {
      // get the data from the api
      await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/merkle/pre_sale/proof/${address}`).then((data) => {
        if (data && data.data.proof) {
          setIsMerkleWhiteListed(true)
          setVerifiedMerkle(true)
          setMerkleInfo({
            merkleProof: data.data.proof,
          })
        } else {
          setIsMerkleWhiteListed(false)
          setVerifiedMerkle(true)
        }
      })
    }
    // call the function
    fetchData()
      // make sure to catch any error
      .catch(console.error)
  }, [address])

  if (!TFGSalesData) {
    return (
      <div className='loading--section'>
        <h1>Minting Loading...</h1>
      </div>
    )
  }

  return (
    <div className='col-xl-5 col-lg-6'>
      <div className='tab__card'>
        <div className='tab__text'>
          <h1>PRESALE Whitelist</h1>

          <MintCountdown
            timeToSale={TFGSalesData.preSaleTime}
            blockTimeStamp={blockTimeStamp}
            showCountdownComplete={false}
          />

          <br />
          <br />
        </div>
        {isConnected ? (
          <>
            <>
              {verifiedMerkle ? (
                <>
                  {isMerkleWhiteListed && merkleInfo ? (
                    <h1 style={{ color: '#075acf' }}>✅ Your In!! 🎉</h1>
                  ) : (
                    <h1 style={{ color: '#d84619' }}>🛑 Not on the list 😢</h1>
                  )}
                </>
              ) : (
                <h2>Checking eligibility...</h2>
              )}
            </>
          </>
        ) : (
          <div className='btn__box'>
            <div className='btn__card btn__anchor'>
              <ConnectButton chainStatus='none' accountStatus='avatar' showBalance={false} />
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default MintPreSale
