import React from 'react'

import Accordion from 'react-bootstrap/Accordion'

import Video1 from '../../assets/images/banner-video1.mp4'
import Video2 from '../../assets/images/banner-video2.mp4'
import Video3 from '../../assets/images/banner-video3.mp4'

import HenImg from '../../assets/images/hen.png'
import CoyoteImg from '../../assets/images/coyotes.png'
import RoosterImg from '../../assets/images/rooster.png'
import TokenomicsImg from '../../assets/images/tokenomics-img.png'
import BreakdownImg from '../../assets/images/breakdown-img.png'
import FAQ from '../FAQ/FAQ'
import Roadmap from '../Roadmap/Roadmap'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const Main = () => {
  return (
    <>
      <main>
        <section id='story' className='sub--hero'>
          <div className='container'>
            <div className='row'>
              <div className='col-12'>
                <div className='sec__head hero__header'>
                  <h2>The Story...</h2>
                </div>
              </div>
            </div>
            <div className='row margin__row align-items-start justify-content-center'>
              <div className='col-lg-6 col-xl-6 col-sm-12 col-12'>
                <div className='hero__text'>
                  <p>
                    It's a calm day on the Metaverse Farm, the Hens and rooster lived happily with each other and the
                    Ranchers, who took care of them especially the Hens, in hopes of them being able to produce more EGG
                    Life was pretty simple, at least up until the point where a pack of Coyotes suddenly stepped foot
                    near the farm. Back then, the Ranchers didn't seem to care about these fellows' arrival, but little
                    did they know that their new enemy was right at their doorstep.
                  </p>
                  <p>
                    One night, while everyone was fast asleep, tragedy struck, when the Coyotes moved through the
                    shadows of the night and barged into the Henhouse, with the intent to take the Hens and the
                    Ranchers' collection of EGG, for their own. The Ranchers and Roosters tried to stop them, but they
                    were too late. The Coyotes succeeded in their late-night raid.
                  </p>
                </div>
              </div>
              <div className='col-lg-6 col-xl-6 col-sm-10 col-12'>
                <div className='banner__video'>
                  <video className='hype__video' width={400} autoPlay muted loop playsInline>
                    <source src={Video1} type='video/mp4' />
                    Your browser does not support HTML video.
                  </video>
                </div>
              </div>
            </div>
            <div className='row row__2 align-items-start justify-content-center'>
              <div className='col-lg-6 col-xl-6 col-sm-12 col-12'>
                <div className='hero__text'>
                  <p>
                    The Coyotes became more and more of a menace with each passing day. Forcing the Hens to live in
                    constant fear, the Ranchers too had a sense of dread looming around the farm at all times. The once
                    peaceful life at the farm now turned into a constant struggle for resources. With the Hens
                    disappearing and the EGG being constantly stolen, the Ranchers had enough. What felt like an
                    eternity, would be brought to an end. They decided, that to stop the Coyotes' constant raids, they
                    would cut a deal with them to pay tax for more EGG production, while the Coyotes would not attack
                    the Hen House from that point on.
                  </p>
                  <p>
                    The Ranchers asked themselves, was this the end..? Would we now be able to return to our normal
                    lives...?
                  </p>
                  <p>
                    Unfortunately, fate chose otherwise. The Coyotes, though being vicious as they are, were also
                    mischievous, and most certainly devious.
                  </p>
                </div>
              </div>
              <div className='col-lg-6 col-xl-6 col-sm-10 col-12'>
                <div className='banner__video'>
                  <video className='hype__video' width={200} autoPlay muted loop playsInline>
                    <source src={Video2} type='video/mp4' />
                    Your browser does not support HTML video.
                  </video>
                </div>
              </div>
            </div>
            <div className='row row__3 align-items-start justify-content-center'>
              <div className='col-lg-6 col-xl-6 col-sm-12 col-12'>
                <div className='hero__text'>
                  <p>
                    They will stop at nothing to attain their goal to get more EGG, and despite the deal being done
                    between them and the Ranchers, they could still be seen, breaking their promise and stealing the
                    Ranchers' EGG and the Hens once more. The solution, thought to have solved everything, was merely a
                    band-aid, that has now fallen off. This was the last straw for the Ranchers. If the Coyotes didn't
                    want peace, then they settled for violence.
                  </p>
                  <p>
                    They trained their only remaining asset left, the Roosters, to fight against the Coyotes, this time,
                    making sure that they won't be getting the last laugh. Eventually, the Ranchers did manage to fight
                    back, but at the cost of the struggle for EGG continuing. The once peaceful farm was now a distant
                    memory, from which it wouldn't be able to return. The right of who owns the EGG shall be decided as
                    the war rages on...
                  </p>
                </div>
              </div>
              <div className='col-lg-6 col-xl-6 col-sm-10 col-12'>
                <div className='banner__video'>
                  <video className='hype__video' width={400} autoPlay muted loop playsInline>
                    <source src={Video3} type='video/mp4' />
                    Your browser does not support HTML video.
                  </video>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id='character' className='character--table'>
          <div className='container'>
            <div className='row justify-content-center'>
              <div className='col-lg-10'>
                <div className='sec__head'>
                  <h1>Characters</h1>
                  <h2>There are 3 characters in TFG</h2>
                  <div className='head__text'>
                    <ul>
                      <li>Hens earn EGG daily and pay a tax to Coyotes</li>
                      <li>Coyotes collect a tax &amp; steal Gen 1+ mints</li>
                      <li>Roosters are rare, they protect Hens, collect daily EGG, rescue dropped EGG other rewards</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='hen--table'>
          <div className='container'>
            <div className='row justify-content-center'>
              <div className='col-lg-10'>
                <div className='sec__head'>
                  <h1>Hens</h1>
                  <div className='hen__image'>
                    <img src={HenImg} alt='HEN' />
                  </div>
                  <div className='head__text'>
                    <ul>
                      <li>There is a 86-89% chance of minting a Hen</li>
                      <li>Each Hen starts with a production rate between 5-8</li>
                      <li>Hens production rate can be upgraded with EGG</li>
                      <li>Staked Hens produce 10,000 EGG per day</li>
                      <li>Hens pay a 20% claim tax to Coyotes</li>
                      <li>Minted Gen 1+ Hens can be stolen by Coyotes</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='Coyotes--table'>
          <div className='container'>
            <div className='row justify-content-center'>
              <div className='col-lg-10'>
                <div className='sec__head'>
                  <h1>Coyotes</h1>
                  <div className='hen__image coyotes__image'>
                    <img src={CoyoteImg} alt='COYOTE' />
                  </div>
                  <div className='head__text coyotes__text'>
                    <ul>
                      <li>There is a 10-12% chance of minting a Coyote</li>
                      <li>Staked Coyotes can receive claim taxes and steal Gen 1+ mints</li>
                      <li>Each Coyote has a willy score ranging between 5-8</li>
                      <li>The higher the rank:</li>
                      <ul className='small__text'>
                        <li className='small'>The higher portion of EGG that Coyote earn from claim taxes</li>
                        <li className='small'>The higher the chance of stealing a newly minted Hen or Coyote</li>
                      </ul>
                      <li>Minted Gen 1+ Coyotes can be stolen by Coyotes</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='rooster--table'>
          <div className='container'>
            <div className='row justify-content-center'>
              <div className='col-lg-10'>
                <div className='sec__head'>
                  <h1>Roosters</h1>
                  <div className='hen__image'>
                    <img src={RoosterImg} alt='ROOSTER' />
                  </div>
                  <div className='head__text'>
                    <ul>
                      <li>There is a 1-2% chance of minting a Rooster</li>
                      <li>Staked Roosters can receive transfer taxes and 1,000 EGG per day</li>
                      <li>Roosters can rescue dropped EGG</li>
                      <li>Each Rooster has a guard rank between 5-8</li>
                      <li>Roosters collect 30% of change &amp; upgrade actions</li>
                      <li>Roosters can not be stolen</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id='tokenomics' className='tokenomics--section'>
          <div className='container'>
            <div className='row'>
              <div className='col-12'>
                <div className='sec__head tokenomics__head'>
                  <h2>EGG tokenomics</h2>
                </div>
                <div className='tokenomics__img'>
                  <img src={TokenomicsImg} alt='Tokenomics' />
                </div>
                <div className='head__text'>
                  <ul>
                    <li>EGG is only minted when you claim</li>
                    <ul className='small__text'>
                      <li>Totally circulating EGG supply = total claimed EGG - burned EGG</li>
                    </ul>
                    <li>
                      When supply reaches 4,000,000,000 EGG from staking, the Hen House EGG production will be shut off
                    </li>
                    <li>Developers will receive 9.09% of EGG emissions</li>
                  </ul>
                </div>
                <div className='sec__head bottom__text'>
                  <h2>Transfer Tax usage breakdown:</h2>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-lg-6 col-sm-6 col-12'>
                <div className='breakdown__text'>
                  <ul>
                    <li>20% to Staked Roosters</li>
                    <li>20% to Liquidity Pool</li>
                    <li>15% to Farm Dao</li>
                    <li>15% is Burned</li>
                    <li>15% is reflected to EGG holders</li>
                    <li>8% to Hen House pool</li>
                    <li>7% to Dev wallet</li>
                  </ul>
                </div>
              </div>
              <div className='col-lg-6 col-sm-6 col-12'>
                <div className='breakdown__img'>
                  <img src={BreakdownImg} alt='Breakdown' />
                </div>
              </div>
            </div>
          </div>
        </section>
        <Roadmap />
        <FAQ />
      </main>
    </>
  )
}

export default Main
