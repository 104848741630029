import React from 'react'

const GasLimitNote = (): JSX.Element => {
  return (
    <h4>
      ** Please Note: Gas limit is over estimated by 30% to account for randomness. You will most likely not be charged
      the full gas amount shown in your wallet
    </h4>
  )
}

export default GasLimitNote
