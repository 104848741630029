import React, { useState, useEffect } from 'react'

import { TFGStatsType } from '../../types'

import { useQuery } from 'urql'

// general
import MintingCharacter from '../../assets/images/minting_animations/new_character_mint.gif'

// hens
import HensMinted from '../../assets/images/stats/hen_check.png'
import TwinsMinted from '../../assets/images/stats/hen_twin_sets_minted.png'
import HensStaked from '../../assets/images/stats/hen_staked.png'
import HensBurned from '../../assets/images/stats/hens_burned.png'
import HensSacrificed from '../../assets/images/stats/hens_sacrificed.png'
import HensKidnapped from '../../assets/images/stats/hen_kidnapped.png'
import HensRescued from '../../assets/images/stats/hens_rescued.png'
import HensTaxPaid from '../../assets/images/stats/hen_egg_tax_paid.png'

// coyotes
import CoyotesMinted from '../../assets/images/stats/coyotes_minted.png'
import CoyotesStaked from '../../assets/images/stats/coyotes_staked.png'
import CoyotesBurned from '../../assets/images/stats/coyotes_burned.png'
import CoyotesSacrificed from '../../assets/images/stats/coyotes_sacrificed.png'
import CoyotesKidnapped from '../../assets/images/stats/coyotes_kidnapped.png'
import CoyoteEggTaxes from '../../assets/images/stats/coyotes_egg_taxes_collected.png'

// roosters
import RoosterMinted from '../../assets/images/stats/roosters_minted.png'
import RoosterBurned from '../../assets/images/stats/roosters_burned.png'
import RoosterStaked from '../../assets/images/stats/roosters_staked.png'
import RoosterRobbed from '../../assets/images/stats/roosters_eggs_robbed.png'
import RoosterFeesCollected from '../../assets/images/stats/rooster_fees_collected.png'

// egg token
import EggsMade from '../../assets/images/stats/eggs_made.png'
import EggsTaxed from '../../assets/images/stats/eggs_taxed.png'
import EggsBroken from '../../assets/images/stats/eggs_broken.png'
import EggsRescued from '../../assets/images/stats/eggs_rescued.png'
import EggsBurned from '../../assets/images/stats/eggs_burned.png'
import EggsDropped from '../../assets/images/stats/eggs_dropped.png'
import EggsEmpty from '../../assets/images/stats/eggs_empty.png'

import ApplePieBaked from '../../assets/images/stats/apple_pies_baked.png'
import ApplePieSold from '../../assets/images/stats/apple_pies_sold.png'
import ApplePieStolen from '../../assets/images/stats/apple_pies_stolen.png'
import ApplePieEaten from '../../assets/images/stats/coyote_apple_pies_eaten.png'

// egg shop
import EggsBasket from '../../assets/images/stats/eggs_basket.png'
import BronzeEggAwarded from '../../assets/images/stats/egg_shop_bronze_egg_awarded.png'
import GoldenEggAwarded from '../../assets/images/stats/egg_shop_gold_egg_awarded.png'
import ImperialEggAwarded from '../../assets/images/stats/egg_shop_imperial_egg_awarded.png'
import PlatinumEggAwarded from '../../assets/images/stats/egg_shop_platinum_egg_awarded.png'
import RainbowEggAwarded from '../../assets/images/stats/egg_shop_rainbow_egg_awarded.png'
import SilverEggAwarded from '../../assets/images/stats/egg_shop_silver_egg_awarded.png'

import './style.scss'

// import {usePrepareLogOrder} from '../../utils/usePrepareLogOrder'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const StatsBoard = () => {
  const [TFGStats, setTFGStats] = useState<TFGStatsType>()

  const GetTFGStats = `
		query TFGStatsQuery {
			tfgstat(id: "season-1"){
				id
				hensMinted
				henTwinsMinted
				hensBurned
				hensStaked
				hensKidnapped
				hensRescued
				hensSacrificed
				hensEggTaxesPaid
				coyotesMinted
				coyotesBurned
				coyotesStaked
				coyotesKidnapped
				coyotesSacrificed
				coyoteEggTaxCollected
				roostersMinted
				roostersBurned
				roostersStaked
				roostersEggRobbed
				eggsProduced
				eggsTaxed
				eggsDropped
				eggsRescued
				eggShopPiesBaked
				eggShopPiesSold
				eggShopPiesStolen
				imperialEggAwarded
				rainbowEggAwarded
				platinumEggAwarded
				goldenEggAwarded
				silverEggAwarded
				bronzeEggAwarded
			}
		}
	`

  // useQuery urql

  const [result, reexecuteQuery] = useQuery({
    query: GetTFGStats,
  })

  const { data: statsData, fetching: statsFetching, error: statsError } = result

  useEffect(() => {
    if (statsData) {
      setTFGStats(statsData.tfgstat)
    }
  }, [statsData])

  if (statsFetching || !TFGStats) {
    return (
      <div className='loading--section'>
        <h1>Fetching stats...</h1>
      </div>
    )
  }
  if (statsError) {
    return (
      <div className='loading--section'>
        <h1>Error fetching stats...</h1>
      </div>
    )
  }
  const totalStaked = TFGStats.hensStaked + TFGStats.coyotesStaked + TFGStats.roostersStaked
  const totalMinted = TFGStats.hensMinted + TFGStats.coyotesMinted + TFGStats.roostersMinted
  const totalStakedPercent = (totalStaked / totalMinted) * 100
  const hensStakedPercent = (TFGStats.hensStaked / TFGStats.hensMinted) * 100
  const coyotesStakedPercent = (TFGStats.coyotesStaked / TFGStats.coyotesMinted) * 100
  const roostersStakedPercent = (TFGStats.roostersStaked / TFGStats.roostersMinted) * 100

  return (
    <section className='board--section'>
      <div className='container'>
        <div className='board__head'>
          <h1>Hen Stats</h1>
          <h2>Total Minted: {totalMinted}</h2>
          <h2>
            Total Staked: {totalStaked} | {totalStakedPercent ? totalStakedPercent.toFixed(2) : 0}%
          </h2>
        </div>

        <div className='row justify-content-center'>
          <div className='col-sm-5 col-lg-3 col-xl-3'>
            <div className='card board__main'>
              <div className='board__card'>
                <img src={HensMinted} alt='' />
                <br />
                <br />
                <h1>Minted</h1>
                <h2>{TFGStats.hensMinted}</h2>
              </div>
            </div>
          </div>
          <div className='col-sm-5 col-lg-3 col-xl-3'>
            <div className='card board__main'>
              <div className='board__card'>
                <img src={TwinsMinted} alt='' />
                <br />
                <br />
                <h1>Twin Sets Minted</h1>
                <h2>{TFGStats.henTwinsMinted}</h2>
              </div>
            </div>
          </div>
          <div className='col-sm-5 col-lg-3 col-xl-3'>
            <div className='card board__main'>
              <div className='board__card'>
                <img src={HensStaked} alt='' />
                <br />
                <br />
                <h1>In Hen House</h1>
                <h2>
                  {TFGStats.hensStaked} | {hensStakedPercent ? hensStakedPercent.toFixed(2) : 0}%
                </h2>
              </div>
            </div>
          </div>
          <div className='col-sm-5 col-lg-3 col-xl-3'>
            <div className='card board__main'>
              <div className='board__card'>
                <img src={HensBurned} alt='' />
                <br />
                <br />
                <h1>Burned</h1>
                <h2>{TFGStats.hensBurned}</h2>
              </div>
            </div>
          </div>
          <div className='col-sm-5 col-lg-3 col-xl-3'>
            <div className='card board__main'>
              <div className='board__card'>
                <img src={HensSacrificed} alt='' />
                <br />
                <br />
                <h1>Sacrificed</h1>
                <h2>{TFGStats.hensSacrificed}</h2>
              </div>
            </div>
          </div>
          <div className='col-sm-5 col-lg-3 col-xl-3'>
            <div className='card board__main'>
              <div className='board__card'>
                <img src={HensKidnapped} alt='' />
                <br />
                <br />
                <h1>Kidnapped</h1>
                <h2>{TFGStats.hensKidnapped}</h2>
              </div>
            </div>
          </div>
          <div className='col-sm-5 col-lg-3 col-xl-3'>
            <div className='card board__main'>
              <div className='board__card'>
                <img src={HensRescued} alt='' />
                <br />
                <br />
                <h1>Rescued</h1>
                <h2>{TFGStats.hensRescued}</h2>
              </div>
            </div>
          </div>
          <div className='col-sm-5 col-lg-3 col-xl-3'>
            <div className='card board__main'>
              <div className='board__card'>
                <img src={HensTaxPaid} alt='' />
                <br />
                <br />
                <h1>Taxes Paid</h1>
                <h2>{TFGStats.hensEggTaxesPaid}</h2>
              </div>
            </div>
          </div>
        </div>

        <div className='board__head'>
          <h1>Coyote Stats</h1>
        </div>
        <div className='row justify-content-center'>
          <div className='col-sm-5 col-lg-3 col-xl-3'>
            <div className='card board__main'>
              <div className='board__card'>
                <img src={CoyotesMinted} alt='' />
                <br />
                <br />
                <h1>Minted</h1>
                <h2>{TFGStats.coyotesMinted}</h2>
              </div>
            </div>
          </div>
          <div className='col-sm-5 col-lg-3 col-xl-3'>
            <div className='card board__main'>
              <div className='board__card'>
                <img src={CoyotesStaked} alt='' />
                <br />
                <br />
                <h1>In Tax Den</h1>
                <h2>
                  {TFGStats.coyotesStaked} | {coyotesStakedPercent ? coyotesStakedPercent.toFixed(2) : 0}%
                </h2>
              </div>
            </div>
          </div>
          <div className='col-sm-5 col-lg-3 col-xl-3'>
            <div className='card board__main'>
              <div className='board__card'>
                <img src={CoyotesBurned} alt='' />
                <br />
                <br />
                <h1>Burned</h1>
                <h2>{TFGStats.coyotesBurned}</h2>
              </div>
            </div>
          </div>
          <div className='col-sm-5 col-lg-3 col-xl-3'>
            <div className='card board__main'>
              <div className='board__card'>
                <img src={CoyotesSacrificed} alt='' />
                <br />
                <br />
                <h1>Sacrificed</h1>
                <h2>{TFGStats.coyotesSacrificed}</h2>
              </div>
            </div>
          </div>
          <div className='col-sm-5 col-lg-3 col-xl-3'>
            <div className='card board__main'>
              <div className='board__card'>
                <img src={CoyotesKidnapped} alt='' />
                <br />
                <br />
                <h1>Kidnapped</h1>
                <h2>{TFGStats.coyotesKidnapped}</h2>
              </div>
            </div>
          </div>

          <div className='col-sm-5 col-lg-3 col-xl-3'>
            <div className='card board__main'>
              <div className='board__card'>
                <img src={CoyoteEggTaxes} alt='' />
                <br />
                <br />
                <h1>Taxes collected</h1>
                <h2>{TFGStats.coyoteEggTaxCollected}</h2>
              </div>
            </div>
          </div>
          <div className='col-sm-5 col-lg-3 col-xl-3'>
            <div className='card board__main'>
              <div className='board__card'>
                <img src={EggsDropped} alt='' />
                <br />
                <br />
                <h1>Dropped</h1>
                <h2>{TFGStats.eggsDropped}</h2>
              </div>
            </div>
          </div>
          <div className='col-sm-5 col-lg-3 col-xl-3'>
            <div className='card board__main'>
              <div className='board__card'>
                <img src={ApplePieStolen} alt='' />
                <br />
                <br />
                <h1>Apple Pies Stolen</h1>
                <h2>{TFGStats.eggShopPiesStolen}</h2>
              </div>
            </div>
          </div>
        </div>

        <div className='board__head'>
          <h1>Rooster Stats</h1>
        </div>
        <div className='row justify-content-center'>
          <div className='col-sm-5 col-lg-3 col-xl-3'>
            <div className='card board__main'>
              <div className='board__card'>
                <img src={RoosterMinted} alt='' />
                <br />
                <br />
                <h1>Minted</h1>
                <h2>{TFGStats.roostersMinted}</h2>
              </div>
            </div>
          </div>
          <div className='col-sm-5 col-lg-3 col-xl-3'>
            <div className='card board__main'>
              <div className='board__card'>
                <img src={RoosterStaked} alt='' />
                <br />
                <br />
                <h1>In Guard House</h1>
                <h2>
                  {TFGStats.roostersStaked} | {roostersStakedPercent ? roostersStakedPercent.toFixed(2) : 0}%
                </h2>
              </div>
            </div>
          </div>
          <div className='col-sm-5 col-lg-3 col-xl-3'>
            <div className='card board__main'>
              <div className='board__card'>
                <img src={RoosterBurned} alt='' />
                <br />
                <br />
                <h1>Burned</h1>
                <h2>{TFGStats.roostersBurned}</h2>
              </div>
            </div>
          </div>

          <div className='col-sm-5 col-lg-3 col-xl-3'>
            <div className='card board__main'>
              <div className='board__card'>
                <img src={RoosterFeesCollected} alt='' />
                <br />
                <br />
                <h1>Fees collected</h1>
                <h2>N/A</h2>
              </div>
            </div>
          </div>
          <div className='col-sm-5 col-lg-3 col-xl-3'>
            <div className='card board__main'>
              <div className='board__card'>
                <img src={RoosterRobbed} alt='' />
                <br />
                <br />
                <h1>Egg Robbed</h1>
                <h2>{TFGStats.roostersEggRobbed}</h2>
              </div>
            </div>
          </div>
        </div>
        <div className='board__head'>
          <h1>Egg Token Stats</h1>
        </div>
        <div className='row justify-content-center'>
          <div className='col-sm-5 col-lg-3 col-xl-3'>
            <div className='card board__main'>
              <div className='board__card'>
                <img src={EggsMade} alt='' />
                <br />
                <br />
                <h1>Produced</h1>
                <h2>{TFGStats.eggsProduced}</h2>
              </div>
            </div>
          </div>
          <div className='col-sm-5 col-lg-3 col-xl-3'>
            <div className='card board__main'>
              <div className='board__card'>
                <img src={EggsBurned} alt='' />
                <br />
                <br />
                <h1>Burned</h1>
                <h2>N/A</h2>
              </div>
            </div>
          </div>

          <div className='col-sm-5 col-lg-3 col-xl-3'>
            <div className='card board__main'>
              <div className='board__card'>
                <img src={EggsBroken} alt='' />
                <br />
                <br />
                <h1>Broken</h1>
                <h2>{TFGStats.eggsDropped}</h2>
              </div>
            </div>
          </div>
          <div className='col-sm-5 col-lg-3 col-xl-3'>
            <div className='card board__main'>
              <div className='board__card'>
                <img src={EggsTaxed} alt='' />
                <br />
                <br />
                <h1>Total Taxed</h1>
                <h2>{TFGStats.eggsTaxed}</h2>
              </div>
            </div>
          </div>
          <div className='col-sm-5 col-lg-3 col-xl-3'>
            <div className='card board__main'>
              <div className='board__card'>
                <img src={EggsRescued} alt='' />
                <br />
                <br />
                <h1>EGG rescued</h1>
                <h2>{TFGStats.eggsRescued}</h2>
              </div>
            </div>
          </div>
        </div>

        <div className='board__head'>
          <h1>Egg Shop Stats</h1>
        </div>
        <div className='row justify-content-center'>
          <div className='col-sm-5 col-lg-3 col-xl-3'>
            <div className='card board__main'>
              <div className='board__card'>
                <img src={ApplePieBaked} alt='' />
                <br />
                <br />
                <h1>Apple Pies Baked</h1>
                <h2>{TFGStats.eggShopPiesBaked}</h2>
              </div>
            </div>
          </div>
          <div className='col-sm-5 col-lg-3 col-xl-3'>
            <div className='card board__main'>
              <div className='board__card'>
                <img src={ApplePieSold} alt='' />
                <br />
                <br />
                <h1>Apple Pies Sold</h1>
                <h2>{TFGStats.eggShopPiesSold}</h2>
              </div>
            </div>
          </div>

          <div className='col-sm-5 col-lg-3 col-xl-3'>
            <div className='card board__main'>
              <div className='board__card'>
                <img src={ImperialEggAwarded} alt='' />
                <br />
                <br />
                <h1>Imperial Egg Awarded</h1>
                <h2>{TFGStats.imperialEggAwarded}</h2>
              </div>
            </div>
          </div>
          <div className='col-sm-5 col-lg-3 col-xl-3'>
            <div className='card board__main'>
              <div className='board__card'>
                <img src={RainbowEggAwarded} alt='' />
                <br />
                <br />
                <h1>Rainbow Egg Awarded</h1>
                <h2>{TFGStats.rainbowEggAwarded}</h2>
              </div>
            </div>
          </div>
          <div className='col-sm-5 col-lg-3 col-xl-3'>
            <div className='card board__main'>
              <div className='board__card'>
                <img src={PlatinumEggAwarded} alt='' />
                <br />
                <br />
                <h1>Platinum Egg Awarded</h1>
                <h2>{TFGStats.platinumEggAwarded}</h2>
              </div>
            </div>
          </div>
          <div className='col-sm-5 col-lg-3 col-xl-3'>
            <div className='card board__main'>
              <div className='board__card'>
                <img src={GoldenEggAwarded} alt='' />
                <br />
                <br />
                <h1>Golden Egg Awarded</h1>
                <h2>{TFGStats.goldenEggAwarded}</h2>
              </div>
            </div>
          </div>
          <div className='col-sm-5 col-lg-3 col-xl-3'>
            <div className='card board__main'>
              <div className='board__card'>
                <img src={SilverEggAwarded} alt='' />
                <br />
                <br />
                <h1>Silver Egg Awarded</h1>
                <h2>{TFGStats.silverEggAwarded}</h2>
              </div>
            </div>
          </div>
          <div className='col-sm-5 col-lg-3 col-xl-3'>
            <div className='card board__main'>
              <div className='board__card'>
                <img src={BronzeEggAwarded} alt='' />
                <br />
                <br />
                <h1>Bronze Egg Awarded</h1>
                <h2>{TFGStats.bronzeEggAwarded}</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default StatsBoard
