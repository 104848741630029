import React from 'react'

import EggShop from '../../components/EggShop'

const EggShopPage = (): JSX.Element => {
  return (
    <section className='egg-shop--section'>
      <div className='container'>
        <div className='egg-shop__main'>
          <div className='row justify-content-sm-center'>
            <div className='egg-shop__head'>
              <h1>EGG SHOP</h1>
            </div>
            <EggShop />
          </div>
        </div>
      </div>
    </section>
  )
}

export default EggShopPage
