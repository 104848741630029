import { useState } from 'react'
import { useAccount, useContractRead } from 'wagmi'

import { ethers } from 'ethers'
import ButtonContractWrite from '../ButtonContractWrite'

import { useContractABIs } from '../../hooks/useContractsABI'
import { useContracts } from '../../hooks/useContracts'

import ClaimEggImg from '../../assets/images/minting_animations/gathering_eggs_start.gif'

import HenLeavingHenHouseStart from '../../assets/images/minting_animations/hen_leaving_hen_house_start.gif'
import CoyoteLeavingTaxDenStart from '../../assets/images/minting_animations/coyote_leaving_tax_den_start.gif'
import RoosterLeavingGuardHouseStart from '../../assets/images/minting_animations/rooster_leaving_guard_house_start.gif'

interface PropTypes {
  stakedTokenList: any[]
  canUnstake?: boolean
  tokenKind?: string
}

const ButtonClaimEgg = (props: PropTypes): JSX.Element => {
  const { stakedTokenList, canUnstake = false, tokenKind } = props

  const ContractAddresses = useContracts()
  const ContractABIs = useContractABIs()

  const { address, isConnected } = useAccount()

  const [tokenUnclaimedEGGTotal, setTokenUnclaimedEGGTotal] = useState<any>()

  const henHouseCalcConfig = {
    address: ContractAddresses.HenHouseCalcContractAddress,
    abi: ContractABIs.HenHouseCalcABI,
  }

  useContractRead({
    ...henHouseCalcConfig,
    functionName: 'calculateAllRewards',
    args: [stakedTokenList],
    enabled: stakedTokenList && stakedTokenList.length > 0,
    overrides: { from: address },
    cacheTime: 30_000,
    watch: true,
    onSuccess(data) {
      if (data) {
        const unclaimedBalance = parseFloat(ethers.utils.formatEther(data))
        setTokenUnclaimedEGGTotal(Math.round(unclaimedBalance * 100) / 100)
      }
    },
    onError(error) {
      console.error('henHouse calculateAllRewards Error', error)
    },
  })

  let toClaimButtonTextInitial = `Claim ${tokenUnclaimedEGGTotal} EGG`

  let toClaimButtonTextPrepareIdle = `Check owed eggs`
  let toClaimButtonTextPrepareLoading = `Preparing`
  let toClaimButtonTextLoading = 'Waiting for approval'
  let toClaimButtonTextWriting = 'Gathering EGG 🪺...'
  let toClaimButtonTextError = `Couldn't gather any EGG`
  let toClaimStartAlertText = `Boxing up EGG!`
  let toClaimStartAlertOptions = { icon: '🥚' }
  let toClaimSuccessAlertText = `Your EGG has been delivered!`
  let toClaimSuccessAlertOptions = { icon: '🥚' }
  let toClaimImageWaitTx = ClaimEggImg

  if (tokenKind === 'HEN') {
    toClaimButtonTextPrepareIdle = `Check owed eggs`
    toClaimButtonTextPrepareLoading = `Preparing`
    toClaimButtonTextLoading = 'Waiting for approval'
    toClaimButtonTextWriting = 'Gathering EGG 🪺...'
    toClaimButtonTextError = `Couldn't gather any EGG`
    toClaimStartAlertText = `Boxing up EGG!`
    toClaimStartAlertOptions = { icon: '🥚' }
    toClaimSuccessAlertText = `Your EGG has been delivered!`
    toClaimSuccessAlertOptions = { icon: '🥚' }
    if (canUnstake) {
      toClaimButtonTextInitial = `Claim ${tokenUnclaimedEGGTotal} EGG & Leave Hen House`
      toClaimImageWaitTx = HenLeavingHenHouseStart
    }
  } else if (tokenKind === 'COYOTE') {
    toClaimButtonTextPrepareIdle = `Check owed eggs`
    toClaimButtonTextPrepareLoading = `Preparing`
    toClaimButtonTextLoading = 'Waiting for approval'
    toClaimButtonTextWriting = 'Gathering EGG 🪺...'
    toClaimButtonTextError = `Couldn't gather any EGG`
    toClaimStartAlertText = `Boxing up EGG!`
    toClaimStartAlertOptions = { icon: '🥚' }
    toClaimSuccessAlertText = `Your EGG has been delivered!`
    toClaimSuccessAlertOptions = { icon: '🥚' }
    if (canUnstake) {
      toClaimButtonTextInitial = `Claim ${tokenUnclaimedEGGTotal} EGG & Leave Tax Den`
      toClaimImageWaitTx = CoyoteLeavingTaxDenStart
    }
  } else if (tokenKind === 'ROOSTER') {
    toClaimButtonTextPrepareIdle = `Check owed eggs`
    toClaimButtonTextPrepareLoading = `Preparing`
    toClaimButtonTextLoading = 'Waiting for approval'
    toClaimButtonTextWriting = 'Gathering EGG 🪺...'
    toClaimButtonTextError = `Couldn't gather any EGG`
    toClaimStartAlertText = `Boxing up EGG!`
    toClaimStartAlertOptions = { icon: '🥚' }
    toClaimSuccessAlertText = `Your EGG has been delivered!`
    toClaimSuccessAlertOptions = { icon: '🥚' }
    if (canUnstake) {
      toClaimButtonTextInitial = `Claim ${tokenUnclaimedEGGTotal} EGG & Leave Guard duty`
      toClaimImageWaitTx = RoosterLeavingGuardHouseStart
    }
  }

  // console.log(`🚀 ~ toClaimImageWaitTx B`, toClaimImageWaitTx)
  // console.log(`🚀 ~ tokenKind B`, tokenKind)
  // console.log(`🚀 ~ canUnstake B`, canUnstake)
  // console.log(`🚀 ~ stakedTokenList B`, stakedTokenList)

  return (
    <>
      {/* {tokenUnclaimedEGGTotal > 0 && ( */}
      <ButtonContractWrite
        ContractAddress={ContractAddresses.HenHouseContractAddress}
        ContractABI={ContractABIs.HenHouseABI}
        ContractFunction='claimManyFromHenHouseAndDen'
        ContractArgs={[stakedTokenList, canUnstake]}
        ContractOverRides={{}}
        ContractIsEnabled={stakedTokenList.length > 0}
        ButtonTextPrepareIdle={toClaimButtonTextPrepareIdle}
        ButtonTextPrepareLoading={toClaimButtonTextPrepareLoading}
        ButtonTextInitial={toClaimButtonTextInitial}
        ButtonTextLoading={toClaimButtonTextLoading}
        ButtonTextWriting={toClaimButtonTextWriting}
        ButtonTextError={toClaimButtonTextError}
        StartAlertText={toClaimStartAlertText}
        StartAlertOptions={toClaimStartAlertOptions}
        SuccessAlertText={toClaimSuccessAlertText}
        SuccessAlertOptions={toClaimSuccessAlertOptions}
        imageWaitTx={toClaimImageWaitTx}
      />
      {/* )} */}
    </>
  )
}

export default ButtonClaimEgg
