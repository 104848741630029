import React from 'react'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const Whitepaper = () => {
  return (
    <>
      <section className='sub--hero'>
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-sm-10 col-lg-8 col-xl-5'>
              <div className='minting__card'>
                <div className='minting__text'>
                  <h1>MINTING</h1>
                  <p>There is a 10% chance of minting a Coyote</p>
                  <p>
                    If you mint a new elf with <span className='font'>$</span>EGGS, there is a 10% chance that your new
                    chicken or coyote us kidnapped by a staked Coyote
                  </p>
                  <p>Token Reveal will happen after 10000 NFTs are minted</p>
                </div>
                <div className='minting__list'>
                  <h2>Minting progression:</h2>
                  <ul>
                    <li>Gen 0: 1-500 Free + gas</li>
                    <li>
                      Gen 1: 501 to 10,000 = 0.07 <span className='font'>$</span>ETH + gas
                    </li>
                    <li>
                      Gen 2: 10,001 - 20,000 = 20,000 <span className='font'>$</span>EGG
                    </li>
                    <li>
                      Gen 3: 20,001 - 40,000 = 40,000 <span className='font'>$</span>EGG
                    </li>
                    <li>
                      Gen 4: 40,001 - 50,000 = 80,000 <span className='font'>$</span>EGG
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className='chain--section'>
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-lg-10'>
              <div className='sec__head'>
                <h1>Automatic On-chain Bonuses</h1>
              </div>
            </div>
            <div className='col-lg-11'>
              <div className='chain__card'>
                <div className='chain__list'>
                  <ul>
                    <li>
                      When Token # 10000 is minted a bonus of 1 ETH will be sent to previous holders{' '}
                      <span className='chain__span'>(1 ETH x 1 holders)</span>
                    </li>
                    <li>
                      When Token # 20000 is minted a bonus of 2 ETH will be sent to previous holders{' '}
                      <span className='chain__span'>(1 ETH x 2 holders)</span>
                    </li>
                    <li>
                      When Token # 30000 is minted a bonus of 3 ETH will be sent to previous holders{' '}
                      <span className='chain__span'>(1 ETH x 3 holders)</span>
                    </li>
                    <li>
                      When Token # 40000 is minted a bonus of 5 ETH will be sent to previous holders{' '}
                      <span className='chain__span'>(1 ETH x 5 holders)</span>
                    </li>
                    <li>
                      When Token # 50000 is minted a bonus of 15 ETH will be sent to previous holders{' '}
                      <span className='chain__span'>(5 ETH x 1 holder & 2 ETH x 5 holders)</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className='col-lg-10'>
              <div className='sec__head bottom__head'>
                <h1>Staking</h1>
                <h2>Staking will open after 10000 NFTs have been minted</h2>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className='chicken--table'>
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-lg-10'>
              <div className='sec__head'>
                <h1>Chickens</h1>
              </div>
            </div>
            <div className='col-lg-12'>
              <div className='table-responsive chicken'>
                <div className='table__text'>
                  <h1>Hens</h1>
                  <p>There is a 60% chance of minting a Hen</p>
                  <p>Each Hen has a production rank between 5-8</p>
                  <h2>Hen Action</h2>
                </div>
                <table className='table table-bordered table-hover'>
                  <thead className='thead-dark'>
                    <tr>
                      <th scope='col'>Action</th>
                      <th scope='col'>Description</th>
                      <th scope='col'>Risks</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Enter Chicken Coop (stake)</td>
                      <td>
                        Accumulate 10,000 <span className='font'>$</span>EGGS / day Prorated per second
                      </td>
                      <td>No risk</td>
                    </tr>
                    <tr>
                      <td>
                        Deliver <span className='font'>$</span>EGGS (claim)
                      </td>
                      <td>
                        Receive 80% of your <span className='font'>$</span>EGGS accumulated
                      </td>
                      <td>
                        Coyotes take 20% tax of <span className='font'>$</span>EGGS for not raiding the Chicken Coop.
                        Taxed $EGGS are split among all staked Coyotes proportional to their alpha rank
                      </td>
                    </tr>
                    <tr>
                      <td>Leave Chicken Coop (unstake)</td>
                      <td>
                        Hen leaves the Chicken Coop and claims all the $EGGS they produced. Hens can only leave the
                        Chicken Coop if they have made the quota of 20,000 <span className='font'>$</span>EGGS
                      </td>
                      <td>
                        50% Chance all your <span className='font'>$</span>EGGS is stolen by Coyotes. Stolen{' '}
                        <span className='font'>$</span>EGGS are split among all staked Coyotes proportional to their
                        alpha rank.
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className='rooster--table'>
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-lg-10'>
              <div className='sec__head'>
                <h1>Roosters</h1>
              </div>
            </div>
            <div className='col-lg-12'>
              <div className='table-responsive rooster'>
                <div className='table__text rooster__text'>
                  <p>There is a 30% chance of minting a Rooster</p>
                  <p>Each Rooster has a guard rank between 5-8</p>
                  <p>
                    Roosters earn 10% times more <span className='font'>$</span>EGG than hens, but have a 55% chance
                    their <span className='font'>$</span>EGG are stolen
                  </p>
                  <p>
                    Roosters can rescue dropped <span className='font'>$</span>EGGS
                  </p>
                  <h2>Rooster Actions</h2>
                </div>
                <table className='table table-bordered table-hover'>
                  <thead className='thead-dark thead-black'>
                    <tr>
                      <th className='text-black' scope='col'>
                        Action
                      </th>
                      <th scope='col'>Description</th>
                      <th scope='col'>Risks</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Guard Chicken Coop (stake)</td>
                      <td>
                        Accumulate 5,000 <span className='font'>$</span>EGGS / day Prorated per second
                      </td>
                      <td>No risk</td>
                    </tr>
                    <tr>
                      <td>
                        Claim <span className='font'>$</span>EGGS (claim)
                      </td>
                      <td>
                        Receive 90% of your <span className='font'>$</span>EGGS accumulated
                      </td>
                      <td>
                        Coyotes take 20% tax of <span className='font'>$</span>EGGS for not raiding the Chicken Coop.
                        Taxed <span className='font'>$</span>EGGS are split among all staked Coyotes proportional to
                        their alpha rank
                      </td>
                    </tr>
                    <tr>
                      <td>Leave Guard Duty (unstake)</td>
                      <td>
                        Roosters leaves the Chicken Coop and claims all the <span className='font'>$</span>EGGS they
                        protected. Roosters can only leave the Chicken Coop if they have finished their guard duty of 5
                        days.
                      </td>
                      <td>
                        20% Chance all your <span className='font'>$</span>EGGS is stolen by Coyotes. Stolen{' '}
                        <span className='font'>$</span>EGGS are split among all staked Coyotes proportional to their
                        alpha rank.
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className='Coyotes--table'>
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-lg-10'>
              <div className='sec__head'>
                <h1>Coyotes</h1>
              </div>
            </div>
            <div className='col-lg-12'>
              <div className='table-responsive Coyotes'>
                <div className='table__text Coyotes__text'>
                  <p>There is a 10% chance of minting a Coyote</p>
                  <p>Each Coyote has a alpha score ranging between 5-8</p>
                  <p>The higher the rank:</p>
                  <span className='green'>
                    The higher portion of <span className='font'>$</span>EGGS that Coyote earn from taxes
                  </span>
                  <span className='green'>The higher the chance of stealing a newly minted Chicken or Coyote</span>
                  <h2>Coyote Actions</h2>
                </div>
                <table className='table table-bordered table-hover'>
                  <thead className='thead-dark'>
                    <tr>
                      <th scope='col'>Action</th>
                      <th scope='col'>Description</th>
                      <th scope='col'>Risks</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Enter Tax Room (stake)</td>
                      <td>
                        Earn your share of 20% tax of all <span className='font'>$</span>EGGS generated by Hens &
                        Roosters
                      </td>
                      <td>No risk</td>
                    </tr>
                    <tr>
                      <td>
                        Claim Taxed <span className='font'>$</span>EGGS (claim)
                      </td>
                      <td>
                        Receive <span className='font'>$</span>EGGS accrued for that staked Coyote & stay in the Tax
                        Room
                      </td>
                      <td>
                        There is a 20% chance that Coyote drops 20% of their <span className='font'>$</span>EGGS
                      </td>
                    </tr>
                    <tr>
                      <td>Leave Tax Room (unstake)</td>
                      <td>
                        Receive <span className='font'>$</span>EGGS accrued for that staked Coyote & leave in the Tax
                        Room
                      </td>
                      <td>
                        There is a 20% chance that Coyote drops 50% of their <span className='font'>$</span>EGGS
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div className='table__text Coyotes__text'>
                  <h2>
                    Dropped <span className='font'>$</span>EGGS
                  </h2>
                  <p>
                    50% of dropped <span className='font'>$</span>EGGS are destroyed (burned)
                  </p>
                  <p>
                    50% of dropped <span className='font'>$</span>EGGS are sent to a randomly selected staked Rooster.
                  </p>
                  <span className='green'>Guard rank & gender affects randomness selection.</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className='chain--section tokenomics'>
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-lg-10'>
              <div className='sec__head'>
                <h1>
                  <span className='font'>$</span>EGGS tokenomics
                </h1>
              </div>
            </div>
            <div className='col-lg-10'>
              <div className='chain__card tokenomics__card'>
                <div className='chain__list'>
                  <ul>
                    <li>
                      Maximum supply of 5,000,000,000 <span className='font'>$</span>EGGS
                    </li>
                    <li>
                      When supply reaches 2,500,000,000 <span className='font'>$</span>EGGS earned for staking, the
                      Chicken Coop will stop making new <span className='font'>$</span>EGGS
                    </li>
                    <li>
                      Community expansion supply 2,000,000,000 <span className='font'>$</span>EGGS
                    </li>
                    <li>
                      Developers will receive 500,000,000 <span className='font'>$</span>EGGS
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Whitepaper
