import React from 'react'
import Accordion from 'react-bootstrap/Accordion'

import { FAQData } from '../../constants'
import './style.scss'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const FAQ = () => {
  return (
    <>
      <section id='faqs' className='faq--section'>
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-lg-12'>
              <div className='faq__head'>
                <h1>FAQs</h1>
              </div>
            </div>
            <div className='col-xl-8 col-lg-10'>
              <div id='main'>
                <Accordion className='accordion' id='faq'>
                  {FAQData.items.map((item, index) => (
                    <Accordion.Item eventKey={index.toString()} key={index.toString()} className='card'>
                      <div className='card-header btn btn-header-link' id={index.toString()}>
                        <Accordion.Header>{item.title}</Accordion.Header>
                      </div>
                      <Accordion.Body className='card-body'>
                        <div dangerouslySetInnerHTML={{ __html: item.description }}></div>
                      </Accordion.Body>
                    </Accordion.Item>
                  ))}
                </Accordion>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default FAQ
