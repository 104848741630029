import React, { useContext, useState } from 'react'

import { ethers } from 'ethers'

import { TFGSalesDataProps, SpecialMintsDataProps } from '../../types'

import { specialMintData } from '../../constants'
import ButtonContractWrite from '../ButtonContractWrite'

import { useAccount, useContractReads } from 'wagmi'
import { ConnectButton } from '@rainbow-me/rainbowkit'
import { useContractABIs } from '../../hooks/useContractsABI'
import { useContracts } from '../../hooks/useContracts'

interface PropType {
  TFGSalesData: TFGSalesDataProps
}

const SpecialMintRewards = (props: PropType): JSX.Element => {
  const { TFGSalesData } = props

  const { address, isConnected } = useAccount()

  const ContractAddresses = useContracts()
  const ContractABIs = useContractABIs()

  // Get users special mint info
  const [specialMintsDataInfo, setSpecialMintsDataInfo] = useState<SpecialMintsDataProps[]>()

  const specialMintContractConfig = {
    address: ContractAddresses.SpecialMintContractAddress,
    abi: ContractABIs.SpecialMintABI,
  }

  useContractReads({
    contracts: [
      { ...specialMintContractConfig, functionName: 'getSpecialMintInfo', args: [1] },
      { ...specialMintContractConfig, functionName: 'getSpecialMintInfo', args: [2] },
      { ...specialMintContractConfig, functionName: 'getSpecialMintInfo', args: [3] },
      { ...specialMintContractConfig, functionName: 'getSpecialMintInfo', args: [4] },
      { ...specialMintContractConfig, functionName: 'getSpecialMintInfo', args: [5] },
    ],
    watch: false,
    onSuccess(data) {
      // Unstaked
      if (data) {
        const specialMintInfo = []

        for (const item of data) {
          specialMintInfo.push({
            typeId: parseInt(item[0].toString()),
            specialMintFee: parseFloat(ethers.utils.formatUnits(item[9])),
            maxSupply: parseInt(item[10].toString()),
            minted: parseInt(item[11].toString()),
          })
        }
        setSpecialMintsDataInfo(specialMintInfo)
      }
    },
    onError(err) {
      console.log(`🚀 ~ getSpecialMintInfo err`, err)
    },
  })

  if (!specialMintsDataInfo || !TFGSalesData) {
    return (
      <div className='loading--section'>
        <h1>Special Mints Loading...</h1>
      </div>
    )
  }

  return (
    <section className='reward--section'>
      <div className='container'>
        <div className='sec__head'>
          <h1>Special Mint Rewards</h1>
        </div>

        <div className='row justify-content-center'>
          {specialMintData.map((item, index) => {
            return (
              <div className='col-sm-5 col-lg-4' key={index}>
                <div className='reward__card'>
                  <div className='reward__image'>
                    <img src={item.img} alt='' />
                  </div>
                  <div className='reward__list'>
                    <div className='list__card'>
                      <ul>
                        {item.list.map((listItem, listIndex) => {
                          return <li key={listIndex}>{listItem}</li>
                        })}
                      </ul>
                      {TFGSalesData.saleStatus !== 'paused' && TFGSalesData.saleStatus !== 'soon' && (
                        <>
                          {' '}
                          <p>
                            <span>
                              {specialMintsDataInfo && specialMintsDataInfo[index]
                                ? specialMintsDataInfo[index].specialMintFee
                                : 0}{' '}
                              ETH
                            </span>
                          </p>
                          <span className='brown'>
                            only{' '}
                            {specialMintsDataInfo && specialMintsDataInfo[index]
                              ? specialMintsDataInfo[index].maxSupply - specialMintsDataInfo[index].minted
                              : 0}{' '}
                            remaining
                          </span>
                        </>
                      )}
                      {isConnected && TFGSalesData.saleStatus !== 'paused' && TFGSalesData?.saleStatus !== 'soon' ? (
                        <>
                          {specialMintsDataInfo[index].maxSupply > specialMintsDataInfo[index].minted ? (
                            <div className='btn__card'>
                              <ButtonContractWrite
                                ContractAddress={ContractAddresses.SpecialMintContractAddress}
                                ContractABI={ContractABIs.SpecialMintABI}
                                ContractFunction='mint'
                                ContractArgs={[specialMintsDataInfo[index].typeId, address]}
                                ContractOverRides={{
                                  value: ethers.utils.parseEther(specialMintsDataInfo[index].specialMintFee.toString()),
                                }}
                                ContractIsEnabled={
                                  TFGSalesData?.saleStatus !== 'paused' && TFGSalesData?.saleStatus !== 'soon'
                                }
                                ButtonTextPrepareIdle={`Check for wrapping paper`}
                                ButtonTextPrepareLoading={`Preparing`}
                                ButtonTextInitial={item.btn}
                                ButtonTextLoading={'Waiting for approval'}
                                ButtonTextWriting={'Gathering rewards...'}
                                ButtonTextError={"Couldn't wrap the reward"}
                                StartAlertText={'Wrapping rewards!'}
                                StartAlertOptions={{ icon: '🎁' }}
                                SuccessAlertText={'Your reward are being delivered!'}
                                SuccessAlertOptions={{ icon: '🎁' }}
                              />
                            </div>
                          ) : (
                            <h3>Out of stock</h3>
                          )}
                        </>
                      ) : (
                        <>
                          {!isConnected ? (
                            <div className='btn__card btn__anchor'>
                              <ConnectButton chainStatus='none' accountStatus='avatar' showBalance={false} />
                            </div>
                          ) : (
                            <h3>Not minting right now</h3>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </section>
  )
}

export default SpecialMintRewards
