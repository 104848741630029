import React, { useState, useEffect } from 'react'

import { useAccount, useBalance, useContractRead, useContractReads } from 'wagmi'

import { TFGSupplyProps } from '../../types'

import NFTCardList from '../NFTCardList'

import { useQuery } from 'urql'
import ButtonContractWrite from '../ButtonContractWrite'

import { ethers } from 'ethers'
import { parseEthersError } from '../../hooks/parseEthersError'
import { useContractABIs } from '../../hooks/useContractsABI'
import { useContracts } from '../../hooks/useContracts'

//TODO: Replace with Silver EGG breaking image
import RancherScramblingEggsImage from '../../assets/images/minting_animations/rancher_scrambling_eggs.gif'

const Inventory = (): JSX.Element => {
  const ContractAddresses = useContracts()
  const ContractABIs = useContractABIs()

  const { address } = useAccount()

  const [userEGGBalance, setUserEGGBalance] = useState('0')

  const theFarmGameMintConfig = {
    address: ContractAddresses.TheFarmGameMintContractAddress,
    abi: ContractABIs.TheFarmGameMintABI,
  }
  const farmAnimalsConfig = {
    address: ContractAddresses.FarmAnimalsContractAddress,
    abi: ContractABIs.FarmAnimalsABI,
  }
  const henHouseConfig = {
    address: ContractAddresses.HenHouseContractAddress,
    abi: ContractABIs.HenHouseABI,
  }

  useBalance({
    addressOrName: address,
    token: ContractAddresses.EGGTokenContractAddress,
    watch: false,
    onSuccess(data) {
      setUserEGGBalance(data.formatted)
    },
    onError(err) {},
  })

  const [userTotalOwned, setUserTotalOwned] = useState(0)

  useContractReads({
    contracts: [
      { ...farmAnimalsConfig, functionName: 'balanceOf', args: [address?.toLowerCase()] },
      { ...henHouseConfig, functionName: 'getStakedByAddress', args: [address?.toLowerCase()] },
    ],
    watch: true,
    onSuccess(data) {
      if (data) {
        // Unstaked
        const unStakedBalance = parseInt(data[0].toString())

        // Staked
        const stakedBalance = data[1].length
        setUserTotalOwned(unStakedBalance + stakedBalance)
      }
    },
  })

  const [silverEggFee, setSilverEggFee] = useState<number>(0)

  const { data: contractReadData } = useContractRead({
    ...theFarmGameMintConfig,
    functionName: 'currentPriceToMint',

    cacheTime: 30_000,
    watch: false,
    onSuccess(data) {
      if (data) {
        setSilverEggFee(parseFloat(ethers.utils.formatEther(data)))
        // const unclaimedBalance = parseFloat(ethers.utils.formatEther(data))
        // setTokenUnclaimedEGGTotal(
        //   Math.round(unclaimedBalance * 100) / 100,
        //   // numbro(unclaimedBalance).format({
        //   //   average: true,
        //   //   mantissa: 3,
        //   // }),
        // )
      }
    },
    onError(error) {
      console.error('inventory mintCost Error', error)
    },
  })

  // Get users farmAnimals balance
  const [walletUnstakedTokenIds, setWalletUnstakedTokenIds] = useState<number[]>([])
  const [walletStakedTokenIds, setWalletStakedTokenIds] = useState<number[]>([])
  const [walletStakedTokenTimestamp, setWalletStakedTokenTimestamp] = useState<number[]>([])
  const [walletUnstakeTokenTimestamp, setWalletUnstakeTokenTimestamp] = useState<number[]>([])

  const [walletEggShopTokenIds, setWalletEggShopTokenIds] = useState<number[]>([])
  const [walletEggShopTokenBalances, setWalletEggShopTokenBalances] = useState<number[]>([])

  const [walletImperialEggTokenIds, setWalletImperialEggTokenIds] = useState<number[]>([])
  const [tfgSupplyData, setTfgSupplyData] = useState<TFGSupplyProps>()

  const GetUserTokens = `
		query GetUserTokens(
			$accountAddress: String!,
			$farmAnimalsContract: String!,
			$imperialEggsContract: String!,
			$eggShopContract: String!
			) @live(interval: 20000) {
			account(id: $accountAddress) {
				farmAnimals: nftTokens(where: {contract: $farmAnimalsContract}) {
					id
					token {
						id
						tokenId
						kind
					}
				}
				eggShop: nftTokens(where: {contract: $eggShopContract}) {
					id
					quantity
					token {
						id
						tokenId
					}
				}
				imperialEggs: nftTokens(where: {contract: $imperialEggsContract}) {
					id
					token {
						id
						tokenId
					}
				}
				stakedTokens(orderBy: kind) {
					id
					tokenId
					kind
					stakedTimestamp
					unstakeTimestamp
				}
			}
			tfgSupply: nfttokenContract(id: $farmAnimalsContract) {
				maxGen0Supply
				maxSupply
				totalSupply
			}
		}
	`

  // useQuery urql

  const [result, reexecuteQuery] = useQuery({
    query: GetUserTokens,
    variables: {
      accountAddress: address?.toLowerCase(),
      imperialEggsContract: ContractAddresses.ImperialEggsContractAddress.toLowerCase(),
      farmAnimalsContract: ContractAddresses.FarmAnimalsContractAddress.toLowerCase(),
      eggShopContract: ContractAddresses.EggShopContractAddress.toLowerCase(),
    },
    // pause: loadingCompleted,
  })

  const { data: urqlData, fetching: urqlFetching, error: urqlError } = result

  useEffect(() => {
    if (result.fetching) return

    // Set up to refetch in 20 seconds, if the query is idle
    const timerId = setTimeout(() => {
      reexecuteQuery({ requestPolicy: 'network-only' })
    }, 20000)
    console.log(`🚀 ~ timerId`, timerId)

    return () => clearTimeout(timerId)
  }, [result.fetching, reexecuteQuery])

  useEffect(() => {
    if (urqlData) {
      if (urqlData.account && urqlData.account.farmAnimals) {
        const tokenIds: any[] = []
        urqlData.account.farmAnimals.forEach((token: any) => {
          tokenIds.push(token.token.tokenId)
        })
        setWalletUnstakedTokenIds(tokenIds)
      }
      if (urqlData.account && urqlData.account.eggShop) {
        const tokenIds: any[] = []
        const balances: any[] = []
        urqlData.account.eggShop.forEach((token: any) => {
          tokenIds.push(token.token.tokenId)
          balances.push(token.quantity)
        })

        setWalletEggShopTokenIds(tokenIds)
        setWalletEggShopTokenBalances(balances)
      }
      if (urqlData.account && urqlData.account.imperialEggs) {
        const tokenIds: any[] = []
        urqlData.account.imperialEggs.forEach((token: any) => {
          tokenIds.push(token.token.tokenId)
        })
        setWalletImperialEggTokenIds(tokenIds)
      }
      if (urqlData.account && urqlData.account.stakedTokens) {
        const tokenIds: any[] = []
        const stakedTimestamps: any[] = []
        const unstakeTimestamps: any[] = []
        urqlData.account.stakedTokens.forEach((token: any) => {
          tokenIds.push(token.tokenId)
          stakedTimestamps.push(token.stakedTimestamp)
          unstakeTimestamps.push(token.unstakeTimestamp)
        })
        setWalletStakedTokenIds(tokenIds)
        setWalletStakedTokenTimestamp(stakedTimestamps)
        setWalletUnstakeTokenTimestamp(unstakeTimestamps)
      }

      if (urqlData.tfgSupply) {
        const data = urqlData.tfgSupply

        setTfgSupplyData({
          maxSupply: data.maxSupply,
          maxGen0Supply: data.maxGen0Supply,
          totalSupply: data.totalSupply,
        })
      }
    }
  }, [urqlData])

  if (urqlFetching) {
    return (
      <div className='loading--section'>
        <h1>Fetching inventory...</h1>
      </div>
    )
  }
  if (urqlError) {
    return (
      <div className='loading--section'>
        <h1>Error fetching inventory...</h1>
      </div>
    )
  }

  return (
    <>
      <div style={{ marginBottom: '20px' }} className='col-xl-11 col-lg-12 border_full'>
        {userTotalOwned > walletUnstakedTokenIds.length + walletStakedTokenIds.length && (
          <>
            <h1>You have: {userTotalOwned} Farm Animals</h1>
            <h1>
              Still indexing: {walletUnstakedTokenIds.length + walletStakedTokenIds.length} of {userTotalOwned}{' '}
            </h1>
          </>
        )}
        {walletUnstakedTokenIds.length > 0 || walletStakedTokenIds.length > 0 ? (
          <>
            <h1>Farm Animals: {walletUnstakedTokenIds.length + walletStakedTokenIds.length}</h1>
            <NFTCardList
              key={'inventory-unstaked'}
              name={`In the wild: ${walletUnstakedTokenIds.length}`}
              ContractAddress={ContractAddresses.FarmAnimalsContractAddress}
              ContractABI={ContractABIs.FarmAnimalsABI}
              ContractType='ERC721'
              tokenList={walletUnstakedTokenIds}
              isStakeable={true}
              showClaimSelect={false}
            />
            <NFTCardList
              key={'inventory-staked'}
              name={`In the Houses: ${walletStakedTokenIds.length}`}
              ContractAddress={ContractAddresses.FarmAnimalsContractAddress}
              ContractABI={ContractABIs.FarmAnimalsABI}
              ContractType='ERC721'
              tokenList={walletStakedTokenIds}
              isStaked={true}
              stakedTimestampList={walletStakedTokenTimestamp}
              unstakeTimestampList={walletUnstakeTokenTimestamp}
              stakedContractAddress={ContractAddresses.HenHouseCalcContractAddress}
              stakedContractABI={ContractABIs.HenHouseCalcABI}
              showClaimSelect={false}
              showUnstakeSelect={false}
              showClaimBalance={false}
            />
          </>
        ) : (
          <>
            <h1>Farm Animals</h1>
            <h3>Looks like you haven't minted any Farm Animals</h3>
          </>
        )}
      </div>
      <div style={{ marginBottom: '20px' }} className='col-xl-11 col-lg-12 border_full'>
        {walletEggShopTokenIds.length > 0 ? (
          <>
            <h1>Egg Shop Items: {walletEggShopTokenIds.length}</h1>
            <NFTCardList
              key={'inventory-eggShopItems'}
              ContractAddress={ContractAddresses.EggShopContractAddress}
              ContractABI={ContractABIs.EggShopABI}
              ContractType='ERC1155'
              tokenList={walletEggShopTokenIds}
              balanceList={walletEggShopTokenBalances}
            />
          </>
        ) : (
          <>
            <h1>Egg Shop Items</h1>
            <h3>Looks like you don't have any Egg Shop Items</h3>
          </>
        )}
        {tfgSupplyData &&
          tfgSupplyData?.totalSupply > tfgSupplyData?.maxGen0Supply &&
          parseFloat(userEGGBalance) > parseFloat(silverEggFee.toString()) && (
            <ButtonContractWrite
              ContractAddress={ContractAddresses.TheFarmGamePlayContractAddress}
              ContractABI={ContractABIs.TheFarmGamePlayABI}
              ContractFunction='scrambleEGG'
              ContractArgs={[ethers.utils.parseEther(silverEggFee.toString())]}
              ContractOverRides={{}}
              ContractIsEnabled={true}
              ButtonTextPrepareIdle={`Polishing the silver`}
              ButtonTextPrepareLoading={`Preparing`}
              ButtonTextInitial={'Buy Silver Egg to upgrade a Hens Production'}
              ButtonTextLoading={'Waiting for approval'}
              ButtonTextWriting={'Preparing the ritual...'}
              ButtonTextError={"Couldn't acquire the silver egg"}
              StartAlertText={'Smelting the Silver'}
              StartAlertOptions={{ icon: '🏭' }}
              SuccessAlertText={'Silver EGG is being delivered!'}
              SuccessAlertOptions={{ icon: '🥚' }}
              imageWaitTx={RancherScramblingEggsImage}
            />
          )}
      </div>

      <div style={{ marginBottom: '20px' }} className='col-xl-11 col-lg-12 border_full'>
        {walletImperialEggTokenIds.length > 0 ? (
          <>
            <h1>Imperial Eggs: {walletImperialEggTokenIds.length}</h1>
            <NFTCardList
              key={'inventory-imperialEggs'}
              ContractAddress={ContractAddresses.ImperialEggsContractAddress}
              ContractABI={ContractABIs.ImperialEggsABI}
              ContractType='ERC721'
              tokenList={walletImperialEggTokenIds}
            />
          </>
        ) : (
          <>
            <h1>Imperial Eggs</h1>
            <h3>Looks like you don't have any Imperial Eggs</h3>
          </>
        )}
      </div>
    </>
  )
}

export default Inventory
