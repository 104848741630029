import React from 'react'

import { DisplayInfoType } from '../../types'

import { usePrepareLogOrder } from '../../hooks/usePrepareLogOrder'

import { Carousel, Modal } from 'react-bootstrap'
import RightArrow from '../../assets/images/arrows/right_arrow.png'
import LeftArrow from '../../assets/images/arrows/left_arrow.png'

import './styles.scss'
import { useAccount } from 'wagmi'
import { useContracts } from '../../hooks/useContracts'

interface PropTypes {
  showModal: boolean
  handelModalDisplay?: any
  rawLogs: any
  imageWaitTx?: string
}

const TransactionModal = (props: PropTypes): JSX.Element => {
  const { showModal, handelModalDisplay, rawLogs, imageWaitTx } = props

  const ContractAddresses = useContracts()
  const handleClose = () => handelModalDisplay(false)
  const { data, isPending, error } = usePrepareLogOrder(rawLogs, showModal, imageWaitTx)

  const { address } = useAccount()

  return (
    <Modal show={showModal} onHide={handleClose}>
      {/* <Modal.Header closeButton>
        <Modal.Title className='brown'>Pending TX</Modal.Title>
      </Modal.Header> */}
      <Modal.Body className='brown show-grid'>
        {imageWaitTx && isPending ? (
          <div className='tx__card'>
            <img src={imageWaitTx} className='img-fluid' alt='' />
            <br />
            <h3>Awaiting delivery, may take a moment...</h3>
          </div>
        ) : (
          // <p>test</p>
          <Carousel
            slide={false}
            variant='dark'
            interval={null}
            wrap={false}
            indicators={false}
            nextIcon={<img src={RightArrow} alt='next' />}
            prevIcon={<img src={LeftArrow} alt='next' />}
          >
            {data &&
              data.map((item, index) =>
                item.map((event: DisplayInfoType, index) => (
                  <Carousel.Item key={index}>
                    <h3>Token ID: {event.tokenId}</h3>
                    <img className='d-block w-100 img-fluid' src={event.image} alt={event.title} />
                    {event.title}
                    {event.tokenId && <p>Token ID: {event.tokenId}</p>}
                    {/* {event.kind && <p>Kind: {event.kind}</p>} */}
                    {event.note && <p>{event.note}</p>}
                    {event.recipient && (
                      <>
                        {address !== event.recipient ? (
                          <>
                            {address === ContractAddresses.HenHouseContractAddress ? (
                              <p>Safely in the Hen House</p>
                            ) : (
                              <p>Now belongs to: {event.recipient}</p>
                            )}
                          </>
                        ) : (
                          <p>You: {event.recipient}</p>
                        )}
                      </>
                    )}

                    {event.value && <p>Value: {event.value}</p>}
                  </Carousel.Item>
                )),
              )}
          </Carousel>
        )}

        <p />
      </Modal.Body>
      {/* <Modal.Footer>
        <Button variant='secondary' onClick={handleClose} disabled={!isPending}>
          Close
        </Button>
      </Modal.Footer> */}
    </Modal>
  )
}

export default TransactionModal
