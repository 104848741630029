import React, { useEffect, useState } from 'react'

import { EggShopItemDataProps, EggShopTokenTypeProps, metadataType, TFGSupplyProps } from '../../types'

import { useAccount } from 'wagmi'
// import Base64ToJson from '../../utils/Base64ToJson'

import { Badge, Col, Container, Form, Modal, Row } from 'react-bootstrap'

import './styles.scss'

import ButtonContractWrite from '../ButtonContractWrite'

import moment from 'moment'

import { useQuery } from 'urql'
import Base64ToJson from '../../utils/Base64ToJson'

import ButtonClaimEgg from '../ButtonClaimEgg/ButtonClaimEgg'
import { useContractABIs } from '../../hooks/useContractsABI'
import { useContracts } from '../../hooks/useContracts'

import HenSacrificedStart from '../../assets/images/minting_animations/hen_sacrificed_start.gif'

// FIXME Hen is just a place holder image
import HenMoveToHenHouse from '../../assets/images/minting_animations/hen_move_to_hen_house.gif'
import CoyoteMoveToTaxDenStart from '../../assets/images/minting_animations/coyote_move_to_tax_den_start.gif'
import RoosterMoveToGuardHouse from '../../assets/images/minting_animations/rooster_move_to_guard_house.gif'

import RainbowEggBreakingStart from '../../assets/images/minting_animations/rainbow_egg_breaking_start.gif'

interface PropTypes {
  showModal: boolean
  tokenId: number
  tokenMetadata: metadataType
  isStakeable?: boolean
  isStaked?: boolean
  stakedTimestamp?: any
  unstakeTimestamp?: any
  stakedContractAddress?: string
  stakedContractABI?: any
  canUnstake?: boolean
  handleSelect?: any
  handelModalDisplay: any
  tokenKind?: string
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const FarmAnimalNFTModal = (props: PropTypes): JSX.Element => {
  const {
    showModal,
    tokenId,
    tokenMetadata,
    isStakeable,
    isStaked,
    stakedTimestamp,
    unstakeTimestamp,
    stakedContractAddress,
    stakedContractABI,
    canUnstake,
    handelModalDisplay,
    tokenKind,
  } = props

  const { address } = useAccount()

  const ContractAddresses = useContracts()
  const ContractABIs = useContractABIs()

  const [changeNameFee, setChangeNameFee] = useState<number>()
  const [changeDescFee, setChangeDescFee] = useState<number>()
  const [changeBGFee, setChangeBGFee] = useState<number>()
  const [tfgSupplyData, setTfgSupplyData] = useState<TFGSupplyProps>()

  const sacrificeHenFee = '0'

  const [newName, setNewName] = useState('')
  const handleName = (event: string) => {
    if (event.length > 3) {
      setNewName(event)
      // setLoadingCompleted(false)
    }
  }

  const [newDesc, setNewDesc] = useState('')
  const handleDesc = (event: string) => {
    if (event.length > 3) {
      setNewDesc(event)
      // setLoadingCompleted(false)
    }
  }

  const [newBGColor, setNewBGColor] = useState('')
  const handleBGColor = (event: string) => {
    if (event.length === 6) {
      setNewBGColor(event)
      // setLoadingCompleted(false)
    }
  }

  const handleClose = () => handelModalDisplay(false)
  const handleTweet = () => {
    const tweetShareURI = 'https://twitter.com/intent/tweet?'
    const hashtags = 'hashtags=TFG,theFarmGame,nft'
    const originalReferer = '&original_referer=https%3A%2F%2Fthefarm.game%2F'
    const imageURI = ''
    // const other = '&ref_src=twsrc%5Etfw%7Ctwcamp%5Ebuttonembed%7Ctwterm%5Eshare%7Ctwgr%5E&related=twitterapi%2Ctwitter'
    const message = `&text=Check%20out%20my%20${tokenMetadata?.name}%20on%20The%20Farm%20Game&url=https%3A%2F%2Fopensea.io/assets/ethereum/${ContractAddresses.FarmAnimalsContractAddress}/${tokenId}`
    const via = '&via=the_farm_game'

    const fullURI = tweetShareURI + hashtags + originalReferer + message + imageURI + via

    const win = window.open(fullURI, '_blank')
    win?.focus()
  }

  const [userRainbowEggBalance, setUserRainbowEggBalance] = useState(0)

  const [userSilverEggBalance, setUserSilverEggBalance] = useState(0)

  const [userRainbowInfo, setUserRainbowEggInfo] = useState<EggShopItemDataProps>()

  const urqlQuery = `
		query MyQuery($accountAddress: String, $applePieUserTokenId: String, $rainbowEggTokenId: String, $rainbowEggUserTokenId: String, $silverEggUserTokenId: String, $farmAnimalsContract: String) {
			tfgdata(id: "season-1") {
				changeBGColorFee
				changeDescFee
				changeNameFee
			}
			account(id: $accountAddress) {
				applePie: nftTokens(where: {id: $applePieUserTokenId}) {
					id
					quantity
					token {
						name
					}
				}
				rainbowEgg: nftTokens(where: {id: $rainbowEggUserTokenId}) {
					id
					quantity
					token {
						name
					}
				}
				silverEgg: nftTokens(where: {id: $silverEggUserTokenId}) {
					id
					quantity
					token {
						name
					}
				}
			}
			rainbowEgg: nfttoken(id: $rainbowEggTokenId) {
				id
				name
				kind
				maxSupply
				mints
				burns
				eggMintAmt
				eggBurnAmt
			}
			tfgSupply: nfttokenContract(id: $farmAnimalsContract) {
				name
				symbol
				maxGen0Supply
				maxSupply
				totalSupply
			}
		}
	`

  // useQuery urql

  const [result] = useQuery({
    query: urqlQuery,
    variables: {
      accountAddress: address?.toLowerCase(),
      rainbowEggTokenId: `${ContractAddresses.EggShopContractAddress.toLowerCase()}_6`,
      rainbowEggUserTokenId: `${address?.toLowerCase()}:${ContractAddresses.EggShopContractAddress.toLowerCase()}_6`,
      silverEggUserTokenId: `${address?.toLowerCase()}:${ContractAddresses.EggShopContractAddress.toLowerCase()}_7`,
      farmAnimalsContract: ContractAddresses.FarmAnimalsContractAddress.toLowerCase(),
    },
    // pause: loadingCompleted,
  })

  const { data: urqlData, fetching: urqlFetching, error: urqlError } = result

  useEffect(() => {
    if (urqlData) {
      if (urqlData.account && urqlData.account.rainbowEgg.length > 0 && urqlData.account.rainbowEgg[0].quantity) {
        setUserRainbowEggBalance(urqlData.account.rainbowEgg[0].quantity)
      }

      if (urqlData.account && urqlData.account.silverEgg.length > 0 && urqlData.account.silverEgg[0].quantity) {
        setUserSilverEggBalance(urqlData.account.silverEgg[0].quantity)
      }

      if (urqlData.rainbowEgg && urqlData.rainbowEgg.length > 0) {
        const rainbowEggData = urqlData.rainbowEgg
        const rainbowEggMetadata: EggShopTokenTypeProps = Base64ToJson(rainbowEggData.tokenURI)
        setUserRainbowEggInfo({
          name: 'Rainbow Egg',
          mints: rainbowEggData.mints,
          burns: rainbowEggData.burns,
          maxSupply: rainbowEggData.maxSupply,
          eggMintAmt: parseFloat(rainbowEggData.eggMintAmt),
          eggBurnAmt: parseFloat(rainbowEggData.eggBurnAmt),
          background: '',
          metadata: rainbowEggMetadata,
          image: rainbowEggMetadata.image,
        })
      }

      if (urqlData.tfgdata) {
        const data = urqlData.tfgdata
        setChangeNameFee(parseFloat(data.changeNameFee))
        setChangeDescFee(parseFloat(data.changeDescFee))
        setChangeBGFee(parseFloat(data.changeBGColorFee))
      }
      if (urqlData.tfgSupply) {
        const data = urqlData.tfgSupply

        setTfgSupplyData({
          maxSupply: data.maxSupply,
          maxGen0Supply: data.maxGen0Supply,
          totalSupply: data.totalSupply,
        })
      }
    }
  }, [urqlData])

  if (!tokenMetadata) {
    return (
      <div className='chick__card col-2 pb-4' key={tokenId}>
        <div className='tmb'>
          <h5>error #{tokenId}...</h5>
        </div>
      </div>
    )
  }

  let toStakeButtonTextPrepareIdle = `Check Rooms`
  let toStakeButtonTextPrepareLoading = `Preparing`
  let toStakeButtonTextInitial = 'Stake to Earn EGG'
  let toStakeButtonTextLoading = 'Waiting for approval'
  let toStakeButtonTextWriting = 'Packing clothes...'
  let toStakeButtonTextError = `Couldn't stake`
  let toStakeStartAlertText = `Moving In!`
  let toStakeStartAlertOptions = { icon: '🚚' }
  let toStakeSuccessAlertText = `Settling in the House!`
  let toStakeSuccessAlertOptions = { icon: '🏠' }
  let toStakeImageWaitTx = ''

  if (tokenKind === 'HEN') {
    toStakeButtonTextPrepareIdle = `Checking Rooms`
    toStakeButtonTextPrepareLoading = `Preparing nest`
    toStakeButtonTextInitial = 'Stake to Earn EGG'
    toStakeButtonTextLoading = 'Waiting for approval'
    toStakeButtonTextWriting = 'Packing clothes...'
    toStakeButtonTextError = `Couldn't stake`
    toStakeStartAlertText = `Moving In!`
    toStakeStartAlertOptions = { icon: '🚚' }
    toStakeSuccessAlertText = `Settling in the Hen House!`
    toStakeSuccessAlertOptions = { icon: '🏠' }
    toStakeImageWaitTx = HenMoveToHenHouse
  } else if (tokenKind === 'COYOTE') {
    toStakeButtonTextPrepareIdle = `Check for EGG`
    toStakeButtonTextPrepareLoading = `Preparing`
    toStakeButtonTextInitial = 'Stake to Earn EGG Tax'
    toStakeButtonTextLoading = 'Waiting for approval'
    toStakeButtonTextWriting = 'Packing clothes...'
    toStakeButtonTextError = `Couldn't stake`
    toStakeStartAlertText = `Moving In!`
    toStakeStartAlertOptions = { icon: '🚚' }
    toStakeSuccessAlertText = `Settling in the Tax Den!`
    toStakeSuccessAlertOptions = { icon: '🏠' }
    toStakeImageWaitTx = CoyoteMoveToTaxDenStart
  } else if (tokenKind === 'ROOSTER') {
    toStakeButtonTextPrepareIdle = `Check for EGG`
    toStakeButtonTextPrepareLoading = `Preparing`
    toStakeButtonTextInitial = 'Stake to Earn EGG'
    toStakeButtonTextLoading = 'Waiting for approval'
    toStakeButtonTextWriting = 'Packing clothes...'
    toStakeButtonTextError = `Couldn't stake`
    toStakeStartAlertText = `Moving In!`
    toStakeStartAlertOptions = { icon: '🚚' }
    toStakeSuccessAlertText = `Settling in the Guard House!`
    toStakeSuccessAlertOptions = { icon: '🏠' }
    toStakeImageWaitTx = RoosterMoveToGuardHouse
  }

  // console.log(`🚀 ~ toStakeImageWaitTx A`, toStakeImageWaitTx)
  // console.log(`🚀 ~ tokenKind A`, tokenKind)

  // console.log(`🚀 ~ canUnstake A`, canUnstake)
  // console.log(`🚀 ~ tokenMetadata A`, tokenMetadata.tokenId)

  return (
    <Modal show={showModal} onHide={handleClose} size='lg'>
      <Modal.Header closeButton>
        <Modal.Title className='brown'>{tokenMetadata?.name}</Modal.Title>
      </Modal.Header>
      <Modal.Body className='brown show-grid'>
        <Container className='p-4'>
          <Row>
            <Col xs={12} md={8}>
              {tokenMetadata && (
                <img src={tokenMetadata.image} alt={tokenMetadata.name} style={{ alignContent: 'center' }} />
              )}
            </Col>
            <Col xs={6} md={4}>
              {/* {tokenMetadata.background_color && <p style={{ fontSize: 14 }}>Background: {tokenMetadata.background_color}</p>} */}
              {tokenMetadata?.attributes &&
                tokenMetadata?.attributes?.map((attribute, index) => {
                  return (
                    <>
                      <Badge bg='primary' key={index} style={{ fontSize: '15px', margin: '2px' }}>
                        {attribute.trait_type} - {attribute.value}
                      </Badge>
                    </>
                  )
                })}
            </Col>
          </Row>
          <Row>
            <Col>
              {tokenMetadata.description && <p style={{ fontSize: 14 }}>Description: {tokenMetadata.description}</p>}
            </Col>
          </Row>
        </Container>
        <p />
        {isStaked && stakedContractAddress ? (
          <>
            <br />
            <ButtonClaimEgg stakedTokenList={[tokenId]} />

            {canUnstake ? (
              <ButtonClaimEgg stakedTokenList={[tokenId]} canUnstake={true} tokenKind={tokenKind} />
            ) : (
              <p style={{ fontSize: 20 }}>
                Unstake after:
                <br /> {moment.unix(unstakeTimestamp).format('MMMM Do YYYY, h:mm:ss a')}
              </p>
            )}
          </>
        ) : (
          <>
            {isStakeable && (
              <ButtonContractWrite
                ContractAddress={ContractAddresses.HenHouseContractAddress}
                ContractABI={ContractABIs.HenHouseABI}
                ContractFunction='addManyToHenHouse'
                ContractArgs={[address, [tokenId]]}
                ContractOverRides={{}}
                ContractIsEnabled={true}
                ButtonTextPrepareIdle={toStakeButtonTextPrepareIdle}
                ButtonTextPrepareLoading={toStakeButtonTextPrepareLoading}
                ButtonTextInitial={toStakeButtonTextInitial}
                ButtonTextLoading={toStakeButtonTextLoading}
                ButtonTextWriting={toStakeButtonTextWriting}
                ButtonTextError={toStakeButtonTextError}
                StartAlertText={toStakeStartAlertText}
                StartAlertOptions={toStakeStartAlertOptions}
                SuccessAlertText={toStakeSuccessAlertText}
                SuccessAlertOptions={toStakeSuccessAlertOptions}
                imageWaitTx={toStakeImageWaitTx}
              />
            )}
          </>
        )}
        {tokenKind === 'HEN' && isStakeable && (
          <>
            <ButtonContractWrite
              ContractAddress={ContractAddresses.TheFarmGamePlayContractAddress}
              ContractABI={ContractABIs.TheFarmGamePlayABI}
              ContractFunction='sacrifice'
              ContractArgs={[tokenId]}
              ContractOverRides={{}}
              ContractIsEnabled={true}
              ButtonTextPrepareIdle={`Checking the moon phases`}
              ButtonTextPrepareLoading={`Preparing`}
              ButtonTextInitial={'Sacrifice for Rainbow Egg'}
              ButtonTextLoading={'Waiting for approval'}
              ButtonTextWriting={'Preparing the ritual...'}
              ButtonTextError={"Couldn't sacrifice the Hen"}
              StartAlertText={'Sharpening Knives!'}
              StartAlertOptions={{ icon: '🔪' }}
              SuccessAlertText={'Rainbow EGG is being delivered!'}
              SuccessAlertOptions={{ icon: '🌈' }}
              imageWaitTx={HenSacrificedStart}
            />
            <>
              {userSilverEggBalance > 0 &&
                tfgSupplyData &&
                tfgSupplyData?.totalSupply > tfgSupplyData?.maxGen0Supply && (
                  <ButtonContractWrite
                    ContractAddress={ContractAddresses.TheFarmGamePlayContractAddress}
                    ContractABI={ContractABIs.TheFarmGamePlayABI}
                    ContractFunction='upgradeAdvantage'
                    ContractArgs={[tokenId]}
                    ContractOverRides={{}}
                    ContractIsEnabled={true}
                    ButtonTextPrepareIdle={`Checking your Silver Egg Stash`}
                    ButtonTextPrepareLoading={`Preparing`}
                    ButtonTextInitial={'Upgrade for 1 Silver Egg'}
                    ButtonTextLoading={'Waiting for approval'}
                    ButtonTextWriting={'Preparing the blessing...'}
                    ButtonTextError={"Couldn't upgrade the Hen"}
                    StartAlertText={'Cracking the silver!'}
                    StartAlertOptions={{ icon: '💫' }}
                    SuccessAlertText={'Hen upgraded!'}
                    SuccessAlertOptions={{ icon: '🌟' }}
                  />
                )}
            </>
          </>
        )}
        {tokenKind === 'COYOTE' &&
          isStakeable &&
          userRainbowEggBalance > 0 &&
          tfgSupplyData &&
          tfgSupplyData?.totalSupply > tfgSupplyData?.maxGen0Supply && (
            <ButtonContractWrite
              ContractAddress={ContractAddresses.TheFarmGamePlayContractAddress}
              ContractABI={ContractABIs.TheFarmGamePlayABI}
              ContractFunction='upgradeAdvantage'
              ContractArgs={[tokenId]}
              ContractOverRides={{}}
              ContractIsEnabled={true}
              ButtonTextPrepareIdle={`Checking your Egg Stash`}
              ButtonTextPrepareLoading={`Preparing`}
              ButtonTextInitial={'Upgrade for 1 Rainbow Egg'}
              ButtonTextLoading={'Waiting for approval'}
              ButtonTextWriting={'Preparing the blessing...'}
              ButtonTextError={"Couldn't upgrade the Coyote"}
              StartAlertText={'Cracking the rainbow!'}
              StartAlertOptions={{ icon: '💫' }}
              SuccessAlertText={'Coyote upgraded!'}
              SuccessAlertOptions={{ icon: '🌟' }}
              imageWaitTx={RainbowEggBreakingStart}
            />
          )}
        <p />
        {changeNameFee && changeNameFee > 0 && (isStakeable || isStaked) && (
          <>
            <Row>
              <Col xs={18}>Change name fee: {changeNameFee}</Col>
            </Row>
            <Row>
              <Col xs={10} md={4}>
                <Form.Control
                  style={{ width: '200px', borderRadius: 0 }}
                  type='text'
                  placeholder='New name'
                  onChange={(e) => {
                    handleName(e.target.value)
                  }}
                />
              </Col>
              <Col xs={8} md={4}>
                <ButtonContractWrite
                  style={{
                    fontSize: '14px',
                    height: '35px',
                    lineHeight: 'normal',
                    paddingTop: '10px',
                    marginBottom: '5px',
                  }}
                  ContractAddress={ContractAddresses.FarmAnimalsTraitsContractAddress}
                  ContractABI={ContractABIs.FarmAnimalsTraitsABI}
                  ContractFunction='changeName'
                  ContractArgs={[tokenId, newName]}
                  ContractOverRides={''}
                  ContractIsEnabled={newName.length > 3}
                  ButtonTextPrepareIdle={`<-- What name`}
                  ButtonTextPrepareLoading={`Preparing`}
                  ButtonTextInitial={`Change name ${changeNameFee} EGG`}
                  ButtonTextLoading={'Waiting for approval'}
                  ButtonTextWriting={'Putting in the paperwork...'}
                  ButtonTextError={"Couldn't change your name"}
                  StartAlertText={'Calling the Rancher!'}
                  StartAlertOptions={{ icon: '📱' }}
                  SuccessAlertText={'Name change approved!'}
                  SuccessAlertOptions={{ icon: '🔖' }}
                />
              </Col>
            </Row>
          </>
        )}
        {changeDescFee && changeDescFee > 0 && (isStakeable || isStaked) && (
          <>
            <Row>
              <Col xs={18}>Change Description fee: {changeDescFee}</Col>
            </Row>
            <Row>
              <Col xs={10} md={4}>
                <Form.Control
                  style={{ width: '200px', borderRadius: 0 }}
                  type='text'
                  placeholder='New Description'
                  onChange={(e) => {
                    handleDesc(e.target.value)
                  }}
                />
              </Col>
              <Col xs={8} md={4}>
                <ButtonContractWrite
                  style={{
                    fontSize: '14px',
                    height: '35px',
                    lineHeight: 'normal',
                    paddingTop: '10px',
                    marginBottom: '5px',
                  }}
                  ContractAddress={ContractAddresses.FarmAnimalsTraitsContractAddress}
                  ContractABI={ContractABIs.FarmAnimalsTraitsABI}
                  ContractFunction='changeDesc'
                  ContractArgs={[tokenId, newDesc]}
                  ContractOverRides={''}
                  ContractIsEnabled={newDesc.length > 3}
                  ButtonTextPrepareIdle={`<-- Make a bio`}
                  ButtonTextPrepareLoading={`Preparing`}
                  ButtonTextInitial={`Change description ${changeDescFee} EGG`}
                  ButtonTextLoading={'Waiting for approval'}
                  ButtonTextWriting={'Consulting copywriters...'}
                  ButtonTextError={"Couldn't change description"}
                  StartAlertText={'Calling news outlets!'}
                  StartAlertOptions={{ icon: '📰' }}
                  SuccessAlertText={'Description updated!'}
                  SuccessAlertOptions={{ icon: '📜' }}
                />
              </Col>
            </Row>
          </>
        )}
        {changeBGFee && changeBGFee > 0 && (isStakeable || isStaked) && (
          <>
            <Row>
              <Col xs={18}>Change background color fee: {changeBGFee}</Col>
            </Row>
            <Row>
              <Col xs={10} md={4}>
                <Form.Control
                  style={{ width: '200px', borderRadius: 0 }}
                  type='text'
                  placeholder='New Background Color'
                  onChange={(e) => {
                    handleBGColor(e.target.value)
                  }}
                />
              </Col>
              <Col xs={8} md={4}>
                <ButtonContractWrite
                  style={{
                    fontSize: '14px',
                    height: '35px',
                    lineHeight: 'normal',
                    paddingTope: '10px',
                    marginBottom: '5px',
                  }}
                  ContractAddress={ContractAddresses.FarmAnimalsTraitsContractAddress}
                  ContractABI={ContractABIs.FarmAnimalsTraitsABI}
                  ContractFunction='changeBGColor'
                  ContractArgs={[tokenId, newBGColor]}
                  ContractOverRides={''}
                  ContractIsEnabled={newBGColor.length === 6}
                  ButtonTextPrepareIdle={`<-- Change hex color`}
                  ButtonTextPrepareLoading={`Preparing`}
                  ButtonTextInitial={`Change BG color ${changeBGFee} EGG`}
                  ButtonTextLoading={'Waiting for approval'}
                  ButtonTextWriting={'Getting the paint bucket...'}
                  ButtonTextError={"Couldn't change background color"}
                  StartAlertText={'Stirring the paint!'}
                  StartAlertOptions={{ icon: '🎨' }}
                  SuccessAlertText={'That looks great!'}
                  SuccessAlertOptions={{ icon: '🖼' }}
                />
              </Col>
            </Row>
          </>
        )}
        <p />
        {/* Share {tokenMetadata?.name} with the world? */}
      </Modal.Body>
      {/* // TODO: Enable for mainnet */}
      {/* <Modal.Footer>
        <Link className='nav-link' to={`/tokens/${ContractAddresses.FarmAnimalsContractAddress}/${tokenId}`}>
          View
        </Link>
        <Button variant='secondary' onClick={handleClose}>
          Not telling anyone
        </Button>
        <Button variant='primary' onClick={handleTweet}>
          Share {tokenMetadata?.name} via Tweet
        </Button>
      </Modal.Footer> */}
    </Modal>
  )
}

export default FarmAnimalNFTModal
