/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'

import StatsBoard from '../../components/StatsBoard'

function StatsPage(): JSX.Element {
  return (
    <div>
      <StatsBoard />
    </div>
  )
}

export default StatsPage
