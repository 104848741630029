import React, { createContext } from 'react'

const EGGTokenContext = createContext({
  name: 'EGG Token',
  symbol: 'EGG',
  totalSupply: 4000000000,
  totalMinted: 0,
  totalBurned: 0,
})

export default EGGTokenContext
