/**
 * Order an array of objects by another array.
 * @param  {array} array The array of objects to sort.
 * @param  {array} order The array of property names to order the objects by.
 * @param  {string} property The property name to use as a sorting target.
 */
export default function mapOrder(array: any[], order: any[], property: string) {
  let ordered: any[] = [],
    unordered: any[] = []

  // Iterate over each item in the supplied array of objects, separating ordered and unordered objects into their own arrays.
  array.forEach((item) => {
    if (order.indexOf(item[property]) === -1) {
      unordered.push(item)
    } else {
      ordered.push(item)
    }
  })

  // Sort the ordered array.
  ordered.sort((a, b) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    ;(a = a[property]), (b = b[property])

    if (order.indexOf(a) < order.indexOf(b)) {
      return -1
    } else {
      return 1
    }
  })

  // Sort the unordered array.
  unordered.sort((a, b) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    ;(a = a[property]), (b = b[property])

    if (a < b) {
      return -1
    } else if (a > b) {
      return 1
    } else {
      return 0
    }
  })

  // Append the sorted, non-ordered array to the sorted, ordered array.
  ordered.push(...unordered)

  return ordered
}
