import React from 'react'

import './style.scss'

import RoadmapImg from '../../assets/images/roadmap.gif'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const Roadmap = () => {
  return (
    <section id='roadmap' className='roadmap--section'>
      <div className='container'>
        <div className='row m4'>
          <div className='col-12'>
            <div className='sec__head roadmap__head'>
              <h1>Roadmap</h1>
            </div>
          </div>
          <div className='roadmap__img d-none img-fluid d-sm-block p4'>
            <img src={RoadmapImg} className='img-fluid' alt='Roadmap' />
          </div>
          <div className='row justify-content-between align-items-start m4'>
            <div className='col-5 col-lg-6 col-sm-12 col-12'>
              <div className='roadmap__wrap'>
                <div className='roadmap__main'>
                  <div className='box__head'>
                    <h3>Sale-Phase</h3>
                  </div>
                  <div className='roadmap__box'>
                    <div className='box__num'>
                      <h3>01</h3>
                    </div>
                    <div className='box__text'>
                      <h3>Grow Together</h3>
                      <p>
                        Follow use on
                        <a className='faq-button' href='https://twitter.com/the_farm_game' target='blank'>
                          Twitter
                        </a>
                      </p>
                      <p>Ongoing contests &amp; raffles in the farm community</p>
                    </div>
                  </div>
                  <div className='roadmap__box'>
                    <div className='box__num'>
                      <h3>02</h3>
                    </div>
                    <div className='box__text'>
                      <h3>Every 1500 sales 5 winners</h3>
                      <p>During the sale, every 1500 sales we will pick 5 Discord members and gift 1 NFT to them</p>
                    </div>
                  </div>
                  <div className='roadmap__box'>
                    <div className='box__num'>
                      <h3>03</h3>
                    </div>
                    <div className='box__text'>
                      <h3>Farm DAO Treasury</h3>
                      <p>
                        Create to the Farm DAO Treasury. Proposal can be voted on by NFT holders to fund other projects,
                        DeFi, charity, or purchasing other NFTs.
                      </p>
                    </div>
                  </div>
                  <div className='roadmap__box'>
                    <div className='box__num'>
                      <h3>04</h3>
                    </div>
                    <div className='box__text'>
                      <h3>Liquidity</h3>
                      <p>Liquidity pool of EGG/ETH is auto-created</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-5 col-lg-6 col-sm-12 col-12'>
              <div className='roadmap__wrap last__child'>
                <div className='roadmap__main second__child'>
                  <div className='box__head'>
                    <h3>Post-Phase</h3>
                  </div>
                  <div className='roadmap__box'>
                    <div className='box__num'>
                      <h3>01</h3>
                    </div>
                    <div className='box__text'>
                      <h3>May the best Hen win</h3>
                      <p>The game opens instantly, stake your Hens and start collecting EGG</p>
                    </div>
                  </div>
                  <div className='roadmap__box'>
                    <div className='box__num'>
                      <h3>02</h3>
                    </div>
                    <div className='box__text'>
                      <h3>Governance</h3>
                      <p>Open the governance for DAO proposals</p>
                    </div>
                  </div>
                  <div className='roadmap__box'>
                    <div className='box__num'>
                      <h3>03</h3>
                    </div>
                    <div className='box__text'>
                      <h3>Season 2</h3>
                      <p>Devs will begin working on Farmland, Ranchers &amp; Breeding</p>
                    </div>
                  </div>
                  <div className='roadmap__box'>
                    <div className='box__num'>
                      <h3>++</h3>
                    </div>
                    <div className='box__text'>
                      <h3>Future plans &amp; seasons</h3>
                      <ul>
                        <li>
                          <p>NFTx.io Liquidity Pool</p>
                        </li>
                        <li>
                          <p>L2 w/ EGG Liquidity Pools &amp; Farming</p>
                        </li>
                        <li>
                          <p>PVP</p>
                        </li>
                        <li>
                          <p>Farm-conomy</p>
                        </li>
                        <li>
                          <p>On-Chain &amp; Discord Mini Games</p>
                        </li>
                        <li>
                          <p>Artist will begin working on the Voxels</p>
                          <p>Gen 0 holders can claim Voxel in Q4-2022</p>
                        </li>
                      </ul>
                      <p>and much more...</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Roadmap
