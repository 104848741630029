import React from 'react'

import { ConnectButton } from '@rainbow-me/rainbowkit'
import { useAccount } from 'wagmi'

import { TFGSalesDataProps } from '../../types'

import MintPausedImg from '../../assets/images/minting_animations/mint_paused.gif'

interface PropTypes {
  TFGSalesData: TFGSalesDataProps
}

const MintPaused = (props: PropTypes): JSX.Element => {
  const { TFGSalesData } = props

  const { isConnected } = useAccount()

  if (!TFGSalesData) {
    return (
      <div className='loading--section'>
        <h1>Minting Loading...</h1>
      </div>
    )
  }

  return (
    <div className='col-xl-5 col-lg-6'>
      <div className='tab__card'>
        <div className='tab__text'>
          {/* <h1>MINTING {TFGSalesData?.saleStatus}</h1> */}
          <img src={MintPausedImg} className='img-fluid' alt='Loading' />
          <br />
          <br />
        </div>

        <div className='btn__box'>
          {!isConnected && (
            <div className='btn__box'>
              <div className='btn__card btn__anchor'>
                <ConnectButton chainStatus='none' accountStatus='avatar' showBalance={false} />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default MintPaused
