import { TFGContractAddresses } from '../types'
import _ from 'lodash'
import deployments from '../deployments.json'
import { useNetwork } from 'wagmi'

export function useContracts() {
  const { chain } = useNetwork()

  let contractAddresses = {}

  if (chain) {
    const currentDeployment = _.pick(deployments, [chain?.id])
    let deploymentValues = Object.values(currentDeployment) // "plain value"

    if (deploymentValues.length) {
      deploymentValues = deploymentValues[0]

      contractAddresses = {
        EGGTokenContractAddress: deploymentValues[0].contracts.EGGToken?.address.toLowerCase() || '',
        EggShopContractAddress: deploymentValues[0].contracts.EggShop?.address.toLowerCase() || '',
        FarmAnimalsContractAddress: deploymentValues[0].contracts.FarmAnimals?.address.toLowerCase() || '',
        FarmAnimalsTraitsContractAddress: deploymentValues[0].contracts.FarmAnimalsTraits?.address.toLowerCase() || '',
        HenHouseAdvantageContractAddress: deploymentValues[0].contracts.HenHouseAdvantage?.address.toLowerCase() || '',
        HenHouseCalcContractAddress: deploymentValues[0].contracts.HenHouseCalc?.address.toLowerCase() || '',
        HenHouseContractAddress: deploymentValues[0].contracts.HenHouse?.address.toLowerCase() || '',
        ImperialEggsContractAddress: deploymentValues[0].contracts.ImperialEggs?.address.toLowerCase() || '',
        SpecialMintContractAddress: deploymentValues[0].contracts.SpecialMint?.address.toLowerCase() || '',
        TheFarmGameMintContractAddress: deploymentValues[0].contracts.TheFarmGameMint?.address.toLowerCase() || '',
        TheFarmGamePlayContractAddress: deploymentValues[0].contracts.TheFarmGamePlay?.address.toLowerCase() || '',
        TheFarmDaoExecutorContractAddress:
          deploymentValues[0].contracts.TheFarmDAOExecutor?.address.toLowerCase() || '',
      }
    }
  }

  return contractAddresses as TFGContractAddresses
}
