import React, { useState } from 'react'

import { useAccount, useContractRead } from 'wagmi'
import numbro from 'numbro'

import { ethers } from 'ethers'

interface PropTypes {
  tokenId: number
  stakedContractAddress: string
  stakedContractABI: any
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const NFTUnClaimedBalance = (props: PropTypes): JSX.Element => {
  const { tokenId, stakedContractAddress, stakedContractABI } = props

  const { address } = useAccount()

  const [tokenEGGBalance, setTokenEGGBalance] = useState<any>()

  const stakedContractConfig = {
    address: stakedContractAddress,
    abi: stakedContractABI,
  }

  useContractRead({
    ...stakedContractConfig,
    functionName: 'calculateRewards',
    args: [tokenId],
    watch: true,
    cacheTime: 30_000,
    overrides: { from: address },
    isDataEqual: (prev, next) => prev === next,
    onSuccess(data) {
      setTokenEGGBalance(
        numbro(parseFloat(ethers.utils.formatEther(data))).format({
          average: false,
          mantissa: 2,
        }),
      )
    },
    onError(error) {
      console.error(`NFTUnClaimedBalance calculateRewards Error tokenId: ${tokenId}`, error)
    },
  })

  return (
    <div>
      <p style={{ fontSize: 14, letterSpacing: '0.05em' }}>{tokenEGGBalance ? `${tokenEGGBalance} 🥚` : `🥚...`}</p>
    </div>
  )
}

export default NFTUnClaimedBalance
